import React, { memo } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { useSeenWelcome } from "@/store/membership/membership.hooks";

import Icon from "@/components/Icon";
import HighlightMembership from "@/components/HighlightMembership";
import Typography from "@/components/Typography";

import { useQuery } from "@/hooks/Router";

// =============================================================================
// Root
// =============================================================================
const MembershipMenuIdentity = ({ name = "", orders, ntc, nrc, achievements }) => {
	const { t } = useTranslation();
	const query = useQuery();
	const qForm = query.get("form") || "rise";

	const direction = qForm === "vertical" ? "vh" : "vw";

	const size = qForm === "vertical" ? 2 : 1.4067;
	const grid = [0, size, size * 2, size * 3, size * 4, size * 5, size * 6];
	const keyframes = [];
	for (let i = 0; i <= 1; i += 0.02) {
		keyframes.push(i.toFixed(2));
	}

	return (
		<div
			className={cn("u-flex", {
				"u-ai-center": qForm === "vertical",
				"u-ai-start": qForm !== "vertical",
			})}
			style={
				qForm === "vertical"
					? {
							position: "absolute",
							top: "40%",
							left: "50%",
							transform: "translate(-50%, -50%)",
					  }
					: null
			}
		>
			<div className="u-flex u-ai-end">
				<Icon
					className={qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"}
					type="profile"
				/>
			</div>

			<div className="u-flex u-ai-end">
				<HighlightMembership
					className={cn("s-dark no-absolute")}
					title={name}
					delay={0.1}
					endDelay={0}
					grid={grid}
					typo={
						qForm === "vertical"
							? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
							: null
					}
					end="block"
					initial={[0, 1]}
					animate={[0, 1]}
					clipPath={[
						"inset(0px 100% 0px 0px)",
						"inset(0px 100% 0px 0px)",
						"inset(0px 100% 0px 0px)",
						"inset(0px 100% 0px 0px)",
						"inset(0px 100% 0px 0px)",
						"inset(0px 100% 0px 0px)",
						"inset(0px 100% 0px 0px)",
						"inset(0px 100% 0px 0px)",
						"inset(0px 100% 0px 0px)",
						"inset(0px 100% 0px 0px)",
						"inset(0px 80% 0px 0px)",
						"inset(0px 60% 0px 0px)",
						"inset(0px 40% 0px 0px)",
						"inset(0px 20% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
						"inset(0px 0% 0px 0px)",
					]}
				/>

				{(orders > 0 || achievements > 0) && (
					<motion.div
						initial={{ display: "none" }}
						animate={{ display: "block" }}
						transition={{ ease: [0, 1, 0, 1], delay: 0.2, duration: 0.2, times: keyframes }}
					>
						<Icon
							className={
								qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
							}
							type="swoosh"
						/>
					</motion.div>
				)}
				{nrc.length > 0 && (
					<motion.div
						initial={{ display: "none", y: `${grid[1]}${direction}` }}
						animate={{ display: "block" }}
						transition={{ ease: [0, 1, 0, 1], delay: 0.4, duration: 0.2, times: keyframes }}
					>
						<Icon
							className={
								qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
							}
							type="nrc-black"
						/>
					</motion.div>
				)}
				{ntc.length > 0 && (
					<motion.div
						initial={{ display: "none" }}
						animate={{ display: "block" }}
						transition={{ ease: [0, 1, 0, 1], delay: 0.6, duration: 0.2, times: keyframes }}
					>
						<Icon
							className={
								qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
							}
							type="ntc-white"
						/>
					</motion.div>
				)}
			</div>
		</div>
	);
};

export default memo(MembershipMenuIdentity);
