import React, { forwardRef, memo } from "react";
import cn from "classnames";
import { useQuery } from "@/hooks/Router";

import { isLangChinese, isLangKorean } from "@/utils/i18n";
import { pixels } from "@/utils/math";
import { fontSizeToPixel, lineHeightToPixel, isLandscape } from "@/constants/pixel-sizes";

import propTypes from "./Typography.propTypes";
import defaultProps from "./Typography.defaultProps";
import { isNil } from "ramda";

const Typography = forwardRef(
	(
		{
			className,
			children,
			tag: Tag,
			primary,
			weight,
			size,
			uppercase,
			lineHeight,
			typeStyle,
			lineHeightSize,
			forceBold,
		},
		ref
	) => {
		const query = useQuery();

		const weightClassName = `u-font-w-${
			((isLangKorean() || isLangChinese()) && primary) || forceBold ? "bold" : weight
		}`;
		const fontFamilyClassName = primary ? "u-font-f-primary" : "u-font-f-secondary";
		const uppercaseClassName = uppercase || primary ? "u-text-up" : "";
		const rootClassName = cn(className, fontFamilyClassName, uppercaseClassName, weightClassName);
		const qForm = query.get("form") || "rise";
		const font = pixels(fontSizeToPixel[size], isLandscape[qForm]);
		const line = lineHeight ? pixels(lineHeightToPixel[lineHeightSize || size], isLandscape[qForm]) : 0;
		let style = typeStyle || {};
		if (isNil(style.fontSize)) style = { ...style, fontSize: font };
		if (isNil(style.lineHeight)) style = { ...style, lineHeight: line };

		return (
			<Tag ref={ref} className={rootClassName} style={style}>
				{children}
			</Tag>
		);
	}
);

Typography.propTypes = propTypes;
Typography.defaultProps = defaultProps;

export default memo(Typography);
