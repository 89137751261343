import React, { memo, useEffect, useState } from "react";
import cn from "classnames";
import { motion } from "framer-motion";

import Typography from "@/components/Typography";
import Codification from "@/components/Codification";

import { useLowGPU } from "@/store/ui/ui.hooks";

import propTypes from "./HighlightMembership.propTypes";
import defaultProps from "./HighlightMembership.defaultProps";
import { useQuery } from "@/hooks/Router";

// =============================================================================
// Root
// =============================================================================
const HighlightMembership = ({
	className = "",
	title,
	delay,
	grid,
	isVisible = true,
	initial,
	animate,
	clipPath,
	endDelay = 0,
	typo = null,
	end = "none",
}) => {
	const [isVisibleState, setIsVisibleState] = useState(isVisible);
	const lowGPU = useLowGPU();
	const query = useQuery();
	const qForm = query.get("form") || "rise";

	const direction = qForm === "vertical" ? "vh" : "vw";

	const typography = typo ?? { primary: true, size: "smmd", tag: "h2", uppercase: true };

	useEffect(() => {
		// Render the background one frame after content has been rendered.
		// In this way, the background knows how big it should become according to the size of the content.
		// When in doubt, setTimeout();
		const timer = setTimeout(() => {
			setIsVisibleState(isVisible);
		}, 1000);

		return () => {
			clearTimeout(timer);
		};
	}, [isVisible]);

	return (
		<div
			className={cn("c-highlight c-highlight-membership", className, {
				"is-hidden": !isVisibleState,
			})}
		>
			{isVisibleState && (
				<>
					<motion.div
						className="c-highlight__bg"
						initial={{
							width: "0%",
							x: `${grid[initial[0]]}${direction}`,
							y: `${grid[initial[1]]}${direction}`,
							display: "none",
						}}
						animate={{
							width: "100%",
							x: `${grid[animate[0]]}${direction}`,
							y: `${grid[animate[1]]}${direction}`,
							display: "block",
						}}
						transition={{ ease: [0, 1, 0, 1], delay }}
					/>
					<motion.div
						className="c-highlight__bg hidden"
						initial={{
							x: `${grid[initial[0]]}${direction}`,
							y: `${grid[initial[1]]}${direction}`,
							visibility: "hidden",
						}}
						animate={{
							x: `${grid[animate[0]]}${direction}`,
							y: `${grid[animate[1]]}${direction}`,
						}}
						transition={{ ease: [0, 1, 0, 1], delay }}
					/>
				</>
			)}

			{title && isVisible && (
				<>
					<motion.div
						initial={{
							clipPath: "inset(0px 100% 0px 0px)",
							x: `${grid[initial[0]]}${direction}`,
							y: `${grid[initial[1]]}${direction}`,
							display: "none",
						}}
						animate={{
							clipPath,
							x: [
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
							],
							y: `${grid[animate[1]]}${direction}`,
							display: "block",
							transition: {
								delay,
								duration: 2 + endDelay,
								ease: [0, 1, 0, 1],
							},
							transitionEnd: { display: end },
						}}
						className="c-highlight__title"
					>
						<div className="c-highlight__title-codification">
							<Codification text={title} typography={typography} lowGPU={lowGPU} isStatic />
						</div>

						<Typography className="c-highlight__title-mask" primary size="smmd" tag="h2" uppercase>
							<Codification text={title} typography={typography} lowGPU={lowGPU} isStatic />
						</Typography>
					</motion.div>
					<motion.div
						initial={{
							x: `${grid[initial[0]]}${direction}`,
							y: `${grid[initial[1]]}${direction}`,
							visibility: "hidden",
						}}
						animate={{
							x: [
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
								`${grid[animate[0]]}${direction}`,
							],
							y: `${grid[animate[1]]}${direction}`,
							transition: {
								delay,
								duration: 2 + endDelay,
								ease: [0, 1, 0, 1],
							},
							transitionEnd: { display: end },
						}}
						className="c-highlight__title hidden"
					>
						<div className="c-highlight__title-codification hidden">
							<Codification text={title} typography={typography} lowGPU={lowGPU} isStatic />
						</div>

						<Typography className="c-highlight__title-mask hidden" primary size="smmd" tag="h2" uppercase>
							<Codification text={title} typography={typography} lowGPU={lowGPU} isStatic />
						</Typography>
					</motion.div>
				</>
			)}
		</div>
	);
};

HighlightMembership.propTypes = propTypes;
HighlightMembership.defaultProps = defaultProps;

export default memo(HighlightMembership);
