import React from "react";
import cn from "classnames";
import defaultProps from "./Image.defaultProps";
import propTypes from "./Image.propTypes";

// =============================================================================
// Root
// =============================================================================
const Image = ({ className = "", children, src, alt, imageClassName }) => {
	return (
		<div className={cn("c-image", className)}>
			{children || <Visual src={src} alt={alt} className={imageClassName} />}
		</div>
	);
};

Image.propTypes = propTypes.Image;

Image.defaultProps = defaultProps.Image;

// =============================================================================
// Children
// =============================================================================
const Visual = ({ className = "", src, alt }) => {
	return <img draggable="false" className={cn("c-image__visual", className)} src={src} alt={alt} />;
};

Visual.propTypes = propTypes.visual;

Visual.defaultProps = defaultProps.Visual;

Image.Visual = Visual;

export default Image;
