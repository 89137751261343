import { DEFAULT_EASE, DEFAULT_DURATION } from "@/constants/transition";

const moveContent = {
	default: {
		y: "0vw",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
			delay: 0.3,
		},
	},

	bottom: {
		y: `${200 * 0.0521}vw`,
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
			delay: 0.3,
		},
	},

	verticalTop: {
		y: `${90 * 0.0521}vh`,
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
			delay: 0.3,
		},
	},

	verticalBottom: {
		y: `${522 * 0.0521}vh`,
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
			delay: 0.3,
		},
	},

	horizontalTop: {
		y: `${85 * 0.0521}vw`,
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
			delay: 0.3,
		},
	},

	horizontalBottom: {
		y: `${422 * 0.0521}vw`,
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
			delay: 0.3,
		},
	},

	verticalCompareTop: {
		y: `${40 * 0.0521}vh`,
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
			delay: 0.3,
		},
	},

	verticalCompareBottom: {
		y: `${380 * 0.0521}vh`,
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
			delay: 0.3,
		},
	},
};

export default moveContent;
