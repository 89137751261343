import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useAllShoes } from "@/store/shoes/shoes.hooks";
import { useAllFilters, useActiveFilters } from "@/store/filters/filters.hooks";
import { setFilter, clearActiveFilters } from "@/store/filters/filters.actions";

import { FILTER_KEYS } from "@/constants/filters";

import filterShoes from "./FilteredShoes.filter";

export default () => {
	const dispatch = useDispatch();
	const { data: shoes, loading } = useAllShoes();
	const { data: filters } = useAllFilters();
	const activeFilters = useActiveFilters();

	const [filteredShoes, setFilteredShoes] = useState(shoes);

	const handleSetFilter = (key, filterId, runningID, footballID, trainingID) => {
		if (key === "category" && ["recommended"].includes(filterId)) {
			dispatch(clearActiveFilters());
			if (filterId === "recommended") {
				dispatch(setFilter(FILTER_KEYS.CATEGORY, "recommended"));
			}
			return;
		}
		if (key === "category" && ["allcategories"].includes(filterId)) {
			dispatch(setFilter(FILTER_KEYS.CATEGORY, ""));
			dispatch(setFilter(FILTER_KEYS.SURFACE, ""));
			return;
		}
		if (key === "gender" && ["allgenders"].includes(filterId)) {
			dispatch(setFilter(FILTER_KEYS.GENDER, ""));
			return;
		}
		if (key === "activity" && ["allactivities"].includes(filterId)) {
			dispatch(setFilter(FILTER_KEYS.ACTIVITY, ""));
			return;
		}
		if (key === "surface" && ["allsurfaces"].includes(filterId)) {
			dispatch(setFilter(FILTER_KEYS.SURFACE, ""));
			return;
		}
		if (key === "category" && activeFilters.category.activeFilter === trainingID) {
			dispatch(setFilter(FILTER_KEYS.ACTIVITY, ""));
		}
		if (
			key === "category" &&
			(activeFilters.category.activeFilter === runningID || activeFilters.category.activeFilter === footballID)
		) {
			dispatch(setFilter(FILTER_KEYS.SURFACE, ""));
		}
		dispatch(setFilter(key, filterId, runningID));
	};

	useEffect(() => {
		setFilteredShoes(filterShoes(shoes, filters, activeFilters));
	}, [shoes, filters, activeFilters]);

	return {
		filteredShoes,
		filters,
		loading,
		handleSetFilter,
	};
};
