import PropTypes from "prop-types";

const Modal = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
};

const Close = {
	onClose: PropTypes.func,
};

export default {
	Modal,
	Close,
};
