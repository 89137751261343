import { useEffect, useState } from "react";
import { find, isEmpty, isNil, propEq, equals, join } from "ramda";
import { createAnalytics } from "analytics";
import { useTranslation } from "react-i18next";

import { useActiveSession, useAnalyticsTypes, useLatestAction, useAllStores } from "@/store/ui/ui.hooks";
import { useIdentity } from "@/store/membership/membership.hooks";
import { useTableLocation } from "@/store/shoes/shoes.hooks";
import { useAllShoes } from "@/store/shoes/shoes.hooks";

import { useQuery } from "@/hooks/Router";

export default () => {
	const query = useQuery();

	const storeID = query.get("store") || undefined;
	const { data: stores } = useAllStores();
	const activeSession = useActiveSession();
	const tableLocation = useTableLocation();
	const { data: identity } = useIdentity();
	const { data: shoes } = useAllShoes();
	const { i18n } = useTranslation();
	const { data: analyticsTypes } = useAnalyticsTypes();
	const latestAction = useLatestAction();
	let tableID = query.get("table-id") || undefined;
	const qSendAnalytics = equals(query.get("send-analytics"), "true");
	const marketplace = tableLocation.data.json_meta?.inside_track?.marketplace;

	const [client] = useState(
		createAnalytics({
			client_secret: process.env.REACT_APP_ANALYTICS_SECRET,
			endpoint: `${process.env.REACT_APP_ANALYTICS_URL}/ingest`,
			batching: 100,
		})
	);

	const trackNuID = !isEmpty(stores)
		? stores.filter((el) => el.nike_store_id === storeID)[0]?.json_meta?.inside_track?.trackNuID
		: false;

	if (isNil(tableID)) {
		tableID = "ETW Test";
	}

	useEffect(() => {
		if (!isEmpty(latestAction?.type) && qSendAnalytics) {
			const product = shoes.find((el) => el.id === latestAction.firstScc) ?? undefined;
			let related_product = undefined;
			if (latestAction.type === "compare") {
				related_product = shoes.find((el) => el.id === latestAction.secondScc);
			}

			// New Analytics
			if (latestAction.type === "session_start") {
				client.startSession();
			} else if (latestAction.type === "session_end") {
				const events = client.getEvents();
				if (events.length === 2 && events[1].type === "place") {
					events.forEach((e) => {
						e.type = `idle_${e.type}`;
					});
				}
				client.setAttributes({
					environment: process.env.NODE_ENV,
					experience_type: "INSIDE_TRACK",
					store_id: storeID,
					source_client: tableID,
					marketplace,
					language: i18n.language,
				});
				client.endSession();
			} else {
				let joined = [latestAction.firstScc, latestAction.secondScc].sort();
				if (!isNil(joined[1])) joined = joined.join("::");
				else joined = joined.join("");
				client.setEvent({
					type: latestAction.type,
					joined,
					style_color_code: latestAction.firstScc,
					related_style_color_code: latestAction.type === "compare" ? latestAction.secondScc : undefined,
					product: product
						? { scc: product.id, thumbnail: product.thumbnail, name: product.name }
						: undefined,
					related_product: related_product
						? {
								scc: related_product.id,
								thumbnail: related_product.thumbnail,
								name: related_product.name,
						  }
						: undefined,
					member_logged_in: !isEmpty(identity),
					nu_id: trackNuID ? identity?.profile?.nuId : undefined,
				});
			}

			// Old Analytics
			const interactionDefinition = find(propEq("interaction_name", latestAction.type), analyticsTypes)?.id;

			if (!isNil(interactionDefinition) && !isEmpty(activeSession)) {
				const myHeaders = new Headers();
				myHeaders.append("Content-Type", "application/json");

				const raw = JSON.stringify({
					store: storeID,
					source_client: tableID,
					style_color_code: latestAction.firstScc,
					related_style_color_code: latestAction.type === "compare" ? latestAction.secondScc : undefined,
					interaction_definition: interactionDefinition,
					source_session: activeSession ? activeSession : latestAction.activeSession,
					member_logged_in: !isEmpty(identity),
					nu_id: trackNuID ? identity?.profile?.nuId : undefined,
				});

				// console.log("INTERACTION", latestAction.type, raw);

				const requestOptions = {
					method: "POST",
					headers: myHeaders,
					body: raw,
					redirect: "follow",
				};

				fetch(`${process.env.REACT_APP_API_URL}/interaction`, requestOptions)
					.then((response) => {
						if (!response.ok) {
							throw new Error(`HTTP error ${response.status}`);
						}
						return response.json();
					})
					.then((data) => {
						// console.log(data);
					})
					.catch((error) => {
						// console.log(error);
					});
			}
		}
	}, [latestAction.id]);

	useEffect(() => {
		document.addEventListener("contextmenu", (e) => e.preventDefault());

		return () => {
			document.removeEventListener("contextmenu", (e) => e.preventDefault());
		};
	}, []);
};
