import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import GridBlock from "@/components/GridBlock";
import Button, { Button1 } from "@/components/Button";

import propTypes from "./ProductGroupingPartialsBack.propTypes";
import defaultProps from "./ProductGroupingPartialsBack.defaultProps";

const ProductGroupingPartialsBack = ({ id, horizontal, vertical }) => {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<GridBlock
			id={id}
			className={cn("c-block--inset-y u-flex u-col u-pl-6 u-ai-start", {
				"u-jc-center": horizontal || vertical,
				"u-jc-end": !horizontal || !vertical,
			})}
		>
			{horizontal ? (
				<Button1
					className="c-button--rounded c-button--large u-flex u-ai-center u-inline-2 u-interactive"
					onClick={() => history.goBack()}
					icon="chevron-left"
					iconPosition="left"
				>
					{t("buttons.back")}
				</Button1>
			) : (
				<Button
					className="u-inline-flex u-ai-center u-jc-center u-inline-5 c-button--rounded"
					onClick={() => history.goBack()}
				>
					<Button.Icon className="c-icon--size-2" type="chevron-left" />
					<Button.Text>{t("buttons.back")}</Button.Text>
				</Button>
			)}
		</GridBlock>
	);
};

ProductGroupingPartialsBack.propTypes = propTypes;
ProductGroupingPartialsBack.defaultProps = defaultProps;

export default ProductGroupingPartialsBack;
