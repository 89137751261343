import { DEFAULT_EASE, DEFAULT_DURATION } from "@/constants/transition";

export const fadeMove = {
	hidden: {
		opacity: 0,
		y: "-30px",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
	show: {
		opacity: 1,
		y: "0px",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
	exit: {
		opacity: 0,
		position: "absolute",
		y: "30px",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
};

export const fadeMoveKo = {
	hidden: {
		opacity: 0,
		y: "-30px",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
	show: {
		opacity: 1,
		y: "10px",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
	exit: {
		opacity: 0,
		y: "30px",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
};

export const fadeMoveFull = {
	hidden: {
		opacity: 0,
		y: "-100%",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
	show: {
		opacity: 1,
		y: "0%",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
};

export const fadeMoveXFull = {
	hidden: {
		opacity: 0,
		x: "-100%",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},

	show: {
		opacity: 1,
		x: 0,
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
};

export const fadeMoveReverse = {
	hidden: {
		opacity: 0,
		y: "30px",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
	show: {
		opacity: 1,
		y: "0px",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
};

export const fadeMoveStagger = {
	hidden: {
		y: 100,
		opacity: 0,
		transition: {
			duration: DEFAULT_DURATION,
			ease: DEFAULT_EASE,
			delay: DEFAULT_DURATION / 2,
		},
	},
	show: {
		y: 0,
		opacity: 1,
		transition: {
			duration: DEFAULT_DURATION,
			ease: DEFAULT_EASE,
			staggerChildren: 0.1,
			delayChildren: DEFAULT_DURATION / 2,
		},
	},
	exit: {
		y: 30,
		opacity: 0,
		transition: {
			duration: DEFAULT_DURATION / 2,
			ease: DEFAULT_EASE,
		},
	},
};

export const getFadeMoveFull = (options) => ({
	...fadeMoveFull,
	hidden: {
		...fadeMoveFull.hidden,
		transition: {
			...fadeMoveFull.hidden.transition,
			...options,
		},
	},
	show: {
		...fadeMoveFull.show,
		transition: {
			...fadeMoveFull.show.transition,
			...options,
		},
	},
});

export const getFadeMove = (options) => ({
	...fadeMove,
	hidden: {
		...fadeMove.hidden,
		transition: {
			...fadeMove.hidden.transition,
			...options,
		},
	},
	show: {
		...fadeMove.show,
		transition: {
			...fadeMove.show.transition,
			...options,
		},
	},
});

export const getFadeMoveXFull = (options) => ({
	...fadeMoveXFull,
	hidden: {
		...fadeMoveXFull.hidden,
		transition: {
			...fadeMoveXFull.hidden.transition,
			...options,
		},
	},
	show: {
		...fadeMoveXFull.show,
		transition: {
			...fadeMoveXFull.show.transition,
			...options,
		},
	},
});
