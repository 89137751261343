import PropTypes from "prop-types";

export default {
	type: PropTypes.oneOf([
		"arrow",
		"arrow-left",
		"arrow-motion-dl",
		"arrow-motion-ur",
		"arrow-white",
		"baseball-black",
		"baseball-white",
		"basketball-black",
		"basketball-white",
		"black",
		"bubble-caret",
		"callout",
		"caret-up",
		"chevron-right",
		"chevron-left",
		"clock",
		"close",
		"elevation",
		"football-black",
		"football-white",
		"globe-white",
		"golf-black",
		"golf-white",
		"heart",
		"heart-filled",
		"instagram",
		"jumpman-black",
		"jumpman-white",
		"language",
		"minus",
		"nikeapp-white",
		"nrc-black",
		"nrc-white",
		"ntc-black",
		"ntc-white",
		"offline",
		"options",
		"options-menu",
		"phone-scan",
		"plus",
		"profile",
		"profile-white",
		"qr-code",
		"qr-code-black",
		"reload",
		"refresh",
		"running-shoe-black",
		"scan-qr-code",
		"skate-black",
		"skate-white",
		"shoe-black",
		"shoe-place",
		"shoe-white",
		"signout-black",
		"soccer-black",
		"soccer-white",
		"star",
		"swoosh",
		"tennis-black",
		"tennis-white",
	]),
};
