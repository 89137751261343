import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isNil, pathOr } from "ramda";

import ROUTES from "@/constants/routes";
import { SIDE } from "@/constants/rfid-tags";

import Codification from "@/components/Codification";
import ReviewStars from "@/components/ReviewStars";
import ButtonIcon from "@/components/ButtonIcon";
import Block from "@/components/Block";
import Typography from "@/components/Typography";

import { updateVisible } from "@/store/shoes/shoes.actions";
import { useActiveShoes } from "@/store/shoes/shoes.hooks";
import { useLowGPU } from "@/store/ui/ui.hooks";

const ProductReviews = ({ activeShoe, direction }) => {
	const { t } = useTranslation();
	const lowGPU = useLowGPU();
	const history = useHistory();
	const dispatch = useDispatch();
	const { left, right } = useActiveShoes();

	const handleReviewsPage = (direction) => {
		if (!isNil(left) && !isNil(right))
			dispatch(updateVisible(false, direction === SIDE.Right ? SIDE.Left : SIDE.Right));
		dispatch(updateVisible(true, direction));
		history.push({ pathname: ROUTES.Reviews, search: location.search });
	};

	return (
		<Block
			onClick={
				!isNil(pathOr(null, ["feedback", "reviews", 0], activeShoe)) ? () => handleReviewsPage(direction) : null
			}
			className="c-block--inset c-block--floating-content"
		>
			<Block.Header>
				<Typography tag="p" size="2xs">
					{activeShoe.feedback.reviews.length} {t("generic.reviews")}
				</Typography>
			</Block.Header>

			<Block.Content className="u-flex u-jc-center u-ai-center u-text-c">
				{activeShoe.feedback && (
					<Codification
						text={
							activeShoe.feedback.avg_rating
								? `${activeShoe.feedback.avg_rating} / 5`
								: t("generic.no_reviews")
						}
						typography={{
							primary: true,
							size: "3xl",
							tag: "span",
						}}
						characterSwitchAmount={4}
						characterNextTrigger={2}
						characterTimeout={20}
						lowGPU={lowGPU}
					/>
				)}
			</Block.Content>

			<Block.Footer className="u-flex u-jc-between u-ac-end">
				<ReviewStars className="u-ai-end" rating={activeShoe.feedback.avg_rating} size={3} space={1} />

				{!isNil(pathOr(null, ["feedback", "reviews", 0], activeShoe)) && (
					<ButtonIcon className="c-icon--size-3" icon="plus" />
				)}
			</Block.Footer>
		</Block>
	);
};

export default React.memo(ProductReviews);
