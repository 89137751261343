import { pathOr, isEmpty, isNil } from "ramda";

const getPulseData = (data, dataItem) => {
	const specPriority = ["franchise", "technology", "all_products"];
	let specs = [];
	let index = 0;
	while (isEmpty(specs)) {
		if (isNil(specPriority[index])) {
			specs = [];
			break;
		}
		specs = data.productGroupings.filter((item) => item.meta.type === specPriority[index])[0];
		specs = specs ? specs.specs : [];
		index += 1;
	}

	if (!isNil(specs)) {
		for (let i = 0; i < specs.length; i += 1) {
			const pulseData = pathOr(null, [i, "nrc", dataItem], specs);

			if (pulseData) {
				return pulseData.toString();
			}
		}
	}
	return null;
};

export default getPulseData;
