import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Button from "@/components/Button";

import { useFlippedOrientation } from "@/store/ui/ui.hooks";
import { setFlippedOrientation } from "@/store/ui/ui.actions";

import { DEFAULT_DURATION } from "@/constants/transition";
import { getMoveY, rotateInfinite } from "@/animations";

import defaultProps from "./TopBar.defaultProps";
import propTypes from "./TopBar.propTypes";
import { useQuery } from "@/hooks/Router";

// =============================================================================
// Root
// =============================================================================
const TopBar = ({ className = "", flipVisible }) => {
	const query = useQuery();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isFlipped = useFlippedOrientation();

	const qForm = query.get("form") || "rise";

	const handleOrientation = () => {
		dispatch(setFlippedOrientation(!isFlipped));
	};

	return (
		<AnimatePresence>
			<motion.div
				initial="hidden"
				animate="show"
				transition={getMoveY({ duration: DEFAULT_DURATION / 2 })}
				className={cn("c-top-bar u-flex u-jc-center u-ai-center", className)}
			>
				{flipVisible && (
					<motion.div initial={{ rotate: "180deg" }} exit={{ scale: 0, opacity: 0 }}>
						<Button
							className="topbar-flip c-button--ghost u-inline-flex u-jc-center u-ai-center u-inline-3 u-p-4"
							onClick={handleOrientation}
						>
							<motion.div animate="show" variants={rotateInfinite}>
								<Button.Icon
									type="refresh"
									className={cn({
										"c-icon--size-8": qForm === "horizontal",
										"c-icon--size-6": qForm === "rise",
									})}
								/>
							</motion.div>

							<Button.Text className="u-pl-2">{t("buttons.orientation")}</Button.Text>
						</Button>
					</motion.div>
				)}
			</motion.div>
		</AnimatePresence>
	);
};

TopBar.propTypes = propTypes.TopBar;
TopBar.defaultProps = defaultProps.TopBar;

export default TopBar;
