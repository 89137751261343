import PropTypes from "prop-types";

const SliderNavigation = {
	slides: PropTypes.number,
	step: PropTypes.number,
	speed: PropTypes.number,
	setCurrentSlide: PropTypes.func,
};

const SliderNavigationItem = {
	i: PropTypes.number,
	step: PropTypes.number,
	speed: PropTypes.number,
	setCurrentSlide: PropTypes.func,
};

export default {
	SliderNavigation,
	SliderNavigationItem,
};
