const Image = {
	src: "",
	alt: "",
};

const Visual = {
	src: "",
	alt: "",
};

export default {
	Image,
	Visual,
};
