import PropTypes from "prop-types";

const ShoeHero = {};

const Background = {
	src: PropTypes.string,
	animation: PropTypes.string,
	speed: PropTypes.number,
	isSmall: PropTypes.bool,
	isUgc: PropTypes.bool,
};

const Video = {
	src: PropTypes.string,
	animation: PropTypes.string,
	speed: PropTypes.number,
};

const Content = {
	animation: PropTypes.string,
};

export default {
	Content,
	Background,
	ShoeHero,
	Video,
};
