import { useSelector } from "react-redux";

import { selectFlippedOrientation } from "@/store/ui/ui.selectors";

import { SIDE } from "@/constants/rfid-tags";

import {
	selectAll,
	selectAllLoading,
	selectAllError,
	selectActive,
	selectIsDetail,
	selectIsCompare,
	selectIsInactive,
	selectLocation,
	selectImagemap,
	selectQuantity,
	selectPrice,
} from "./shoes.selectors";

export const useAllShoes = () => {
	const data = useSelector((state) => selectAll(state));
	const loading = useSelector((state) => selectAllLoading(state));
	const error = useSelector((state) => selectAllError(state));

	return { data, loading, error };
};

export const useActiveShoes = () => {
	const shoes = { ...useSelector((state) => selectActive(state)) };
	const isFlipped = useSelector((state) => selectFlippedOrientation(state));

	if (isFlipped) {
		return { left: shoes.right, right: shoes.left };
	}

	return { right: shoes.right, left: shoes.left };
};

export const useActiveShoe = () => {
	const { left, right } = useActiveShoes();

	if (left && left.detail && left.isVisible) {
		return left;
	}

	if (right && right.detail && right.isVisible) {
		return right;
	}

	return null;
};

export const useIsDetail = () => useSelector((state) => selectIsDetail(state));

export const useIsCompare = () => useSelector((state) => selectIsCompare(state));

export const useIsInactive = () => useSelector((state) => selectIsInactive(state));

export const useTableLocation = () => useSelector((state) => selectLocation(state));

export const useImagemap = () => useSelector((state) => selectImagemap(state));

export const useQtyRight = () => useSelector((state) => selectQuantity(state, SIDE.Right));

export const useQtyLeft = () => useSelector((state) => selectQuantity(state, SIDE.Left));

export const usePriceRight = () => useSelector((state) => selectPrice(state, SIDE.Right));

export const usePriceLeft = () => useSelector((state) => selectPrice(state, SIDE.Left));
