import React, { useEffect, useState } from "react";
import { find, pathOr, propEq, replace, isNil } from "ramda";
import { wrap } from "popmotion";
import { motion } from "framer-motion";

import { useQuery } from "@/hooks/Router";
import useGrid from "@/hooks/Grid";
import { useFlippedOrientation, useLowGPU } from "@/store/ui/ui.hooks";
import { useMembershipActive, useWelcomeRail } from "@/store/membership/membership.hooks";

import groupingHorizontalGrid from "@/assets/grids/groupingHorizontal.json";

import GridBlock from "@/components/GridBlock";
import SliderNavigation from "@/components/SliderNavigation";
import ShoePickerNavigation from "@/components/ShoePickerNavigation";

import SWIPE_TRESHOLD from "@/constants/events";

import swipePower from "@/utils/slider/swipePower";

import { slideFull } from "@/animations";

import propTypes from "./Horizontal.propTypes";
import defaultProps from "./Horizontal.defaultProps";

import {
	ProductGroupingPartialsTitle,
	ProductGroupingPartialsDescription,
	ProductGroupingPartialsBack,
	ProductGroupingPartialsLogo,
	ProductGroupingPartialsImage,
	ProductGroupingPartialsVideo,
} from "../Partials";

const Horizontal = ({ data = {}, allProductGroupings = [], lang }) => {
	const query = useQuery();
	const { isGridLoading, start: startGrid, end: endGrid, shouldRerenderGrid } = useGrid();
	const lowGPU = useLowGPU();
	const isFlipped = useFlippedOrientation();
	const membershipActive = useMembershipActive();
	const welcomeRail = useWelcomeRail();

	const AMOUNT_PER_VIEW = 1;
	const technologies = allProductGroupings.filter((el) => el.assets.length > 2 && el.meta.type === "technology");
	const startPage = technologies.findIndex((el) => el.id === data.id);

	const [[page, direction], setPage] = useState([startPage, 0]);

	const count = technologies.length;
	const totalPages = Math.ceil(count / AMOUNT_PER_VIEW);
	const pageIndex = wrap(0, totalPages, page);
	const start = pageIndex * AMOUNT_PER_VIEW;
	const end = start + AMOUNT_PER_VIEW > count ? count : start + AMOUNT_PER_VIEW;
	data = technologies.slice(start, end)[0];

	const assets = pathOr(null, ["assets"], data);
	const hasVideo = Boolean(find(propEq("media_type", "video"), assets));
	const qCdnUrl = query.get("cdn-url") || null;
	const qForm = query.get("form") || "rise";

	let videoUrl = "";
	if (hasVideo) {
		videoUrl = find(propEq("media_type", "video"), assets).video_url;
		if (!isNil(qCdnUrl)) videoUrl = replace("https://res.cloudinary.com/", qCdnUrl, videoUrl);
		videoUrl = replace(/\/upload\//, `/upload/c_fill,h_960,w_1920,g_auto/`, videoUrl);
	}
	let topLeft = pathOr(null, ["assets", 0, "portrait"], data);
	let topRight = pathOr(null, ["assets", 1, "portrait"], data);
	let bottom = pathOr(null, ["assets", 2, "portrait"], data);
	let logo = null;

	if (
		find((img) => /top_left/.test(img.portrait), assets) &&
		(find((img) => /top_right/.test(img.portrait), assets) || find((img) => /right/.test(img.portrait), assets)) &&
		find((img) => /bottom/.test(img.portrait), assets) &&
		find((img) => /logo/.test(img.portrait), assets)
	) {
		const right =
			find((img) => /top_right/.test(img.portrait), assets) || find((img) => /right/.test(img.portrait), assets);
		topLeft = find((img) => /top_left/.test(img.portrait), assets).portrait;
		topRight = right.portrait;
		bottom = find((img) => /bottom/.test(img.portrait), assets).portrait;
		logo = find((img) => /logo/.test(img.portrait), assets).portrait;
	}

	// #region LIFECYCLE HOOKS
	useEffect(() => {
		endGrid();
		startGrid(groupingHorizontalGrid);
	}, [isGridLoading, page]);
	// #endregion LIFECYCLE HOOKS

	// #region FUNCTIONS
	const handlePage = (newDirection) => {
		let newPage = page + newDirection;

		if (newPage < 0) newPage = totalPages - 1;
		if (newPage > totalPages - 1) newPage = 0;

		setPage([newPage, newDirection]);
	};

	const handleDragEnd = (e, { offset, velocity }) => {
		if (count <= AMOUNT_PER_VIEW) {
			return;
		}

		let swipe = swipePower(offset.x, velocity.x);

		if (isFlipped) {
			swipe = -swipe;
		}

		if (swipe < -SWIPE_TRESHOLD) {
			handlePage(1);
		} else if (swipe > SWIPE_TRESHOLD) {
			handlePage(-1);
		}
	};
	// #endregion FUNCTIONS

	// #region RENDER
	return (
		<>
			<motion.div
				drag="x"
				onDragEnd={handleDragEnd}
				dragElastic={1}
				initial="enter"
				animate="center"
				exit="exit"
				key={page}
				custom={isFlipped ? -direction : direction}
				variants={slideFull}
				transformTemplate={({ x }) => {
					const xTransform = parseFloat(x, 10);
					return `translate3d(${isFlipped ? -xTransform : xTransform}px, 0, 0)`;
				}}
				dragConstraints={{ left: 0, right: 0 }}
				className="u-flex u-fill-height"
				transition={{
					x: { type: "spring", bounce: 0, duration: 0 },
					opacity: { duration: 0.2 },
				}}
			>
				<ProductGroupingPartialsTitle
					id="TechnologyRightMiddle"
					data={data}
					lang={lang}
					lowGPU={lowGPU}
					horizontal={qForm === "horizontal"}
					size="5xl"
				/>

				<ProductGroupingPartialsDescription id="TechnologyRightCorner" data={data} lineHeightSize="sm" />

				<ProductGroupingPartialsLogo id="TechnologyCenterMiddle" data={data} lowGPU={lowGPU} src={logo} />

				{hasVideo ? (
					<ProductGroupingPartialsVideo id="TechnologyLeftPrimary" src={videoUrl} shouldShowBottom />
				) : (
					<ProductGroupingPartialsImage id="TechnologyLeftPrimary" src={topLeft} shouldShowBottom />
				)}

				<ProductGroupingPartialsImage id="TechnologyRightBottom" src={topRight} shouldShowBottom delay={0.15} />

				<ProductGroupingPartialsImage
					id="TechnologyLeftBottom"
					src={bottom}
					delay={0.3}
					horizontal={qForm === "horizontal"}
				/>

				<GridBlock id="TechnologyLeftEmpty" className="u-flex u-jc-center u-ai-center"></GridBlock>
				<GridBlock id="TechnologyCenterMiddleBottom" className="u-flex u-jc-center u-ai-center"></GridBlock>
				<GridBlock id="TechnologyRightTop" className="u-flex u-jc-center u-ai-center"></GridBlock>

				{technologies.length > 1 ? (
					<>
						<GridBlock id="TechnologyNavB" className="u-flex u-jc-center u-ai-center">
							<SliderNavigation
								slides={totalPages}
								speed={0}
								step={page}
								setCurrentSlide={(newPage) => {
									setPage([newPage, direction]);
								}}
							/>
						</GridBlock>
						<GridBlock id="TechnologyNavC" className="u-pl-5 u-flex u-jc-start u-ai-center">
							<ShoePickerNavigation
								className="u-flex-1"
								slides={totalPages}
								speed={0}
								step={page}
								setCurrentSlide={(newPage) => {
									if (newPage > page) handlePage(1);
									else handlePage(-1);
								}}
							/>
						</GridBlock>
					</>
				) : null}
			</motion.div>
			<ProductGroupingPartialsBack id="TechnologyNavA" horizontal={qForm === "horizontal"} />
		</>
	);
	// #endregion RENDER
};

Horizontal.propTypes = propTypes;
Horizontal.defaultProps = defaultProps;

export default Horizontal;
