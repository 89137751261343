import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { equals, isNil, find, propEq, isEmpty } from "ramda";
import cn from "classnames";

import Modal from "@/components/Modal";
import Button from "@/components/Button";
import Typography from "@/components/Typography";

import { LANGUAGES } from "@/constants/language";
import { SIDE } from "@/constants/rfid-tags";

import { useQuery } from "@/hooks/Router";

import { setLanguageModal, setEnabledLanguages, setLowGPU } from "@/store/ui/ui.actions";
import { getAllFilters } from "@/store/filters/filters.actions";
import { getShoeBySCC, setActive, fetchShoeInventory, fetchShoePrice } from "@/store/shoes/shoes.actions";

import { useActiveShoes, useTableLocation, useImagemap } from "@/store/shoes/shoes.hooks";
import { useLanguageModal, useFlippedOrientation, useStoreID, useEnabledLanguages } from "@/store/ui/ui.hooks";
import { useMembershipActive, useIdentity, useStyles } from "@/store/membership/membership.hooks";
import { useFilterIDs } from "@/store/filters/filters.hooks";
import { use404List } from "@/store/rfid/rfid.hooks";

import { getSCC } from "@/utils/shoes";

import setAnalytics from "@/utils/analytics";

// =============================================================================
// Root
// =============================================================================
const LangSelector = () => {
	const query = useQuery();
	const dispatch = useDispatch();
	const isFlipped = useFlippedOrientation();
	const { t, i18n } = useTranslation();
	const { isOpen } = useLanguageModal();
	const { left, right } = useActiveShoes();
	const location = useTableLocation();
	const membershipActive = useMembershipActive();
	const { data: identity } = useIdentity();
	const { data: styles } = useStyles();
	const { data: storeID } = useStoreID();
	const filterIDs = useFilterIDs();
	const imagemap = useImagemap();
	const fourList = use404List();
	const languages = useEnabledLanguages();

	const store = query.get("store") || null;
	const showStock = query.get("show-stock") || false;
	const showPrice = query.get("show-price") || false;
	const qCdnUrl = query.get("cdn-url") || null;
	const qForm = query.get("form") || "rise";

	const replaceShoeData = async () => {
		const leftSCC = getSCC(left, null);
		const rightSCC = getSCC(right, null);
		const city = location.data.json_meta || { sportpulse_id: null };
		const marketplace = location.data.json_meta?.inside_track?.marketplace;

		if (!isNil(leftSCC)) {
			const detail = await dispatch(
				getShoeBySCC(
					leftSCC,
					"cache",
					store,
					city,
					imagemap,
					membershipActive,
					identity,
					filterIDs,
					left.detail.recommendations,
					styles,
					fourList,
					marketplace,
					qCdnUrl
				)
			);
			const updatedLeft = { ...left, detail };

			dispatch(setActive(updatedLeft, isFlipped ? SIDE.Right : SIDE.Left));
			if (showStock && !isEmpty(storeID))
				dispatch(fetchShoeInventory(storeID, leftSCC, isFlipped ? SIDE.Right : SIDE.Left));
			if (showPrice && !isEmpty(storeID))
				dispatch(fetchShoePrice(storeID, leftSCC, isFlipped ? SIDE.Right : SIDE.Left));
		}

		if (!isNil(rightSCC)) {
			const detail = await dispatch(
				getShoeBySCC(
					rightSCC,
					"cache",
					store,
					city,
					imagemap,
					membershipActive,
					identity,
					filterIDs,
					right.detail.recommendations,
					styles,
					fourList,
					marketplace,
					qCdnUrl
				)
			);
			const updatedRight = { ...right, detail };

			dispatch(setActive(updatedRight, isFlipped ? SIDE.Left : SIDE.Right));
			if (showStock && !isEmpty(storeID))
				dispatch(fetchShoeInventory(storeID, rightSCC, isFlipped ? SIDE.Left : SIDE.Right));
			if (showPrice && !isEmpty(storeID))
				dispatch(fetchShoePrice(storeID, rightSCC, isFlipped ? SIDE.Left : SIDE.Right));
		}
	};

	const updateLowGPU = () => {
		let qLowGPU = query.get("low-gpu") || null;

		if (!isNil(qLowGPU) && qLowGPU !== "true") {
			qLowGPU = qLowGPU.split(",");
		}

		if (qLowGPU === "true") {
			dispatch(setLowGPU(true));
		} else if (Array.isArray(qLowGPU)) {
			dispatch(setLowGPU(Boolean(qLowGPU.find((el) => el === i18n.language))));
		}
	};

	const handleChangeLanguage = (lang) => {
		if (equals(i18n.language, lang)) {
			dispatch(setLanguageModal(false));
			return;
		}

		i18n.changeLanguage(lang);
		dispatch(getAllFilters(languages));
		setAnalytics("change_language", { new_language: lang, member: !isEmpty(identity) });
		dispatch(setLanguageModal(false));
		updateLowGPU();
	};

	let enabledLanguages = LANGUAGES;
	let qEnabledLang = query.get("enabled-languages") || null;
	const defaultLang = query.get("default-language") || null;

	if (!isNil(qEnabledLang)) {
		qEnabledLang = qEnabledLang.split(",");
		enabledLanguages = LANGUAGES.filter((lang) => qEnabledLang.includes(lang.CODE));
	}

	useEffect(() => {
		if (enabledLanguages.length === 1) handleChangeLanguage(enabledLanguages[0].CODE);
		else if (!isNil(defaultLang) && find(propEq("CODE", defaultLang))(enabledLanguages)) {
			handleChangeLanguage(defaultLang);
		}
		dispatch(setEnabledLanguages(enabledLanguages));
		updateLowGPU();
	}, []);

	const renderButton = (NATIVE, CODE) => (
		<Button
			type="button"
			key={CODE}
			onClick={() => {
				handleChangeLanguage(CODE);
				replaceShoeData();
			}}
			className="c-button--rounded c-button--outline"
		>
			{NATIVE}
		</Button>
	);

	return (
		<Modal
			className={cn("options", {
				vertical: qForm === "vertical",
				rise: qForm === "rise",
			})}
			isOpen={isOpen}
			onClose={setLanguageModal(false)}
		>
			<div className="u-pt-18 u-px-15 u-pb-9">
				<Typography tag="h1" size="2xl" className="u-text-c" primary uppercase>
					{t("generic.lang_select")}
				</Typography>

				<div className="u-flex u-col u-ai-center">
					<div className="u-flex u-inline-16 u-mt-18">
						{enabledLanguages.map(({ NATIVE, CODE }) => renderButton(NATIVE, CODE))}
					</div>

					<Modal.Close className="u-mt-12 u-ml-auto" onClose={setLanguageModal(false)}>
						{t("buttons.dismiss")}
					</Modal.Close>
				</div>
			</div>
		</Modal>
	);
};

export default memo(LangSelector);
