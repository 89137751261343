import { useSelector } from "react-redux";

import { selectAll, selectIsLoading, selectError, selectFilterIDs, selectActiveFilters } from "./filters.selectors";

// eslint-disable-next-line import/prefer-default-export
export const useAllFilters = () => {
	const data = useSelector((state) => selectAll(state));
	const loading = useSelector((state) => selectIsLoading(state));
	const error = useSelector((state) => selectError(state));

	return { data, loading, error };
};

export const useFilterIDs = () => {
	const data = useSelector((state) => selectFilterIDs(state));

	return data;
};

export const useActiveFilters = () => {
	const data = useSelector((state) => selectActiveFilters(state));

	return data;
};
