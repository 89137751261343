import React, { useEffect, useState } from "react";
import { find, pathOr, propEq, replace, isNil } from "ramda";
import { wrap } from "popmotion";
import { motion } from "framer-motion";

import { useQuery } from "@/hooks/Router";
import useGrid from "@/hooks/Grid";
import { useFlippedOrientation, useLowGPU } from "@/store/ui/ui.hooks";

import groupingVerticalGrid from "@/assets/grids/groupingVertical.json";

import GridBlock from "@/components/GridBlock";
import SliderNavigation from "@/components/SliderNavigation";
import ShoePickerNavigation from "@/components/ShoePickerNavigation";

import SWIPE_TRESHOLD from "@/constants/events";

import swipePower from "@/utils/slider/swipePower";

import { fadeMove, slideFull } from "@/animations";

import propTypes from "./Vertical.propTypes";
import defaultProps from "./Vertical.defaultProps";

import {
	ProductGroupingPartialsTitleDesc,
	ProductGroupingPartialsShoeBack,
	ProductGroupingPartialsLogo,
	ProductGroupingPartialsImage,
	ProductGroupingPartialsVideo,
} from "../Partials";

const Vertical = ({ data = {}, allProductGroupings = [], lang }) => {
	const query = useQuery();

	const { isGridLoading, start: startGrid, end: endGrid, shouldRerenderGrid } = useGrid();
	const lowGPU = useLowGPU();

	const AMOUNT_PER_VIEW = 1;
	const technologies = allProductGroupings.filter((el) => el.assets.length > 2 && el.meta.type === "technology");
	const startPage = technologies.findIndex((el) => el.id === data.id);

	const [[page, direction], setPage] = useState([startPage, 0]);

	const count = technologies.length;
	const totalPages = Math.ceil(count / AMOUNT_PER_VIEW);
	const pageIndex = wrap(0, totalPages, page);
	const start = pageIndex * AMOUNT_PER_VIEW;
	const end = start + AMOUNT_PER_VIEW > count ? count : start + AMOUNT_PER_VIEW;
	data = technologies.slice(start, end)[0];

	const assets = pathOr(null, ["assets"], data);
	const hasVideo = Boolean(find(propEq("media_type", "video"), assets));
	const qCdnUrl = query.get("cdn-url") || null;
	const qForm = query.get("form") || "rise";

	let videoUrl = "";
	if (hasVideo) {
		videoUrl = find(propEq("media_type", "video"), assets).video_url;
		if (!isNil(qCdnUrl)) videoUrl = replace("https://res.cloudinary.com/", qCdnUrl, videoUrl);
		videoUrl = replace(/\/upload\//, `/upload/c_fill,h_800,w_1088,g_auto/`, videoUrl);
	}
	let topLeft = pathOr(null, ["assets", 0, "portrait"], data);
	let topRight = pathOr(null, ["assets", 1, "portrait"], data);
	let bottom = pathOr(null, ["assets", 2, "portrait"], data);
	let logo = null;

	if (
		find((img) => /top_left/.test(img.portrait), assets) &&
		(find((img) => /top_right/.test(img.portrait), assets) || find((img) => /right/.test(img.portrait), assets)) &&
		find((img) => /bottom/.test(img.portrait), assets) &&
		find((img) => /logo/.test(img.portrait), assets)
	) {
		const right =
			find((img) => /top_right/.test(img.portrait), assets) || find((img) => /right/.test(img.portrait), assets);
		topLeft = find((img) => /top_left/.test(img.portrait), assets).portrait;
		topRight = right.portrait;
		bottom = find((img) => /bottom/.test(img.portrait), assets).portrait;
		logo = find((img) => /logo/.test(img.portrait), assets).portrait;
	}

	// #region LIFECYCLE HOOKS
	useEffect(() => {
		endGrid();
		startGrid(groupingVerticalGrid);
	}, [isGridLoading, page]);
	// #endregion LIFECYCLE HOOKS

	// #region FUNCTIONS
	const handlePage = (newDirection) => {
		let newPage = page + newDirection;

		if (newPage < 0) newPage = totalPages - 1;
		if (newPage > totalPages - 1) newPage = 0;

		setPage([newPage, newDirection]);
	};

	const handleDragEnd = (e, { offset, velocity }) => {
		if (count <= AMOUNT_PER_VIEW) {
			return;
		}

		let swipe = swipePower(offset.x, velocity.x);

		if (swipe < -SWIPE_TRESHOLD) {
			handlePage(1);
		} else if (swipe > SWIPE_TRESHOLD) {
			handlePage(-1);
		}
	};
	// #endregion FUNCTIONS

	// #region RENDER
	return (
		<>
			<motion.div
				drag="x"
				onDragEnd={handleDragEnd}
				dragElastic={1}
				initial="enter"
				animate="center"
				exit="exit"
				key={page}
				custom={direction}
				variants={slideFull}
				transformTemplate={({ x }) => {
					const xTransform = parseFloat(x, 10);
					return `translate3d(${xTransform}px, 0, 0)`;
				}}
				dragConstraints={{ left: 0, right: 0 }}
				className="u-flex u-fill-height"
				transition={{
					x: { type: "spring", bounce: 0, duration: 0 },
					opacity: { duration: 0.2 },
				}}
			>
				<ProductGroupingPartialsTitleDesc
					id="TechnologyMiddleLeft"
					data={data}
					lang={lang}
					lowGPU={lowGPU}
					horizontal={qForm === "horizontal"}
					vertical={qForm === "vertical"}
					size="3xl"
					className="u-flex u-col u-p-12"
					lineHeightSize="smmd"
				/>

				<ProductGroupingPartialsLogo
					className="u-flex u-jc-center"
					id="TechnologyBottomRight"
					data={data}
					lowGPU={lowGPU}
					vertical
					src={logo}
				/>

				{hasVideo ? (
					<ProductGroupingPartialsVideo id="TechnologyMain" src={videoUrl} shouldShowBottom />
				) : (
					<ProductGroupingPartialsImage id="TechnologyMain" src={topLeft} shouldShowBottom />
				)}

				<ProductGroupingPartialsImage id="TechnologyMiddleRight" src={topRight} shouldShowBottom delay={0.15} />

				<ProductGroupingPartialsImage
					id="TechnologyBottomLeft"
					src={bottom}
					delay={0.3}
					shouldShowBottom
					horizontal={qForm === "horizontal"}
				/>

				<ProductGroupingPartialsShoeBack
					id="TechnologyTop"
					className=""
					vertical={qForm === "vertical"}
					horizontal={qForm === "horizontal"}
				>
					{technologies.length > 1 ? (
						<ShoePickerNavigation
							className="u-flex-1 u-mr-5"
							slides={totalPages}
							speed={0}
							step={page}
							setCurrentSlide={(newPage) => {
								if (newPage > page) handlePage(1);
								else handlePage(-1);
							}}
						/>
					) : null}
				</ProductGroupingPartialsShoeBack>
			</motion.div>
		</>
	);
	// #endregion RENDER
};

Vertical.propTypes = propTypes;
Vertical.defaultProps = defaultProps;

export default Vertical;
