import React, { memo } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import Icon from "@/components/Icon";

import HighlightMembership from "@/components/HighlightMembership";

import { useQuery } from "@/hooks/Router";

// =============================================================================
// Root
// =============================================================================
const MembershipMenuPersonalizing = () => {
	const { t } = useTranslation();

	const query = useQuery();
	const qForm = query.get("form") || "rise";

	const direction = qForm === "vertical" ? "vh" : "vw";

	const size = qForm === "vertical" ? 2 : 1.4067;
	const grid = [0, size, size * 2, size * 3, size * 4, size * 5, size * 6];
	const duration = 2;
	const keyframes = [];
	for (let i = 0; i <= 1; i += 0.02) {
		keyframes.push(i.toFixed(2));
	}

	return (
		<div className="u-flex u-jc-start u-ai-start">
			<div className="u-flex">
				<motion.div
					initial={{
						overflow: "hidden",
						x: `${grid[1]}${direction}`,
						y: `${grid[3]}${direction}`,
						width: `${grid[1]}${direction}`,
					}}
					animate={{}}
					transition={{ ease: [0, 1, 0, 1], duration, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="profile-white"
					/>
				</motion.div>
				<div
					className="u-flex"
					style={{ transform: `translate(${grid[1]}${direction}, ${grid[2]}${direction})` }}
				>
					<HighlightMembership
						className={cn("s-dark no-absolute")}
						title={t("membership.personalizing_0a")}
						delay={0.1}
						endDelay={0}
						grid={grid}
						typo={
							qForm === "vertical"
								? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
								: null
						}
						end="block"
						initial={[0, 0]}
						animate={[0, 0]}
						clipPath={[
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 80% 0px 0px)",
							"inset(0px 60% 0px 0px)",
							"inset(0px 40% 0px 0px)",
							"inset(0px 20% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
						]}
					/>
				</div>
				<div
					className="u-flex"
					style={{ transform: `translate(-${grid[2]}${direction}, ${grid[3]}${direction})` }}
				>
					<HighlightMembership
						className={cn("s-dark no-absolute")}
						title={t("membership.personalizing_0b")}
						delay={0.1}
						endDelay={0}
						grid={grid}
						typo={
							qForm === "vertical"
								? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
								: null
						}
						end="block"
						initial={[0, 0]}
						animate={[0, 0]}
						clipPath={[
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 100% 0px 0px)",
							"inset(0px 80% 0px 0px)",
							"inset(0px 60% 0px 0px)",
							"inset(0px 40% 0px 0px)",
							"inset(0px 20% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
							"inset(0px 0% 0px 0px)",
						]}
					/>
				</div>
			</div>
			<div className="u-flex" style={{ transform: `translate(-${grid[2]}${direction}, ${grid[2]}${direction})` }}>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 0.5, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="ntc-white"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 0.8, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="skate-white"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 1.1, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="football-black"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 1.4, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="basketball-white"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 1.7, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="tennis-black"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 2, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="golf-white"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 2.3, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="soccer-black"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 2.6, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="nrc-black"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 2.9, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="golf-black"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 3.2, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="baseball-white"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 3.5, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="tennis-black"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 3.8, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="football-white"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 4.1, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="ntc-white"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 4.4, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="basketball-white"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 4.7, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="skate-black"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 5.0, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="soccer-white"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 5.3, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="ntc-white"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 5.6, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="golf-white"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 5.9, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="baseball-black"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 6.2, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="nrc-black"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 6.5, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="skate-black"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 6.8, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="tennis-white"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "none",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 7.1, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="basketball-black"
					/>
				</motion.div>
				<motion.div
					initial={{
						display: "none",
						position: "absolute",
						marginBottom: "0px",
					}}
					animate={{
						display: "block",
						marginBottom: "1px",
						transitionEnd: {
							display: "block",
						},
					}}
					transition={{ ease: [0, 1, 0, 1], delay: 7.4, duration: 0.3, times: keyframes }}
				>
					<Icon
						className={
							qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
						}
						type="baseball-white"
					/>
				</motion.div>
			</div>
		</div>
	);
};

export default memo(MembershipMenuPersonalizing);
