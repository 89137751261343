import { includes, isEmpty, isNil, propOr, find, propEq, paths } from "ramda";
import i18n from "@/i18n";

import { BANNED_EPC } from "@/constants/banned";
import { sentryLogError, sentryLogException } from "@/utils/sentry";

import { setShoeNotFoundModal } from "@/store/ui/ui.actions";
import { addSCCTo404List, addEPCTo404List } from "@/store/rfid/rfid.actions";

import getAcceptedShoes from "@/utils/data/getAcceptedShoes";
import setAnalytics from "@/utils/analytics";

import ShoesActionTypes from "./shoes.action-types";
import { shoeFormatter, gridShoesFormatter } from "./shoes.formatter";
import {
	setupRecommendations,
	setupSingleRecommendations,
	setupFallbackRecommendations,
} from "../membership/membership.recommendations";

export const fetchImagemap = () => (dispatch) => {
	dispatch({
		type: ShoesActionTypes.GET_IMAGEMAP,
	});

	return fetch(`${process.env.REACT_APP_API_URL}/aurora/viewCodeMap`)
		.then((response) => {
			if (!response.ok) {
				throw new Error(`HTTP error ${response.status}`);
			}
			return response.json();
		})
		.then((data) => {
			dispatch({
				type: ShoesActionTypes.GET_IMAGEMAP_SUCCESS,
				data: data.FOOTWEAR,
			});
		})
		.catch((error) => {
			sentryLogException("Fetch Imagemap", error);
			dispatch({
				type: ShoesActionTypes.GET_IMAGEMAP_ERROR,
				error: "Something went wrong",
			});
		});
};

export const getAllShoes =
	(
		filterType = null,
		store = null,
		membershipActive = false,
		identity = {},
		filterIDs = {},
		styles = {},
		cdnUrl = null
	) =>
	(dispatch) => {
		dispatch({
			type: ShoesActionTypes.GET_ALL,
		});

		const params = new URLSearchParams({
			lang: "all",
			productTypes: "FOOTWEAR",
			...(!isNil(filterType) && !isEmpty(filterType) && { filterType }),
		});

		if (!isNil(store)) {
			fetch(`${process.env.REACT_APP_API_URL}/product/store/${store}/grid?${params}`)
				.then((response) => {
					if (!response.ok) {
						throw new Error(`HTTP error ${response.status}`);
					}

					return response.json();
				})
				.then((data) => {
					const temp = data.filter((shoe) => {
						const test = !isNil(
							paths(
								[
									["product_name", i18n.language],
									["product_name", "en"],
								],
								shoe
							).find((el) => {
								return !isNil(el);
							})
						);
						return test && !isNil(shoe.thumbnail_url);
					});
					try {
						let shoes = [];
						if (membershipActive) {
							shoes = setupRecommendations(
								gridShoesFormatter(getAcceptedShoes(temp), cdnUrl),
								identity,
								filterIDs,
								i18n.language,
								styles
							);
							if (shoes.filter((shoe) => shoe.recommendations.length > 0).length === 0) {
								shoes = setupFallbackRecommendations(shoes, identity);
							}
						} else {
							shoes = gridShoesFormatter(getAcceptedShoes(temp), cdnUrl);
						}
						shoes.sort(() => Math.random() - 0.5);
						dispatch({
							type: ShoesActionTypes.GET_ALL_SUCCESS,
							data: shoes,
						});
					} catch (e) {
						// console.log(e);
						sentryLogException("Shoe Formatter", e);
					}
				})
				.catch((e) => {
					// console.log(e);
					sentryLogException("Shoe Formatter", e);
					dispatch({
						type: ShoesActionTypes.GET_ALL_ERROR,
						error: "Something went wrong",
					});
				});
		}
	};

export const getShoeBySCC =
	(
		scc,
		source = "cache",
		store = null,
		city = { sportpulse_id: null },
		imagemap = {},
		membershipActive = false,
		identity = {},
		filterIDs = {},
		recommendations = null,
		styles = {},
		fourList = { epcs: [], sccs: [] },
		marketplace = "US",
		cdnUrl,
		fromPicker = false
	) =>
	(dispatch) => {
		dispatch({
			type: ShoesActionTypes.GET_SHOE_BY_SCC,
		});

		const fourTest = find((item) => item === scc, fourList.sccs);

		if (!isNil(scc) && (!fourTest || fromPicker)) {
			return fetch(
				`${process.env.REACT_APP_API_URL}/product/scc/${scc}?lang=all&store=all&location_city=${
					city.sportpulse_id
				}&reviewParams=marketplace(${marketplace}${marketplace !== "US" ? ",US" : ""})`
			)
				.then(async (response) => {
					if (!response.ok) {
						if (response.status === 404 || response.status === 400) dispatch(addSCCTo404List(scc));

						return null;
					}

					const data = await response.json();

					let shoe = null;
					try {
						shoe = shoeFormatter(
							data,
							imagemap,
							filterIDs.categoryID,
							filterIDs.surfaceID,
							filterIDs.runningID,
							filterIDs.danceID,
							filterIDs.lifestyleVal,
							filterIDs.athleticsID,
							filterIDs.everydayID,
							cdnUrl,
							marketplace,
							i18n.language,
							filterIDs.prioritizedTaxonomyIDs,
						);

						if (membershipActive) {
							shoe = setupSingleRecommendations(
								shoe,
								identity,
								filterIDs,
								i18n.language,
								recommendations,
								styles
							);
						}
					} catch (e) {
						// console.log(e);
						sentryLogError(
							"Shoe Formatter",
							[
								{
									name: "SCC",
									value: scc,
								},
							],
							`Bad Data: Shoe Formatter Error - ${e}`
						);
					}

					if (shoe && shoe.isMissingData) {
						setAnalytics("product_missing_data", { product_id: scc, member: !isEmpty(identity) });
						dispatch(setShoeNotFoundModal(true, { isDigital: true, type: "not_found", side: "DIGITAL" }));
						sentryLogError(
							"Missing Data",
							[
								{
									name: "SCC",
									value: shoe.id,
								},
							],
							`Missing Data - ${shoe.id}: Carousel Images`
						);

						return null;
					}

					return shoe;
				})
				.catch((e) => {
					// console.log(e);
					sentryLogException("Shoe Formatter", e);
					dispatch({
						type: ShoesActionTypes.GET_SHOE_BY_SCC_ERROR,
						error: "Something went wrong",
					});
					return null;
				});
		}
		dispatch({
			type: ShoesActionTypes.GET_SHOE_BY_SCC_ERROR,
			error: "Something went wrong",
		});
		return null;
	};

export const getShoeByEPC =
	(
		epc,
		store = null,
		city = { sportpulse_id: null },
		imagemap = {},
		membershipActive = false,
		identity = {},
		filterIDs = {},
		recommendations = [],
		styles = {},
		fourList = { epcs: [], sccs: [] },
		cdnUrl,
		marketplace = "US"
	) =>
	(dispatch) => {
		dispatch({
			type: ShoesActionTypes.GET_SHOE_BY_EPC,
		});

		const fourTest = find((item) => item === epc, fourList.epcs);

		// Filter banned EPC
		const isBanned = includes(epc, BANNED_EPC);
		if ((isEmpty(epc) || isBanned) && !fourTest) {
			if (isBanned) {
				// eslint-disable-next-line no-console
				// console.log(`%c BANNED SHOE`, "background-color: black; color: white; font-weight: bold; padding: 4px");
				sentryLogError(
					"Banned Shoe",
					[
						{
							name: "EPC",
							value: epc,
						},
					],
					`Banned Shoe - ${epc}`
				);
			}

			return null;
		}

		return fetch(
			`${process.env.REACT_APP_API_URL}/product/epc/${epc}?lang=all&store=${store}&location_city=${city.sportpulse_id}&reviewParams=lang:${i18n.language}`
		)
			.then(async (response) => {
				if (!response.ok) {
					if (response.status === 404 || response.status === 400) dispatch(addEPCTo404List(epc));

					return null;
				}

				const data = await response.json();

				let shoe = null;
				try {
					shoe = shoeFormatter(
						data,
						imagemap,
						filterIDs.categoryID,
						filterIDs.surfaceID,
						filterIDs.runningID,
						filterIDs.danceID,
						filterIDs.lifestyleVal,
						filterIDs.athleticsID,
						filterIDs.everydayID,
						cdnUrl,
						marketplace,
						i18n.language,
						filterIDs.prioritizedTaxonomyIDs,
					);

					if (membershipActive) {
						shoe = setupSingleRecommendations(
							shoe,
							identity,
							filterIDs,
							i18n.language,
							recommendations,
							styles
						);
					}
				} catch (e) {
					// console.log(e);
					sentryLogError(
						"Shoe Formatter",
						[
							{
								name: "EPC",
								value: epc,
							},
						],
						`Bad Data: Shoe Formatter Error - ${e}`
					);
				}

				if (shoe && shoe.isMissingData) {
					setAnalytics("product_missing_data", { product_id: shoe.id, member: !isEmpty(identity) });
					dispatch(setShoeNotFoundModal(true, { isDigital: true, type: "not_found", side: "DIGITAL" }));
					sentryLogError(
						"Missing Data",
						[
							{
								name: "SCC",
								value: shoe.id,
							},
							{
								name: "EPC",
								value: epc,
							},
						],
						`Missing Data - ${shoe.id}: Carousel Images`
					);

					return null;
				}

				return shoe;
			})
			.catch((e) => {
				sentryLogException("Shoe Formatter", e);
				dispatch({
					type: ShoesActionTypes.GET_SHOE_BY_EPC_ERROR,
					error: "Something went wrong",
				});

				return null;
			});
	};

export const fetchSCCByEPC =
	(epc, fourList = { epcs: [], sccs: [] }) =>
	(dispatch) => {
		dispatch({
			type: ShoesActionTypes.GET_SCC_BY_EPC,
		});

		const fourTest = find((item) => item === epc, fourList.epcs);

		if (!fourTest) {
			return fetch(`${process.env.REACT_APP_SP_API_URL}/public/epc/${epc}`)
				.then((response) => {
					if (!response.ok) {
						if (response.status === 400 || response.status === 404) dispatch(addEPCTo404List(epc));
					}
					return response.json();
				})
				.then((data) => {
					const scc = propOr(null, "scc", data);

					if (scc) {
						return scc;
					}

					return null;
				})
				.catch((e) => {
					// Open shoe not found modal here
					sentryLogException("Fetch SCC by EPC", e);
					// console.log(e);
					dispatch({
						type: ShoesActionTypes.GET_SCC_BY_EPC_ERROR,
						error: "Something went wrong",
					});
				});
		}

		return null;
	};

export const fetchLocation =
	(store = null) =>
	(dispatch) => {
		dispatch({
			type: ShoesActionTypes.GET_LOCATION,
		});

		return fetch(`${process.env.REACT_APP_SP_API_URL}/public/rise/stores`)
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error ${response.status}`);
				}
				return response.json();
			})
			.then((data) => {
				const location = find(propEq("nike_store_id", store))(data);

				dispatch({
					type: ShoesActionTypes.GET_LOCATION_SUCCESS,
					data: location,
				});
			})
			.catch((e) => {
				// Open shoe not found modal here
				sentryLogException("Fetch Location", e);
				dispatch({
					type: ShoesActionTypes.GET_LOCATION_ERROR,
					error: "Something went wrong",
				});
			});
	};

export const fetchShoeInventory =
	(storeID = {}, scc, side) =>
	(dispatch) => {
		dispatch({
			type: ShoesActionTypes.GET_INVENTORY,
		});

		const myHeaders = new Headers();
		const apiKey = process.env.REACT_APP_SPORTPULSE_STORE_KEY;
		myHeaders.append("x-api-key", apiKey);

		const requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		const url = `${process.env.REACT_APP_SP_API_URL}/private`;

		let count = 0;

		fetch(
			`${url}/store_stock/version/v5/country/${storeID.address.country}/store/${storeID.id}?styleColorCodes=${scc}&count=100`,
			requestOptions
		)
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error ${response.status}`);
				}

				return response.json();
			})
			.then((data) => {
				try {
					data.objects.forEach((item) => {
						count += item.physicalStock.quantity;
					});
					dispatch({
						type: ShoesActionTypes.GET_INVENTORY_SUCCESS,
						side,
						data: count,
					});
				} catch (e) {
					sentryLogError(
						"Shoe Inventory",
						[
							{
								name: "SCC",
								value: data.style_color_code,
							},
						],
						`Bad Data: Shoe Inventory Error - ${e}`
					);
					throw e;
				}
			})
			.catch((error) => {
				// console.log(error);
				sentryLogException("Shoe Inventory", error);
				dispatch({
					type: ShoesActionTypes.GET_INVENTORY_ERROR,
					error: "Something went wrong",
				});
			});
	};

export const fetchShoePrice =
	(storeID = {}, scc, side) =>
	(dispatch) => {
		dispatch({
			type: ShoesActionTypes.GET_PRICE,
		});

		const myHeaders = new Headers();
		const apiKey = process.env.REACT_APP_SPORTPULSE_PRICE_KEY;
		myHeaders.append("x-api-key", apiKey);

		const requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		const url = `${process.env.REACT_APP_SP_API_URL}/private`;

		fetch(`${url}/gpps_price/${storeID.id}?styleColorCode=${scc}`, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error ${response.status}`);
				}

				return response.json();
			})
			.then((data) => {
				try {
					if (data.objects.length === 1 && data.objects[0].prices.length === 1) {
						dispatch({
							type: ShoesActionTypes.GET_PRICE_SUCCESS,
							side,
							data: data.objects[0].prices[0].amount,
						});
					}
				} catch (e) {
					sentryLogError(
						"Shoe Price",
						[
							{
								name: "SCC",
								value: data.style_color_code,
							},
						],
						`Bad Data: Shoe Price Error - ${e}`
					);
					throw e;
				}
			})
			.catch((error) => {
				// console.log(error);
				sentryLogException("Shoe Inventory", error);
				dispatch({
					type: ShoesActionTypes.GET_PRICE_ERROR,
					error: "Something went wrong",
				});
			});
	};

export const setActive = (data, side) => (dispatch) => {
	dispatch({
		type: ShoesActionTypes.SET_ACTIVE,
		data,
		side,
	});
};

export const updateVisible = (isVisible, side) => (dispatch) => {
	dispatch({
		type: ShoesActionTypes.UPDATE_VISIBLE,
		data: isVisible,
		side,
	});
};

export const updateDigital = (isDigital, side) => (dispatch) => {
	dispatch({
		type: ShoesActionTypes.UPDATE_DIGITAL,
		data: isDigital,
		side,
	});
};

export const updateTemplate = (index, side) => (dispatch) => {
	dispatch({
		type: ShoesActionTypes.UPDATE_TEMPLATE,
		data: index,
		side,
	});
};

export const clearActive = (side) => (dispatch) => {
	dispatch({
		type: ShoesActionTypes.CLEAR_ACTIVE,
		side,
	});
};

export const clearQty = (side) => (dispatch) => {
	dispatch({
		type: ShoesActionTypes.CLEAR_QTY,
		side,
	});
};

export const clearPrice = (side) => (dispatch) => {
	dispatch({
		type: ShoesActionTypes.CLEAR_PRICE,
		side,
	});
};
