import { find, pathOr, propEq, filter } from "ramda";

import { STATUS } from "@/constants/rfid-tags";

export const selectOrientation = (state) => pathOr(null, ["rfid", "orientation"], state);

export const selectIsInteraction = (state) => pathOr(null, ["rfid", "isInteraction"], state);

export const selectInteractionLock = (state) => pathOr(null, ["rfid", "interactionLock"], state);

export const selectAllTags = (state) => pathOr(null, ["rfid", "tags"], state);

export const selectBySide = (state, side) => find(propEq("side", side))(pathOr([], ["rfid", "tags"], state));

export const selectIdleTags = (state) => filter(propEq("status", STATUS.Idle))(selectAllTags(state));

export const select404List = (state) => pathOr(null, ["rfid", "fourList"], state);
