import { useSelector } from "react-redux";

import {
	selectIdentity,
	selectIdentityLoading,
	selectIdentityError,
	selectStyles,
	selectStylesLoading,
	selectStylesError,
	selectMembershipActive,
	selectSimulatorActive,
	selectMenuMinimized,
	selectPrivacy,
	selectEndSession,
	selectSeenWelcome,
	selectWishlists,
	selectWishlistProducts,
	selectWelcomeRail,
	selectShowStats,
} from "./membership.selectors";

export const useIdentity = () => {
	const data = useSelector((state) => selectIdentity(state));
	const loading = useSelector((state) => selectIdentityLoading(state));
	const error = useSelector((state) => selectIdentityError(state));

	return { data, loading, error };
};

export const useStyles = () => {
	const data = useSelector((state) => selectStyles(state));
	const loading = useSelector((state) => selectStylesLoading(state));
	const error = useSelector((state) => selectStylesError(state));

	return { data, loading, error };
};

export const useMembershipActive = () => useSelector((state) => selectMembershipActive(state));

export const useSimlulatorActive = () => useSelector((state) => selectSimulatorActive(state));

export const useMenuMinimized = () => useSelector((state) => selectMenuMinimized(state));

export const usePrivacy = () => useSelector((state) => selectPrivacy(state));

export const useEndSession = () => useSelector((state) => selectEndSession(state));

export const useSeenWelcome = () => useSelector((state) => selectSeenWelcome(state));

export const useWelcomeRail = () => useSelector((state) => selectWelcomeRail(state));

export const useShowStats = () => useSelector((state) => selectShowStats(state));

export const useWishlists = () => useSelector((state) => selectWishlists(state));

export const useWishlistProducts = () => useSelector((state) => selectWishlistProducts(state));
