const COLOR_CODES = [
	{
		code: "001",
		name: "Black",
	},
	{
		code: "100",
		name: "Light",
	},
	{
		code: "111",
		name: "White",
	},
	{
		code: "400",
		name: "Blue",
	},
	{
		code: "900",
		name: "Dark",
	},
];

export default COLOR_CODES;
