import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import GridBlock from "@/components/GridBlock";
import Button from "@/components/Button";
import ShoeHeading from "@/components/ShoeHeading";

import { useActiveShoe } from "@/store/shoes/shoes.hooks";

import propTypes from "./ProductGroupingPartialsShoeBack.propTypes";
import defaultProps from "./ProductGroupingPartialsShoeBack.defaultProps";

const ProductGroupingPartialsShoeBack = ({ id, horizontal, vertical, children, className }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { detail: activeShoe = null } = useActiveShoe() || {};

	return (
		<GridBlock className={cn("u-flex u-ai-end", className)} id={id}>
			<div className="u-flex u-fill-width u-row u-mb-16 u-jc-between" style={{ height: "fit-content" }}>
				<ShoeHeading>
					<ShoeHeading.Image className="u-ml-12" src={activeShoe.assets.left.square} />

					<ShoeHeading.Title
						className="u-mt-4"
						size="smmmd"
						lineHeightSize="sm"
						weight="normal"
						primary={false}
					>
						{activeShoe.content.name}
					</ShoeHeading.Title>
				</ShoeHeading>
				<div className="u-flex u-row u-ai-end">
					{children}
					<div>
						<Button
							className="c-button--rounded u-flex u-ai-center u-mr-12"
							onClick={() => history.goBack()}
							icon="chevron-left"
							iconPosition="left"
						>
							{t("buttons.back")}
						</Button>
					</div>
				</div>
			</div>
		</GridBlock>
	);
};

ProductGroupingPartialsShoeBack.propTypes = propTypes;
ProductGroupingPartialsShoeBack.defaultProps = defaultProps;

export default ProductGroupingPartialsShoeBack;
