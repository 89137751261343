const ACTIVITY_TYPES = [
	{
		type: "run",
		category: "Running",
	},
	{
		type: "hiking",
		category: "Hiking",
	},
	{
		type: "basketball",
		category: "Basketball",
	},
];

export default ACTIVITY_TYPES;
