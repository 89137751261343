import { isEmpty, isNil } from "ramda";

import { clearActiveFilters, setFilter } from "@/store/filters/filters.actions";

import { FILTER_KEYS } from "@/constants/filters";
import { WL_COUNTRIES, WL_REGIONS } from "@/constants/wishlist_regions";

import capitalize from "@/utils/identity";
import { sentryLogException } from "@/utils/sentry";

import MembershipActionTypes from "./membership.action-types";

import { setShoeNotFoundModal } from "../ui/ui.actions";

export const createWishlist =
	(upmId = null, country = null) =>
	(dispatch) => {
		if (!isNil(upmId) && !isNil(country)) {
			dispatch({
				type: MembershipActionTypes.CREATE_WISHLIST,
			});

			const myHeaders = new Headers();
			const apiKey = process.env.REACT_APP_WISHLIST_API_KEY;
			myHeaders.append("x-api-key", apiKey);
			myHeaders.append("Content-Type", "application/json");

			const raw = JSON.stringify({
				country,
				brand: "NIKE",
				isDefault: true,
			});

			const requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: raw,
				redirect: "follow",
			};

			return fetch(`${process.env.REACT_APP_SP_API_URL}/private/wishlist/${upmId}/lists`, requestOptions)
				.then((response) => {
					if (!response.ok) {
						throw new Error(`HTTP error ${response.status}`);
					}
					return response.json();
				})
				.then((data) => {
					dispatch(fetchWishlistProducts(upmId, data?.id));
					dispatch({
						type: MembershipActionTypes.GET_WISHLISTS_SUCCESS,
						data,
					});
				})
				.catch((error) => {
					sentryLogException("Create Wishlist Error", error);
				});
		} else {
			sentryLogException("Create Wishlist Error");
		}
	};

export const postMemberPrivacyUpdate = (privacyValue, upmId) => (dispatch) => {
	dispatch({
		type: MembershipActionTypes.POST_PRIVACY,
	});

	const myHeaders = new Headers();
	const apiKey = process.env.REACT_APP_SPORTPULSE_API_KEY;
	myHeaders.append("x-api-key", apiKey);
	myHeaders.append("Content-Type", "application/json");

	const raw = JSON.stringify({
		profile: {
			healthdata: {
				enhancedAcceptance: privacyValue,
			},
		},
	});

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow",
	};

	return fetch(`${process.env.REACT_APP_SP_API_URL}/private/member/${upmId}/profile`, requestOptions)
		.then((response) => {
			if (!response.ok) {
				throw new Error(`HTTP error ${response.status}`);
			}
			return response.json();
		})
		.then((data) => {
			dispatch({
				type: MembershipActionTypes.POST_PRIVACY_SUCCESS,
				data: privacyValue,
			});
		})
		.catch((error) => {
			sentryLogException("Post Privacy Error", error);
			dispatch({
				type: MembershipActionTypes.POST_PRIVACY_ERROR,
				error: "Something went wrong",
			});
		});
};

export const removeProductFromWishlist =
	(upmId = "", productId = "", newFavorites = []) =>
	(dispatch) => {
		dispatch({
			type: MembershipActionTypes.REMOVE_PRODUCT_FROM_WISHLIST,
		});

		const myHeaders = new Headers();
		const apiKey = process.env.REACT_APP_WISHLIST_API_KEY;
		myHeaders.append("x-api-key", apiKey);

		const requestOptions = {
			method: "DELETE",
			headers: myHeaders,
		};

		return fetch(`${process.env.REACT_APP_SP_API_URL}/private/wishlist/${upmId}/item/${productId}`, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error ${response.status}`);
				}
				return response.json();
			})
			.then((data) => {
				dispatch({
					type: MembershipActionTypes.GET_WISHLIST_PRODUCTS_SUCCESS,
					data: newFavorites,
				});
				return true;
			})
			.catch((error) => {
				sentryLogException("Fetch Styles", error);
				dispatch({
					type: MembershipActionTypes.REMOVE_PRODUCT_FROM_WISHLIST_ERROR,
					error: "Something went wrong",
				});
				dispatch(setShoeNotFoundModal(true, { type: "wishlist_error" }));
				return false;
			});
	};

export const addProductToWishlist =
	(upmId = "", wishlistId = "", merchGroup = "", shoeScc = "", newFavorites = []) =>
	(dispatch) => {
		dispatch({
			type: MembershipActionTypes.ADD_PRODUCT_TO_WISHLIST,
		});

		const myHeaders = new Headers();
		const apiKey = process.env.REACT_APP_WISHLIST_API_KEY;
		myHeaders.append("x-api-key", apiKey);

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
		};

		return fetch(
			`${process.env.REACT_APP_SP_API_URL}/private/wishlist/${upmId}/${wishlistId}/merchGroup/${merchGroup}/item/${shoeScc}`,
			requestOptions
		)
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error ${response.status}`);
				}
				return response.json();
			})
			.then((data) => {
				dispatch(fetchWishlistProducts(upmId, wishlistId));
				return true;
			})
			.catch((error) => {
				sentryLogException("Add Wishlist Product", error);
				dispatch({
					type: MembershipActionTypes.ADD_PRODUCT_TO_WISHLIST_ERROR,
					error: "Something went wrong",
				});
				dispatch(setShoeNotFoundModal(true, { type: "wishlist_error" }));
				return false;
			});
	};

export const fetchStyles = () => (dispatch) => {
	dispatch({
		type: MembershipActionTypes.GET_STYLES,
	});

	return fetch(`${process.env.REACT_APP_API_URL}/style/all?display=ids&ignoreTemplates=true`)
		.then((response) => {
			if (!response.ok) {
				throw new Error(`HTTP error ${response.status}`);
			}
			return response.json();
		})
		.then((data) => {
			dispatch({
				type: MembershipActionTypes.GET_STYLES_SUCCESS,
				data,
			});
		})
		.catch((error) => {
			sentryLogException("Fetch Styles", error);
			dispatch({
				type: MembershipActionTypes.GET_STYLES_ERROR,
				error: "Something went wrong",
			});
		});
};

export const fetchWishlists =
	(upmId = null, country = null) =>
	(dispatch) => {
		dispatch({
			type: MembershipActionTypes.GET_WISHLISTS,
		});

		const myHeaders = new Headers();
		const apiKey = process.env.REACT_APP_WISHLIST_API_KEY;
		myHeaders.append("x-api-key", apiKey);

		const requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		if (!isNil(upmId))
			return fetch(`${process.env.REACT_APP_SP_API_URL}/private/wishlist/${upmId}/lists`, requestOptions)
				.then((response) => {
					if (!response.ok) {
						throw new Error(`HTTP error ${response.status}`);
					}
					return response.json();
				})
				.then((data) => {
					const lists = data.objects.filter((list) => list.isDefault);

					//Country mapping - need to get the correct wishlist based on user profile
					let list = lists.find((list) => list.country.toUpperCase() === country);
					if (isNil(list)) {
						//get other countries in same region as user profile country
						const region = WL_COUNTRIES[country];
						const countries = WL_REGIONS[region];
						//find the first list in the user's wishlists that matches one of the countries in the region
						list = lists.find((list) => countries.includes(list.country.toUpperCase()));
					}

					if (!isNil(list)) {
						dispatch(fetchWishlistProducts(upmId, list?.id));
						dispatch({
							type: MembershipActionTypes.GET_WISHLISTS_SUCCESS,
							data: list,
						});
					} else {
						dispatch(createWishlist(upmId, country));
					}
				})
				.catch((error) => {
					// console.log(error);
					sentryLogException("Fetch Lists", error);
					dispatch({
						type: MembershipActionTypes.GET_WISHLISTS_ERROR,
						error: "Something went wrong",
					});
				});
	};

export const fetchWishlistProducts =
	(upmId = null, listId = "") =>
	(dispatch) => {
		dispatch({
			type: MembershipActionTypes.GET_WISHLIST_PRODUCTS,
		});

		const myHeaders = new Headers();
		const apiKey = process.env.REACT_APP_WISHLIST_API_KEY;
		myHeaders.append("x-api-key", apiKey);

		const requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		if (!isNil(upmId) && !isEmpty(listId))
			return fetch(
				`${process.env.REACT_APP_SP_API_URL}/private/wishlist/${upmId}/${listId}/items?count=1000`,
				requestOptions
			)
				.then((response) => {
					if (!response.ok) {
						throw new Error(`HTTP error ${response.status}`);
					}
					return response.json();
				})
				.then((data) => {
					dispatch({
						type: MembershipActionTypes.GET_WISHLIST_PRODUCTS_SUCCESS,
						data: data.objects,
					});
				})
				.catch((error) => {
					sentryLogException("Fetch Lists", error);
					dispatch({
						type: MembershipActionTypes.GET_WISHLIST_PRODUCTS_ERROR,
						error: "Something went wrong",
					});
				});
	};

export const getIdentity =
	(qrCode = "", type = null, importData = {}) =>
	(dispatch) => {
		dispatch({
			type: MembershipActionTypes.GET_IDENTITY,
		});

		if (!isEmpty(importData)) {
			importData.firstName = capitalize(importData.firstName.match(/[a-zA-Z]*/g)[0]); // eslint-disable-line no-param-reassign
			dispatch({
				type: MembershipActionTypes.GET_IDENTITY_SUCCESS,
				data: importData,
			});
			dispatch(clearActiveFilters());
			dispatch(setFilter(FILTER_KEYS.CATEGORY, "recommended"));
			dispatch(fetchWishlists(importData.upmId, importData.profile.location.country.toUpperCase()));

			return importData;
		}

		if (!isEmpty(qrCode)) {
			const myHeaders = new Headers();

			const apiKey = process.env.REACT_APP_SPORTPULSE_API_KEY;

			myHeaders.append("x-api-key", apiKey);
			myHeaders.append("Content-Type", "application/json");

			const raw = JSON.stringify({
				scanpass: qrCode,
			});

			const requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: raw,
				redirect: "follow",
			};

			const url = `${process.env.REACT_APP_SP_API_URL}/private/member/`;

			return fetch(url, requestOptions)
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.statusCode >= 400) {
						throw new Error(`HTTP error ${data.statusCode} - ${data.message} - ${qrCode.slice(-20)}`);
					}
					data.federatedMember.firstName = capitalize(data.federatedMember.firstName.match(/[a-zA-Z]*/g)[0]); // eslint-disable-line no-param-reassign
					dispatch({
						type: MembershipActionTypes.GET_IDENTITY_SUCCESS,
						data: data.federatedMember,
					});
					dispatch(
						fetchWishlists(
							data.federatedMember.upmId,
							data.federatedMember.profile.location.country.toUpperCase()
						)
					);
					dispatch(clearActiveFilters());
					dispatch(setFilter(FILTER_KEYS.CATEGORY, "recommended"));
					if (!isEmpty(data.errors)) {
						Object.keys(data.errors).forEach((key) => {
							const error = data.errors[key];
							sentryLogException("Get Identity", `${key} - ${error.reason}`);
							// console.log(error.reason);
						});
					}
				})
				.catch((error) => {
					sentryLogException("Get Identity", error);
					dispatch({
						type: MembershipActionTypes.GET_IDENTITY_ERROR,
						error,
					});
				});
		}

		return importData;
	};

export const clearIdentity = () => (dispatch) => {
	dispatch({
		type: MembershipActionTypes.CLEAR_IDENTITY,
	});
};

export const setMembershipActive = (active) => (dispatch) => {
	dispatch({
		type: MembershipActionTypes.SET_MEMBERSHIP_ACTIVE,
		data: active,
	});
};

export const setSimulatorActive = (active) => (dispatch) => {
	dispatch({
		type: MembershipActionTypes.SET_SIMULATOR_ACTIVE,
		data: active,
	});
};

export const setMenuMinimized = (active) => (dispatch) => {
	dispatch({
		type: MembershipActionTypes.SET_MENU_MINIMIZED,
		data: active,
	});
};

export const setPrivacy = (active) => (dispatch) => {
	dispatch({
		type: MembershipActionTypes.SET_PRIVACY,
		data: active,
	});
};

export const setEndSession = (active) => (dispatch) => {
	dispatch({
		type: MembershipActionTypes.SET_END_SESSION,
		data: active,
	});
};

export const setSeenWelcome = (active) => (dispatch) => {
	dispatch({
		type: MembershipActionTypes.SET_SEEN_WELCOME,
		data: active,
	});
};

export const setWelcomeRail = (active) => (dispatch) => {
	dispatch({
		type: MembershipActionTypes.SET_WELCOME_RAIL,
		data: active,
	});
};

export const setShowStats = (active) => (dispatch) => {
	dispatch({
		type: MembershipActionTypes.SET_SHOW_STATS,
		data: active,
	});
};

export const clearIdentityError = () => (dispatch) => {
	dispatch({
		type: MembershipActionTypes.CLEAR_IDENTITY_ERROR,
	});
};
