import PropTypes, { string } from "prop-types";

const Button = {
	type: PropTypes.oneOf(["button", "submit"]),
	icon: PropTypes.oneOf([
		"callout",
		"chevron-right",
		"chevron-left",
		"instagram",
		"language",
		"minus",
		"options",
		"plus",
		"refresh",
		"star",
		"star_half",
	]),
	onClick: PropTypes.func,
	url: string,
};

export default {
	Button,
};
