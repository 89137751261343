const MILES_PER_KM = 0.62137119223733;

// COVERSION UTILS (RETURN TYPE NUMBER)

// Comes as long decimal from Nike API and we want fixed precision to show in FR
const kmsToMiles = (kms) => MILES_PER_KM * kms;

// Convert fixed precision from FE app to full decimal value so we can compare with Nike API values
const milesToKm = (miles) => miles / MILES_PER_KM;

// Format string with leading '0' where needed
const timestringPortionFormatter = (unit) => (unit < 10 ? `0${unit}` : unit);

// Calculate substrings for each portion of the timestring
const msToHoursPortion = (milliseconds) =>
	timestringPortionFormatter(Math.floor((milliseconds / (1000 * 60 * 60)) % 24));
const msToMinutesPortion = (milliseconds) => timestringPortionFormatter(Math.floor((milliseconds / (1000 * 60)) % 60));
const msToSecondsPortion = (milliseconds) => timestringPortionFormatter(Math.floor((milliseconds / 1000) % 60));

const minsPerKmToMinsPerMile = (minsPerKm) => (minsPerKm * 60) / MILES_PER_KM / 60;
const minsPerMileToMinsPerKm = (minsPerMile) => (minsPerMile * 60 * MILES_PER_KM) / 60;

// DISPLAY UTILS (RETURN TYPE STRING)

// Convert milliseconds to a formatted timestring (disregards any trailing ms -- )
const msToTimestring = (milliseconds) => {
	return `${msToHoursPortion(milliseconds)}:${msToMinutesPortion(milliseconds)}:${msToSecondsPortion(milliseconds)}`;
};

// Convert minutes to a formatted timestring
const minsToTimestring = (mins) => {
	const milliseconds = mins * 1000 * 60;
	return msToTimestring(milliseconds);
};

const minsToPace = (mins) => {
	const fullMins = Math.floor(mins);
	const seconds = ((mins - fullMins) * 60).toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });
	return `${fullMins}:${seconds}`;
};

const milesPace = (minsPerKm) => minsToPace(minsPerKmToMinsPerMile(minsPerKm));

const toTitleCase = (str) => {
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
};

module.exports = {
	kmsToMiles,
	milesToKm,
	timestringPortionFormatter,
	msToHoursPortion,
	msToMinutesPortion,
	msToSecondsPortion,
	minsPerKmToMinsPerMile,
	minsPerMileToMinsPerKm,
	msToTimestring,
	minsToTimestring,
	minsToPace,
	milesPace,
	toTitleCase,
};
