import React, { memo, useMemo, useEffect, useState, useRef } from "react";
import { isEmpty, isNil, equals, pathOr } from "ramda";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import Icon from "@/components/Icon";
import Typography from "@/components/Typography";
import Image from "@/components/Image";

import { useTableLocation } from "@/store/shoes/shoes.hooks";
import { useIdentity, useMenuMinimized, useEndSession, useSeenWelcome } from "@/store/membership/membership.hooks";
import { setWelcomeRail } from "@/store/membership/membership.actions";
import { setShoeNotFoundModal } from "@/store/ui/ui.actions";
import { setInteractionLock } from "@/store/rfid/rfid.actions";

import { useQuery } from "@/hooks/Router";

import MembershipMenuMember from "./MembershipMenuMember.component";
import MembershipMenuNonMember from "./MembershipMenuNonMember.component";
import MembershipMenuIdentity from "./MembershipMenuIdentity.component";

import { pixels } from "@/utils/math";
import { fontSizeToPixel, isLandscape } from "@/constants/pixel-sizes";
import ROUTES from "@/constants/routes";

const dayjs = require("dayjs");

// =============================================================================
// Root
// =============================================================================
const MembershipMenu = () => {
	const dispatch = useDispatch();
	const query = useQuery();
	const { t, i18n } = useTranslation();

	const menuMinimized = useMenuMinimized();
	const endSession = useEndSession();

	const activityTimeout = useRef();
	const memberMenuTimeout = useRef();
	const nonMemberMenuTimeout = useRef();
	const personalizingMenuTimeout = useRef();
	const loopTimeout = useRef();

	const [showCode, setShowCode] = useState(false);
	const [showMemberMenu, setShowMemberMenu] = useState(false);
	const [showNonMemberMenu, setShowNonMemberMenu] = useState(false);
	const [showEndSession, setShowEndSession] = useState(false);

	const { data: identity, error, loading } = useIdentity();
	const tableLocation = useTableLocation();
	const seenWelcome = useSeenWelcome();

	const city = tableLocation?.data?.json_meta || { sportpulse_id: null };
	const qForm = query.get("form") || "rise";

	const checkPage = (page) => equals(page, pathOr("", ["pathname"], location));
	const isPlacePage = checkPage(ROUTES.Place);

	const clearPersonalizingTimeout = () => {
		if (isNil(personalizingMenuTimeout.current)) {
			return;
		}

		clearTimeout(personalizingMenuTimeout.current);
		personalizingMenuTimeout.current = null;
	};

	const clearActivityTimeout = () => {
		if (isNil(activityTimeout.current)) {
			return;
		}

		clearTimeout(activityTimeout.current);
		activityTimeout.current = null;
	};

	const startAnimation = () => {
		clearActivityTimeout();
		setShowCode(false);
	};

	const stopAnimation = (show) => {
		// show scan icon
		// stop cycle
		setShowCode(show);

		activityTimeout.current = setTimeout(() => {
			startAnimation();
		}, 20000);
	};

	const handleTap = () => {
		if (isEmpty(identity)) {
			stopAnimation(true);
		} else if (!isEmpty(error)) {
			stopAnimation(false);
		}
	};

	const clearLoopTimeout = () => {
		if (isNil(loopTimeout.current)) {
			return;
		}

		clearTimeout(loopTimeout.current);
		loopTimeout.current = null;
	};

	const clearMemberMenuTimeout = () => {
		if (isNil(memberMenuTimeout.current)) {
			return;
		}

		clearTimeout(memberMenuTimeout.current);
		memberMenuTimeout.current = null;
	};

	const clearNonMemberMenuTimeout = () => {
		if (isNil(nonMemberMenuTimeout.current)) {
			return;
		}

		clearTimeout(nonMemberMenuTimeout.current);
		nonMemberMenuTimeout.current = null;
	};

	const startMemberMenuTimeout = () => {
		clearMemberMenuTimeout();
		memberMenuTimeout.current = setTimeout(() => {
			setShowMemberMenu(true);
			setShowNonMemberMenu(false);
		}, 500);
		// eslint-disable-next-line no-use-before-define
		startNonMemberMenuTimeout();
	};

	const startLoopTimeout = () => {
		clearLoopTimeout();
		loopTimeout.current = setTimeout(() => {
			setShowMemberMenu(true);
			setShowNonMemberMenu(false);
			startMemberMenuTimeout();
		}, 26000);
	};

	const startNonMemberMenuTimeout = () => {
		clearNonMemberMenuTimeout();
		nonMemberMenuTimeout.current = setTimeout(() => {
			setShowMemberMenu(false);
			setShowNonMemberMenu(true);
		}, 17500);
		startLoopTimeout();
	};

	useEffect(() => {
		if (isEmpty(identity) && !loading) startMemberMenuTimeout();

		return () => {
			clearActivityTimeout();
			clearLoopTimeout();
			clearMemberMenuTimeout();
			clearNonMemberMenuTimeout();
			clearPersonalizingTimeout();
		};
	}, []);

	useEffect(() => {
		if (endSession) {
			clearActivityTimeout();
			startMemberMenuTimeout();
			setShowCode(false);
		}
	}, [endSession]);

	useEffect(() => {
		if (!isEmpty(identity) && !seenWelcome && !menuMinimized) {
			dispatch(setWelcomeRail(true));
		} else if (!isEmpty(identity) && seenWelcome && menuMinimized) {
			dispatch(setWelcomeRail(false));
		}
	}, [identity, seenWelcome, menuMinimized]);

	useEffect(() => {
		clearActivityTimeout();
		setShowEndSession(seenWelcome);
		dispatch(setInteractionLock(true));

		if (!isEmpty(identity)) {
			clearLoopTimeout();
			clearMemberMenuTimeout();
			clearNonMemberMenuTimeout();
		}
	}, [identity, loading]);

	const Identity = () => {
		const workoutPrivacyAccepted =
			identity.profile && identity.profile.healthdata && identity.profile.healthdata.enhancedAcceptance;

		const ntc = [];
		const nrc = [];
		let orders = 0;
		let achievements = 0;

		if (identity.aggregates && workoutPrivacyAccepted) {
			if (identity.aggregates.run && identity.aggregates.run.activity[0].count > 0)
				nrc.push(identity.aggregates.run.activity[0]);
			if (identity.aggregates.basketball && identity.aggregates.basketball.activity[0].count > 0)
				ntc.push(identity.aggregates.basketball.activity[0]);
			if (identity.aggregates.hiit && identity.aggregates.hiit.activity[0].count > 0)
				ntc.push(identity.aggregates.hiit.activity[0]);
			if (identity.aggregates.hiking && identity.aggregates.hiking.activity[0].count > 0)
				ntc.push(identity.aggregates.hiking.activity[0]);
			if (identity.aggregates.training && identity.aggregates.training.activity[0].count > 0)
				ntc.push(identity.aggregates.training.activity[0]);
		}
		if (identity.orders) orders = identity.orders.length;
		if (
			!isEmpty(identity.achievements) &&
			identity.achievements.running &&
			identity.achievements.training &&
			identity.achievements.running.achievements &&
			identity.achievements.training.achievements
		)
			achievements =
				identity.achievements.running.achievements.length + identity.achievements.training.achievements.length;

		return (
			<div
				className={cn("u-flex u-fill-width u-fill-height u-jc-end", {
					"u-jc-end": qForm === "vertical",
					"u-jc-between": qForm !== "vertical",
				})}
			>
				<div
					className={cn("u-flex u-fill-height", {
						"u-p-1": qForm !== "vertical",
					})}
				>
					<MembershipMenuIdentity
						name={identity.firstName}
						year={dayjs(identity.profile.registration?.timestamp).format("YYYY")}
						orders={orders}
						nrc={nrc}
						ntc={ntc}
						achievements={achievements}
					/>

					<button
						className="u-flex u-ai-end u-jc-end u-p-1"
						style={{
							position: "absolute",
							bottom: 0,
							right: 0,
						}}
						type="button"
						onClick={() => dispatch(setShoeNotFoundModal(true, { type: "end_session" }))}
					>
						<p
							style={{
								fontSize: pixels(fontSizeToPixel["2xs"], isLandscape[qForm]),
							}}
							className="u-font-f-secondary u-font-w-normal u-pr-2"
						>
							{t("membership.attract_end")}
						</p>
						<Icon className="c-icon-membership--size-9" type="signout-black" />
					</button>
				</div>
			</div>
		);
	};

	const identityMenu = useMemo(() => {
		if (endSession) {
			return <div className="u-flex u-ai-start u-fill-space" />;
		}
		if (showCode && isEmpty(identity)) {
			return (
				<>
					{isPlacePage && <div className="u-width-75% u-pl-5" />}
					<button
						className={cn("u-flex u-p-1", {
							"u-width-25%": isPlacePage,
							"u-fill-width": !isPlacePage && qForm !== "vertical",
							"u-mt-16 u-ml-55": qForm === "vertical",
						})}
						type="button"
						onClick={() => startAnimation()}
						onKeyDown={() => startAnimation()}
					>
						<Image
							className="nike-qr-code"
							src={
								city.sportpulse_id !== "Seoul"
									? "https://nikeqr.io/?url=https%3A%2F%2Fnikerunning.sng.link%2FAstn5%2Fpayv%3F_dl%3Dmynike%253A%252F%252Fx-callback-url%252Fshop%26_smtype%3D3%26cp%3Dwpcn"
									: "https://nikeqr.io/?url=https%3A%2F%2Fwww.nike.com%2Fnike-app"
							}
						/>
						<div className="u-flex u-fill-height">
							<Icon
								className={cn("u-flex-1 u-ai-start", {
									"c-icon-membership--size-9": qForm !== "vertical",
									"c-icon-membership--size-member": qForm === "vertical",
								})}
								type="arrow-left"
							/>
							<div className="u-flex u-flex-grow-5 u-ai-end">
								<Typography
									className="c-highlight__title u-pr-2"
									key="name"
									primary
									uppercase
									size={qForm === "vertical" ? "2lg" : "md"}
									lineHeightSize={qForm === "vertical" ? "3md" : "xs"}
									tag="h3"
								>
									{t("membership.attract_scan")}
								</Typography>
							</div>
							<div className="u-flex-1 u-fill-space">
								<Icon
									className={cn("u-flex u-ai-start u-jc-end", {
										"c-icon-membership--size-9": qForm !== "vertical",
										"c-icon-membership--size-member": qForm === "vertical",
									})}
									type="close"
								/>
							</div>
						</div>
					</button>
				</>
			);
		}
		if (!showCode && isEmpty(identity)) {
			return (
				<>
					<div
						className={cn("u-flex u-p-1", {
							"u-width-25%": isPlacePage,
							"u-fill-width": !isPlacePage,
						})}
						role="button"
						tabIndex={0}
						onClick={() => handleTap()}
						onKeyDown={() => handleTap()}
					>
						{showMemberMenu && !loading && <MembershipMenuMember />}
						{showNonMemberMenu && !loading && <MembershipMenuNonMember />}
					</div>
				</>
			);
		}
		if (!showCode && !isEmpty(identity)) {
			return (
				<div className="u-flex u-ai-center u-fill-space">
					<Identity />
				</div>
			);
		}
		return null;
	}, [
		identity,
		endSession,
		loading,
		showCode,
		showMemberMenu,
		showNonMemberMenu,
		menuMinimized,
		showEndSession,
		seenWelcome,
		i18n.language,
	]);

	return <div className="u-flex u-flex-grow-1 u-fill-height c-membership-menu">{identityMenu}</div>;
};

export default memo(MembershipMenu);
