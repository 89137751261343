/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { equals, isEmpty, isNil } from "ramda";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Switch from "react-switch";

import { useQuery } from "@/hooks/Router";
import { useAllFilters, useFilterIDs } from "@/store/filters/filters.hooks";
import { useAllStores } from "@/store/ui/ui.hooks";

import {
	setResetModal,
	setShoesModal,
	setLanguageModal,
	setOptionsModal,
	setShoeNotFoundModal,
} from "@/store/ui/ui.actions";

import IDLE_TIMER_DEFAULT_TIMING from "@/constants/idle-timer";

import Typography from "@/components/Typography";
import Display from "@/components/Display";
import TopBar from "@/components/TopBar";
import Button from "@/components/Button";
import NumPad from "@/components/NumPad";

const Setup = () => {
	const query = useQuery();
	const location = useLocation();
	const dispatch = useDispatch();
	const history = useHistory();
	const password = useRef(null);
	const filterIDs = useFilterIDs();
	const { data: stores } = useAllStores();
	const { data: filters, loading: filtersLoading } = useAllFilters();

	const [adminAccess, setAdminAccess] = useState(false);

	const qForm = query.get("form") || "";
	const [form, setForm] = useState(qForm);
	const qStore = query.get("store") || "";
	const [store, setStore] = useState(qStore);
	const qCategory = query.get("default-category") || "";
	const [category, setCategory] = useState(qCategory);
	const qGender = query.get("default-gender") || "";
	const [gender, setGender] = useState(qGender);
	const qFilter = query.get("filter-type") || "";
	const [filter, setFilter] = useState(qFilter);

	const qLanguage = query.get("default-language") || "";
	const [language, setLanguage] = useState(qLanguage);
	const qELanguage = query.get("enabled-languages") || "";
	const [eLanguage, setELanguage] = useState(qELanguage);
	const qTable = query.get("table-id") || "";
	const [table, setTable] = useState(qTable);
	const qIdle = query.get("idle-time") || `${IDLE_TIMER_DEFAULT_TIMING.ATTRACT}`;
	const [idle, setIdle] = useState(qIdle);
	const qSessionTime = query.get("session-time") || `${IDLE_TIMER_DEFAULT_TIMING.SESSION}`;
	const [sessionTime, setSessionTime] = useState(qSessionTime);
	const qMockEpcs = query.get("mock-epcs") || "";
	const [mockEpcs, setMockEpcs] = useState(qMockEpcs);
	const qLowGPU = query.get("low-gpu") || "";
	const [lowGPU, setLowGPU] = useState(qLowGPU);
	const qDebug = query.get("debug") || "";
	const [debug, setDebug] = useState(qDebug);
	const qCdnUrl = query.get("cdn-url") || "";
	const [cdnUrl, setCdnUrl] = useState(decodeURI(qCdnUrl));

	const qDemo = equals(query.get("demo"), "true");
	const [demo, setDemo] = useState(qDemo);
	const qShowStock = equals(query.get("show-stock"), "true");
	const [showStock, setShowStock] = useState(qShowStock);
	const qShowPrice = equals(query.get("show-price"), "true");
	const [showPrice, setShowPrice] = useState(qShowPrice);
	const qScreensaver = equals(query.get("screensaver"), "true");
	const [screensaver, setScreensaver] = useState(qScreensaver);
	const qObserve = equals(query.get("observe"), "true");
	const [observe, setObserve] = useState(qObserve);
	const qMembership = equals(query.get("membership"), "true");
	const [membership, setMembership] = useState(qMembership);
	const qSessionReload = equals(query.get("session-reload"), "true");
	const [sessionReload, setSessionReload] = useState(qSessionReload);
	const qElectronReload = equals(query.get("electron-reload"), "true");
	const [electronReload, setElectronReload] = useState(qElectronReload);
	const qSendAnalytics = equals(query.get("send-analytics"), "true");
	const [sendAnalytics, setSendAnalytics] = useState(qSendAnalytics);
	const qFavorites = equals(query.get("favorites"), "true");
	const [favorites, setFavorites] = useState(qFavorites);

	const qNewEPC = equals(query.get("new-epc"), "true");
	const [newEPC, setNewEPC] = useState(qNewEPC);

	const [params, setParams] = useState("");

	const isElectron = navigator.userAgent.toLowerCase().indexOf(" electron/") > -1;

	const handleReload = () => {
		window.location.reload();
	};

	useEffect(() => {
		dispatch(setShoesModal(false));
		dispatch(setLanguageModal(false));
		dispatch(setOptionsModal(false));
		dispatch(setShoeNotFoundModal(false, { type: "not_found", side: "left" }));
		dispatch(setResetModal(false));
		if (!isNil(password.current)) password.current.click();
	}, []);

	useEffect(() => {
		if (!isEmpty(filterIDs.category) && !isEmpty(filterIDs.gender)) {
			const enCategories = Object.values(filterIDs.category.find((el) => el.en).en);
			const enGenders = Object.values(filterIDs.gender.find((el) => el.en).en);
			// Convert EN to IDs for select inputs
			const categoryID =
				!filtersLoading && !isEmpty(filterIDs.category) && !isEmpty(category)
					? enCategories.filter((item) => item.val === category)[0]?.id
					: "";
			const genderID =
				!filtersLoading && !isEmpty(filterIDs.gender) && !isEmpty(gender)
					? enGenders.filter((item) => item.val === gender)[0]?.id
					: "";
			if (!isEmpty(category)) setCategory(categoryID);
			if (!isEmpty(gender)) setGender(genderID);
		}
	}, [filterIDs]);

	const handleSave = () => {
		const enCategories = Object.values(filterIDs.category.find((el) => el.en).en);
		const enGenders = Object.values(filterIDs.gender.find((el) => el.en).en);
		// Convert Korean to English
		const categoryEN =
			!filtersLoading && !isEmpty(filterIDs.category) && !isEmpty(category)
				? enCategories.filter((item) => item.id === category)[0].val
				: "";
		const genderEN =
			!filtersLoading && !isEmpty(filterIDs.gender) && !isEmpty(gender)
				? enGenders.filter((item) => item.id === gender)[0].val
				: "";

		const tempParams = new URLSearchParams({
			...(!isEmpty(form) && { form }),
			...(!isEmpty(store) && { store }),
			...(!isEmpty(category) && { "default-category": categoryEN }),
			...(!isEmpty(gender) && { "default-gender": genderEN }),
			...(!isEmpty(filter) && filter !== "active" && { "filter-type": filter }),
			...(!isEmpty(language) && { "default-language": language }),
			...(!isEmpty(eLanguage) && { "enabled-languages": eLanguage }),
			...(!isEmpty(table) && { "table-id": table }),
			...(!isEmpty(idle) && idle !== `${IDLE_TIMER_DEFAULT_TIMING.ATTRACT}` && { "idle-time": idle }),
			...(!isEmpty(sessionTime) &&
				sessionTime !== `${IDLE_TIMER_DEFAULT_TIMING.SESSION}` && { "session-time": sessionTime }),
			...(!isEmpty(mockEpcs) && { "mock-epcs": mockEpcs }),
			...(!isEmpty(lowGPU) && { "low-gpu": lowGPU }),
			...(!isEmpty(debug) && { debug }),
			...(!isEmpty(cdnUrl) && { "cdn-url": encodeURI(cdnUrl) }),
			...(!isEmpty(demo) && demo && { demo }),
			...(!isEmpty(showStock) && showStock && { "show-stock": showStock }),
			...(!isEmpty(showPrice) && showPrice && { "show-price": showPrice }),
			...(!isEmpty(screensaver) && screensaver && { screensaver }),
			...(!isEmpty(observe) && observe && { observe }),
			...(!isEmpty(newEPC) && newEPC && { "new-epc": newEPC }),
			...(!isEmpty(membership) && membership && { membership }),
			...(!isEmpty(sessionReload) && sessionReload && { "session-reload": sessionReload }),
			...(!isEmpty(electronReload) && electronReload && { "electron-reload": electronReload }),
			...(!isEmpty(sendAnalytics) && sendAnalytics && { "send-analytics": sendAnalytics }),
			...(!isEmpty(favorites) && favorites && { favorites }),
		});
		setParams(`${tempParams}`);

		history.push(`${location.pathname}?${tempParams}`);
		if (!isElectron) window.location.reload();
	};

	return (
		<Display className="u-fill-height">
			<div className="u-fill-height u-grid u-cols-4 u-rows-10">
				<TopBar className="u-col-2 u-col-start-2" flipVisible />
				{adminAccess && !isEmpty(filters) && !isEmpty(stores) ? (
					<>
						<div className="u-col-2 u-col-start-2">
							<Typography className="u-text-c u-flex u-col" tag="h1" size="5xl" uppercase primary>
								Table Setup
							</Typography>
						</div>
						<div className="u-col-4 u-col-start-1 u-row-4 u-row-start-3 u-flex u-col">
							<div className="u-flex u-row u-jc-center u-mt-12">
								<div className="p-place__center u-width-50%">
									<label htmlFor="iForm" className="u-flex u-mt-4">
										<select
											id="iForm"
											className="u-width-50%"
											value={form}
											onChange={(e) => setForm(e.target.value)}
										>
											<option value="rise">Rise</option>
											<option value="vertical">Vertical - One Nike</option>
											<option value="horizontal">Horizontal - One Nike</option>
										</select>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Form Factor
										</Typography>
									</label>
									<label htmlFor="iNikeStoreID" className="u-flex u-mt-4">
										{stores ? (
											<select
												id="iNikeStoreID"
												className="u-width-50%"
												value={store}
												onChange={(e) => setStore(e.target.value)}
											>
												<option value="">None</option>
												{stores
													.filter((item) => {
														return !isNil(item.nike_store_id);
													})
													.map((item) => (
														<option key={item.nike_store_id} value={item.nike_store_id}>
															{item.name}
														</option>
													))}
											</select>
										) : (
											<input
												id="iNikeStoreID"
												className="u-width-50%"
												type="text"
												value={store}
												onChange={(e) => setStore(e.target.value)}
											/>
										)}
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Nike Store ID*
										</Typography>
									</label>
									<label htmlFor="iCategory" className="u-flex u-mt-4">
										<select
											id="iCategory"
											className="u-width-50%"
											value={category}
											onChange={(e) => setCategory(e.target.value)}
										>
											<option value="">None</option>
											{filters.category.options.map((item) => (
												<option key={item.id} value={item.id}>
													{item.label}
												</option>
											))}
										</select>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Default Category
										</Typography>
									</label>
									<label htmlFor="iGender" className="u-flex u-mt-4">
										<select
											id="iGender"
											className="u-width-50%"
											value={gender}
											onChange={(e) => setGender(e.target.value)}
										>
											<option value="">None</option>
											{filters.gender.options.map((item) => (
												<option key={item.id} value={item.id}>
													{item.label}
												</option>
											))}
										</select>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Default Gender
										</Typography>
									</label>
									<label htmlFor="iFilter" className="u-flex u-mt-4">
										<select
											id="iFilter"
											className="u-width-50%"
											value={filter}
											onChange={(e) => setFilter(e.target.value)}
										>
											<option value="active">Active (default)</option>
											<option value="none">None</option>
											<option value="deactivated">Deactivated</option>
											<option value="activatedFuture">Activated Future</option>
											<option value="activeOrActivatedFuture">Active or Activated Future</option>
										</select>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Filter Type
										</Typography>
									</label>
									<label htmlFor="iLanguage" className="u-flex u-mt-4">
										<input
											id="iLanguage"
											className="u-width-50%"
											type="text"
											value={language}
											onChange={(e) => setLanguage(e.target.value)}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Default Language
										</Typography>
									</label>
									<label htmlFor="iELanguage" className="u-flex u-mt-4">
										<input
											id="iELanguage"
											className="u-width-50%"
											type="text"
											value={eLanguage}
											onChange={(e) => setELanguage(e.target.value)}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Enabled Languages
										</Typography>
									</label>
									<label htmlFor="iTable" className="u-flex u-mt-4">
										<input
											id="iTable"
											className="u-width-50%"
											type="text"
											value={table}
											onChange={(e) => setTable(e.target.value)}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Table ID
										</Typography>
									</label>
									<label htmlFor="iIdle" className="u-flex u-mt-4">
										<input
											id="iIdle"
											className="u-width-50%"
											type="text"
											value={idle}
											onChange={(e) => setIdle(e.target.value)}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Idle Time
										</Typography>
									</label>
									<label htmlFor="iSessionTime" className="u-flex u-mt-4">
										<input
											id="iSessionTime"
											className="u-width-50%"
											type="text"
											value={sessionTime}
											onChange={(e) => setSessionTime(e.target.value)}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											End Session Time
										</Typography>
									</label>
									<label htmlFor="iMockEpcs" className="u-flex u-mt-4">
										<input
											id="iMockEpcs"
											className="u-width-50%"
											type="text"
											value={mockEpcs}
											onChange={(e) => setMockEpcs(e.target.value)}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Mock EPCs
										</Typography>
									</label>
									<label htmlFor="iLowGPU" className="u-flex u-mt-4">
										<input
											id="iLowGPU"
											className="u-width-50%"
											type="text"
											value={lowGPU}
											onChange={(e) => setLowGPU(e.target.value)}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Low GPU (lang code / true)
										</Typography>
									</label>
									<label htmlFor="iDebug" className="u-flex u-mt-4">
										<input
											id="iDebug"
											className="u-width-50%"
											type="text"
											value={debug}
											onChange={(e) => setDebug(e.target.value)}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Debug (1-5)
										</Typography>
									</label>
									<label htmlFor="iCdnUrl" className="u-flex u-mt-4">
										<input
											id="iCdnUrl"
											className="u-width-50%"
											type="text"
											value={cdnUrl}
											onChange={(e) => setCdnUrl(e.target.value)}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											CDN Url
										</Typography>
									</label>
								</div>
								<div className="p-place__center">
									<>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
									<label htmlFor="iDemo" className="u-flex u-mt-4">
										<Switch id="iDemo" onChange={() => setDemo(!demo)} checked={demo} />
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Demo Mode
										</Typography>
									</label>
									<>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
									<label htmlFor="iShowStock" className="u-flex u-mt-4">
										<Switch
											id="iShowStock"
											onChange={() => setShowStock(!showStock)}
											checked={showStock}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Show Stock
										</Typography>
									</label>
									<>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
									<label htmlFor="iShowPrice" className="u-flex u-mt-4">
										<Switch
											id="iShowPrice"
											onChange={() => setShowPrice(!showPrice)}
											checked={showPrice}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Show Price
										</Typography>
									</label>
									<>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
									<label htmlFor="iScreensaver" className="u-flex u-mt-4">
										<Switch
											id="iScreensaver"
											onChange={() => setScreensaver(!screensaver)}
											checked={screensaver}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Screensaver
										</Typography>
									</label>
									<>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
									<label htmlFor="iObserve" className="u-flex u-mt-4">
										<Switch id="iObserve" onChange={() => setObserve(!observe)} checked={observe} />
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Observe
										</Typography>
									</label>
									<>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
									<label htmlFor="iNewEPC" className="u-flex u-mt-4">
										<Switch id="iNewEPC" onChange={() => setNewEPC(!newEPC)} checked={newEPC} />
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Use New EPC Conversion
										</Typography>
									</label>
									<>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
									<label htmlFor="iMembership" className="u-flex u-mt-4">
										<Switch
											id="iMembership"
											onChange={() => setMembership(!membership)}
											checked={membership}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Membership
										</Typography>
									</label>
									<>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
									<label htmlFor="iSessionReload" className="u-flex u-mt-4">
										<Switch
											id="iSessionReload"
											onChange={() => setSessionReload(!sessionReload)}
											checked={sessionReload}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Session Reload
										</Typography>
									</label>
									<>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
									<label htmlFor="iElectronReload" className="u-flex u-mt-4">
										<Switch
											id="iElectronReload"
											onChange={() => setElectronReload(!electronReload)}
											checked={electronReload}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Electron Reload
										</Typography>
									</label>
									<>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
									<label htmlFor="iSendAnalytics" className="u-flex u-mt-4">
										<Switch
											id="iSendAnalytics"
											onChange={() => setSendAnalytics(!sendAnalytics)}
											checked={sendAnalytics}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Send Analytics
										</Typography>
									</label>
									<>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
									<label htmlFor="iFavorites" className="u-flex u-mt-4">
										<Switch
											id="iFavorites"
											onChange={() => setFavorites(!favorites)}
											checked={favorites}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Favorites
										</Typography>
									</label>
									{/* <>eslint-disable-next-line jsx-a11y/label-has-associated-control</>
									<label htmlFor="iMembershipSimulator" className="u-flex u-mt-4">
										<Switch
											id="iMembershipSimulator"
											onChange={() => setMembershipSimulator(!membershipSimulator)}
											checked={membershipSimulator}
										/>
										<Typography
											className="u-text-c u-flex u-col u-ml-6"
											tag="span"
											size="md"
											primary
										>
											Membership Simulator
										</Typography>
									</label> */}
								</div>
							</div>
							<div className="u-row-2 u-flex u-jc-center u-ai-center u-mt-12">
								<Button
									type="button"
									onClick={handleSave}
									className="c-button--rounded u-mt-2 u-mr-6 c-updateTable-button"
								>
									<Button.Text>Save and Reload</Button.Text>
								</Button>
							</div>
							<div style={{ position: "absolute", left: 30, top: 30 }}>
								<Button type="button" className="c-button--rounded c-button--secondary c-reboot-button">
									<Button.Text>Reboot</Button.Text>
								</Button>
							</div>
							<div style={{ position: "absolute", left: 30, bottom: 30 }}>
								<Button
									type="button"
									onClick={handleReload}
									className="c-button--rounded u-mt-2 u-mr-6"
								>
									<Button.Text>Reload</Button.Text>
								</Button>
							</div>
						</div>
					</>
				) : (
					<div className="u-flex u-jc-center u-col-2 u-col-start-2 u-row-6">
						<NumPad.Number
							onChange={(value) => {
								if (value === "1111") {
									setAdminAccess(true);
								} else handleReload();
							}}
							placeholder="Password"
							value=""
							decimal={false}
							negative={false}
							password
						>
							<input type="password" ref={password} />
						</NumPad.Number>
					</div>
				)}
				<input id="updateTableParams" hidden onChange={() => {}} value={params} />
			</div>
		</Display>
	);
};

export default Setup;
