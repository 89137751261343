import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import { AnimateSharedLayout, motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { isNil } from "ramda";

import { LANGUAGES } from "@/constants/language";
import { DEFAULT_TRANSITION } from "@/constants/transition";

import { setLanguageModal } from "@/store/ui/ui.actions";

import { fade } from "@/animations";

import { useQuery } from "@/hooks/Router";

import Button from "../Button.component";
import defaultProps from "../Button.defaultProps";
import propTypes from "../Button.propTypes";

// =============================================================================
// Root
// =============================================================================
const ButtonLanguage = ({ className = "", withShadow = true, vertical = false }) => {
	const query = useQuery();

	const text = useRef(null);
	const visibilityTimeout = useRef();

	const [active, setIsActive] = useState(0);
	const [isVisible, setIsVisible] = useState(true);

	const { t } = useTranslation();
	const dispatch = useDispatch();

	let enabledLanguages = LANGUAGES;
	let qEnabledLang = query.get("enabled-languages") || null;

	if (!isNil(qEnabledLang)) {
		qEnabledLang = qEnabledLang.split(",");
		enabledLanguages = LANGUAGES.filter((lang) => qEnabledLang.includes(lang.CODE));
	}

	const items = enabledLanguages.map(({ CODE }) => t("generic.select_language", { lng: CODE }));

	const clearVisibilityTimeout = () => {
		if (visibilityTimeout.current) {
			clearTimeout(visibilityTimeout.current);
		}
	};

	const startVisibilityTimeout = () => {
		clearVisibilityTimeout();

		visibilityTimeout.current = setTimeout(() => setIsVisible(false), 2000);
	};

	const onAnimationComplete = () => {
		clearVisibilityTimeout();

		if (!isVisible) {
			setIsActive(active >= items.length - 1 ? 0 : active + 1);
			setIsVisible(true);
			return;
		}

		startVisibilityTimeout();
	};

	useEffect(() => {
		startVisibilityTimeout();

		return () => {
			setIsActive(0);
			setIsVisible(false);
			clearVisibilityTimeout();
		};
	}, []);

	return (
		<div
			className={cn("u-flex u-ai-center u-jc-center u-width-45 u-text-c", {
				"u-width-45": !enabledLanguages.find(({ CODE }) => CODE === "fr"),
				"u-width-58": enabledLanguages.find(({ CODE }) => CODE === "fr"),
			})}
		>
			<Button
				className={cn(
					"c-button--rounded u-inline-flex u-jc-center u-ai-center u-inline-3 u-text-nowrap u-fill-width",
					{
						"c-button--float": withShadow,
						"c-button--ghost": vertical,
					},
					className
				)}
				onClick={() => dispatch(setLanguageModal(true))}
			>
				<AnimateSharedLayout>
					<motion.span layout>
						<Button.Icon className="c-icon--size-4" type="language" />
					</motion.span>
					<Button.Text>
						<motion.span
							layout
							animate={isVisible ? "show" : "hidden"}
							ref={text}
							variants={fade}
							onAnimationComplete={onAnimationComplete}
							transition={DEFAULT_TRANSITION}
						>
							{items[active]}
						</motion.span>
					</Button.Text>
				</AnimateSharedLayout>
			</Button>
		</div>
	);
};

ButtonLanguage.propTypes = propTypes;
ButtonLanguage.defaultProps = defaultProps;

export default ButtonLanguage;
