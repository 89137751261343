import React, { memo } from "react";
import cn from "classnames";

import ButtonIcon from "@/components/ButtonIcon";

import propTypes from "./ShoePickerNavigation.propTypes";
import defaultProps from "./ShoePickerNavigation.defaultProps";

// =============================================================================
// Root
// =============================================================================
const ShoePickerNavigation = ({ className = "", step, setCurrentSlide, inverted = false }) => {
	return (
		<div className={cn("c-slider-nav--shoe-picker u-jc-end", { "s-visual": !inverted }, className)}>
			<ButtonIcon
				className="c-icon--size-4 c-button-icon--large"
				icon="chevron-left"
				onClick={() => setCurrentSlide(step - 1)}
			/>
			<ButtonIcon
				className="c-icon--size-4 c-button-icon--large"
				icon="chevron-right"
				onClick={() => setCurrentSlide(step + 1)}
			/>
		</div>
	);
};

ShoePickerNavigation.propTypes = propTypes.ShoePickerNavigation;
ShoePickerNavigation.defaultProps = defaultProps.ShoePickerNavigation;

export default memo(ShoePickerNavigation);
