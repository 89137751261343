import { BANNED_SCC } from "@/constants/banned";

import { sentryLogError } from "@/utils/sentry";

const isSCCBanned = ({ style_color_code: styleColorCode = "" }) => {
	const isBanned = BANNED_SCC.includes(styleColorCode);

	if (isBanned) {
		sentryLogError("Banned Shoe", [{ name: "SCC", value: styleColorCode }], `Banned Shoe - ${styleColorCode}`);
	}

	return isBanned;
};

// INFO: Filters out banned SCC codes
const getAcceptedShoes = (items) => {
	return items.filter((item) => !isSCCBanned(item));
};

export default getAcceptedShoes;
