import { isEmpty, isNil } from "ramda";

const ALLOWED_TYPES = ["number", "string"];
const THOUSANDS_SEPARATOR_REGEX = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/;

// INFO: this function will always return a string with a comma as thousands separators.
// EXAMPLES: 2954 -> "2,954" | "2954" -> "2,954"
const formatNumber = (number) => {
	if (isNil(number) || !ALLOWED_TYPES.includes(typeof number) || isEmpty(number)) {
		return "";
	}

	const numberToFormat = typeof number === "number" ? number.toString() : number;

	return numberToFormat.replace(THOUSANDS_SEPARATOR_REGEX, ",");
};

export default formatNumber;
