import React, { memo } from "react";
import cn from "classnames";

import propTypes from "./Icon.propTypes";
import defaultProps from "./Icon.defaultProps";

// =============================================================================
// Root
// =============================================================================
const Icon = ({ className = "", type }) => (
	<div className={cn("c-icon", className)}>
		<svg>
			<use xlinkHref={`#${type}`} />
		</svg>
	</div>
);

Icon.propTypes = propTypes;

Icon.defaultProps = defaultProps;

export default memo(Icon);
