import React, { useState, useEffect, memo } from "react";
import cn from "classnames";
import { motion } from "framer-motion";

import Typography from "@/components/Typography";
import { DEFAULT_EASE, DEFAULT_DURATION } from "@/constants/transition";

import propTypes from "./Countdown.propTypes";
import defaultProps from "./Countdown.defaultProps";

// These settings make sure you can work with percentages inside the strokeDasharray attribute
const CIRCLE_CONFIG = {
	viewBox: "0 0 38 38",
	x: "19",
	y: "19",
	radius: "15.91549430918954",
	strokeWidth: 2,
	initialOffset: 25,
};

// =============================================================================
// Root
// =============================================================================
const Countdown = ({ className = "", duration, isOpen, onFinished }) => {
	const [counter, setCounter] = useState(duration);

	useEffect(() => {
		let timer = null;

		if (isOpen && counter > 0) {
			timer = setInterval(() => setCounter((prevState) => prevState - 1), 1000);
		}

		return () => {
			if (timer) {
				clearInterval(timer);
				timer = null;
			}

			setCounter(0);
		};
	}, [isOpen]);

	useEffect(() => {
		if (counter === 0) {
			onFinished();
		}
	}, [counter]);

	const prefixCounter = () => (counter < 10 ? `0${counter}` : counter);

	const percentage = (counter / duration) * 100;

	return (
		<div className={cn("c-countdown", className)}>
			<div className="c-countdown__text u-text-c">
				<Typography uppercase lineHeight={false} size="5xl" tag="h2" weight="bold">
					{counter === 0 ? `00` : prefixCounter()}
				</Typography>
			</div>

			<svg viewBox={CIRCLE_CONFIG.viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle
					className="c-countdown__progress"
					cx={CIRCLE_CONFIG.x}
					cy={CIRCLE_CONFIG.y}
					r={CIRCLE_CONFIG.radius}
					strokeWidth={CIRCLE_CONFIG.strokeWidth}
					fill="transparent"
					fillOpacity="0"
				/>

				<motion.circle
					className="c-countdown__circle"
					transition={{ ease: DEFAULT_EASE, duration: DEFAULT_DURATION / 2 }}
					animate={{ strokeDasharray: `${100 - percentage} ${percentage}` }}
					cx={CIRCLE_CONFIG.x}
					cy={CIRCLE_CONFIG.y}
					r={CIRCLE_CONFIG.radius}
					strokeWidth={CIRCLE_CONFIG.strokeWidth + 0.2}
					fill="transparent"
					fillOpacity="0"
					strokeDashoffset={CIRCLE_CONFIG.initialOffset}
				/>
			</svg>
		</div>
	);
};

Countdown.propTypes = propTypes;
Countdown.defaultProps = defaultProps;

export default memo(Countdown);
