import React, { memo, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { isNil, isEmpty } from "ramda";
import { useLocation, useHistory } from "react-router-dom";
import cn from "classnames";

import Modal from "@/components/Modal";
import {
	MembershipMenuPersonalizing,
	MembershipMenuIdentityRail,
	MembershipMenuIdentityStats,
} from "@/components/Membership";

import { useQuery } from "@/hooks/Router";

import { useMembershipModal } from "@/store/ui/ui.hooks";
import { setInteractionLock } from "@/store/rfid/rfid.actions";
import { useIsDetail, useIsCompare, useIsInactive, useActiveShoes } from "@/store/shoes/shoes.hooks";

import { useIdentity, useSeenWelcome, useShowStats } from "@/store/membership/membership.hooks";

import { setShoesModal, setLanguageModal, setOptionsModal } from "@/store/ui/ui.actions";
import { setSeenWelcome } from "@/store/membership/membership.actions";

import ROUTES from "@/constants/routes";

const dayjs = require("dayjs");

// =============================================================================
// Root
// =============================================================================
const MembershipModal = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const query = useQuery();

	const { isOpen, toggleIsOpen } = useMembershipModal();
	const { data: identity, loading } = useIdentity();
	const seenWelcome = useSeenWelcome();
	const isDetail = useIsDetail();
	const isCompare = useIsCompare();
	const showStats = useShowStats();

	const minimizeTimeout = useRef();

	const qForm = query.get("form") || "rise";

	const direction = qForm === "vertical" ? "vh" : "vw";

	const size = qForm === "vertical" ? 2 : 1.4067;
	const grid = [0, size, size * 2, size * 3, size * 4, size * 5, size * 6];

	const ntc = [];
	const nrc = [];

	const clearMinimizeTimeout = () => {
		if (isNil(minimizeTimeout.current)) {
			return;
		}

		clearTimeout(minimizeTimeout.current);
		minimizeTimeout.current = null;
	};

	const startMinimizeTimeout = () => {
		clearMinimizeTimeout();
		minimizeTimeout.current = setTimeout(() => {
			hideWelcomeMenu();
		}, 14250);
	};

	const hideWelcomeMenu = () => {
		dispatch(setSeenWelcome(true));
		toggleIsOpen();
		clearMinimizeTimeout();
		if (isDetail && location.pathname !== ROUTES.Detail)
			history.push({ pathname: ROUTES.Detail, search: location.search });
		else if (isCompare && location.pathname !== ROUTES.Compare)
			history.push({ pathname: ROUTES.Compare, search: location.search });
	};

	useEffect(() => {
		clearMinimizeTimeout();
		dispatch(setInteractionLock(true));
		dispatch(setShoesModal(false));
		dispatch(setLanguageModal(false));
		dispatch(setOptionsModal(false));
	}, [identity, loading]);

	if (!isEmpty(identity)) {
		const workoutPrivacyAccepted =
			identity.profile && identity.profile.healthdata && identity.profile.healthdata.enhancedAcceptance;

		if (identity.aggregates && workoutPrivacyAccepted) {
			if (identity.aggregates.run && identity.aggregates.run.activity[0].count > 0)
				nrc.push(identity.aggregates.run.activity[0]);
			if (identity.aggregates.basketball && identity.aggregates.basketball.activity[0].count > 0)
				ntc.push(identity.aggregates.basketball.activity[0]);
			if (identity.aggregates.hiit && identity.aggregates.hiit.activity[0].count > 0)
				ntc.push(identity.aggregates.hiit.activity[0]);
			if (identity.aggregates.hiking && identity.aggregates.hiking.activity[0].count > 0)
				ntc.push(identity.aggregates.hiking.activity[0]);
			if (identity.aggregates.training && identity.aggregates.training.activity[0].count > 0)
				ntc.push(identity.aggregates.training.activity[0]);
		}
	}

	return (
		<Modal
			className={cn("options", {
				vertical: qForm === "vertical",
				rise: qForm === "rise",
			})}
			isOpen={isOpen}
			onClose={() => {
				toggleIsOpen();
				hideWelcomeMenu();
			}}
		>
			<div>
				<div
					style={{ height: `${grid[6]}${direction}` }}
					className={cn("u-flex u-ai-start u-jc-center", {
						"": qForm === "vertical",
					})}
				>
					{loading && <MembershipMenuPersonalizing />}
					{!loading && !seenWelcome && !isEmpty(identity) && !isNil(showStats) && (
						<div
							className="u-flex u-fill-width u-fill-height fill-color"
							style={{ marginTop: `-${size / 2}${direction}` }}
							role="button"
							tabIndex={0}
							onClick={() => handleIdentityTap()}
							onKeyDown={() => handleIdentityTap()}
						>
							<MembershipMenuIdentityRail
								name={identity.firstName}
								year={dayjs(identity.profile.registration?.timestamp).format("YYYY")}
								duration={showStats ? 6 : 10}
								startMinimizeTimeout={startMinimizeTimeout}
								hideWelcomeMenu={showStats ? null : hideWelcomeMenu}
							/>
							{showStats && (
								<MembershipMenuIdentityStats
									name={identity.firstName}
									nrc={nrc}
									duration={8}
									delay={6}
								/>
							)}
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default memo(MembershipModal);
