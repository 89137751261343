import React from "react";
import { motion } from "framer-motion";
import cn from "classnames";

import { getScale, getScaleWithProps } from "@/animations";

import Button, { MotionButton } from "../Button.component";
import defaultProps from "../Button.defaultProps";
import propTypes from "../Button.propTypes";

// =============================================================================
// Root
// =============================================================================
const Button1 = ({
	className = "",
	children,
	type = "",
	url,
	onClick,
	delay = 0.2,
	icon,
	iconPosition = "right",
	iconClassName,
}) => (
	<MotionButton
		url={url}
		type={type}
		className={cn("u-overflow-hidden u-text-nowrap", className)}
		onClick={onClick}
		animate="show"
		initial="hidden"
		variants={getScaleWithProps({ originX: 0, originY: 0 })}
	>
		{iconPosition === "left" && icon && (
			<motion.span variants={getScale({ delay })}>
				<Button.Icon className={cn("c-icon--size-3", iconClassName)} type={icon} />
			</motion.span>
		)}

		<Button.Text>{children}</Button.Text>

		{iconPosition !== "left" && icon && (
			<motion.span variants={getScale({ delay })}>
				<Button.Icon className={cn("c-icon--size-3", iconClassName)} type={icon} />
			</motion.span>
		)}
	</MotionButton>
);

Button1.propTypes = propTypes;
Button1.defaultProps = defaultProps;

export default Button1;
