import React, { useState, useRef, memo, useEffect } from "react";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { equals, isNil, pathOr, propEq, find, isEmpty, findIndex } from "ramda";
import { useTranslation } from "react-i18next";
import { wrap } from "popmotion";
import { v4 as uuidv4 } from "uuid";

import { SIDE } from "@/constants/rfid-tags";
import { FILTER_KEYS } from "@/constants/filters";
import SWIPE_TRESHOLD from "@/constants/events";
// import AVAILABILITY from "@/constants/availability";

import ShoePreview from "@/components/ShoePreview";
// import Status from "@/components/Status";
import FilterDropdown from "@/components/FilterDropdown";
import Typography from "@/components/Typography";
import Modal from "@/components/Modal";
import ShoePickerNavigation from "@/components/ShoePickerNavigation";

import { setLatestAction, setShoesModal, setActiveSession } from "@/store/ui/ui.actions";
import { getShoeBySCC, setActive, fetchShoeInventory, fetchShoePrice } from "@/store/shoes/shoes.actions";

import {
	useShoesModal,
	useOptionsModal,
	useFlippedOrientation,
	useStoreID,
	useQAMode,
	useActiveSession,
} from "@/store/ui/ui.hooks";
import { useActiveShoes, useTableLocation, useImagemap } from "@/store/shoes/shoes.hooks";
import { useFilterIDs, useAllFilters, useActiveFilters } from "@/store/filters/filters.hooks";
import { useMembershipActive, useIdentity, useStyles } from "@/store/membership/membership.hooks";
import { use404List } from "@/store/rfid/rfid.hooks";

import { useQuery } from "@/hooks/Router";

import useFilteredShoes from "@/hooks/FilteredShoes";

import { fadeMove, slideFull } from "@/animations";

import swipePower from "@/utils/slider/swipePower";
import { flipSides } from "@/utils/shoes";
import setAnalytics from "@/utils/analytics";
import { sentryLogException } from "@/utils/sentry";
import { toTitleCase } from "@/utils/conversions";

const ShoePickerVertical = () => {
	const wrapperRef = useRef(null);
	const dispatch = useDispatch();
	const query = useQuery();
	const location = useTableLocation();
	const { t, i18n } = useTranslation();

	const qaMode = useQAMode();
	const isFlipped = useFlippedOrientation();
	const { filteredShoes, filters, loading, handleSetFilter } = useFilteredShoes();
	const filterIDs = useFilterIDs();
	const activeFilters = useActiveFilters();
	const { loading: filtersLoading } = useAllFilters();
	const { left, right } = useActiveShoes();
	const { isOpen, data: modalData } = useShoesModal();
	const { isOpen: isOptionsModalOpen, toggleIsOpen: toggelIsOptionsModalOpen } = useOptionsModal();
	const imagemap = useImagemap();
	const membershipActive = useMembershipActive();
	const { data: identity } = useIdentity();
	const { data: styles } = useStyles();
	const fourList = use404List();
	const { data: storeID } = useStoreID();
	const activeSession = useActiveSession();

	const [[page, direction], setPage] = useState([0, 0]);
	const [filterName, setFilterName] = useState("You");

	const store = query.get("store") || null;
	const showStock = query.get("show-stock") || false;
	const showPrice = query.get("show-price") || false;
	const qCdnUrl = query.get("cdn-url") || null;
	const qForm = query.get("form") || "rise";

	const AMOUNT_PER_VIEW = qForm === "vertical" ? 15 : 12;

	const shoeCount = filteredShoes.length;
	const totalPages = Math.ceil(shoeCount / AMOUNT_PER_VIEW);
	const pageIndex = wrap(0, totalPages, page);
	const isFirstPage = pageIndex === 0;
	const start = pageIndex * AMOUNT_PER_VIEW;
	const end = start + AMOUNT_PER_VIEW > shoeCount ? shoeCount : start + AMOUNT_PER_VIEW;
	const formattedShoes = filteredShoes.slice(start, end);

	let runningID = "";
	let footballID = "";
	let trainingID = "";

	// #region FUNCTIONS
	const handlePage = (newDirection) => {
		let newPage = page + newDirection;

		if (newPage < 0) newPage = totalPages - 1;
		if (newPage > totalPages - 1) newPage = 0;

		setPage([newPage, newDirection]);
	};

	const handleDragEnd = (e, { offset, velocity }) => {
		if (shoeCount <= AMOUNT_PER_VIEW) {
			return;
		}

		let swipe = swipePower(offset.x, velocity.x);

		if (isFlipped) {
			swipe = -swipe;
		}

		if (swipe < -SWIPE_TRESHOLD) {
			handlePage(1);
		} else if (swipe > SWIPE_TRESHOLD) {
			handlePage(-1);
		}
	};

	const getFilterLabelByKey = (key, filterId) => {
		return pathOr("", ["label"], find(propEq(["id"], filterId))(filters[key].options));
	};

	const checkIsAlreadyActive = (productScc) => {
		const leftScc = pathOr("", ["scc"], left);
		const rightScc = pathOr("", ["scc"], right);

		return !!(equals(leftScc, productScc) || equals(rightScc, productScc));
	};

	const getAvailableSide = () => {
		if (isNil(left)) {
			return SIDE.Left;
		}

		if (isNil(right)) {
			return SIDE.Right;
		}

		return SIDE.Left;
	};

	const setShoeActive = async (shoe, side = null) => {
		const activeSide = modalData?.side || getAvailableSide();
		const city = location.data.json_meta || { sportpulse_id: null };
		const marketplace = location.data.json_meta?.inside_track?.marketplace;

		const data = await dispatch(
			getShoeBySCC(
				shoe.id,
				"cache",
				store,
				city,
				imagemap,
				membershipActive,
				identity,
				filterIDs,
				shoe.recommendations,
				styles,
				fourList,
				marketplace,
				qCdnUrl,
				true
			)
		);

		if (!data) {
			return;
		}

		const shoeData = {
			epc: "",
			scc: shoe.id,
			detail: data,
			isDigital: true,
			isVisible: true,
			template: null,
		};

		dispatch(setActive(shoeData, !isNil(side) ? side : flipSides(isFlipped, activeSide)));
		dispatch(setLatestAction({ type: "select", id: uuidv4(), firstScc: shoe?.id }));

		if (showStock && !isEmpty(storeID))
			dispatch(fetchShoeInventory(storeID, shoe.id, !isNil(side) ? side : flipSides(isFlipped, activeSide)));
		if (showPrice && !isEmpty(storeID))
			dispatch(fetchShoePrice(storeID, shoe.id, !isNil(side) ? side : flipSides(isFlipped, activeSide)));

		dispatch(setShoesModal(false));

		if (isOptionsModalOpen) {
			toggelIsOptionsModalOpen();
		}
	};

	const getTitle = () => {
		const noFilters = Object.keys(activeFilters).every((key) => {
			const filter = activeFilters[key];
			return filter.activeFilter === null;
		});

		if (noFilters) {
			return t("generic.all");
		}

		const category = activeFilters?.category?.activeFilter;
		const gender = activeFilters?.gender?.activeFilter;
		const surface = activeFilters?.surface?.activeFilter;
		const activity = activeFilters?.activity?.activeFilter;

		let slug = ``;

		if (gender) {
			slug += getFilterLabelByKey("gender", gender);
			if (getFilterLabelByKey("gender", gender) === "Men" || getFilterLabelByKey("gender", gender) === "Women")
				slug += "'s";
			else if (getFilterLabelByKey("gender", gender) === "Boys") slug = "Boy's";
			else if (getFilterLabelByKey("gender", gender) === "Girls") slug = "Girl's";
		}

		if (category) {
			slug += " ";
			slug += getFilterLabelByKey("category", category);
		}

		if (surface) {
			slug += " ";
			slug += getFilterLabelByKey("surface", surface);
		}

		if (activity) {
			slug += " ";
			slug += getFilterLabelByKey("activity", activity);
		}

		if (activeFilters?.category?.activeFilter === "recommended") {
			return t("membership.shoepicker_filter", { name: filterName });
		}

		return t("generic.slug_shoes", { slug });
	};

	const handleKeyDown = (e) => {
		if (e.keyCode === 40 && qaMode) {
			// go to next shoe
			let activeSide = null;
			if (left && !isFlipped) activeSide = "left";
			else if (right && !isFlipped) activeSide = "right";
			else if (left && isFlipped) activeSide = "right";
			else if (right && isFlipped) activeSide = "left";
			const shoeID = left?.scc || right?.scc;
			let nextShoe = findIndex(propEq("id", shoeID), filteredShoes) + 1;
			if (nextShoe >= filteredShoes.length) nextShoe = 0;
			setShoeActive(filteredShoes[nextShoe], activeSide);
		}
		if (e.keyCode === 38 && qaMode) {
			// go to prev shoe
			let activeSide = null;
			if (left && !isFlipped) activeSide = "left";
			else if (right && !isFlipped) activeSide = "right";
			else if (left && isFlipped) activeSide = "right";
			else if (right && isFlipped) activeSide = "left";
			const shoeID = left?.scc || right?.scc;
			let nextShoe = findIndex(propEq("id", shoeID), filteredShoes) - 1;
			if (nextShoe < 0) nextShoe = filteredShoes.length - 1;
			setShoeActive(filteredShoes[nextShoe], activeSide);
		}
	};
	// #endregion FUNCTIONS

	// #region LIFECYCLE HOOKS
	useEffect(() => {
		setPage([0, direction]);
	}, [filters]);

	useEffect(() => {
		setFilterName(identity.firstName);
	}, [identity]);

	useEffect(() => {
		document.addEventListener("keydown", handleKeyDown, false);

		return () => {
			document.removeEventListener("keydown", handleKeyDown, false);
		};
	}, [left, right, qaMode]);

	useEffect(() => {
		try {
			if (!filtersLoading && !isEmpty(filterIDs.category)) {
				runningID = filterIDs.runningID;
				trainingID = filterIDs.trainingID;
				footballID = filterIDs.footballID;
			}
		} catch (e) {
			sentryLogException("Filter IDs No Running", e);
		}
	}, [filterIDs]);

	useEffect(() => {
		if (isOpen) setAnalytics("shoe_picker", { member: !isEmpty(identity) });
	}, [isOpen]);
	// #endregion LIFECYCLE HOOKS

	// #region RENDER
	if (loading || !Object.keys(filters).length) {
		return null;
	}

	const renderFilter = (key) => {
		const filter = filters[key];

		try {
			if (!filtersLoading && !isEmpty(filterIDs.category)) {
				runningID = filterIDs.runningID;
				trainingID = filterIDs.trainingID;
				footballID = filterIDs.footballID;
			}
		} catch (e) {
			sentryLogException("Filter IDs No Running", e);
		}

		let { options } = filter;

		const recommendedShoes = formattedShoes.filter((shoe) => shoe.recommendations.length > 0);

		if (!isEmpty(identity) && recommendedShoes.length > 0) {
			if (key === FILTER_KEYS.CATEGORY) {
				const option = find(propEq("id", "recommended"))(options);
				if (isNil(option)) {
					options = options.filter((element) => element.id !== "recommended");
					options.push({
						id: "recommended",
						label:
							i18n.language === "kr"
								? `${filterName}${t("generic.for")}`
								: `${t("generic.for")}${filterName}`,
					});
				}
			}
		}

		if (key === FILTER_KEYS.CATEGORY && isEmpty(identity)) {
			options = options.filter((element) => element.id !== "recommended");
		}

		if (key === FILTER_KEYS.CATEGORY) {
			const option = find(propEq("id", "allcategories"))(options);
			if (isNil(option)) options.unshift({ id: "allcategories", label: t("generic.all_filter") });
			const dance = find(propEq("label", "Dance"))(options);
			if (!isNil(dance)) options = options.filter((el) => el.label !== "Dance");
		}

		if (key === FILTER_KEYS.GENDER) {
			const option = find(propEq("id", "allgenders"))(options);
			if (isNil(option)) options.unshift({ id: "allgenders", label: t("generic.all_filter") });
		}

		if (key === FILTER_KEYS.ACTIVITY) {
			const option = find(propEq("id", "allactivities"))(options);
			if (isNil(option)) options.unshift({ id: "allactivities", label: t("generic.all_filter") });
		}

		if (key === FILTER_KEYS.SURFACE) {
			let newFilters = [];
			if (activeFilters[FILTER_KEYS.CATEGORY]?.activeFilter === runningID) newFilters = filters.runningSurfaces;
			else if (activeFilters[FILTER_KEYS.CATEGORY]?.activeFilter === footballID)
				newFilters = filters.footballSurfaces;
			options = newFilters.map((el) => {
				return { id: el.id, label: el.val };
			});
			const option = find(propEq("id", "allsurfaces"))(options);
			if (isNil(option)) options.unshift({ id: "allsurfaces", label: t("generic.all_filter") });
		}

		return (
			<FilterDropdown
				key={filter.label}
				options={options}
				activeFilter={activeFilters[key]?.activeFilter}
				label={filter.label}
				vertical
				onChange={(filterId) => {
					handleSetFilter(key, filterId, runningID, footballID, trainingID);
					setPage([0, direction]);
				}}
			/>
		);
	};

	return (
		<Modal
			className={cn("c-modal--fullscreen", {
				vertical: qForm === "vertical",
				horizontal: qForm === "horizontal",
				rise: qForm === "rise",
			})}
			isOpen={isOpen}
		>
			<div className="u-pt-20 u-fill-space u-overflow-hidden u-flex u-col u-jc-start u-divider-y c-grid-block">
				<AnimateSharedLayout>
					{/* Header */}
					<div className="u-flex u-px-13 u-ai-end u-jc-between u-pb-9">
						<div className="u-flex u-col">
							<Typography tag="h1" size="2xl" className="u-mt-2" uppercase primary>
								{getTitle()}
							</Typography>
						</div>

						{totalPages > 1 && (
							<ShoePickerNavigation
								className="u-flex-1"
								slides={totalPages}
								speed={0}
								step={page}
								setCurrentSlide={(newPage) => {
									const newDir = newPage > page ? 1 : -1;
									setPage([newPage, newDir]);
								}}
							/>
						)}

						<div className="u-flex">
							<Modal.Close
								onClose={() => {
									dispatch(setShoesModal(false));
								}}
							>
								{t("buttons.dismiss")}
							</Modal.Close>
						</div>
					</div>

					{/* Shoes */}
					<AnimatePresence exitBeforeEnter>
						<div className="u-px-10 u-overflow-hidden u-pb-20">
							<div className="u-flex u-flex-1 u-ai-center u-inline-7 u-mt-12 u-mb-34">
								{activeFilters[FILTER_KEYS.CATEGORY]?.activeFilter !== "recommended" &&
									renderFilter(FILTER_KEYS.GENDER)}
								{renderFilter(FILTER_KEYS.CATEGORY)}
								{(activeFilters[FILTER_KEYS.CATEGORY]?.activeFilter === runningID ||
									activeFilters[FILTER_KEYS.CATEGORY]?.activeFilter === footballID) &&
									!isNil(activeFilters[FILTER_KEYS.CATEGORY]?.activeFilter) &&
									renderFilter(FILTER_KEYS.SURFACE)}
								{activeFilters[FILTER_KEYS.CATEGORY]?.activeFilter === trainingID &&
									renderFilter(FILTER_KEYS.ACTIVITY)}
							</div>
							{shoeCount === 0 ? (
								<div className="p-shoes__no-shoes u-flex u-py-120 u-col u-jc-center u-ai-center">
									<Typography size="2xl" className="u-color-primary-tint" uppercase primary>
										{t("generic.noShoes")}
									</Typography>
								</div>
							) : (
								<motion.div
									ref={wrapperRef}
									drag="x"
									onDragEnd={handleDragEnd}
									whileHover={{ cursor: "pointer" }}
									whileDrag={{ pointerEvents: "none" }}
									dragElastic={1}
									initial="enter"
									animate="center"
									exit="exit"
									key={page}
									custom={isFlipped ? -direction : direction}
									variants={slideFull}
									dragConstraints={{ left: 0, right: 0 }}
									transformTemplate={({ x }) => {
										const xTransform = parseFloat(x, 10);
										return `translate3d(${isFlipped ? -xTransform : xTransform}px, 0, 0)`;
									}}
									transition={{
										x: { type: "spring", bounce: 0 },
										opacity: { duration: 0.2 },
									}}
								>
									<div
										className={cn(`p-shoes__grid`, {
											"p-shoes__grid--offset": !isFirstPage,
										})}
									>
										{formattedShoes.map((product, index) => {
											const { id, name, thumbnail, recommendations } = product;
											const isSelected = checkIsAlreadyActive(id);

											const shoePreviewProps = {
												...(!isSelected && {
													onClick: () => {
														if (!activeSession) {
															const tempSession = uuidv4();
															dispatch(setActiveSession(tempSession));
															dispatch(
																setLatestAction({
																	type: "session_start",
																	id: uuidv4(),
																	activeSession: tempSession,
																})
															);
														}
														setTimeout(() => setShoeActive(product), 100);
													},
												}),
												variants: fadeMove,
												key: id,
											};

											return (
												<motion.div
													{...shoePreviewProps}
													className={cn("u-height-58 c-shoe-preview--container", {
														"c-shoe-preview--selected": isSelected,
													})}
												>
													<div
														className={cn({
															"c-shoe-preview--outline": isSelected,
														})}
													/>
													<ShoePreview
														horizontal={qForm !== "vertical"}
														index={index}
														title={toTitleCase(name)}
														src={thumbnail}
														recommendations={recommendations}
														recommendedFilter={
															activeFilters?.category?.activeFilter === "recommended"
														}
													/>
												</motion.div>
											);
										})}
									</div>
								</motion.div>
							)}
						</div>
					</AnimatePresence>
				</AnimateSharedLayout>
			</div>
		</Modal>
	);
	// #endregion RENDER
};

export default memo(ShoePickerVertical);
