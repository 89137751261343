import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isNil, isEmpty } from "ramda";
import cn from "classnames";

import CURRENCIES from "@/constants/currencies";
import AVAILABILITY from "@/constants/availability";

import Button from "@/components/Button";
import Block from "@/components/Block";
import Status from "@/components/Status";
import Typography from "@/components/Typography";
import Codification from "@/components/Codification";
import FavoritesButton from "@/components/FavoritesButton";

import { useQtyLeft, useQtyRight, usePriceLeft, usePriceRight } from "@/store/shoes/shoes.hooks";
import { useStoreID } from "@/store/ui/ui.hooks";
import useActiveShoe from "@/hooks/ActiveShoe";

import getAvailability from "@/utils/data/getAvailability";
import { isLangKorean } from "@/utils/i18n";

const RawAvailabilityStatus = ({ isFlipped, qtyRight, qtyLeft }) => {
	const { t } = useTranslation();

	const { isRight, left, right } = useActiveShoe();

	let availability = null;
	if (!isFlipped) {
		if (isRight && !isNil(right) && !isNil(qtyRight)) {
			availability = qtyRight;
		} else if (!isRight && !isNil(left) && !isNil(qtyLeft)) {
			availability = qtyLeft;
		}
	} else if (isFlipped) {
		if (!isRight && !isNil(left) && !isNil(qtyRight)) {
			availability = qtyRight;
		} else if (isRight && !isNil(right) && !isNil(qtyLeft)) {
			availability = qtyLeft;
		}
	}

	if (isEmpty(availability)) return null;
	availability = getAvailability(availability);

	return (
		<Status
			label={t(`status.${[availability]}`)}
			warning={availability === AVAILABILITY.LIMITED}
			error={availability === AVAILABILITY.NONE}
			size="2xs"
		/>
	);
};

const AvailabilityStatus = React.memo(RawAvailabilityStatus);

const HorizontalAvailability = ({
	isFlipped,
	showStock,
	showPrice,
	lowGPU,
	handleBackToCompare,
	isFavoritesEnabled,
}) => {
	const { t } = useTranslation();
	const [price, setPrice] = useState(null);
	const priceLeft = usePriceLeft();
	const priceRight = usePriceRight();
	const qtyLeft = useQtyLeft();
	const qtyRight = useQtyRight();
	const { data: storeID } = useStoreID();
	const { isRight, isCompareMode, left, right } = useActiveShoe();

	let title = null;
	let availability = null;
	let shoe = null;

	if (!isFlipped) {
		if (isRight && !isNil(right) && !isNil(qtyRight)) {
			availability = qtyRight;
			shoe = right;
		} else if (!isRight && !isNil(left) && !isNil(qtyLeft)) {
			availability = qtyLeft;
			shoe = left;
		}
	} else if (isFlipped) {
		if (!isRight && !isNil(left) && !isNil(qtyRight)) {
			availability = qtyRight;
			shoe = left;
		} else if (isRight && !isNil(right) && !isNil(qtyLeft)) {
			availability = qtyLeft;
			shoe = right;
		}
	}

	if (showStock && showPrice && !isEmpty(price) && !isEmpty(availability)) title = t("titles.price_availability");
	else if ((isNil(showStock) || isEmpty(availability)) && showPrice && !isEmpty(price)) title = t("titles.price");
	else if ((isNil(showStock) && isNil(showPrice)) || (isNil(showStock) && isNil(price)) || isEmpty(availability))
		title = null;
	else title = t("titles.availability");

	let text = "";
	if (!isEmpty(price) && !isEmpty(storeID) && !isNil(storeID)) text = `${CURRENCIES[storeID.currencies[0]]}${price}`;

	useEffect(() => {
		if (!isFlipped) {
			if (isRight) {
				setPrice(priceRight);
			} else if (!isRight) {
				setPrice(priceLeft);
			}
		} else if (isFlipped) {
			if (!isRight) {
				setPrice(priceRight);
			} else if (isRight) {
				setPrice(priceLeft);
			}
		}
	}, [priceLeft, priceRight, isRight, isFlipped]);

	const renderTitle = () => {
		if (!isCompareMode) {
			if (isFavoritesEnabled && showStock && showPrice && !isEmpty(price))
				return <AvailabilityStatus isFlipped={isFlipped} qtyRight={qtyRight} qtyLeft={qtyLeft} />;
			if (
				(isFavoritesEnabled && showStock) ||
				(isFavoritesEnabled && showPrice && !isEmpty(price)) ||
				(!isFavoritesEnabled && showStock) ||
				(!isFavoritesEnabled && showPrice && !isEmpty(price))
			)
				return title;
		} else {
			if (showStock && showPrice && !isEmpty(price))
				return <AvailabilityStatus isFlipped={isFlipped} qtyRight={qtyRight} qtyLeft={qtyLeft} />;
			if (
				(isFavoritesEnabled && showStock) ||
				(isFavoritesEnabled && showPrice && !isEmpty(price)) ||
				(!isFavoritesEnabled && showStock) ||
				(!isFavoritesEnabled && showPrice && !isEmpty(price))
			)
				return title;
		}
		return null;
	};

	return (
		<Block className="u-pl-7 c-block--inset favorite">
			<Block.Header className="u-flex u-jc-between u-pr-2">
				<Typography tag="span" size="2xs">
					{renderTitle()}
				</Typography>
			</Block.Header>

			<Block.Content className={cn("u-flex u-ai-center")}>
				{(showPrice && !isEmpty(price) && !isEmpty(storeID) && (
					<Codification
						tag="p"
						text={text}
						typography={{ primary: true, size: "3xl", uppercase: true }}
						characterSwitchAmount={4}
						characterNextTrigger={2}
						characterTimeout={20}
						lowGPU={lowGPU}
					/>
				)) ||
					(!isFavoritesEnabled ||
					(isFavoritesEnabled && !showPrice) ||
					(isFavoritesEnabled && showStock && showPrice && isEmpty(price)) ? (
						<AvailabilityStatus isFlipped={isFlipped} qtyRight={qtyRight} qtyLeft={qtyLeft} />
					) : null)}
			</Block.Content>

			<Block.Footer className="u-flex u-ai-end">
				<div
					className={cn("u-flex u-ai-center u-inline-5", {
						"u-fill-width u-jc-between": isCompareMode,
					})}
				>
					{isCompareMode && (
						<Button
							type="button"
							onClick={handleBackToCompare}
							className="c-button--rounded u-flex u-inline-2 u-ai-center"
						>
							<Button.Icon className="c-icon--size-3" type="chevron-left" />
							<Button.Text className={cn({ "u-mt-1": isLangKorean() })} size="3xs">
								{t("buttons.back_compare")}
							</Button.Text>
						</Button>
					)}
					{!isCompareMode && showPrice && price && !isFavoritesEnabled && (
						<AvailabilityStatus isFlipped={isFlipped} qtyRight={qtyRight} qtyLeft={qtyLeft} />
					)}
					{isFavoritesEnabled && !isCompareMode && (
						<FavoritesButton shoe={shoe} page={isCompareMode ? "compare" : "detail"} />
					)}
					{isFavoritesEnabled && isCompareMode && (
						<FavoritesButton shoe={shoe} page={isCompareMode ? "compare" : "detail"} iconOnly />
					)}
				</div>
			</Block.Footer>
		</Block>
	);
};

export default React.memo(HorizontalAvailability);
