import React, { useContext } from "react";
import cn from "classnames";

import Typography from "@/components/Typography";

import Context from "./Codification.context";
import Character from "./CodificationCharacter.component";
import Space from "./CodificationSpace.component";
import defaultProps from "./Codification.defaultProps";
import propTypes from "./Codification.propTypes";

const CodificationWord = ({ characters, index, isLastWordOnLine }) => {
	const { text, words, isAlignLeft, isAlignRight, typography, lowGPU, extend } = useContext(Context);
	const isFirstWord = index === 0;
	const isLastWord = index === words.length - 1;
	const startIndex = words
		.slice(0, index + 1)
		.reduce((total, item, itemIndex) => (itemIndex < index ? total + item.length : total), 0);

	return (
		<span className={cn("c-codification__word", { extend })}>
			{characters.map((character, characterIndex) => {
				const totalIndex = startIndex + characterIndex;
				const isFirstCharacter = characterIndex === 0;
				const isLastCharacter = characterIndex === characters.length - 1;

				return (
					// eslint-disable-next-line react/no-array-index-key
					<React.Fragment key={`codification-character-${text}-${totalIndex}`}>
						{isAlignRight && isFirstCharacter && !isFirstWord && <Space />}
						{lowGPU ? (
							<Typography className="c-codification__character" {...typography} tag="span">
								<span>{character}</span>
							</Typography>
						) : (
							<Character text={character} index={totalIndex} />
						)}

						{isAlignLeft && isLastCharacter && !isLastWord && !isLastWordOnLine && <Space />}
					</React.Fragment>
				);
			})}
		</span>
	);
};

CodificationWord.defaultProps = defaultProps.word;
CodificationWord.propTypes = propTypes.word;

export default CodificationWord;
