import { DEFAULT_EASE, DEFAULT_DURATION } from "@/constants/transition";

export const animation = {
	hidden: {
		strokeDashoffset: -2450,
	},
	show: {
		// offset 1px to fix rounding issue in chrome
		strokeDashoffset: [-2451, 3675],
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION * 2,
			repeat: "Infinity",
			repeatType: "reverse",
			repeatDelay: DEFAULT_DURATION * 2,
		},
	},
	trigger: {
		strokeDashoffset: [-2451, 3675],
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION * 2,
		},
	},
};

export const bottomAnimation = {
	hidden: {
		strokeDashoffset: 3768,
	},
	show: {
		// offset 1px to fix rounding issue in chrome
		strokeDashoffset: [3768, -2450],
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION * 2,
			repeat: "Infinity",
			repeatType: "reverse",
			repeatDelay: DEFAULT_DURATION * 2,
		},
	},
	trigger: {
		strokeDashoffset: [3768, -2450],
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION * 2,
		},
	},
};
