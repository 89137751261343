/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import cn from "classnames";
import { isEmpty } from "ramda";

import { isLangKorean } from "@/utils/i18n";

import Codification from "@/components/Codification";
import Button, { ButtonLanguage } from "@/components/Button";
import TopBar from "@/components/TopBar";
import GridBlock from "@/components/GridBlock";
import { MembershipMenu } from "@/components/Membership";

import { useEnabledLanguages, useLowGPU } from "@/store/ui/ui.hooks";
import { useMembershipActive, useIdentity, useStyles } from "@/store/membership/membership.hooks";
import { setShoesModal } from "@/store/ui/ui.actions";
import { useAllShoes } from "@/store/shoes/shoes.hooks";
import { useFilterIDs, useAllFilters } from "@/store/filters/filters.hooks";

import placeGrid from "@/assets/grids/place.json";
import placeHorizontalGrid from "@/assets/grids/placeHorizonal.json";

import { useQuery } from "@/hooks/Router";
import useGrid from "@/hooks/Grid";

import { fadeMove, moveX, moveXReverse } from "@/animations";

import setAnalytics from "@/utils/analytics";

import PlacePartialsCircle from "./Partials";

const Place = () => {
	const query = useQuery();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const languages = useEnabledLanguages();
	const lowGPU = useLowGPU();
	const membershipActive = useMembershipActive();
	const { isGridLoading, start: startGrid, end: endGrid, shouldRerenderGrid } = useGrid();
	const { data: identity, loading } = useIdentity();
	const { data: shoes, loading: shoesLoading } = useAllShoes();
	const { loading: filtersLoading } = useAllFilters();
	const { loading: stylesLoading } = useStyles();
	const filterIDs = useFilterIDs();

	const [animationTrigger, setAnimationTrigger] = useState("show");
	const [contentAnimationCompleted, setContentAnimationCompleted] = useState(false);

	const qForm = query.get("form") || "rise";

	const placeButton = t("buttons.place").replace(" ", "\n");

	// #region FUNCTIONS
	const onContentAnimationComplete = () => {
		setContentAnimationCompleted(true);
	};
	// #endregion FUNCTIONS

	// #region LIFECYCLE HOOKS
	useEffect(() => {
		endGrid();
		if (qForm === "horizontal") startGrid(placeHorizontalGrid);
		else startGrid(placeGrid);
	}, [isGridLoading]);

	useEffect(() => {
		setAnimationTrigger("show");
		setAnalytics("place", { member: !isEmpty(identity) });
	}, []);
	// #endregion LIFECYCLE HOOKS

	// #region RENDER
	return (
		!shouldRerenderGrid &&
		!isGridLoading && (
			<div className="place">
				<GridBlock id="PlaceTop">
					<TopBar flipVisible />
				</GridBlock>

				<GridBlock id="PlaceLeft" className="u-flex u-col u-jc-center">
					<AnimatePresence>
						{animationTrigger === "show" && (
							<motion.div
								className={cn("u-pl-6", { "u-pt-3": isLangKorean() })}
								variants={moveX}
								initial="hidden"
								animate="show"
								exit="hidden"
							>
								<Codification
									tag="h3"
									text={placeButton}
									typography={{
										primary: true,
										uppercase: true,
										size: "md",
										lineHeightSize: isLangKorean() ? "lg" : "md",
									}}
									characterSwitchAmount={12}
									characterNextTrigger={6}
									characterTimeout={20}
									start={contentAnimationCompleted}
									lowGPU={lowGPU}
								/>
							</motion.div>
						)}
					</AnimatePresence>

					{/* TODO: temp fix until grid framework covers this */}
					<PlacePartialsCircle type="left" animationTrigger={animationTrigger} />
				</GridBlock>

				<GridBlock id="PlaceCenter" className="u-flex u-col u-jc-center u-ai-center">
					<motion.div
						variants={fadeMove}
						initial="hidden"
						animate="show"
						exit="hidden"
						onAnimationComplete={onContentAnimationComplete}
					>
						{/*
							u-mb-25 and u-pt-25 makes sure that inside track text is
							centered vertically in the middle of the page
						*/}
						<div className="u-max-width-120 u-stack-10 u-text-c u-mb-22 u-pt-25">
							<Codification
								className="s-english"
								text={t("generic.name").replace(" ", "\n")}
								tag="h1"
								typography={{ primary: true, uppercase: true, size: "8xl", lineHeightSize: "7xl" }}
								characterSwitchAmount={6}
								characterNextTrigger={3}
								characterTimeout={30}
								lowGPU={lowGPU}
							/>
						</div>
						<AnimatePresence>
							{animationTrigger === "show" && (
								<motion.div
									className="u-flex u-jc-center u-ai-start u-col-4 u-inline-10"
									variants={fadeMove}
									initial="hidden"
									animate="show"
									exit="hidden"
								>
									<div className="u-flex u-jc-center u-ai-center">
										<Button
											onClick={() => {
												if (
													!loading &&
													!shoesLoading &&
													!stylesLoading &&
													!filtersLoading &&
													!isEmpty(filterIDs.category) &&
													!isEmpty(shoes)
												)
													dispatch(setShoesModal(true));
											}}
											className={cn(
												"c-button--rounded c-button--inverse c-button--float c-button--large",
												{ "u-mr-10": languages.length > 1 }
											)}
										>
											<Button.Text>
												{loading
													? t("buttons.personalizing")
													: shoesLoading ||
													  stylesLoading ||
													  isEmpty(filterIDs.category) ||
													  isEmpty(shoes)
													? t("buttons.loading")
													: t("buttons.explore")}
											</Button.Text>
										</Button>
										{languages.length > 1 ? <ButtonLanguage /> : null}
									</div>
								</motion.div>
							)}
						</AnimatePresence>
					</motion.div>
				</GridBlock>

				<GridBlock id="PlaceRight" className="u-flex u-col u-jc-center">
					<AnimatePresence>
						{animationTrigger === "show" && (
							<motion.div
								className={cn("u-pr-6", { "u-pt-3": isLangKorean() })}
								variants={moveXReverse}
								initial="hidden"
								animate="show"
								exit="hidden"
							>
								<Codification
									tag="h3"
									align="right"
									text={placeButton}
									typography={{
										primary: true,
										uppercase: true,
										size: "md",
										lineHeightSize: isLangKorean() ? "lg" : "md",
									}}
									characterSwitchAmount={12}
									characterNextTrigger={6}
									characterTimeout={20}
									start={contentAnimationCompleted}
									lowGPU={lowGPU}
								/>
							</motion.div>
						)}
					</AnimatePresence>

					{/* TODO: temp fix until grid framework covers this */}
					<PlacePartialsCircle type="right" animationTrigger={animationTrigger} />
				</GridBlock>

				{membershipActive ? (
					<GridBlock id="PlaceBottom" className="u-flex u-fill-space">
						<MembershipMenu />
					</GridBlock>
				) : null}
			</div>
		)
	);
	// #endregion RENDER
};

export default Place;
