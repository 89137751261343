const UIInitialState = () => ({
	flippedOrientation: false,
	modals: {
		langSelector: { isOpen: false },
		reset: { isOpen: false },
		attract: { isOpen: false },
		options: { isOpen: false },
		membership: { isOpen: false },
		adminPanel: { isOpen: false },
		shoes: { isOpen: false, data: {} },
		shoeNotFound: { isOpen: false, data: { type: "not_found", side: "digital" } },
	},
	grid: {
		loading: true,
	},
	languages: [],
	screensaverTrigged: false,
	lowGPU: false,
	stores: [],
	activeSession: false,
	reloadTrigger: false,
	attractTrigger: false,
	qaMode: false,
	latestAction: { type: "", id: "" },
	storeID: {
		data: {},
		loading: false,
		error: null,
	},
	analyticsTypes: {
		data: {},
		loading: false,
		error: null,
	},
});

export default UIInitialState;
