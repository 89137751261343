import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { isNil, isEmpty, equals, pathOr } from "ramda";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { isLangKorean, isLangChinese, isLangEnglish, isLangFrench } from "@/utils/i18n";

import LangSelector from "@/components/LangSelector";
import LangSelectorVertical from "@/components/LangSelectorVertical";
import Reset from "@/components/Reset";
import Display from "@/components/Display";
import ShoePicker from "@/components/ShoePicker";
import Options from "@/components/Options";
import ShoeNotFound from "@/components/ShoeNotFound";
import SetupButton from "@/components/SetupButton";
import AdminPanel from "@/components/AdminPanel";
import ReloadButton from "@/components/ReloadButton";
import ShoePickerVertical from "@/components/ShoePickerVertical";
import MembershipModal from "@/components/MembershipModal";

import Grid from "@/providers/Grid";
import Routing from "@/providers/Routing";
import ROUTES from "@/constants/routes";

import { DEFAULT_DURATION } from "@/constants/transition";
import IDLE_TIMER_DEFAULT_TIMING from "@/constants/idle-timer";

import useNikeTableSession from "@/hooks/NikeTableSession";
import useHotspots from "@/hooks/Hotspots";
import useFilters from "@/hooks/Filters";
import useInteraction from "@/hooks/Interaction";
import useTracking from "@/hooks/Tracking";
import { useRouter, useQuery } from "@/hooks/Router";
import useAdmin from "@/hooks/Admin";
import useMembership from "@/hooks/Membership";
import useNetwork from "@/hooks/Network";

import {
	setResetModal,
	setAttractModal,
	setScreensaverTrigged,
	getAnalyticsTypes,
	getAllStores,
	getStoreID,
	setShoeNotFoundModal,
	setFlippedOrientation,
	setAttractTrigger,
	setLatestAction,
	setShoesModal,
} from "@/store/ui/ui.actions";
import { fetchLocation, getAllShoes, fetchImagemap } from "@/store/shoes/shoes.actions";
import {
	useFlippedOrientation,
	useShoeNotFoundModal,
	useActiveSession,
	useResetModal,
	useAttractModal,
	useAttractTrigger,
	useShoesModal,
	useOptionsModal,
	useMembershipModal,
} from "@/store/ui/ui.hooks";
import { useAllShoes, useActiveShoes, useTableLocation } from "@/store/shoes/shoes.hooks";
import { useFilterIDs, useAllFilters } from "@/store/filters/filters.hooks";
import {
	setMembershipActive,
	setSimulatorActive,
	setEndSession,
	fetchStyles,
} from "@/store/membership/membership.actions";
import { useMembershipActive, useIdentity, useStyles, useEndSession } from "@/store/membership/membership.hooks";
import {
	useInteractionLock,
	useRFIDOrientation,
	use404List,
	useHotspotLeft,
	useHotspotRight,
} from "@/store/rfid/rfid.hooks";
import { setInteractionLock, set404List } from "@/store/rfid/rfid.actions";
import { getAllFilters } from "./store/filters/filters.actions";

import { checkIsDigital } from "@/utils/shoes";
import { checkIsStatusObserve, checkIsStatusActive } from "@/utils/rfid";
import setAnalytics from "./utils/analytics";
import { random, pixels } from "./utils/math";

import { fontSizeToPixel, isLandscape, borders, shadows, block, shoeHero, generic } from "@/constants/pixel-sizes";

const App = () => {
	useNikeTableSession();
	useHotspots();
	useFilters();
	useInteraction();
	useRouter();
	useTracking();
	useAdmin();
	useMembership();
	const networkOnline = useNetwork();

	const query = useQuery();
	const history = useHistory();
	const location = useLocation();

	const membershipActive = useMembershipActive();
	const { data: shoes, loading: shoesLoading } = useAllShoes();
	const { data: identity, loading } = useIdentity();
	const { data: styles } = useStyles();
	const filterIDs = useFilterIDs();
	const { loading: filtersLoading, error: filterError } = useAllFilters();
	const interactionLock = useInteractionLock();
	const RFIDOrientation = useRFIDOrientation();
	const { left, right } = useActiveShoes();
	const leftID = left?.scc;
	const rightID = right?.scc;
	const activeSession = useActiveSession();
	const attractTrigger = useAttractTrigger();
	const fourList = use404List();
	const { isOpen: isShoeNotFoundOpen, data: modalData } = useShoeNotFoundModal();
	const { isOpen: isResetOpen } = useResetModal();
	const { isOpen: isAttractOpen } = useAttractModal();
	const { isOpen: isShoesOpen } = useShoesModal();
	const { isOpen: isOptionsModalOpen } = useOptionsModal();
	const { toggleIsOpen: toggleIsOpenMembership } = useMembershipModal();
	const hotspotLeft = useHotspotLeft();
	const hotspotRight = useHotspotRight();
	const endSession = useEndSession();
	const tableLocation = useTableLocation();

	const marketplace = tableLocation.data.json_meta?.inside_track?.marketplace;

	const interactionTimer = useRef(null);
	const attractIdleTimer = useRef(null);
	const sessionIdleTimer = useRef(null);

	const isObserveEnabled = equals(query.get("observe"), "true");
	// const observeTags = isObserveEnabled ? tags.filter((tag) => checkIsStatusObserve(tag)) : [];

	// React hooks
	const canvasRef = useRef(null);
	const [isCanvasLoaded, setCanvasLoaded] = useState(false);

	// Library hooks
	const dispatch = useDispatch();

	if (isNil(membershipActive)) {
		const qMembership = equals(query.get("membership"), "true");
		dispatch(setMembershipActive(qMembership));
	}

	let idleTimeAttract = IDLE_TIMER_DEFAULT_TIMING.ATTRACT;
	let idleTimeSession = isEmpty(identity) ? IDLE_TIMER_DEFAULT_TIMING.SESSION * 2 : IDLE_TIMER_DEFAULT_TIMING.SESSION;

	const qIdleTime = query.get("idle-time") || null;
	const qSessionTime = query.get("session-time") || null;
	const isScreenSaverEnabled = equals(query.get("screensaver"), "true");
	const qCdnUrl = query.get("cdn-url") || null;
	const qForm = query.get("form") || "rise";

	if (!isNil(qIdleTime)) {
		idleTimeAttract = qIdleTime;
	}

	if (!isNil(qSessionTime)) {
		idleTimeSession = isEmpty(identity) ? qSessionTime * 2 : qSessionTime;
	}

	// Own hooks
	const isFlipped = useFlippedOrientation();

	// Dynamic Fonts
	// create components using React.lazy
	const EnglishFonts = React.lazy(() => import("./components/Fonts/EnglishFonts.component"));
	const KoreanFonts = React.lazy(() => import("./components/Fonts/KoreanFonts.component"));
	const ChineseFonts = React.lazy(() => import("./components/Fonts/ChineseFonts.component"));

	// create a parent component that will load the components conditionally using React.Suspense
	const FontSelector = () => {
		return (
			<>
				<React.Suspense fallback={<></>}>
					{<EnglishFonts />}
					{<KoreanFonts />}
					{<ChineseFonts />}
				</React.Suspense>
			</>
		);
	};

	const onIndex = equals(ROUTES.Index, pathOr("", ["pathname"], location));
	if (!networkOnline && !isShoeNotFoundOpen && !onIndex && modalData.type !== "network_offline") {
		dispatch(setShoeNotFoundModal(true, { type: "network_offline" }));
	} else if (networkOnline && isShoeNotFoundOpen && modalData.type === "network_offline") {
		dispatch(setShoeNotFoundModal(false, { type: "network_offline" }));
	}

	const checkPage = (page) => equals(page, pathOr("", ["pathname"], location));

	const handleInteraction = () => {
		if (!activeSession) {
			setAnalytics("session_start", { member: !isEmpty(identity) });
			setAnalytics("session_id", { session_id: random(1, 100000000) });
		}

		dispatch(setInteractionLock(true));

		if (isAttractOpen) dispatch(setAttractModal(false));

		if (!isNil(sessionIdleTimer.current)) {
			clearTimeout(sessionIdleTimer.current);
			sessionIdleTimer.current = null;
		}

		if (!isNil(interactionTimer.current)) {
			clearTimeout(interactionTimer.current);
			interactionTimer.current = null;
		}

		interactionTimer.current = setTimeout(() => {
			dispatch(setInteractionLock(false));
		}, IDLE_TIMER_DEFAULT_TIMING.INTERACTION);
	};

	const handleAttractTimer = () => {
		const isPlacePage = checkPage(ROUTES.Place);

		const atLeastOneHotspotActive = checkIsStatusActive(hotspotLeft) || checkIsStatusActive(hotspotRight);
		const leftDigital = checkIsDigital(left);
		const rightDigital = checkIsDigital(right);
		const noneDigital = !leftDigital && !rightDigital && (!rightDigital || !leftDigital);

		if (!isNil(attractIdleTimer.current)) {
			clearTimeout(attractIdleTimer.current);
			attractIdleTimer.current = null;
			dispatch(setAttractTrigger(false));
		}

		if (isPlacePage || (atLeastOneHotspotActive && noneDigital)) {
			attractIdleTimer.current = setTimeout(() => {
				dispatch(setAttractTrigger(true));
			}, idleTimeAttract);
		}
	};

	const handleSessionTimer = () => {
		const active = isNil(left) ? right : left;
		const bothActive = !isNil(left) && !isNil(right);
		const onlyOneActive = isNil(left) || isNil(right);
		const oneDigital = onlyOneActive && checkIsDigital(active);
		const bothDigital = bothActive && checkIsDigital(left) && checkIsDigital(right);

		if (activeSession) {
			if (isNil(sessionIdleTimer.current))
				sessionIdleTimer.current = setTimeout(() => {
					clearTimeout(sessionIdleTimer.current);
					sessionIdleTimer.current = null;
					dispatch(setResetModal(true));
				}, idleTimeSession);
		}
	};

	useEffect(() => {
		const store = query.get("store") || null;
		const showStock = query.get("show-stock") || false;
		const showPrice = query.get("show-price") || false;

		document.documentElement.style.setProperty(
			"--font-s-default",
			pixels(fontSizeToPixel["2xs"], isLandscape[qForm])
		);
		document.documentElement.style.setProperty("--border-width", pixels(borders.borderWidth, isLandscape[qForm]));
		document.documentElement.style.setProperty(
			"--radius-default",
			pixels(borders.radiusDefault, isLandscape[qForm])
		);
		document.documentElement.style.setProperty("--radius-small", pixels(borders.radiusSmall, isLandscape[qForm]));
		document.documentElement.style.setProperty("--radius-large", pixels(borders.radiusLarge, isLandscape[qForm]));
		document.documentElement.style.setProperty("--box-shadow-y", pixels(shadows.boxShadowY, isLandscape[qForm]));
		document.documentElement.style.setProperty(
			"--box-shadow-blur",
			pixels(shadows.boxShadowBlur, isLandscape[qForm])
		);
		if (qForm === "horizontal")
			document.documentElement.style.setProperty(
				"--block-space",
				pixels(block.horizontalSpace, isLandscape[qForm])
			);
		else document.documentElement.style.setProperty("--block-space", pixels(block.space, isLandscape[qForm]));
		document.documentElement.style.setProperty("--shoe-hero-space", pixels(shoeHero.space, isLandscape[qForm]));
		generic.forEach((num) =>
			document.documentElement.style.setProperty(`--generic-${num}`, pixels(num, isLandscape[qForm]))
		);

		// Fetch style IDs for shoe recommendations
		dispatch(fetchStyles());
		dispatch(fetchLocation(store));
		dispatch(getAllStores());
		dispatch(getAnalyticsTypes());
		dispatch(fetchImagemap());
		const qSimulator = equals(query.get("membership-simulator"), "true");
		dispatch(setSimulatorActive(qSimulator));

		attractIdleTimer.current = setTimeout(() => {
			dispatch(setAttractTrigger(true));
		}, idleTimeAttract);

		// localStorage.clear();
		if (isNil(localStorage.getItem("404List"))) localStorage.setItem("404List", JSON.stringify(fourList));
		else dispatch(set404List(JSON.parse(localStorage.getItem("404List"))));

		if (canvasRef.current) {
			setCanvasLoaded(true);
		}

		return () => {
			dispatch(setResetModal(false));
			dispatch(setAttractModal(false));
			if (!isNil(interactionTimer.current)) {
				clearTimeout(interactionTimer.current);
				interactionTimer.current = null;
			}
			if (!isNil(attractIdleTimer.current)) {
				clearTimeout(attractIdleTimer.current);
				attractIdleTimer.current = null;
				dispatch(setAttractTrigger(false));
			}
			if (!isNil(sessionIdleTimer.current)) {
				clearTimeout(sessionIdleTimer.current);
				sessionIdleTimer.current = null;
			}
		};
	}, []);

	useEffect(() => {
		if (!interactionLock && !activeSession) {
			if (RFIDOrientation === "reverse") {
				dispatch(setFlippedOrientation(true));
			} else {
				dispatch(setFlippedOrientation(false));
			}
		}
	}, [RFIDOrientation]);

	useEffect(() => {
		if (loading) toggleIsOpenMembership();
	}, [loading]);

	useEffect(() => {
		const store = query.get("store") || null;
		if (!tableLocation.loading && marketplace) dispatch(getStoreID(store, marketplace));
	}, [tableLocation]);

	useEffect(() => {
		localStorage.setItem("404List", JSON.stringify(fourList));
	}, [fourList]);

	useEffect(() => {
		if (
			!isEmpty(styles) &&
			!isEmpty(filterIDs.category) &&
			isEmpty(filterError) &&
			!shoesLoading &&
			!filtersLoading &&
			isEmpty(shoes) &&
			isEmpty(identity)
		) {
			const store = query.get("store") || null;
			const filterType = query.get("filter-type") || null;
			dispatch(getAllShoes(filterType, store, membershipActive, identity, filterIDs, styles, qCdnUrl));
		} else if (
			!isEmpty(styles) &&
			!isEmpty(filterIDs.category) &&
			!shoesLoading &&
			!filtersLoading &&
			isEmpty(filterError) &&
			!isEmpty(identity)
		) {
			const store = query.get("store") || null;
			const filterType = query.get("filter-type") || null;
			dispatch(getAllShoes(filterType, store, membershipActive, identity, filterIDs, styles, qCdnUrl));
		}
	}, [identity, styles, filterIDs]);

	useEffect(() => {
		if (left || right) {
			handleInteraction();
			dispatch(setResetModal(false));
		}
	}, [left, right]);

	useEffect(() => {
		let timerId;
		if (!isNil(leftID) && !isNil(rightID)) {
			timerId = setTimeout(
				() =>
					dispatch(setLatestAction({ type: "compare", id: uuidv4(), firstScc: leftID, secondScc: rightID })),
				100
			);
		}
		return () => {
			clearTimeout(timerId);
		};
	}, [leftID, rightID]);

	useEffect(() => {
		if (isResetOpen) {
			return;
		}

		const isAttractPage = checkPage(ROUTES.Index);

		if (
			!isAttractPage &&
			attractTrigger &&
			isScreenSaverEnabled &&
			!interactionLock &&
			isNil(left) &&
			isNil(right) &&
			isEmpty(identity) &&
			!loading &&
			!isShoeNotFoundOpen &&
			!isShoesOpen &&
			!isOptionsModalOpen &&
			!isResetOpen &&
			!isAttractOpen
		) {
			if (attractTrigger) {
				history.push({ pathname: ROUTES.Index, search: location.search });
				setAnalytics("screensaver_triggered", { member: !isEmpty(identity) });
				dispatch(setScreensaverTrigged(true));
				dispatch(setAttractModal(true));
				if (activeSession && !isResetOpen && !endSession) {
					dispatch(setEndSession(true));
				}
				dispatch(setShoesModal(false));
			} else {
				dispatch(setAttractModal(false));
				dispatch(setAttractTrigger(false));
				if (!isNil(attractIdleTimer.current)) {
					clearTimeout(attractIdleTimer.current);
					attractIdleTimer.current = null;
				}
				attractIdleTimer.current = setTimeout(() => {
					dispatch(setAttractTrigger(true));
				}, idleTimeAttract);
			}
		}
		if (
			!isScreenSaverEnabled &&
			!isAttractPage &&
			attractTrigger &&
			!interactionLock &&
			isNil(left) &&
			isNil(right) &&
			isEmpty(identity) &&
			!loading &&
			activeSession &&
			!isResetOpen &&
			!endSession
		) {
			dispatch(setEndSession(true));
			dispatch(setShoesModal(false));
		}

		handleSessionTimer();

		if (isScreenSaverEnabled) {
			handleAttractTimer();
		}
	}, [left, right, isResetOpen, isAttractOpen, location, interactionLock, identity, attractTrigger]);

	useEffect(() => {
		const duration = DEFAULT_DURATION / 2;

		document.body.style.transition = `transform ${duration}s ease`;
		document.body.style.transform = isFlipped ? "rotate(180deg)" : "rotate(0deg)";
		const button = document.getElementsByClassName("topbar-flip")[0];
		if (!isNil(button)) {
			button.blur();
		}

		const timer = setTimeout(() => {
			document.body.style.transition = "";
		}, duration * 1000);

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [isFlipped]);

	const isKiosk = Boolean(window.navigator.userAgent.match(/NikeTouchTableKiosk/gm));

	return (
		isKiosk && (
			<div
				className={cn({
					"s-korean": isLangKorean(),
					"s-chinese": isLangChinese(),
					"s-english": isLangEnglish(),
					"s-french": isLangFrench(),
					vertical: qForm === "vertical",
					horizontal: qForm === "horizontal",
					rise: qForm === "rise",
				})}
				role="button"
				tabIndex={0}
				onMouseDown={() => {
					handleInteraction();
				}}
			>
				<Display>
					<div className="c-canvas-grid" ref={canvasRef} />
					{isCanvasLoaded && (
						<Grid canvas={canvasRef.current}>
							<Routing />
						</Grid>
					)}

					<SetupButton />
					<ReloadButton />
				</Display>

				{/* Modals */}
				<FontSelector />
				{qForm === "vertical" ? <LangSelectorVertical /> : <LangSelector />}
				{isEmpty(filterError) && qForm !== "vertical" && <ShoePicker />}
				{isEmpty(filterError) && qForm === "vertical" && <ShoePickerVertical />}
				<Reset />
				<Options />
				<MembershipModal />
				<ShoeNotFound />
				<AdminPanel />
			</div>
		)
	);
};

export default App;
