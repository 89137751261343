import React, { memo } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import Icon from "@/components/Icon";
import Typography from "@/components/Typography";

import { DEFAULT_DURATION } from "@/constants/transition";

import { getFade, fade } from "@/animations";

import defaultProps from "./ReviewStars.defaultProps";
import propTypes from "./ReviewStars.propTypes";
import { useQuery } from "@/hooks/Router";

// =============================================================================
// Root
// =============================================================================
const ReviewStars = ({ className = "", rating, size, space, showLabel }) => {
	const { t } = useTranslation();
	const query = useQuery();

	const qForm = query.get("form") || "rise";

	const stars = [];
	const count = 5;

	for (let i = 1; i < count + 1; i += 1) {
		stars.push(
			<motion.li key={`star_${i}`} variants={fade} className="u-inline-flex">
				<Icon className={cn(`c-icon--size-${size}`, rating < i && "u-color-contrast-low")} type="star" />
			</motion.li>
		);
	}

	return (
		<div className={cn("c-review-stars", "u-flex u-ai-center u-inline-4", className)}>
			<motion.ul
				className={cn(`u-inline-${space}`, { "u-height-4": qForm === "horizontal" })}
				variants={getFade({ staggerChildren: 0.1 })}
				initial="hidden"
				animate="show"
			>
				{stars}
			</motion.ul>

			{showLabel && (
				<motion.div variants={getFade({ delay: DEFAULT_DURATION / 2 })} initial="hidden" animate="show">
					<Typography className={className} tag="p" weight="medium" size="xs">
						{rating} {t("generic.stars")}
					</Typography>
				</motion.div>
			)}
		</div>
	);
};

ReviewStars.propTypes = propTypes;

ReviewStars.defaultProps = defaultProps;

export default memo(ReviewStars);
