import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { isEmpty } from "ramda";
import cn from "classnames";

import Modal from "@/components/Modal";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import Countdown from "@/components/Countdown";

import { useResetModal } from "@/store/ui/ui.hooks";
import { useIdentity } from "@/store/membership/membership.hooks";

import { setResetModal } from "@/store/ui/ui.actions";
import { setEndSession } from "@/store/membership/membership.actions";

import setAnalytics from "@/utils/analytics";
import { useQuery } from "@/hooks/Router";

// =============================================================================
// Root
// =============================================================================
const Reset = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const query = useQuery();

	const { isOpen } = useResetModal();
	const { data: identity } = useIdentity();

	const qForm = query.get("form") || "rise";

	const handleNavigation = () => {
		dispatch(setEndSession(true));
	};

	const handleStillUsing = () => {
		dispatch(setResetModal(false));
	};

	useEffect(() => {
		if (isOpen) setAnalytics("show_modal", { modal_type: "reset", member: !isEmpty(identity) });
	}, [isOpen]);

	return (
		<Modal
			className={cn("options", {
				vertical: qForm === "vertical",
				horizontal: qForm === "horizontal",
			})}
			isOpen={isOpen}
			onClose={handleStillUsing}
		>
			<div className="u-pt-18 u-px-15 u-pb-9 u-flex u-col u-ai-center">
				<Typography tag="h1" size="2xl" className="u-text-c" primary uppercase>
					{t("buttons.reset")}
				</Typography>

				<Countdown
					className="u-mt-18"
					isOpen={isOpen}
					duration={Number(process.env.REACT_APP_RESET_COUNTDOWN_TIME) || 10}
					onFinished={handleNavigation}
				/>

				<Button type="button" onClick={handleStillUsing} className="c-button--rounded u-mt-12">
					<Button.Text>{t("buttons.using")}</Button.Text>
				</Button>
			</div>
		</Modal>
	);
};

export default memo(Reset);
