import imageDefaultProps from "@/components/Image/Image.defaultProps";

const ShoePreview = {
	title: "",
	url: "",
	isDimmed: false,
	...imageDefaultProps.Image,
};

export default {
	ShoePreview,
};
