import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isNil, isEmpty, propEq, find, keys } from "ramda";

import { useTableLocation, useImagemap, useAllShoes } from "@/store/shoes/shoes.hooks";
import { getShoeBySCC, getShoeByEPC, setActive, fetchShoeInventory, fetchShoePrice } from "@/store/shoes/shoes.actions";
import { useFlippedOrientation, useStoreID } from "@/store/ui/ui.hooks";
import { setShoeNotFoundModal, setAdminPanelModal } from "@/store/ui/ui.actions";
import { useFilterIDs } from "@/store/filters/filters.hooks";
import { useIdentity, useMembershipActive, useStyles } from "@/store/membership/membership.hooks";
import { use404List } from "@/store/rfid/rfid.hooks";

import { useQuery } from "@/hooks/Router";

import { SIDE } from "@/constants/rfid-tags";

import { flipSides } from "@/utils/shoes";

export default () => {
	const query = useQuery();
	const dispatch = useDispatch();
	const location = useTableLocation();
	const isFlipped = useFlippedOrientation();
	const imagemap = useImagemap();
	const { data: identity } = useIdentity();
	const { data: styles } = useStyles();
	const { data: allShoes } = useAllShoes();
	const membershipActive = useMembershipActive();
	const filterIDs = useFilterIDs();
	const fourList = use404List();
	const { data: storeID } = useStoreID();

	const leftId = query.get("left");
	const rightId = query.get("right");

	const store = query.get("store") || null;
	const showStock = query.get("show-stock") || false;
	const showPrice = query.get("show-price") || false;
	const qCdnUrl = query.get("cdn-url") || null;

	const setRecommendations = (shoe) => {
		const membershipShoe = find(propEq("id", shoe.id))(allShoes);
		if (!isNil(membershipShoe)) shoe.recommendations = membershipShoe.recommendations; // eslint-disable-line no-param-reassign
	};

	const setShoeActive = async (id, side, isSCC) => {
		let data = null;
		const city = location.data.json_meta || { sportpulse_id: null };
		const marketplace = location.data.json_meta?.inside_track?.marketplace;

		if (isSCC) {
			data = await dispatch(
				getShoeBySCC(
					id,
					"cache",
					store,
					city,
					imagemap,
					membershipActive,
					identity,
					filterIDs,
					[],
					styles,
					fourList,
					marketplace,
					qCdnUrl
				)
			);
		} else {
			data = await dispatch(
				getShoeByEPC(
					id,
					store,
					city,
					imagemap,
					membershipActive,
					identity,
					filterIDs,
					[],
					styles,
					fourList,
					qCdnUrl,
					marketplace
				)
			);
		}

		if (!data) {
			dispatch(setShoeNotFoundModal(true, { type: "not_found", side: flipSides(isFlipped, side) }));
			return;
		}

		setRecommendations(data);

		const shoeData = {
			epc: !isSCC ? id : "",
			scc: isSCC ? id : "",
			detail: data,
			isDigital: true,
			isVisible: true,
			template: null,
		};

		dispatch(setActive(shoeData, flipSides(isFlipped, side)));
		if (showStock && !isEmpty(storeID))
			dispatch(fetchShoeInventory(storeID, shoeData.scc, flipSides(isFlipped, side)));
		if (showPrice && !isEmpty(storeID)) dispatch(fetchShoePrice(storeID, shoeData.scc, flipSides(isFlipped, side)));
	};

	const handleKeyDown = (e) => {
		if (e.keyCode === 27) {
			dispatch(setAdminPanelModal(true));
		}
	};

	useEffect(() => {
		if (isEmpty(imagemap)) return;
		if (isEmpty(allShoes)) return;

		const isLeftSCC = leftId ? leftId.includes("-") : false;
		const isRightSCC = rightId ? rightId.includes("-") : false;

		if (leftId) {
			setShoeActive(leftId, SIDE.Left, isLeftSCC);
		}

		if (rightId) {
			setShoeActive(rightId, SIDE.Right, isRightSCC);
		}
	}, [leftId, rightId, location, keys(imagemap).length, allShoes.length]);

	useEffect(() => {
		document.addEventListener("keydown", handleKeyDown, false);

		return () => {
			document.removeEventListener("keydown", handleKeyDown, false);
		};
	}, []);
};
