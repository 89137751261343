import React from "react";
import cn from "classnames";
import { isNil } from "ramda";
import { motion, AnimatePresence } from "framer-motion";

import GridBlock from "@/components/GridBlock";
import Codification from "@/components/Codification";
import Image from "@/components/Image";

import { getFadeMoveFull, getFadeMoveXFull } from "@/animations";

import propTypes from "./ProductGroupingPartialsLogo.propTypes";
import defaultProps from "./ProductGroupingPartialsLogo.defaultProps";

const ProductGroupingPartialsLogo = ({ id, data, lowGPU, src = null, delay = 0 }) => {
	if (isNil(src)) {
		const { display_name: displayName } = data;

		return (
			<GridBlock id={id} className="u-flex u-jc-center u-ai-center">
				<AnimatePresence>
					<motion.div initial="hidden" animate="show" variants={getFadeMoveFull({ delay: 0.9 })}>
						{/* TODO: remove once we have the logo's */}
						<Codification
							text={displayName}
							typography={{ primary: true, size: "md", uppercase: true }}
							characterSwitchAmount={4}
							characterNextTrigger={2}
							characterTimeout={20}
							lowGPU={lowGPU}
						/>
					</motion.div>
				</AnimatePresence>
			</GridBlock>
		);
	}
	return (
		<GridBlock id={id} className="u-flex u-jc-center u-ai-center">
			<AnimatePresence>
				<motion.div className="u-inset" initial="hidden" animate="show" variants={getFadeMoveXFull({ delay })}>
					<Image className="c-image--logo" src={src} />
				</motion.div>
			</AnimatePresence>
		</GridBlock>
	);
};

ProductGroupingPartialsLogo.propTypes = propTypes;
ProductGroupingPartialsLogo.defaultProps = defaultProps;

export default ProductGroupingPartialsLogo;
