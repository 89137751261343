import React, { useEffect, useState, useMemo } from "react";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { isNil, pathOr, propEq, find, isEmpty, equals } from "ramda";

import { SIDE } from "@/constants/rfid-tags";
import ROUTES from "@/constants/routes";

import Button from "@/components/Button";
import Typography from "@/components/Typography";
import SliderNavigation from "@/components/SliderNavigation";
import SliderShoeHero from "@/components/SliderShoeHero";
import Codification from "@/components/Codification";
import GridBlock from "@/components/GridBlock";
import ButtonIcon from "@/components/ButtonIcon";
import Block from "@/components/Block";
import ReviewStars from "@/components/ReviewStars";
import { MembershipMenu } from "@/components/Membership";
import Icon from "@/components/Icon";
import ProductAvailabilityPrice from "@/components/ProductAvailabilityPrice";
import PlaceDetail from "@/components/PlaceDetail";
import ProductReviews from "@/components/ProductReviews";
import ProductSportPulse from "@/components/ProductSportPulse";
import FavoritesButton from "@/components/FavoritesButton";

import { useFlippedOrientation, useOptionsModal, useLowGPU } from "@/store/ui/ui.hooks";
import { useAllShoes, useActiveShoes } from "@/store/shoes/shoes.hooks";
import { useMembershipActive, useIdentity, useWelcomeRail } from "@/store/membership/membership.hooks";
import { useFilterIDs } from "@/store/filters/filters.hooks";

import { updateVisible, clearActive, clearQty, clearPrice } from "@/store/shoes/shoes.actions";
import { removeTag } from "@/store/rfid/rfid.actions";

import useSlider from "@/hooks/Slider";
import useGrid from "@/hooks/Grid";
import { useQuery } from "@/hooks/Router";

import compareVerticalGrid from "@/assets/grids/compareVertical.json";
import compareVerticalGridLeftOne from "@/assets/grids/compareVerticalLeftOne.json";
import compareVerticalGridRightOne from "@/assets/grids/compareVerticalRightOne.json";
import compareVerticalGridBothOne from "@/assets/grids/compareVerticalBothOne.json";
// import compareVerticalGridMembership from "@/assets/grids/compareVerticalMembership";
// import compareVerticalGridMembershipBothOne from "@/assets/grids/compareVerticalMembershipBothOne";
// import compareVerticalGridMembershipLeftOne from "@/assets/grids/compareVerticalMembershipLeftOne";
// import compareVerticalGridMembershipRightOne from "@/assets/grids/compareVerticalMembershipRightOne";

import { flipSides } from "@/utils/shoes";
import setAnalytics from "@/utils/analytics";
import { sentryLogException } from "@/utils/sentry";

const CompareVertical = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const query = useQuery();
	const isFlipped = useFlippedOrientation();
	const { data: allShoes, loading } = useAllShoes();
	const { data: identity, error: identityError } = useIdentity();
	const { toggleIsOpen } = useOptionsModal();
	const { left, right } = useActiveShoes();
	const shoes = useActiveShoes();
	const lowGPU = useLowGPU();
	const filterIDs = useFilterIDs();
	const membershipActive = useMembershipActive();
	const { isGridLoading, start: startGrid, end: endGrid, shouldRerenderGrid } = useGrid();
	const {
		slider,
		currentSlide,
		initSlider,
		speed,
		handlePrev,
		handleNext,
		updateCurrentSlide,
		handleDestroy,
		direction: globalDirection,
	} = useSlider({
		speed: 10,
	});
	const welcomeRail = useWelcomeRail();

	const [showAllUgc, setShowAllUgc] = useState(false);
	const [gridShoeLeft, setGridShoeLeft] = useState(null);
	const [gridShoeRight, setGridShoeRight] = useState(null);
	const [recommendationCopyLeft, setRecommendationCopyLeft] = useState("");
	const [recommendationCopyRight, setRecommendationCopyRight] = useState("");
	const [showRail, setShowRail] = useState(false);

	const leftId = pathOr(null, ["scc"], left);
	const rightId = pathOr(null, ["scc"], right);
	const hasLeftData = !isNil(pathOr(null, ["detail"], left));
	const hasRightData = !isNil(pathOr(null, ["detail"], right));

	const showStock = query.get("show-stock") || null;
	const showPrice = query.get("show-price") || false;
	const isFavoritesEnabled = equals(query.get("favorites"), "true");
	const qForm = query.get("form") || "rise";

	// #region FUNCTIONS
	const handleShowUgc = () => {
		// Only show UGC slides when both shoes have UGC data
		const leftActiveSlides = pathOr([], ["detail", "slides"], left);
		const rightActiveSlides = pathOr([], ["detail", "slides"], right);

		const lastLeftSlide = leftActiveSlides[leftActiveSlides.length - 1];
		const lastRightSlide = rightActiveSlides[rightActiveSlides.length - 1];

		if (
			lastLeftSlide &&
			lastLeftSlide.images &&
			lastLeftSlide.images.length > 1 &&
			lastRightSlide &&
			lastRightSlide.images &&
			lastRightSlide.images.length > 1
		) {
			// INFO (Katia) -  Only Show All UGC when both shoes have 2 images, otherwise show 1.
			setShowAllUgc(true);
		} else {
			setShowAllUgc(false);
		}

		initSlider({ slides: Math.min(leftActiveSlides.length - 1, rightActiveSlides.length - 1) });
	};

	const handleSetCurrentSlide = (i) => {
		updateCurrentSlide(i);
	};

	const handleShowDetail = (tempDirection) => {
		dispatch(updateVisible(false, tempDirection === SIDE.Right ? SIDE.Left : SIDE.Right));
		dispatch(updateVisible(true, tempDirection));
		history.push({ pathname: ROUTES.Detail, search: location.search });
	};

	const handleReviewsPage = (direction) => {
		dispatch(updateVisible(false, flipSides(isFlipped, direction === SIDE.Right ? SIDE.Left : SIDE.Right)));
		dispatch(updateVisible(true, flipSides(isFlipped, direction)));
		history.push({ pathname: ROUTES.Reviews, search: location.search });
	};

	const handleSetProductGroupings = (id, direction) => {
		dispatch(updateVisible(false, flipSides(isFlipped, direction === SIDE.Right ? SIDE.Left : SIDE.Right)));
		dispatch(updateVisible(true, flipSides(isFlipped, direction)));

		history.push({
			pathname: ROUTES.ProductGrouping,
			search: location.search,
			state: { id, side: direction === SIDE.Right ? SIDE.Right : SIDE.Left },
		});
	};

	const handleRemove = (side) => {
		dispatch(removeTag(pathOr("", ["epc"], shoes[side])));
		dispatch(clearQty(side));
		dispatch(clearPrice(side));
		dispatch(clearActive(side));
	};

	const checkShowSportPulse = (shoe) => {
		if (process.env.REACT_APP_LOCATION === "china") return false;
		let performance = false;

		try {
			const category = pathOr(null, ["meta", "category"], shoe.detail);
			performance = category === filterIDs.runningID; // || category === filterIDs.trainingID;
		} catch (e) {
			// console.log(e);
			sentryLogException("No Performance for Language", e);
		}

		return performance;
	};

	const tagLineTypography = (primaryLength, secondaryLength) => {
		if (primaryLength >= 79 || secondaryLength >= 79)
			return {
				primary: true,
				size: "lg",
				lineHeightSize: "md",
				tag: "span",
			};
		else if (primaryLength >= 65 || secondaryLength >= 65)
			return {
				primary: true,
				size: "xl",
				lineHeightSize: "lg",
				tag: "span",
			};
		else
			return {
				primary: true,
				size: "2xl",
				lineHeightSize: "xl",
				tag: "span",
			};
	};
	// #endregion FUNCTIONS

	// #region LIFECYCLE HOOKS
	useEffect(() => {
		if (!isNil(left) && !isNil(right)) {
			setAnalytics("product", { product_id: leftId, member: !isEmpty(identity) });
			setAnalytics("product", { product_id: rightId, member: !isEmpty(identity) });
			setAnalytics("products_compared", {
				compare_product_ids: `${leftId}, ${rightId}`,
				member: !isEmpty(identity),
			});

			// Reset slide to the first one
			handleShowUgc();
			updateCurrentSlide(0);
		}
	}, [left, right]);

	useEffect(() => {
		try {
			if (left && gridShoeLeft && gridShoeLeft.recommendations.length > 0) {
				left.detail.tempRecommendations = gridShoeLeft.recommendations;
			} else if (left) {
				left.detail.tempRecommendations = left.detail.recommendations;
			}
			if (right && gridShoeRight && gridShoeRight.recommendations.length > 0) {
				right.detail.tempRecommendations = gridShoeRight.recommendations;
			} else if (right) {
				right.detail.tempRecommendations = right.detail.recommendations;
			}

			if (
				left &&
				right &&
				gridShoeLeft &&
				gridShoeLeft.recommendations.length > 0 &&
				gridShoeRight &&
				gridShoeRight.recommendations.length > 0
			) {
				left.detail.tempRecommendations = left.detail.tempRecommendations.filter(
					(leftItem) => leftItem.copy !== right.detail.tempRecommendations[0].copy
				);
			} else if (
				left &&
				right &&
				gridShoeLeft &&
				gridShoeLeft.recommendations.length === 0 &&
				gridShoeRight &&
				gridShoeRight.recommendations.length === 0
			) {
				left.detail.tempRecommendations = left.detail.recommendations;
				right.detail.tempRecommendations = right.detail.recommendations;
			}
		} catch (e) {
			if (left && !isNil(left.detail)) left.detail.tempRecommendations = [];
			if (right && !isNil(right.detail)) right.detail.tempRecommendations = [];

			sentryLogException("Compare", e);
		}
	}, [gridShoeLeft, gridShoeRight, left, right]);

	useEffect(() => {
		if (!isNil(leftId)) {
			const membershipShoe = find(propEq("id", leftId))(allShoes);
			if (!isEmpty(identity)) {
				setGridShoeLeft(membershipShoe);
			}
		}
		if (!isNil(rightId)) {
			const membershipShoe = find(propEq("id", rightId))(allShoes);
			if (!isEmpty(identity)) {
				setGridShoeRight(membershipShoe);
			}
		}
	}, [allShoes, left, right]);

	useEffect(() => {
		setShowRail(false);
		let showRailTimer = null;

		const reloadTimer = setTimeout(() => {
			endGrid();
			let grid = compareVerticalGrid;
			// if (membershipActive && !isEmpty(identity) && !isNil(left) && !isNil(right)) {
			// 	if (checkShowSportPulse(left) && checkShowSportPulse(right)) grid = compareVerticalGridMembership;
			// 	else if (!checkShowSportPulse(left) && !checkShowSportPulse(right))
			// 		grid = compareVerticalGridMembershipBothOne;
			// 	else if (!checkShowSportPulse(left)) grid = compareVerticalGridMembershipLeftOne;
			// 	else if (!checkShowSportPulse(right)) grid = compareVerticalGridMembershipRightOne;
			// }
			if (!checkShowSportPulse(left) && !checkShowSportPulse(right)) grid = compareVerticalGridBothOne;
			else if (!checkShowSportPulse(left)) grid = compareVerticalGridLeftOne;
			else if (!checkShowSportPulse(right)) grid = compareVerticalGridRightOne;

			startGrid(grid);

			try {
				if (left && !isNil(left.detail)) {
					// let recommendationIntervalLeft = null;
					// if (left.detail.tempRecommendations.length > 1) {
					// 	setRecommendationCopyLeft(left.detail.tempRecommendations[0].copy);
					// 	let count = 1;
					// 	recommendationIntervalLeft = setInterval(() => {
					// 		setRecommendationCopyLeft(left.detail.tempRecommendations[count].copy);
					// 		count += 1;
					// 		if (count === left.detail.tempRecommendations.length) {
					// 			count = 0;
					// 		}
					// 	}, 3000);
					// } else
					if (left.detail.tempRecommendations.length > 0) {
						setRecommendationCopyLeft(left.detail.tempRecommendations[0].copy);
					}
				}
				if (right && !isNil(right.detail)) {
					// let recommendationIntervalRight = null;
					// if (!isNil(right.detail.tempRecommendations) && right.detail.tempRecommendations.length > 1) {
					// 	setRecommendationCopyRight(right.detail.tempRecommendations[0].copy);
					// 	let count = 1;
					// 	recommendationIntervalRight = setInterval(() => {
					// 		setRecommendationCopyRight(right.detail.tempRecommendations[count].copy);
					// 		count += 1;
					// 		if (count === right.detail.tempRecommendations.length) {
					// 			count = 0;
					// 		}
					// 	}, 3000);
					// } else
					if (right.detail.tempRecommendations.length > 0)
						setRecommendationCopyRight(right.detail.tempRecommendations[0].copy);
				}
			} catch (e) {
				if (left && !isNil(left.detail)) left.detail.tempRecommendations = [];
				if (right && !isNil(right.detail)) right.detail.tempRecommendations = [];

				sentryLogException("Compare", e);
			}
		}, 10);

		if (!isEmpty(identity)) {
			showRailTimer = setTimeout(() => {
				setShowRail(true);
				clearTimeout(showRailTimer);
			}, 1000);
		}

		return () => {
			clearTimeout(reloadTimer);
			clearTimeout(showRailTimer);
		};
	}, [language, gridShoeLeft, gridShoeRight, identity]);

	useEffect(() => {
		setAnalytics("compare", { member: !isEmpty(identity) });
		return () => {
			handleDestroy();
		};
	}, []);
	// #endregion LIFECYCLE HOOKS

	// #region RENDER
	const renderRecommendationHighlight = useMemo(() => (recommendation, popular = false) => {
		const copy = t(recommendation.text, recommendation.data ?? null);
		const newCopy = copy.split(" ");
		const highlight = newCopy.splice(-2);
		let string = "";
		if (!popular) string += t("recommendations.because");
		string += newCopy.join(" ");
		string += " ";
		string += highlight.join(" ");
		return (
			<>
				<Codification
					text={string}
					typography={{ primary: true, size: "lg", lineHeightSize: "md", uppercase: true }}
					characterSwitchAmount={4}
					characterNextTrigger={2}
					characterTimeout={10}
					lowGPU={lowGPU}
				/>
			</>
		);
	});

	const hero = useMemo(() => (code, missingDetailImages, slides, direction) => (
		<SliderShoeHero
			code={code}
			showAllUgc={showAllUgc}
			slides={slides}
			missingDetailImages={missingDetailImages}
			handleSetProductGroupings={(id) => handleSetProductGroupings(id, direction)}
			currentSlide={currentSlide}
			handlePrev={handlePrev}
			handleNext={handleNext}
			speed={speed}
			direction={globalDirection}
			verticalCompare
		/>
	));

	const block2 = useMemo(() => (data, direction) => (
		<Block
			className="c-block--floating-content c-block--inset"
			onClick={!isNil(pathOr(null, ["feedback", "reviews", 0], data)) ? () => handleReviewsPage(direction) : null}
		>
			<Block.Header>
				<Typography tag="p" size="2xs">
					{data.feedback.reviews.length} {t("generic.reviews")}
				</Typography>
			</Block.Header>

			<Block.Content
				className={`c-block--inset u-flex u-ai-center ${
					isEmpty(identity) ? "u-jc-center" : "u-jc-left"
				} u-fill-width`}
			>
				<Codification
					text={data.feedback.avg_rating ? `${data.feedback.avg_rating} / 5` : t("generic.no_reviews")}
					typography={{
						primary: true,
						size: data.feedback.avg_rating && isEmpty(identity) ? "3xl" : "lg",
						uppercase: true,
					}}
					characterSwitchAmount={4}
					characterNextTrigger={2}
					characterTimeout={20}
					lowGPU={lowGPU}
				/>
			</Block.Content>

			<Block.Footer className="u-flex u-jc-between u-ac-end u-mt-0">
				<ReviewStars className="u-ai-end" rating={data.feedback.avg_rating} size={3} space={1} />

				{!isNil(pathOr(null, ["feedback", "reviews", 0], data)) && (
					<ButtonIcon className="c-icon--size-3" icon="plus" />
				)}
			</Block.Footer>
		</Block>
	));

	const block3 = useMemo(() => (productGrouping, asset, direction) => {
		const hasDetail = pathOr([], ["assets"], productGrouping).length > 2;

		if (isNil(productGrouping)) {
			return <></>;
		}

		return (
			<Block
				className="s-visual c-block--floating-content c-block--inset"
				onClick={hasDetail ? () => handleSetProductGroupings(productGrouping.id, direction) : null}
			>
				<Block.Background
					className={cn("c-block__image compare-page", {
						"no-detail": hasDetail,
						recommended: !isEmpty(identity),
					})}
					src={asset}
				/>

				<Block.Header className="u-m-0">
					<Typography tag="span" size="2xs">
						{t("titles.technology_style")}
					</Typography>
				</Block.Header>

				<Block.Content className="u-flex u-jc-center u-ai-center u-text-c u-fill-width">
					<Codification
						text={pathOr("", ["meta", "name"], productGrouping)}
						typography={{ primary: true, size: "md", uppercase: true }}
						characterSwitchAmount={4}
						characterNextTrigger={2}
						characterTimeout={20}
						lowGPU={lowGPU}
					/>
				</Block.Content>

				<Block.Footer className="u-flex u-jc-end u-ac-end">
					{hasDetail && <ButtonIcon className="c-icon--size-3" icon="plus" />}
				</Block.Footer>
			</Block>
		);
	});

	const block4 = useMemo(() => (recommendation, popularWith, side) => {
		return (
			<Block className="c-block--inset c-block--floating-content">
				{recommendation ? (
					<Block.Header>
						<Typography tag="p" size="2xs">
							{t("titles.recommended_for_you")}
						</Typography>
					</Block.Header>
				) : (
					<Block.Header>
						<Typography tag="p" size="2xs">
							{t("titles.popular_with")}
						</Typography>
					</Block.Header>
				)}

				<Block.Content
					className={cn("c-block--inset u-flex u-jc-start u-ai-center recommendation", {
						recommendation,
					})}
				>
					<Typography primary tag="span" size="lg" uppercase lineHeightSize="md">
						{recommendation
							? renderRecommendationHighlight(
									side === "left" ? recommendationCopyLeft : recommendationCopyRight
							  )
							: renderRecommendationHighlight(popularWith, true)}
					</Typography>
				</Block.Content>
			</Block>
		);
	});

	return (
		!shouldRerenderGrid &&
		!isGridLoading &&
		!loading && (
			<>
				<GridBlock id="CompareSliderNav" style={{ zIndex: 1, backgroundColor: "rgba(0, 0, 0, 0.09)" }}>
					<SliderNavigation
						setCurrentSlide={handleSetCurrentSlide}
						slides={pathOr(0, ["totalSlides"], slider)}
						step={currentSlide}
						speed={speed}
						className="u-jc-center u-ai-center u-fill-space"
					/>
				</GridBlock>
				{hasLeftData && (
					<>
						<GridBlock id="CompareSliderLeft">
							{hero(
								pathOr(null, ["meta", "color_code"], left?.detail),
								left?.detail.missingDetailImages,
								left?.detail.slides.slice(1),
								SIDE.Left
							)}
						</GridBlock>
						<GridBlock id="CompareContentLeft" className="compare">
							<div className={cn("c-block--base u-px-16 u-py-64")}>
								<div className={cn("u-flex u-col u-jc-between u-fill-height")}>
									<Codification
										className="u-max-width-120"
										align="left"
										text={left?.detail.content.tagline}
										typography={tagLineTypography(
											left?.detail.content.tagline.length,
											right?.detail.content.tagline.length
										)}
										lowGPU={lowGPU}
										characterSwitchAmount={4}
										characterNextTrigger={2}
										characterTimeout={20}
									/>
									<div>
										<ProductAvailabilityPrice
											className={cn("u-flex u-row u-ai-center")}
											blockHeaderClassName=""
											blockContentClassName=""
											name={left?.detail.content.name}
											direction={isFlipped ? SIDE.Left : SIDE.Right}
											side={SIDE.Left}
											lowGPU={lowGPU}
											showPrice={showPrice}
											showStock={showStock}
											isFavoritesEnabled={isFavoritesEnabled && !isEmpty(identity)}
										/>
										<div className="u-flex u-mt-12">
											<Button
												className="c-button--rounded u-mr-5"
												onClick={() => handleShowDetail(SIDE.Left)}
											>
												<Button.Text>{t("buttons.more_details")}</Button.Text>
											</Button>
											{left?.isDigital && (
												<Button
													onClick={() => handleRemove(SIDE.Left)}
													className="c-button--rounded c-button--outline u-mr-5"
												>
													<Button.Text size="2xs">{t("buttons.remove_shoe")}</Button.Text>
												</Button>
											)}
											{!isEmpty(identity) && (
												<FavoritesButton
													shoe={left}
													vertical
													page="compare"
													iconOnly
													isDigital={left?.isDigital}
												/>
											)}
										</div>
									</div>
								</div>
							</div>
						</GridBlock>
						<GridBlock id="CompareMiddleLeftOne">
							<ProductReviews activeShoe={left?.detail} direction={SIDE.Left} />
						</GridBlock>
						<GridBlock id="CompareMiddleLeftTwo">
							{
								isEmpty(identity) && checkShowSportPulse(left) ? (
									<ProductSportPulse activeShoe={left?.detail} />
								) : null
								// <ProductStyle
								// 	productGroupings={pathOr([], ["productGroupings"], left?.detail)}
								// 	activeShoe={left?.detail}
								// 	identity={identity}
								// 	side={SIDE.Left}
								// 	handleSetProductGroupings={handleSetProductGroupings}
								// 	style
								// />
							}
						</GridBlock>
						{!isEmpty(identity) ? (
							<GridBlock id="CompareMiddleLeftTwo">
								{block4(left.detail.tempRecommendations[0], left.detail.popularWith, SIDE.Left)}
							</GridBlock>
						) : null}
					</>
				)}

				{hasRightData && (
					<>
						<GridBlock id="CompareSliderRight">
							{hero(
								pathOr(null, ["meta", "color_code"], right?.detail),
								right?.detail.missingDetailImages,
								right?.detail.slides.slice(1),
								SIDE.Right
							)}
						</GridBlock>
						<GridBlock id="CompareContentRight" className="compare">
							<div className={cn("c-block--base u-px-16 u-py-64")}>
								<div className={cn("u-flex u-col u-jc-between u-fill-height")}>
									<Codification
										className="u-max-width-120"
										align="left"
										text={right?.detail.content.tagline}
										typography={tagLineTypography(
											right?.detail.content.tagline.length,
											left?.detail.content.tagline.length
										)}
										lowGPU={lowGPU}
										characterSwitchAmount={4}
										characterNextTrigger={2}
										characterTimeout={20}
									/>
									<div>
										<ProductAvailabilityPrice
											className={cn("u-flex u-row u-ai-center")}
											blockHeaderClassName=""
											blockContentClassName=""
											name={right?.detail.content.name}
											direction={isFlipped ? SIDE.Left : SIDE.Right}
											side={SIDE.Right}
											lowGPU={lowGPU}
											showPrice={showPrice}
											showStock={showStock}
											isFavoritesEnabled={isFavoritesEnabled && !isEmpty(identity)}
										/>
										<div className="u-flex u-mt-12">
											<Button
												className="c-button--rounded u-mr-5"
												onClick={() => handleShowDetail(SIDE.Right)}
											>
												<Button.Text>{t("buttons.more_details")}</Button.Text>
											</Button>
											{right?.isDigital && (
												<Button
													onClick={() => handleRemove(SIDE.Right)}
													className="c-button--rounded c-button--outline u-mr-5"
												>
													<Button.Text size="2xs">{t("buttons.remove_shoe")}</Button.Text>
												</Button>
											)}
											{!isEmpty(identity) && (
												<FavoritesButton
													shoe={right}
													vertical={qForm === "vertical"}
													page="compare"
													iconOnly
													isDigital={right?.isDigital}
												/>
											)}
										</div>
									</div>
								</div>
							</div>
						</GridBlock>
						<GridBlock id="CompareMiddleRightOne">
							<ProductReviews activeShoe={right?.detail} direction={SIDE.Right} />
						</GridBlock>
						<GridBlock id="CompareMiddleRightTwo">
							{
								isEmpty(identity) && checkShowSportPulse(right) ? (
									<ProductSportPulse activeShoe={right?.detail} />
								) : null
								// <ProductStyle
								// 	productGroupings={pathOr([], ["productGroupings"], right?.detail)}
								// 	activeShoe={right?.detail}
								// 	identity={identity}
								// 	side={SIDE.Right}
								// 	handleSetProductGroupings={handleSetProductGroupings}
								// 	style
								// />
							}
						</GridBlock>
						{!isEmpty(identity) ? (
							<GridBlock id="CompareMiddleRightTwo">
								{block4(right.detail.tempRecommendations[0], right.detail.popularWith, SIDE.Right)}
							</GridBlock>
						) : null}
					</>
				)}

				<GridBlock id="CompareTopLeft" className="u-flex u-col u-jc-end u-ai-center">
					<PlaceDetail side="left" />
				</GridBlock>
				<GridBlock id="CompareTopRight" className="u-flex u-col u-jc-end u-ai-center">
					<PlaceDetail side="right" />
				</GridBlock>

				<GridBlock className="u-flex u-ai-center" id="CompareBottomFull">
					<div
						onClick={toggleIsOpen}
						onKeyDown={toggleIsOpen}
						role="button"
						tabIndex={0}
						className="options-button u-p-1"
						style={!welcomeRail ? { zIndex: 10 } : null}
					>
						<Icon className="c-icon-membership--size-9" type="options-menu" />
						<Icon className="c-icon-membership--size-9" type="caret-up" />
					</div>
					{membershipActive && !welcomeRail && (
						<div
							className="u-flex u-ai-start u-jc-start u-fill-space u-mt--20"
							style={{ height: "fit-content" }}
						>
							<MembershipMenu />
						</div>
					)}
					{membershipActive && welcomeRail && showRail && (
						<div style={{}} className="u-fill-space u-p-1">
							<MembershipMenu />
						</div>
					)}
				</GridBlock>
			</>
		)
	);
	// #endregion RENDER
};

export default CompareVertical;
