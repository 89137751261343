import { useContext, useState, useEffect, useRef } from "react";

import { GridContext } from "@/providers/Grid";

import { useIsGridLoading } from "@/store/ui/ui.hooks";

export default () => {
	const [isMounted, setIsMounted] = useState(false);
	const [shouldRerenderGrid, setShouldRerenderGrid] = useState(false);
	const gridContext = useContext(GridContext);
	const isGridLoading = useIsGridLoading();
	const hasGridStarted = useRef(false);

	const start = async (data) => {
		if (isGridLoading || hasGridStarted.current) {
			return;
		}

		if (isMounted) {
			setShouldRerenderGrid(true);
		}

		await gridContext.startSequence(data);

		if (isMounted) {
			setShouldRerenderGrid(false);
		}

		hasGridStarted.current = true;
	};

	const end = async () => {
		hasGridStarted.current = false;
	};

	useEffect(() => {
		setIsMounted(true);

		return () => {
			setIsMounted(false);
		};
	}, []);

	return {
		isGridLoading,
		shouldRerenderGrid,
		start,
		end,
	};
};
