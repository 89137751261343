import React, { memo, useEffect, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { wrap } from "popmotion";
import { AnimateSharedLayout, AnimatePresence, motion } from "framer-motion";
import { isEmpty, isNil, pathOr } from "ramda";

import SWIPE_TRESHOLD from "@/constants/events";

import ShoeHero from "@/components/ShoeHero";
import Highlight from "@/components/Highlight";
import CircleLabel from "@/components/CircleLabel";
import Codification from "@/components/Codification";
import { Button1 } from "@/components/Button";

import { useFlippedOrientation, useLowGPU } from "@/store/ui/ui.hooks";

import swipePower from "@/utils/slider/swipePower";
import preloadImages from "@/utils/preload";
import { getProductGroupingSliderButtons } from "@/utils/data/productGroupings";
import { isLangKorean } from "@/utils/i18n";

import { slideFull } from "@/animations";

import propTypes from "./SliderShoeHero.propTypes";
import defaultProps from "./SliderShoeHero.defaultProps";

const SliderShoeHero = ({
	slides,
	handleSetProductGroupings,
	handleNext,
	handlePrev,
	currentSlide,
	code,
	speed,
	isDetail,
	vertical = false,
	verticalCompare = false,
	horizontal = false,
	direction,
	showAllUgc,
	missingDetailImages,
	small,
}) => {
	const { t } = useTranslation();
	const isFlipped = useFlippedOrientation();
	const lowGPU = useLowGPU();

	const [page, setPage] = useState(0);
	const [isPreloading, setIsPreloading] = useState(true);
	const pageIndex = wrap(0, slides.length, page);

	// #region FUNCTIONS
	const paginate = () => {
		const newPage = page + direction;

		setPage(newPage);
	};

	const handleDragEnd = (e, { offset, velocity }) => {
		const swipe = swipePower(offset.x, velocity.x);

		if (swipe < -SWIPE_TRESHOLD) {
			handleNext();
			paginate();
		} else if (swipe > SWIPE_TRESHOLD) {
			handlePrev();
			paginate();
		}
	};
	const getSize = (text) => {
		const isKorean = isLangKorean();

		const initialSize = isKorean ? 2.4 : 3.2823;
		const initialLineHeight = 3.2;
		const stepSize = isKorean ? 0.4 : 1.2;
		const stepLineHeight = 0.4;

		if (!text) {
			return {
				size: "",
				lineHeight: initialLineHeight,
			};
		}

		// eslint-disable-next-line
		const length = text.length;
		const avgLineLength = isKorean ? 18 : 22;
		const maxLinesChars = avgLineLength * 4;

		if (!isDetail && length > maxLinesChars) {
			const diff = length - maxLinesChars;
			const lines = Math.floor(diff / avgLineLength);

			const totalSize = lines * stepSize;
			const totalLineHeight = lines * stepLineHeight;

			return {
				size: isKorean ? initialSize - totalSize : initialSize,
				lineHeight: initialLineHeight,
			};
		}

		return {
			size: isKorean ? initialSize : "",
			lineHeight: initialLineHeight,
		};
	};

	const determineAnimation = () => {
		if (vertical && slides[pageIndex].isAlignedBottom) return "verticalBottom";
		else if (vertical && !slides[pageIndex].isAlignedBottom) return "verticalTop";
		else if (horizontal && slides[pageIndex].isAlignedBottom) return "horizontalBottom";
		else if (horizontal && !slides[pageIndex].isAlignedBottom) return "horizontalTop";
		else if (verticalCompare && slides[pageIndex].isAlignedBottom) return "verticalCompareBottom";
		else if (verticalCompare && !slides[pageIndex].isAlignedBottom) return "verticalCompareTop";
		else if (slides[pageIndex].isAlignedBottom) return "bottom";
		else return "default";
	};
	// #endregion FUNCTIONS

	// #region LIFECYCLE HOOKS
	useEffect(() => {
		if (pageIndex !== currentSlide) {
			setPage(currentSlide);
		}
	}, [currentSlide]);

	useEffect(() => {
		setIsPreloading(true);

		const images = slides.reduce((acc, slide) => {
			if (!isNil(slide.image)) {
				return [...acc, slide.image];
			}

			if (!isNil(slide.images)) {
				return [...acc, ...slide.images];
			}

			return acc;
		}, []);

		preloadImages(images, () => {
			setIsPreloading(false);
		});
	}, [slides]);
	// #endregion LIFECYCLE HOOKS

	// #region RENDER
	if (!slides[pageIndex]) {
		return null;
	}

	const renderShoeHeading = (title) => {
		const { size, lineHeight } = getSize(title);

		return (
			<ShoeHero.Heading>
				<Codification
					text={title}
					typography={{
						primary: true,
						size: "3xl",
						tag: "h1",
						uppercase: true,
						typeStyle: {
							lineHeight: `${lineHeight}vw`,
							...(!isEmpty(size) && { fontSize: `${size}vw` }),
						},
					}}
					lowGPU={lowGPU}
					characterSwitchAmount={4}
					characterNextTrigger={2}
					characterTimeout={20}
				/>
			</ShoeHero.Heading>
		);
	};

	const renderProductGroupButtons = () => {
		const sliderButtons = pathOr([], [pageIndex, "buttons"], slides);

		if (isEmpty(sliderButtons)) {
			return null;
		}

		const buttons = getProductGroupingSliderButtons(sliderButtons, verticalCompare ? 2 : 4);

		return (
			<div className={cn("u-pointer-events-all u-flex u-ai-center u-inline-1")}>
				{buttons.map((button) =>
					!isEmpty(button.name) ? (
						<Button1
							className={!button.hasMedia && "is-disabled"}
							key={button.id}
							type="highlight"
							icon={button.hasMedia && "plus"}
							onClick={button.hasMedia ? () => handleSetProductGroupings(button.id) : null}
							iconClassName={button.hasMedia && "c-icon--outline"}
						>
							{button.name}
						</Button1>
					) : null
				)}
			</div>
		);
	};

	return (
		<>
			<AnimatePresence custom={direction}>
				<motion.div
					key={page}
					custom={direction}
					variants={slideFull}
					initial="enter"
					animate="center"
					exit="exit"
					drag="x"
					transition={{
						x: { type: "spring", bounce: 0, delay: 0.3 },
						opacity: { duration: 0.2 },
					}}
					transformTemplate={({ x }) => {
						const xTransform = parseFloat(x, 10);
						return `translate3d(${isFlipped ? -xTransform : xTransform}px, 0, 0)`;
					}}
					dragConstraints={{ left: 0, right: 0 }}
					dragElastic={1}
					onDragEnd={handleDragEnd}
					className={cn("c-slide c-slide__drag", {
						"c-slide--front": slides[pageIndex].imageZIndex === "front",
						"c-slide--back": slides[pageIndex].imageZIndex === "back",
						"u-pointer-events-none": isPreloading,
					})}
				>
					<ShoeHero>
						{!slides[pageIndex].isUgc && (
							<ShoeHero.Background
								missingDetailImages={missingDetailImages}
								src={slides[pageIndex].image}
								isSmall={slides[pageIndex].isImageSmall}
								speed={speed}
							/>
						)}
						{slides[pageIndex].isVideo && (
							<ShoeHero.Video
								src={verticalCompare ? slides[pageIndex].videoVerticalCompare : slides[pageIndex].video}
								speed={speed}
							/>
						)}
						{slides[pageIndex].isUgc &&
							slides[pageIndex].images &&
							slides[pageIndex].images.length > 1 &&
							showAllUgc && <ShoeHero.Images images={slides[pageIndex].images} speed={speed} />}
						{slides[pageIndex].isUgc &&
							slides[pageIndex].images &&
							(slides[pageIndex].images.length === 1 || !showAllUgc) && (
								<>
									<ShoeHero.Background
										isUgc={slides[pageIndex].isUgc}
										src={slides[pageIndex].images[0]}
										isSmall={slides[pageIndex].isImageSmall}
										speed={speed}
									/>
									<ShoeHero.Icon icon="instagram" />
								</>
							)}
					</ShoeHero>
				</motion.div>
			</AnimatePresence>

			<AnimateSharedLayout>
				<div className="c-slide">
					<ShoeHero>
						{slides[pageIndex] &&
							!slides[pageIndex].isHighlighted &&
							!isDetail &&
							renderShoeHeading(slides[pageIndex].title)}

						<ShoeHero.Content className={cn({ verticalCompare })} animation={determineAnimation()}>
							<CircleLabel
								isVisible={
									vertical || verticalCompare || horizontal ? false : slides[pageIndex].isHighlighted
								}
								label={code}
							/>

							<div className={cn("u-flex u-col u-ai-start u-mt-2", { "u-ml-11": !verticalCompare })}>
								<Highlight
									className={cn("s-dark", {
										"u-mb-3": slides[pageIndex].isHighlighted && !horizontal,
										"u-mb-2": slides[pageIndex].isHighlighted && horizontal,
										"not-first-slide": pageIndex > 0,
									})}
									title={slides[pageIndex].title}
									tagline={pageIndex === 0 ? t("generic.good_for") : ""}
									isVisible={!!slides[pageIndex].title && slides[pageIndex].isHighlighted}
									verticalDetail={vertical}
									verticalCompare={verticalCompare && pageIndex > 0}
									small={small}
								/>

								<Highlight
									className={cn("s-dark", {
										"u-mb-3":
											slides[pageIndex].isHighlighted &&
											slides[pageIndex].description &&
											!horizontal,
										"u-mb-1":
											slides[pageIndex].isHighlighted &&
											slides[pageIndex].description &&
											horizontal,
									})}
									isVisible={!!slides[pageIndex].description && slides[pageIndex].isHighlighted}
									description={slides[pageIndex].description}
									verticalDetail={vertical}
									verticalCompare={verticalCompare && pageIndex > 0}
								/>

								{slides[pageIndex].buttons && renderProductGroupButtons()}
							</div>
						</ShoeHero.Content>
					</ShoeHero>
				</div>
			</AnimateSharedLayout>
		</>
	);
	// #endregion RENDER
};

SliderShoeHero.propTypes = propTypes;
SliderShoeHero.defaultProps = defaultProps;

export default memo(SliderShoeHero);
