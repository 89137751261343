import React, { memo } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";

import Icon from "@/components/Icon";
import Typography from "@/components/Typography";

import { fadeMove } from "@/animations";

import propTypes from "./CircleLabel.propTypes";
import defaultProps from "./CircleLabel.defaultProps";

// =============================================================================
// Root
// =============================================================================
const CircleLabel = ({ className = "", label, isVisible }) => {
	const { t } = useTranslation();

	return (
		<AnimatePresence>
			{isVisible && (
				<motion.div
					layoutId="circle-label"
					variants={fadeMove}
					initial="hidden"
					animate="show"
					exit="hidden"
					className={cn("c-circle-label", className)}
				>
					<Typography
						uppercase
						size="4xs"
						className="c-circle-label__text u-flex u-col u-ml-auto"
						lineHeight={false}
					>
						<span className="u-ml-3">{t("generic.title")}</span>
						<span>{label}</span>
					</Typography>

					<Icon type="callout" className="c-icon--size-9" />
				</motion.div>
			)}
		</AnimatePresence>
	);
};

CircleLabel.propTypes = propTypes;
CircleLabel.defaultProps = defaultProps;

export default memo(CircleLabel);
