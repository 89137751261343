import React from "react";
import { pathOr } from "ramda";
import { motion, AnimatePresence } from "framer-motion";
import cn from "classnames";

import { isLangKorean } from "@/utils/i18n";

import GridBlock from "@/components/GridBlock";
import Codification from "@/components/Codification";

import { fadeMove, fadeMoveKo } from "@/animations";

import propTypes from "./ProductGroupingPartialsTitle.propTypes";
import defaultProps from "./ProductGroupingPartialsTitle.defaultProps";

const ProductGroupingPartialsTitle = ({ data, id, lang, lowGPU, horizontal, vertical, size = "4xl", className }) => (
	<GridBlock id={id}>
		<AnimatePresence>
			<motion.div
				className={cn({ "u-width-102%": horizontal }, className)}
				initial="hidden"
				animate="show"
				variants={lang === "ko" ? fadeMoveKo : fadeMove}
			>
				<Codification
					text={pathOr("", ["content", "heading"], data)}
					className={cn({ "u-line-anywhere u-mt--2": horizontal, "u-mt-1": isLangKorean() && horizontal })}
					typography={{
						primary: true,
						size: size,
						tag: "h1",
						uppercase: true,
						lineHeightSize: !isLangKorean() ? "5xl" : "",
					}}
					characterSwitchAmount={4}
					characterNextTrigger={2}
					characterTimeout={20}
					lowGPU={lowGPU}
				/>
			</motion.div>
		</AnimatePresence>
	</GridBlock>
);

ProductGroupingPartialsTitle.propTypes = propTypes;
ProductGroupingPartialsTitle.defaultProps = defaultProps;

export default ProductGroupingPartialsTitle;
