import UIActionTypes from "./ui.action-types";
import UIInitialState from "./ui.initial-state";

const UIReducer = (state = UIInitialState(), action) => {
	switch (action.type) {
		default: {
			return state;
		}

		case UIActionTypes.SET_LANGUAGE_MODAL: {
			return {
				...state,
				modals: {
					...state.modals,
					langSelector: action.data,
				},
			};
		}

		case UIActionTypes.SET_RESET_MODAL: {
			return {
				...state,
				modals: {
					...state.modals,
					reset: action.data,
				},
			};
		}

		case UIActionTypes.SET_ATTRACT_MODAL: {
			return {
				...state,
				modals: {
					...state.modals,
					attract: action.data,
				},
			};
		}

		case UIActionTypes.SET_SHOE_NOT_FOUND_MODAL: {
			return {
				...state,
				modals: {
					...state.modals,
					shoeNotFound: action.data,
				},
			};
		}

		case UIActionTypes.SET_OPTIONS_MODAL: {
			return {
				...state,
				modals: {
					...state.modals,
					options: action.data,
				},
			};
		}

		case UIActionTypes.SET_MEMBERSHIP_MODAL: {
			return {
				...state,
				modals: {
					...state.modals,
					membership: action.data,
				},
			};
		}

		case UIActionTypes.SET_ORIENTATION: {
			return {
				...state,
				flippedOrientation: action.data,
			};
		}

		case UIActionTypes.SET_GRID_LOADING: {
			return {
				...state,
				grid: {
					...state.grid,
					loading: action.data,
				},
			};
		}

		case UIActionTypes.SET_SHOES_MODAL: {
			return {
				...state,
				modals: {
					...state.modals,
					shoes: action.data,
				},
			};
		}

		case UIActionTypes.SET_ADMIN_PANEL_MODAL: {
			return {
				...state,
				modals: {
					...state.modals,
					adminPanel: action.data,
				},
			};
		}

		case UIActionTypes.SET_ENABLED_LANGUAGES: {
			return {
				...state,
				languages: action.data,
			};
		}

		case UIActionTypes.SET_SCREENSAVER_TIMER_ACTIVE: {
			return {
				...state,
				screensaverTrigged: action.data,
			};
		}

		case UIActionTypes.SET_LOW_GPU: {
			return {
				...state,
				lowGPU: action.data,
			};
		}

		case UIActionTypes.SET_QA_MODE: {
			return {
				...state,
				qaMode: action.data,
			};
		}

		case UIActionTypes.SET_LATEST_ACTION: {
			return {
				...state,
				latestAction: action.data,
			};
		}

		case UIActionTypes.SET_ACTIVE_SESSION: {
			return {
				...state,
				activeSession: action.data,
			};
		}

		case UIActionTypes.SET_RELOAD_TRIGGER: {
			return {
				...state,
				reloadTrigger: action.data,
			};
		}

		case UIActionTypes.SET_ATTRACT_TRIGGER: {
			return {
				...state,
				attractTrigger: action.data,
			};
		}

		case UIActionTypes.GET_ALL_STORES: {
			return {
				...state,
				stores: {
					...state.stores,
					loading: true,
					error: null,
				},
			};
		}

		case UIActionTypes.GET_ALL_STORES_SUCCESS: {
			return {
				...state,
				stores: {
					...state.stores,
					data: action.data,
					loading: false,
					error: null,
				},
			};
		}

		case UIActionTypes.GET_ALL_STORES_ERROR: {
			return {
				...state,
				stores: {
					...state.stores,
					loading: false,
					error: action.error,
				},
			};
		}

		case UIActionTypes.GET_ANALYTICS_TYPES_SUCCESS: {
			return {
				...state,
				analyticsTypes: {
					...state.analyticsTypes,
					data: action.data,
					loading: false,
					error: null,
				},
			};
		}

		case UIActionTypes.GET_ANALYTICS_TYPES_ERROR: {
			return {
				...state,
				analyticsTypes: {
					...state.analyticsTypes,
					loading: false,
					error: action.error,
				},
			};
		}

		case UIActionTypes.GET_STORE_ID: {
			return {
				...state,
				storeID: {
					...state.storeID,
					loading: true,
					error: null,
				},
			};
		}

		case UIActionTypes.GET_STORE_ID_SUCCESS: {
			return {
				...state,
				storeID: {
					...state.storeID,
					data: action.data,
					loading: false,
					error: null,
				},
			};
		}

		case UIActionTypes.GET_STORE_ID_ERROR: {
			return {
				...state,
				storeID: {
					...state.storeID,
					loading: false,
					error: action.error,
				},
			};
		}
	}
};

export default UIReducer;
