import PropTypes from "prop-types";

export default {
	code: PropTypes.string,
	slides: PropTypes.array,
	handleSetProductGroupings: PropTypes.func,
	handlePrev: PropTypes.func,
	handleNext: PropTypes.func,
	totalSlides: PropTypes.number,
	currentSlide: PropTypes.number,
	speed: PropTypes.number,
	isDetail: PropTypes.bool,
	showAllUgc: PropTypes.bool,
};
