import { pathOr } from "ramda";

export const selectIdentity = (state) => pathOr([], ["membership", "identity", "data"], state);
export const selectIdentityLoading = (state) => pathOr(true, ["membership", "identity", "loading"], state);
export const selectIdentityError = (state) => pathOr("", ["membership", "identity", "error"], state);

export const selectStyles = (state) => pathOr([], ["membership", "styles", "data"], state);
export const selectStylesLoading = (state) => pathOr(true, ["membership", "styles", "loading"], state);
export const selectStylesError = (state) => pathOr("", ["membership", "styles", "error"], state);

export const selectWishlists = (state) => pathOr([], ["membership", "wishlists", "data"], state);

export const selectWishlistProducts = (state) => pathOr([], ["membership", "wishlistProducts", "data"], state);

export const selectMembershipActive = (state) => pathOr(null, ["membership", "membershipActive"], state);

export const selectSimulatorActive = (state) => pathOr(false, ["membership", "simulatorActive"], state);

export const selectMenuMinimized = (state) => pathOr(false, ["membership", "menuMinimized"], state);

export const selectPrivacy = (state) => pathOr(false, ["membership", "privacy"], state);

export const selectEndSession = (state) => pathOr(false, ["membership", "endSession"], state);

export const selectSeenWelcome = (state) => pathOr(false, ["membership", "seenWelcome"], state);

export const selectWelcomeRail = (state) => pathOr(false, ["membership", "welcomeRail"], state);

export const selectShowStats = (state) => pathOr(null, ["membership", "showStats"], state);
