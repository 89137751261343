export const SIDE = {
	Left: "left",
	Right: "right",
	Both: "both",
};

export const STATUS = {
	Active: "active",
	Near: "active_near",
	Observe: "observe",
	Idle: "idle",
};

export const ORIENTATION = {
	Normal: "normal",
	Reverse: "reverse",
};
