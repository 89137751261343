import { STATUS } from "@/constants/rfid-tags";
import { concat, findIndex, propEq, filter, compose, not, find } from "ramda";

import RFIDActionTypes from "./rfid.action-types";
import RFIDInitialState from "./rfid.initial-state";

const RFIDReducer = (state = RFIDInitialState(), action) => {
	switch (action.type) {
		case RFIDActionTypes.SET_ORIENTATION: {
			const locked = state.interactionLock;
			if (!locked) {
				return {
					...state,
					orientation: action.data,
				};
			}
			return {
				...state,
			};
		}

		case RFIDActionTypes.SET_IS_SESSION: {
			return {
				...state,
				isSession: action.data,
			};
		}

		case RFIDActionTypes.SET_INTERACTION_LOCK: {
			return {
				...state,
				interactionLock: action.data,
			};
		}

		case RFIDActionTypes.SET_IS_INTERACTION: {
			return {
				...state,
				isInteraction: action.data,
			};
		}

		case RFIDActionTypes.SET_404_LIST: {
			return {
				...state,
				fourList: action.data,
			};
		}

		case RFIDActionTypes.ADD_SCC_TO_404_LIST: {
			const newSccs = state.fourList.sccs;
			if (!find((scc) => scc === action.data, newSccs)) newSccs.push(action.data);

			return {
				...state,
				fourList: {
					...state.fourList,
					sccs: newSccs,
				},
			};
		}

		case RFIDActionTypes.ADD_EPC_TO_404_LIST: {
			const newEpcs = state.fourList.epcs;
			if (!find((epc) => epc === action.data, newEpcs)) newEpcs.push(action.data);

			return {
				...state,
				fourList: {
					...state.fourList,
					epcs: newEpcs,
				},
			};
		}

		case RFIDActionTypes.UPDATE_TAGS: {
			const { id, data } = action;
			const { tags } = state;

			// Is new tag already in state?
			const foundIndex = findIndex(propEq("id", id))(tags);
			// Is there a tag already on the same side?
			const sameSide = findIndex(propEq("side", data.side))(tags);

			// New tag is not in state and there is a shoe on the same side, override current side shoe
			if (foundIndex === -1 && sameSide !== -1) {
				const updatedTags = tags.map((tag) => (tag.side === data.side ? data : tag));

				return {
					...state,
					tags: updatedTags,
				};
			}

			// New tag is not in state and there is not a shoe on the same side, add the new tag to the current tags
			if (foundIndex === -1 && sameSide === -1) {
				return {
					...state,
					tags: concat(tags, [data]),
				};
			}

			const updatedTags = tags.map((tag, i) => (i === foundIndex ? data : tag));

			return {
				...state,
				tags: updatedTags,
			};
		}

		case RFIDActionTypes.REMOVE_TAG: {
			const { tags } = state;
			const { id } = action;

			return {
				...state,
				tags: filter(compose(not, propEq("id", id)), tags),
			};
		}

		case RFIDActionTypes.CLEAR_TAGS: {
			return {
				...state,
				tags: filter(propEq("status", STATUS.Observe))(state.tags),
			};
		}

		default: {
			return state;
		}
	}
};

export default RFIDReducer;
