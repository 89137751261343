import React, { memo } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import Icon from "@/components/Icon";
import HighlightMembership from "@/components/HighlightMembership";

import { useQuery } from "@/hooks/Router";

// =============================================================================
// Root
// =============================================================================
const MembershipMenuMember = () => {
	const { t } = useTranslation();

	const query = useQuery();

	const qForm = query.get("form") || "rise";

	const direction = qForm === "vertical" ? "vh" : "vw";

	const size = qForm === "vertical" ? 2 : 1.4067;
	const grid = [0, size, size * 2, size * 3, size * 4, size * 5, size * 6];
	const duration = 15;
	const keyframes = [];
	for (let i = 0; i <= 1; i += 0.005) {
		keyframes.push(i.toFixed(2));
	}

	return (
		<motion.div
			initial={qForm === "vertical" ? { paddingLeft: "19.5vh" } : null}
			animate={
				qForm === "vertical"
					? {
							paddingLeft: [
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"19.5vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"17vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"16vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
								"14vh",
							],
					  }
					: null
			}
			transition={{ ease: [0, 1, 0, 1], duration, times: keyframes }}
		>
			<motion.div
				initial={{
					overflow: "hidden",
					position: "absolute",
					x: `${grid[1]}${direction}`,
					width: `${grid[0]}${direction}`,
				}}
				animate={{
					x: [
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
					],
					y: [
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
					],
					width: [
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
					],
				}}
				transition={{ ease: [0, 1, 0, 1], duration, times: keyframes }}
			>
				<Icon
					className={qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"}
					type="swoosh"
				/>
			</motion.div>
			<motion.div
				initial={{
					overflow: "hidden",
					position: "absolute",
					x: `${grid[1]}${direction}`,
					width: `${grid[0]}${direction}`,
				}}
				animate={{
					x: [
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
					],
					y: [
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
					],
					width: [
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
					],
				}}
				transition={{ ease: [0, 1, 0, 1], duration, times: keyframes }}
			>
				<Icon
					className={qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"}
					type="qr-code"
				/>
			</motion.div>
			<motion.div
				initial={{
					overflow: "hidden",
					position: "absolute",
					x: `${grid[2]}${direction}`,
					width: `${grid[0]}${direction}`,
				}}
				animate={{
					x: [
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
					],
					y: [
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
					],
					width: [
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
					],
				}}
				transition={{ ease: [0, 1, 0, 1], duration, times: keyframes }}
			>
				<Icon
					className={qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"}
					type="arrow"
				/>
			</motion.div>
			<motion.div
				initial={{
					overflow: "hidden",
					position: "absolute",
					x: `${grid[1]}${direction}`,
					y: `${grid[1]}${direction}`,
					width: `${grid[0]}${direction}`,
				}}
				animate={{
					x: [
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
					],
					y: [
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
					],
					width: [
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
					],
				}}
				transition={{ ease: [0, 1, 0, 1], duration, times: keyframes }}
			>
				<Icon
					className={qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"}
					type="arrow-motion-dl"
				/>
			</motion.div>
			<motion.div
				initial={{
					overflow: "hidden",
					position: "absolute",
					x: `${grid[1]}${direction}`,
					y: `${grid[1]}${direction}`,
					width: `${grid[0]}${direction}`,
				}}
				animate={{
					x: [
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
					],
					y: [
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
					],
					width: [
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
					],
				}}
				transition={{ ease: [0, 1, 0, 1], duration, times: keyframes }}
			>
				<Icon
					className={qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"}
					type="arrow-motion-ur"
				/>
			</motion.div>
			<motion.div
				initial={{
					overflow: "hidden",
					position: "absolute",
					x: `${grid[3]}${direction}`,
					width: `${grid[0]}${direction}`,
				}}
				animate={{
					x: [
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
					],
					y: [
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
					],
					width: [
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
					],
				}}
				transition={{ ease: [0, 1, 0, 1], duration, times: keyframes }}
			>
				<Icon
					className={qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"}
					type="profile"
				/>
			</motion.div>
			<motion.div
				initial={{
					overflow: "hidden",
					position: "absolute",
					x: `${grid[4]}${direction}`,
					width: `${grid[0]}${direction}`,
				}}
				animate={{
					x: [
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[3]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
					],
					y: [
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
					],
					width: [
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
					],
				}}
				transition={{ ease: [0, 1, 0, 1], duration, times: keyframes }}
			>
				<Icon
					className={qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"}
					type="black"
				/>
			</motion.div>
			<motion.div
				initial={{
					overflow: "hidden",
					position: "absolute",
					x: `${grid[4]}${direction}`,
					width: `${grid[0]}${direction}`,
				}}
				animate={{
					x: [
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[4]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
						`${grid[5]}${direction}`,
					],
					y: [
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
					],
					width: [
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
					],
				}}
				transition={{ ease: [0, 1, 0, 1], duration, times: keyframes }}
			>
				<Icon
					className={qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"}
					type="black"
				/>
			</motion.div>
			<HighlightMembership
				className={cn("s-dark")}
				title={t("membership.attract_0a")}
				delay={0.6}
				grid={grid}
				typo={
					qForm === "vertical"
						? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
						: null
				}
				initial={[4, 0]}
				animate={[4, 0]}
				clipPath={[
					"inset(0px 100% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 100% 0px 0px)",
				]}
			/>
			<HighlightMembership
				className={cn("s-dark")}
				title={t("membership.attract_0b")}
				delay={0.6}
				grid={grid}
				typo={
					qForm === "vertical"
						? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
						: null
				}
				initial={[5, 1]}
				animate={[5, 1]}
				clipPath={[
					"inset(0px 100% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 100% 0px 0px)",
				]}
			/>
			<HighlightMembership
				className={cn("s-dark")}
				title={t("membership.attract_1a")}
				delay={2.6}
				grid={grid}
				typo={
					qForm === "vertical"
						? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
						: null
				}
				initial={[4, 0]}
				animate={[4, 0]}
				clipPath={[
					"inset(0px 100% 0px 0px)",
					"inset(0px 100% 0px 0px)",
					"inset(0px 100% 0px 0px)",
					"inset(0px 100% 0px 0px)",
					"inset(0px 100% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 100% 0px 0px)",
				]}
			/>
			<HighlightMembership
				className={cn("s-dark")}
				title={t("membership.attract_1b")}
				delay={3}
				endDelay={-0.4}
				grid={grid}
				typo={
					qForm === "vertical"
						? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
						: null
				}
				initial={[5, 1]}
				animate={[5, 1]}
				clipPath={[
					"inset(0px 100% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 100% 0px 0px)",
				]}
			/>
			<HighlightMembership
				className={cn("s-dark")}
				title={t("membership.attract_2a")}
				delay={5.55}
				endDelay={4}
				grid={grid}
				typo={
					qForm === "vertical"
						? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
						: null
				}
				initial={[4, 0]}
				animate={[4, 0]}
				clipPath={[
					"inset(0px 100% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 100% 0px 0px)",
				]}
			/>
			<HighlightMembership
				className={cn("s-dark")}
				title={t("membership.attract_2b")}
				delay={5.55}
				endDelay={4}
				grid={grid}
				typo={
					qForm === "vertical"
						? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
						: null
				}
				initial={[5, 1]}
				animate={[5, 1]}
				clipPath={[
					"inset(0px 100% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 100% 0px 0px)",
				]}
			/>
			<HighlightMembership
				className={cn("s-dark")}
				title={t("membership.attract_3a")}
				delay={11.7}
				endDelay={4}
				grid={grid}
				typo={
					qForm === "vertical"
						? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
						: null
				}
				initial={[4, 0]}
				animate={[4, 0]}
				clipPath={[
					"inset(0px 100% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 100% 0px 0px)",
				]}
			/>
			<HighlightMembership
				className={cn("s-dark")}
				title={t("membership.attract_3b")}
				delay={12}
				endDelay={3.7}
				grid={grid}
				typo={
					qForm === "vertical"
						? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
						: null
				}
				initial={[5, 1]}
				animate={[5, 1]}
				clipPath={[
					"inset(0px 100% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 100% 0px 0px)",
				]}
			/>
		</motion.div>
	);
};

export default memo(MembershipMenuMember);
