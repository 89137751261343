/*
INFO:
1: when setting left or right it should look like this:
{
	epc: string; -> Refers to the id of the tag
	scc: string; -> Refers to the id of the shoe
	detail: Object; -> API data
	isDigital: boolean; -> Physical or digital shoe
	isVisible: boolean; -> Used to show a detail page while in compare mode
	template: index; -> Used to link a product grouping template so we can reuse it
}
*/
const ShoesInitialState = () => ({
	all: {
		data: [],
		loading: false,
		error: null,
	},
	active: {
		left: null, // 1
		right: null, // 1
		loading: false,
		error: null,
		qty: {
			left: null,
			right: null,
		},
		price: {
			left: null,
			right: null,
		},
	},
	location: {
		data: {
			json_meta: {
				sportpulse_id: null,
			},
		},
		loading: false,
		error: null,
	},
	imagemap: { data: [], loading: false, error: null },
});

export default ShoesInitialState;
