import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import Image from "@/components/Image";
import ImageDefaultProps from "@/components/Image/Image.defaultProps";
import ImagePropTypes from "@/components/Image/Image.propTypes";

import defaultProps from "./Block.defaultProps";
import propTypes from "./Block.propTypes";

// =============================================================================
// Root
// =============================================================================
const Block = ({ className = "", children, url = "", onClick }) => {
	const rootClassName = cn("c-block", className);

	if (url) {
		return (
			<Link to={url} className={rootClassName}>
				{children}
			</Link>
		);
	}

	if (onClick) {
		return (
			<button type="button" onClick={onClick} className={rootClassName}>
				{children}
			</button>
		);
	}

	return <div className={rootClassName}>{children}</div>;
};

Block.propTypes = propTypes.Block;
Block.defaultProps = defaultProps.Block;

// =============================================================================
// Children
// =============================================================================
const BlockHeader = ({ className = "", children }) => (
	<div className={cn("c-block__header", className)}>{children}</div>
);

Block.Header = BlockHeader;

const BlockContent = ({ className = "", children }) => (
	<div className={cn("c-block__content", className)}>{children}</div>
);

Block.Content = BlockContent;

const BlockFooter = ({ className = "", children }) => (
	<div className={cn("c-block__footer", className)}>{children}</div>
);

Block.Footer = BlockFooter;

const BlockBackground = ({ className = "", src }) => <Image className={cn("c-block__bg", className)} src={src} />;

BlockBackground.propTypes = ImagePropTypes.Image;
BlockBackground.defaultProps = ImageDefaultProps.Image;

Block.Background = BlockBackground;

export default Block;
