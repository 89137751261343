/* eslint-disable */

import { isEmpty, isNil, pathOr, filter } from "ramda";
import { Translation } from "react-i18next";

import COLOR_CODES from "./color_codes";
import ACTIVITY_TYPES from "./activity_types";
import RECOMMENDATION_PRIORITIES from "./recommendation_priorities";

import { kmsToMiles, minsPerKmToMinsPerMile, minsToPace } from "@/utils/conversions";

const dayjs = require("dayjs");
const weekOfYear = require("dayjs/plugin/weekOfYear");
const isBetween = require("dayjs/plugin/isBetween");

dayjs.extend(weekOfYear);
dayjs.extend(isBetween);

function getRandomInt(max) {
	return Math.floor(Math.random() * max);
}

// ntc
const loggedActivity = (activities, category, shoe, filterIDs, language, gender) => {
	const priority = RECOMMENDATION_PRIORITIES.loggedActivity;
	let count = 0;
	const activity = ACTIVITY_TYPES.find((el) => el.category === category);
	let newCategory = null;
	if (!isEmpty(filterIDs?.category)) {
		const langCategories = Object.values(filterIDs.category.find((el) => el[language])[language]);
		const enCategories = Object.values(filterIDs.category.find((el) => el.en).en);
		if (activity && activities[activity.type] && activities[activity.type].activity[0].count > 0) {
			count += 1;
		}
		if (category) {
			const enCategory = enCategories.find((el) => el.val === category);
			const langCategory = langCategories.find((el) => el.id === enCategory?.id);
			newCategory = langCategory?.val;
		}
		const rand = getRandomInt(priority);
		if (count > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender)) && activity.type === "run")
			return {
				type: "loggedRun",
				subtype: "loggedRun",
				priority: priority,
				copy: {
					text: "recommendations.logged_activity",
					data: { category: newCategory },
				},
			};
		else if (
			count > 0 &&
			rand === 0 &&
			(shoe.filters.gender === gender || isNil(gender)) &&
			activity.type !== "run"
		)
			return {
				type: "ntc",
				subtype: "loggedActivity",
				priority: priority,
				copy: {
					text: "recommendations.logged_activity",
					data: { category: newCategory },
				},
			};
	}

	return { copy: null };
};

// Requires a custom aggregate call
// const loggedTrainingPerWeek = (activities, category, shoe) => {
// 	const priority = RECOMMENDATION_PRIORITIES.loggedTrainingPerWeek;
// 	let data = {};
// 	if (category && category === "Training") {
// 		activities.forEach((act) => {
// 			if (act.type === "training" || act.type === "hiit") {
// 				const type = act.type;
// 				const year = dayjs(act.start_epoch_ms).get("year");
// 				const week = dayjs(act.start_epoch_ms).week();
// 				let count = pathOr(0, [year, week, type], data);
// 				count += 1;
// 				data[year] = { ...data[year] };
// 				data[year][week] = { ...data[year][week] };
// 				data = { ...data, [year]: { ...data[year], [week]: { ...data[year][week], [type]: count } } };
// 			}
// 		});
// 	}
// 	const training = filter((year) => {
// 		return !isEmpty(filter((week) => week.training >= 3, year));
// 	}, data);
// 	const hiit = filter((year) => {
// 		return !isEmpty(filter((week) => week.hiit >= 3, year));
// 	}, data);
// 	const rand = getRandomInt(priority);
// 	if (!isEmpty(hiit) && rand === 0)
// 		return {
// 			type: "ntc",
// 			subtype: "loggedTrainingPerWeek",
// 			priority: priority,
// 			copy: "Do HIIT at least 3x per week",
// 		};
// 	else if (!isEmpty(training) && rand === 0)
// 		return {
// 			type: "ntc",
// 			subtype: "loggedTrainingPerWeek",
// 			priority: priority,
// 			copy: "Train at least 3x per week",
// 		};
// 	else return { copy: null };
// };

// ntc
const preferTraining = (activities, category, shoe, filterIDs, language, gender) => {
	const priority = RECOMMENDATION_PRIORITIES.preferTraining;
	let data = {};
	if (category && category === "Training & Gym") {
		for (const [key, value] of Object.entries(activities)) {
			data = { ...data, [key]: value.activity[0].count };
		}
	}
	if (!isEmpty(data)) {
		const highest = Object.keys(data).reduce((a, b) => (data[a] > data[b] ? a : b));
		const rand = getRandomInt(priority);
		if (highest === "hiit" && rand === 0 && (shoe.filters.gender === gender || isNil(gender)))
			return {
				type: "ntc",
				subtype: "preferTraining",
				priority: priority,
				copy: {
					text: "recommendations.prefer_training",
				},
			};
	}
	return { copy: null };
};

// nrc_month
const runDistancePerWeek = (aggregates, designedFor, distanceUnit, shoe, gender) => {
	const priority = RECOMMENDATION_PRIORITIES.runDistancePerWeek;
	let data = {};

	if (designedFor && (designedFor === "Long-Distance Running" || designedFor === "Race Running")) {
		if (!isEmpty(aggregates)) {
			Object.entries(aggregates.batchAggregates).forEach((day) => {
				const week = dayjs(day[0]).week();
				const prevDistance = data[week] || 0;
				let distance = 0;
				if (!isEmpty(day[1].aggregates)) {
					distance =
						day[1].aggregates.filter((agg) => agg.metric === "distance")[0].calculated_value + prevDistance;
				} else {
					distance = prevDistance;
				}
				data = { ...data, [week]: distance };
			});
		}
	}

	if (!isEmpty(data)) {
		const values = [];
		Object.keys(data).forEach((week) => values.push(data[week]));
		const sum = values.reduce((a, b) => a + b, 0);
		let avg = sum / values.length || 0;
		if (distanceUnit === "IMPERIAL") {
			avg = Math.round(kmsToMiles(avg));
		}
		const rand = getRandomInt(priority);
		if (
			(designedFor &&
				distanceUnit === "METRIC" &&
				avg >= 20 &&
				(designedFor === "Long-Distance Running" || designedFor === "Race Running") &&
				rand === 0 &&
				(shoe.filters.gender === gender || isNil(gender))) ||
			(designedFor &&
				distanceUnit === "IMPERIAL" &&
				avg >= 10 &&
				(designedFor === "Long-Distance Running" || designedFor === "Race Running") &&
				rand === 0 &&
				(shoe.filters.gender === gender || isNil(gender)))
		)
			return {
				type: "nrc",
				subtype: "runDistancePerWeek",
				priority: priority,
				copy:
					distanceUnit === "METRIC"
						? {
								text: "recommendations.run_distance_per_week",
								data: { avg },
						  }
						: {
								text: "recommendations.run_distance_per_week_imperial",
								data: { avg },
						  },
			};
	}
	return { copy: null };
};

// Use nrc_month
const runTimesPerWeek = (aggregates, designedFor, distanceUnit, shoe, gender) => {
	const priority = RECOMMENDATION_PRIORITIES.runTimesPerWeek;
	let data = {};
	if (
		designedFor &&
		(designedFor === "Long-Distance Running" ||
			designedFor === "Race Running" ||
			designedFor === "Everyday Running")
	) {
		if (!isEmpty(aggregates)) {
			Object.entries(aggregates.batchAggregates).forEach((day) => {
				const week = dayjs(day[0]).week();
				const prevCount = data[week] || 0;
				const count = day[1].activity[0].count + prevCount;
				data = { ...data, [week]: count };
			});
		}
	}

	if (!isEmpty(data)) {
		const values = [];
		Object.keys(data).forEach((week) => values.push(data[week]));
		const sum = values.reduce((a, b) => a + b, 0);
		let avg = sum / values.length || 0;
		avg = Math.round(avg);
		const rand = getRandomInt(priority);
		if (
			designedFor &&
			avg >= 10 &&
			(designedFor === "Long-Distance Running" || designedFor === "Race Running") &&
			rand === 0 &&
			(shoe.filters.gender === gender || isNil(gender))
		)
			return {
				type: "nrc",
				subtype: "runTimesPerWeek",
				priority: priority,
				copy: {
					text: "recommendations.run_times_per_week",
					data: { avg },
				},
			};
		else if (
			designedFor &&
			avg >= 5 &&
			designedFor === "Everyday Running" &&
			rand === 0 &&
			(shoe.filters.gender === gender || isNil(gender))
		)
			return {
				type: "nrc",
				subtype: "runTimesPerWeek",
				priority: priority,
				copy: {
					text: "recommendations.run_times_per_week",
					data: { avg },
				},
			};
	}
	return { copy: null };
};

// nrc
const avgPace = (activities, category, shoe, designedFor, distanceUnit, gender) => {
	const priority = RECOMMENDATION_PRIORITIES.avgPace;
	let avg = 99;
	if (activities.run && activities.run.aggregates && activities.run.aggregates.length > 0)
		avg = Math.round(activities.run.aggregates.filter((act) => act.metric === "pace")[0].calculated_value);
	if (distanceUnit === "IMPERIAL") {
		avg = Math.round(minsPerKmToMinsPerMile(avg));
	}
	const rand = getRandomInt(priority);
	if (
		(distanceUnit === "IMPERIAL" &&
			avg >= 6 &&
			avg <= 10 &&
			designedFor === "Everyday Running" &&
			rand === 0 &&
			(shoe.filters.gender === gender || isNil(gender))) ||
		(distanceUnit === "METRIC" &&
			avg >= 4 &&
			avg <= 6 &&
			designedFor === "Everyday Running" &&
			rand === 0 &&
			(shoe.filters.gender === gender || isNil(gender)))
	)
		return {
			type: "nrc",
			subtype: "avgPace",
			priority: priority,
			copy: {
				text: "recommendations.avg_pace",
				data: { avg },
			},
		};
	else if (
		(designedFor &&
			distanceUnit === "IMPERIAL" &&
			avg < 6 &&
			(designedFor === "Long-Distance Running" || designedFor === "Race Running") &&
			rand === 0 &&
			(shoe.filters.gender === gender || isNil(gender))) ||
		(designedFor &&
			distanceUnit === "METRIC" &&
			avg < 4 &&
			(designedFor === "Long-Distance Running" || designedFor === "Race Running") &&
			rand === 0 &&
			(shoe.filters.gender === gender || isNil(gender)))
	)
		return {
			type: "nrc",
			subtype: "avgPace",
			priority: priority,
			copy: {
				text: "recommendations.avg_pace",
				data: { avg },
			},
		};

	return { copy: null };
};

/// nrc
const fastestPace = (activities, category, shoe, designedFor, distanceUnit, gender) => {
	const priority = RECOMMENDATION_PRIORITIES.fastestPace;
	let pace = 99;
	if (activities.run && !isEmpty(activities.run.activity) && activities.run.activity[0]?.maxes.length > 0)
		pace = activities.run.activity[0]?.maxes.filter((act) => act.metric === "pace")[0].value;
	if (distanceUnit === "IMPERIAL") {
		pace = minsPerKmToMinsPerMile(pace);
	}
	const rand = getRandomInt(priority);
	if (
		(designedFor &&
			distanceUnit === "IMPERIAL" &&
			pace <= 5 &&
			(designedFor === "Long-Distance Running" || designedFor === "Race Running") &&
			rand === 0 &&
			(shoe.filters.gender === gender || isNil(gender))) ||
		(designedFor &&
			distanceUnit === "METRIC" &&
			pace <= 3 &&
			(designedFor === "Long-Distance Running" || designedFor === "Race Running") &&
			rand === 0 &&
			(shoe.filters.gender === gender || isNil(gender)))
	)
		return {
			type: "nrc",
			subtype: "fastestPace",
			priority: priority,
			copy:
				distanceUnit === "METRIC"
					? {
							text: "recommendations.fastest_pace",
							data: { pace: minsToPace(pace) },
					  }
					: {
							text: "recommendations.fastest_pace_imperial",
							data: { pace: minsToPace(pace) },
					  },
		};

	return { copy: null };
};

// achievements
const consecutiveWeeks = (achievements, designedFor, category, shoe, gender) => {
	const priority = RECOMMENDATION_PRIORITIES.achievements;
	let count = [];
	if (achievements && achievements.running && !isEmpty(achievements.running.achievements)) {
		if (designedFor && designedFor === "Everyday Running") {
			count = achievements.running.achievements.map((badge) => {
				if (badge.name === "PR.STREAK.THREE_MONTHS") {
					return {
						type: "achievement",
						subtype: "consecutiveMonths",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.three_months_streak",
						},
					};
				} else if (badge.name === "PR.STREAK.SIX_MONTHS") {
					return {
						type: "achievement",
						subtype: "consecutiveMonths",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.six_months_streak",
						},
					};
				} else if (badge.name === "PR.FREQUENCY.THREE_ACTIVITIES_IN_WEEK_ACHIEVED") {
					return {
						type: "achievement",
						subtype: "consecutiveDays",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.three_times_week",
						},
					};
				} else if (badge.name === "PR.STREAK.THREE_WEEKS") {
					return {
						type: "achievement",
						subtype: "consecutiveWeeks",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.three_week_streak",
						},
					};
				} else if (badge.name === "PR.STREAK.FOUR_WEEKS") {
					return {
						type: "achievement",
						subtype: "consecutiveWeeks",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.four_week_streak",
						},
					};
				} else if (badge.name === "RUNNING.BRAND.JDIS_5K") {
					return {
						type: "achievement",
						subtype: "jdis5K",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.five_k",
						},
					};
				} else if (badge.name === "RUNNING.BRAND.JDIS_10K") {
					return {
						type: "achievement",
						subtype: "jdis10K",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.ten_k",
						},
					};
				}
			});
			count = count.filter((item) => !isNil(item));
			const rand = 0;
			if (count.length > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender))) return count;
		} else if (designedFor && designedFor === "Race Running") {
			count = achievements.running.achievements.map((badge) => {
				if (badge.name === "PR.STREAK.SIX_MONTHS") {
					return {
						type: "achievement",
						subtype: "consecutiveMonths",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.six_months_streak",
						},
					};
				} else if (badge.name === "PR.STREAK.NINE_MONTHS") {
					return {
						type: "achievement",
						subtype: "consecutiveMonths",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.nine_months_streak",
						},
					};
				} else if (badge.name === "PR.STREAK.TWELVE_MONTHS") {
					return {
						type: "achievement",
						subtype: "consecutiveMonths",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.twelve_months_streak",
						},
					};
				} else if (badge.name === "PR.FREQUENCY.THREE_ACTIVITIES_IN_WEEK_ACHIEVED") {
					return {
						type: "achievement",
						subtype: "consecutiveDays",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.three_times_week",
						},
					};
				} else if (badge.name === "PR.FREQUENCY.FIVE_ACTIVITIES_IN_WEEK_ACHIEVED") {
					return {
						type: "achievement",
						subtype: "consecutiveDays",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.five_times_week",
						},
					};
				} else if (badge.name === "PR.FREQUENCY.SEVEN_ACTIVITIES_IN_WEEK_ACHIEVED") {
					return {
						type: "achievement",
						subtype: "consecutiveDays",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.everyday_week",
						},
					};
				} else if (badge.name === "PR.STREAK.THREE_WEEKS") {
					return {
						type: "achievement",
						subtype: "consecutiveWeeks",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.three_week_streak",
						},
					};
				} else if (badge.name === "PR.STREAK.FOUR_WEEKS") {
					return {
						type: "achievement",
						subtype: "consecutiveWeeks",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.four_week_streak",
						},
					};
				} else if (badge.name === "PR.STREAK.FIVE_WEEKS") {
					return {
						type: "achievement",
						subtype: "consecutiveWeeks",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.five_week_streak",
						},
					};
				} else if (badge.name === "PR.STREAK.SIX_WEEKS") {
					return {
						type: "achievement",
						subtype: "consecutiveWeeks",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.six_week_streak",
						},
					};
				} else if (badge.name === "RUNNING.BRAND.JDIS_10K") {
					return {
						type: "achievement",
						subtype: "jdis10K",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.ten_k",
						},
					};
				}
			});
			count = count.filter((item) => !isNil(item));
			const rand = 0;
			if (count.length > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender))) return count;
		} else if (designedFor && designedFor === "Long-Distance Running") {
			count = achievements.running.achievements.map((badge) => {
				if (badge.name === "PR.STREAK.NINE_MONTHS") {
					return {
						type: "achievement",
						subtype: "consecutiveMonths",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.nine_months_streak",
						},
					};
				} else if (badge.name === "PR.STREAK.TWELVE_MONTHS") {
					return {
						type: "achievement",
						subtype: "consecutiveMonths",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.twelve_months_streak",
						},
					};
				} else if (badge.name === "PR.FREQUENCY.FIVE_ACTIVITIES_IN_WEEK_ACHIEVED") {
					return {
						type: "achievement",
						subtype: "consecutiveDays",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.five_times_week",
						},
					};
				} else if (badge.name === "PR.FREQUENCY.SEVEN_ACTIVITIES_IN_WEEK_ACHIEVED") {
					return {
						type: "achievement",
						subtype: "consecutiveDays",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.everyday_week",
						},
					};
				} else if (badge.name === "PR.STREAK.FIVE_WEEKS") {
					return {
						type: "achievement",
						subtype: "consecutiveWeeks",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.five_week_streak",
						},
					};
				} else if (badge.name === "PR.STREAK.SIX_WEEKS") {
					return {
						type: "achievement",
						subtype: "consecutiveWeeks",
						icon: badge.name,
						priority: priority,
						copy: {
							text: "recommendations.six_week_streak",
						},
					};
				}
			});
			count = count.filter((item) => !isNil(item));
			const rand = 0;
			if (count.length > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender))) return count;
		}
	}

	return [{ copy: null }];
};

// achievements
const trainingActivity = (achievements, designedFor, category, shoe, gender) => {
	const priority = RECOMMENDATION_PRIORITIES.achievements;
	let count = [];
	if (
		category &&
		category === "Training & Gym" &&
		achievements &&
		achievements.training &&
		!isEmpty(achievements.training.achievements) &&
		!isNil(achievements.training.achievements)
	) {
		count = achievements.training.achievements.map((badge) => {
			if (badge.name === "NTC.STREAK.3_WEEKS") {
				return {
					type: "training",
					subtype: "trainingActivity",
					icon: "NTC",
					priority: priority,
					copy: {
						text: "recommendations.train_three_times",
					},
				};
			} else if (badge.name === "NTC.MILESTONE.5_WORKOUT") {
				return {
					type: "training",
					subtype: "trainingActivity",
					icon: "NTC",
					priority: priority,
					copy: {
						text: "recommendations.five_workouts",
					},
				};
			}
		});
		count = count.filter((item) => !isNil(item));
		const rand = 0;
		if (count.length > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender))) return count;
	}
	return [{ copy: null }];
};

// purchases
const boughtFranchise = (purchases, category, shoe, kingdom, designedFor, filterIDs, language, styles, gender) => {
	const tempShoe = shoe;
	const tempKingdom = kingdom;
	const priority = RECOMMENDATION_PRIORITIES.boughtFranchise;
	let count = 0;
	if (!isEmpty(purchases)) {
		if (designedFor && (designedFor === "Long-Distance Running" || designedFor === "Race Running")) {
			purchases.forEach((order) => {
				const bool = dayjs(order.orderDate).isBetween(dayjs(), dayjs().subtract(1, "year"));
				order.items.forEach((item) => {
					if (!item.isReturned) {
						if (/VAPORFLY/.test(item.itemDescription.toUpperCase()) && bool) count += 1;
					}
				});
			});
			const rand = getRandomInt(priority);
			if (count > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender)))
				return {
					type: "purchase",
					subtype: "boughtFranchise",
					priority: priority,
					copy: {
						text: "recommendations.bought_franchise_last_year",
						data: { franchise: "Vaporfly" },
					},
				};
		} else if (designedFor && designedFor === "Everyday Running") {
			purchases.forEach((order) => {
				const bool = dayjs(order.orderDate).isBetween(dayjs(), dayjs().subtract(9, "month"));
				order.items.forEach((item) => {
					if (!item.isReturned) {
						if (/REACT INFINITY/.test(item.itemDescription.toUpperCase()) && bool) count += 1;
					}
				});
			});
			const rand = getRandomInt(priority);
			if (count > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender)))
				return {
					type: "purchase",
					subtype: "boughtFranchise",
					priority: priority,
					copy: {
						text: "recommendations.bought_franchise_recently",
						data: { franchise: "React Infinity" },
					},
				};
		} else if (designedFor && designedFor === "Everyday Running") {
			purchases.forEach((order) => {
				const bool = dayjs(order.orderDate).isBetween(dayjs(), dayjs().subtract(9, "month"));
				order.items.forEach((item) => {
					if (!item.isReturned) {
						if (/PEGASUS/.test(item.itemDescription.toUpperCase()) && bool) count += 1;
					}
				});
			});
			const rand = getRandomInt(priority);
			if (count > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender)))
				return {
					type: "purchase",
					subtype: "boughtFranchise",
					priority: priority,
					copy: {
						text: "recommendations.bought_franchise_recently",
						data: { franchise: "Pegasus" },
					},
				};
		} else if (designedFor && designedFor === "Trail Running") {
			purchases.forEach((order) => {
				const bool = dayjs(order.orderDate).isBetween(dayjs(), dayjs().subtract(9, "month"));
				order.items.forEach((item) => {
					if (!item.isReturned) {
						if (/KIGER/.test(item.itemDescription.toUpperCase()) && bool) count += 1;
					}
				});
			});
			const rand = getRandomInt(priority);
			if (count > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender)))
				return {
					type: "purchase",
					subtype: "boughtFranchise",
					priority: priority,
					copy: {
						text: "recommendations.bought_franchise_recently",
						data: { franchise: "Kiger" },
					},
				};
		} else if (category && category === "Skate") {
			purchases.forEach((order) => {
				const bool = dayjs(order.orderDate).isBetween(dayjs(), dayjs().subtract(9, "month"));
				order.items.forEach((item) => {
					if (!item.isReturned) {
						if (/NYJAH/.test(item.itemDescription.toUpperCase()) && bool) count += 1;
					}
				});
			});
			const rand = getRandomInt(priority);
			if (count > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender)))
				return {
					type: "purchase",
					subtype: "boughtFranchise",
					priority: priority,
					copy: {
						text: "recommendations.bought_franchise_recently",
						data: { franchise: "Nyjah" },
					},
				};
		} else if (/AIR FORCE 1/.test(shoe.name.toUpperCase()) || /AF1/.test(shoe.name.toUpperCase())) {
			purchases.forEach((order) => {
				const bool = dayjs(order.orderDate).isBetween(dayjs(), dayjs().subtract(9, "month"));
				order.items.forEach((item) => {
					if (!item.isReturned) {
						if (
							(/AIR FORCE 1/.test(item.itemDescription.toUpperCase()) ||
								/AF1/.test(item.itemDescription.toUpperCase())) &&
							bool
						)
							count += 1;
					}
				});
			});
			const rand = getRandomInt(priority);
			if (count > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender)))
				return {
					type: "purchase",
					subtype: "boughtFranchise",
					priority: priority,
					copy: {
						text: "recommendations.bought_franchise_recently",
						data: { franchise: "Air Force 1" },
					},
				};
		} else if (category && category === "Basketball") {
			purchases.forEach((order) => {
				const bool = dayjs(order.orderDate).isBetween(dayjs(), dayjs().subtract(9, "month"));
				order.items.forEach((item) => {
					if (!item.isReturned) {
						if (/LEBRON/.test(item.itemDescription.toUpperCase()) && bool) count += 1;
					}
				});
			});
			const rand = getRandomInt(priority);
			if (count > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender)))
				return {
					type: "purchase",
					subtype: "boughtFranchise",
					priority: priority,
					copy: {
						text: "recommendations.bought_franchise_recently",
						data: { franchise: "LeBron" },
					},
				};
		} else if (/AIR MAX/.test(shoe.name.toUpperCase())) {
			purchases.forEach((order) => {
				const bool = dayjs(order.orderDate).isBetween(dayjs(), dayjs().subtract(9, "month"));
				order.items.forEach((item) => {
					if (!item.isReturned) {
						if (/AIR MAX/.test(item.itemDescription.toUpperCase()) && bool) count += 1;
					}
				});
			});
			const rand = getRandomInt(priority + 2);
			if (count > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender)))
				return {
					type: "purchase",
					subtype: "boughtFranchise",
					priority: priority,
					copy: {
						text: "recommendations.bought_franchise_recently",
						data: { franchise: "Air Max" },
					},
				};
		} else if (category && category === "Jordan") {
			purchases.forEach((order) => {
				const bool = dayjs(order.orderDate).isBetween(dayjs(), dayjs().subtract(9, "month"));
				order.items.forEach((item) => {
					if (!item.isReturned) {
						if (/JORDAN/.test(item.itemDescription.toUpperCase()) && bool) count += 1;
					}
				});
			});
			const rand = getRandomInt(priority + 1);
			if (count > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender)))
				return {
					type: "purchase",
					subtype: "boughtFranchise",
					priority: priority,
					copy: {
						text: "recommendations.bought_franchise_recently",
						data: { franchise: "Jordan" },
					},
				};
		} else if (category && category === "Training") {
			purchases.forEach((order) => {
				const bool = dayjs(order.orderDate).isBetween(dayjs(), dayjs().subtract(1, "year"));
				order.items.forEach((item) => {
					if (!item.isReturned) {
						if (/METCON/.test(item.itemDescription.toUpperCase()) && bool) count += 1;
					}
				});
			});
			const rand = getRandomInt(priority);
			if (count > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender)))
				return {
					type: "purchase",
					subtype: "boughtFranchise",
					priority: priority,
					copy: {
						text: "recommendations.bought_franchise_last_year",
						data: { franchise: "Metcon" },
					},
				};
		}
	}

	return { copy: null };
};

// purchases
const boughtKingdom = (purchases, category, shoe, kingdom, designedFor, filterIDs, language, styles, gender) => {
	const priority = RECOMMENDATION_PRIORITIES.boughtKingdom;
	const matches = [];
	let match = null;

	if (!isEmpty(purchases)) {
		if (kingdom && kingdom === "Lifestyle") {
			const kingdomID = filterIDs.lifestyleID;
			purchases.forEach((order) => {
				order.items.forEach((item) => {
					if (!isEmpty(item.styleCode)) {
						const found = styles.filter((shoe) => shoe.style_code === item.styleCode)[0];
						if (!isNil(found)) match = found.taxonomy.includes(kingdomID);
					}
				});

				if (match) matches.push(kingdomID);
			});
			if (!isEmpty(matches)) {
				const newKingdom = filterIDs.lifestyleVal;
				const rand = getRandomInt(priority);
				if (matches.length > 2 && rand === 0 && (shoe.filters.gender === gender || isNil(gender)))
					return {
						type: "purchase",
						subtype: "boughtKingdom",
						priority: priority,
						copy: {
							text: "recommendations.bought_category_frequently",
							data: { category: newKingdom },
						},
					};
			}
		}
	}

	return { copy: null };
};

// purchases
const boughtCategory = (purchases, category, shoe, kingdom, designedFor, filterIDs, language, styles, gender) => {
	const priority = RECOMMENDATION_PRIORITIES.boughtCategory;
	let count = 0;
	let orderCategory = "";
	const langCategories = Object.values(filterIDs.category.find((el) => el[language])[language]);
	const enCategories = Object.values(filterIDs.category.find((el) => el.en).en);
	let match = null;

	if (!isEmpty(purchases)) {
		if (category && (category === "Hiking" || category === "Outdoor")) {
			const categoryID = enCategories.find((el) => el.val === category).id;
			purchases.forEach((order) => {
				order.items.forEach((item) => {
					if (!isEmpty(item.styleCode)) {
						const found = styles.filter((shoe) => shoe.style_code === item.styleCode)[0];
						if (!isNil(found)) match = found.taxonomy.includes(categoryID);
					}
				});

				if (match) count += 1;
			});
			if (match) {
				const newCategory = langCategories.find((el) => el.id === orderCategory).val;
				const rand = getRandomInt(priority);
				if (count > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender)))
					return {
						type: "purchase",
						subtype: "boughtCategory",
						priority: priority,
						copy: {
							text: "recommendations.bought_category",
							data: { category: newCategory },
						},
					};
			}
		}
	}

	return { copy: null };
};

// purchases
const boughtSameColor = (purchases, category, shoe, kingdom, designedFor, filterIDs, language, styles, gender) => {
	const tempCategory = category;
	const priority = RECOMMENDATION_PRIORITIES.boughtSameColor;
	let colors = [];

	if (!isEmpty(purchases)) {
		purchases.forEach((order) => {
			order.items.forEach((item) => {
				if (!isEmpty(item.colorCode)) {
					const orderColor = item.colorCode;
					const shoeColor = shoe.meta.color_code.match(/-(.*)/)[1];
					if (shoeColor === orderColor) {
						const color = COLOR_CODES.find((el) => el.code === shoeColor);
						if (!isNil(color)) colors.push(color.name);
					}
				}
			});
		});
		const rand = getRandomInt(priority);
		if (colors.length >= 2 && rand === 0 && (shoe.filters.gender === gender || isNil(gender)))
			return {
				type: "purchase",
				subtype: "boughtSameColor",
				priority: priority,
				copy: {
					text: "recommendations.bought_same_color_frequently",
					data: { color: colors[0] },
				},
			};
		else if (colors.length > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender)))
			return {
				type: "purchase",
				subtype: "boughtSameColor",
				priority: priority + 1,
				copy: {
					text: "recommendations.bought_same_color",
					data: { color: colors[0] },
				},
			};
	}

	return { copy: null };
};

// purchases
const boughtSameShoe = (purchases, category, shoe, kingdom, designedFor, filterIDs, language, styles, gender) => {
	const tempCategory = category;
	const priority = RECOMMENDATION_PRIORITIES.boughtSameShoe;
	let data = [];

	if (!isEmpty(purchases)) {
		purchases.forEach((order) => {
			order.items.forEach((item) => {
				if (!isEmpty(item.styleCode) && !isEmpty(item.colorCode)) {
					const orderSCC = item.styleCode + "-" + item.colorCode;
					const shoeSCC = shoe.meta.color_code;
					if (shoeSCC === orderSCC) {
						data.push(orderSCC);
					}
				}
			});
		});
		const rand = getRandomInt(priority);
		if (data.length > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender)))
			return {
				type: "purchase",
				subtype: "boughtSameShoe",
				priority: priority,
				copy: {
					text: "recommendations.bought_same_shoe",
				},
			};
	}

	return { copy: null };
};

// purchases
const boughtSameShoeDiffColor = (
	purchases,
	category,
	shoe,
	kingdom,
	designedFor,
	filterIDs,
	language,
	styles,
	gender
) => {
	const tempCategory = category;
	const priority = RECOMMENDATION_PRIORITIES.boughtSameShoeDiffColor;
	let data = [];

	if (!isEmpty(purchases)) {
		purchases.forEach((order) => {
			order.items.forEach((item) => {
				if (!isEmpty(item.styleCode)) {
					const orderStyle = item.styleCode;
					const shoeStyle = shoe.meta.color_code.match(/(.*)-/)[1];
					if (shoeStyle === orderStyle) {
						data.push(orderStyle);
					}
				}
			});
		});
		const rand = getRandomInt(priority);
		if (data.length > 0 && rand === 0 && (shoe.filters.gender === gender || isNil(gender)))
			return {
				type: "purchase",
				subtype: "boughtSameShoeDiffColor",
				priority: priority,
				copy: {
					text: "recommendations.bought_same_shoe_diff_color",
				},
			};
	}

	return { copy: null };
};

// fallbacks
const profileAge = (achievements, shoe) => {
	const priority = RECOMMENDATION_PRIORITIES.fallbacks;
	let count = [];
	if (achievements && achievements.running && !isEmpty(achievements.running.achievements)) {
		const anniversay = achievements.running.achievements.filter((badge) => /ANNIVERSARY/.test(badge.name));
		if (!isEmpty(anniversay)) {
			if (
				/VAPORFLY/.test(shoe.name) ||
				/NEXT/.test(shoe.name) ||
				/DUNK/.test(shoe.name) ||
				/BLAZER/.test(shoe.name)
			) {
				return {
					type: "achievement",
					subtype: "profileAge",
					icon: "SWOOSH",
					priority: priority,
					copy: {
						text: "recommendations.longterm_member",
					},
				};
			}
		} else {
			if (
				/AF1/.test(shoe.name) ||
				/AIR JORDAN 1/.test(shoe.name) ||
				/BLAZER/.test(shoe.name) ||
				/REACT/.test(shoe.name) ||
				/PEGASUS/.test(shoe.name)
			) {
				return {
					type: "achievement",
					subtype: "profileAge",
					icon: "SWOOSH",
					priority: priority,
					copy: {
						text: "recommendations.new_to_nike",
					},
				};
			}
		}
	} else {
		if (
			/AF1/.test(shoe.name) ||
			/AIR JORDAN 1/.test(shoe.name) ||
			/BLAZER/.test(shoe.name) ||
			/REACT/.test(shoe.name) ||
			/PEGASUS/.test(shoe.name)
		) {
			return {
				type: "achievement",
				subtype: "profileAge",
				icon: "SWOOSH",
				priority: priority,
				copy: {
					text: "recommendations.new_to_nike",
				},
			};
		}
	}
	return [{ copy: null }];
};

const RECOMMENDATIONS = {
	ntc: [loggedActivity, preferTraining],
	nrc: [avgPace, fastestPace],
	purchases: [
		boughtFranchise,
		boughtKingdom,
		boughtCategory,
		boughtSameColor,
		boughtSameShoe,
		boughtSameShoeDiffColor,
	],
	achievements: [consecutiveWeeks, trainingActivity],
	month: [runDistancePerWeek, runTimesPerWeek],
	interests: [{}],
	sneakers: [{}],
	regional: [{}],
	fallbacks: [profileAge],
};

export default RECOMMENDATIONS;
