import { isEmpty, isNil, prop, sortWith, ascend } from "ramda";

import { sentryLogError, sentryLogException } from "@/utils/sentry";

import RECOMMENDATIONS from "@/constants/recommendations";

export const setupFallbackRecommendations = (shoes, identity) => {
	if (!isEmpty(shoes) && !isEmpty(identity)) {
		// for every shoe, go through the possible recommendations for that shoe's category, when we have a match, add it to the shoe's recommendations field
		for (const shoe of shoes) {
			shoe.recommendations = [];

			if (!isEmpty(identity.achievements)) {
				RECOMMENDATIONS.fallbacks.forEach((recommendation) => {
					const temp = recommendation(identity.achievements, shoe);
					if (!isNil(temp.copy)) shoe.recommendations.push(temp);
				});
			} else {
				RECOMMENDATIONS.fallbacks.forEach((recommendation) => {
					const temp = recommendation(null, shoe);
					if (!isNil(temp.copy)) shoe.recommendations.push(temp);
				});
			}

			shoe.recommendations = sortWith([ascend(prop("priority"))], shoe.recommendations);
		}
	}

	return shoes;
};

export const setupRecommendations = (shoes, identity, filterIDs, language, styles) => {
	if (!isEmpty(shoes) && !isEmpty(identity)) {
		// for every shoe, go through the possible recommendations for that shoe's category, when we have a match, add it to the shoe's recommendations field
		for (const shoe of shoes) {
			const workoutPrivacyAccepted =
				identity.profile && identity.profile.healthdata && identity.profile.healthdata.enhancedAcceptance;

			let category = null;
			let designedFor = null;
			let kingdom = null;
			let userGenderID = null;
			const genderTranslation = { F: "W", M: "M" };

			try {
				const enGenders = filterIDs.gender.find(({ en }) => en).en;
				const shoeGenders = Object.values(enGenders).filter((value) => shoe.filters.includes(value.id));
				shoe.filters.gender = shoeGenders[0]?.id;
				// Search through the list of categoryIDs and find a match in the shoe filters
				const enCategories = filterIDs.category.find(({ en }) => en).en;
				const shoeCategories = Object.values(enCategories).filter((value) => shoe.filters.includes(value.id));
				const filteredCategories = shoeCategories.filter(
					(el) => el.val !== "Dance" && el.val !== "Lifestyle" && el.val !== "Athletics"
				);
				category = filteredCategories[0]?.val;

				const racingShoe = shoe.filters.includes(filterIDs.athleticsID);
				const everydayShoe = shoe.filters.includes(filterIDs.everydayID);
				if (racingShoe && !everydayShoe) designedFor = "Race Running";
				else if (everydayShoe && !racingShoe) designedFor = "Everyday Running";

				const lifestyleShoe = shoe.filters.includes(filterIDs.lifestyleID);
				if (lifestyleShoe) kingdom = "Lifestyle";
				if (identity.profile.gender === "M" || identity.profile.gender === "F") {
					userGenderID = Object.values(enGenders).find(
						(el) => el.val.charAt(0) === genderTranslation[identity.profile.gender]
					).id;
				}
			} catch (e) {
				// console.log(e);
				sentryLogException("Shoe Recommendations", e);
				sentryLogError(
					"Membership",
					[
						{
							name: "SCC",
							value: shoe.id,
						},
						{
							name: "Gender",
							value: identity.profile.gender,
						},
					],
					`Shoe recommendations failed to load`
				);
			}

			shoe.recommendations = [];

			if (!isNil(identity.aggregates) && workoutPrivacyAccepted) {
				RECOMMENDATIONS.ntc.forEach((recommendation) => {
					const temp = recommendation(identity.aggregates, category, shoe, filterIDs, language, userGenderID);
					if (!isNil(temp.copy)) shoe.recommendations.push(temp);
				});
				RECOMMENDATIONS.nrc.forEach((recommendation) => {
					const temp = recommendation(
						identity.aggregates,
						category,
						shoe,
						designedFor,
						identity.profile.preferences.distanceUnit || "METRIC",
						userGenderID
					);
					if (!isNil(temp.copy)) shoe.recommendations.push(temp);
				});
			}
			if (!isNil(identity.achievements)) {
				RECOMMENDATIONS.achievements.forEach((recommendation) => {
					const temp = recommendation(identity.achievements, designedFor, category, shoe, userGenderID);
					if (temp.length > 0) {
						temp.forEach((rec) => {
							if (!isNil(rec.copy)) shoe.recommendations.push(rec);
						});
					}
				});
			}
			if (!isNil(identity.nrc_month) && workoutPrivacyAccepted) {
				RECOMMENDATIONS.month.forEach((recommendation) => {
					const temp = recommendation(
						identity.nrc_month,
						designedFor,
						identity.profile.preferences.distanceUnit || "METRIC",
						shoe,
						userGenderID
					);
					if (!isNil(temp.copy)) shoe.recommendations.push(temp);
				});
			}
			if (!isEmpty(identity.orders)) {
				RECOMMENDATIONS.purchases.forEach((recommendation) => {
					const temp = recommendation(
						identity.orders,
						category,
						shoe,
						kingdom,
						designedFor,
						filterIDs,
						language,
						styles,
						userGenderID
					);
					if (!isNil(temp.copy)) shoe.recommendations.push(temp);
				});
			}
			shoe.recommendations = sortWith([ascend(prop("priority"))], shoe.recommendations);
			const tempRecs = shoe.recommendations.filter((rec) => rec.type === "nrc" || rec.type === "achievement");
			if (tempRecs.length > 0) {
				shoe.recommendations = shoe.recommendations.filter((rec) => rec.type !== "loggedRun");
			}
		}
	}
	return shoes;
};

export const setupSingleRecommendations = (shoe, identity, filterIDs, language, recommendations, styles) => {
	const newShoe = shoe;
	if (isNil(recommendations)) {
		if (!isEmpty(newShoe) && !isEmpty(identity)) {
			// for every shoe, go through the possible recommendations for that shoe's category, when we have a match, add it to the shoe's recommendations field
			const workoutPrivacyAccepted =
				identity.profile && identity.profile.healthdata && identity.profile.healthdata.enhancedAcceptance;

			let category = null;
			let designedFor = null;
			let kingdom = null;
			let userGenderID = null;
			const genderTranslation = { F: "W", M: "M" };

			try {
				const enGenders = filterIDs.gender.find(({ en }) => en).en;
				const shoeGenders = Object.values(enGenders).filter((value) => newShoe.filters.includes(value.id));
				newShoe.filters.gender = shoeGenders[0]?.id;
				// Search through the list of categoryIDs and find a match in the shoe filters
				const enCategories = filterIDs.category.find(({ en }) => en).en;
				const shoeCategories = Object.values(enCategories).filter((value) =>
					newShoe.filters.includes(value.id)
				);
				const filteredCategories = shoeCategories.filter(
					(el) => el.val !== "Dance" && el.val !== "Lifestyle" && el.val !== "Athletics"
				);
				category = filteredCategories[0]?.val;

				const racingShoe = newShoe.filters.includes(filterIDs.athleticsID);
				const everydayShoe = newShoe.filters.includes(filterIDs.everydayID);
				if (racingShoe && !everydayShoe) designedFor = "Race Running";
				else if (everydayShoe && !racingShoe) designedFor = "Everyday Running";

				const lifestyleShoe = newShoe.filters.includes(filterIDs.lifestyleID);
				if (lifestyleShoe) kingdom = "Lifestyle";
				if (identity.profile.gender === "M" || identity.profile.gender === "F") {
					userGenderID = Object.values(enGenders).find(
						(el) => el.val.charAt(0) === genderTranslation[identity.profile.gender]
					).id;
				}
			} catch (e) {
				// console.log(e);
				sentryLogException("Shoe Recommendations", e);
				sentryLogError(
					"Membership",
					[
						{
							name: "SCC",
							value: shoe.id,
						},
						{
							name: "Gender",
							value: identity.profile.gender,
						},
					],
					`Shoe recommendations failed to load`
				);
			}

			newShoe.recommendations = [];

			if (!isNil(identity.aggregates) && workoutPrivacyAccepted) {
				RECOMMENDATIONS.ntc.forEach((recommendation) => {
					const temp = recommendation(
						identity.aggregates,
						category,
						newShoe,
						filterIDs,
						language,
						userGenderID
					);
					if (!isNil(temp.copy)) newShoe.recommendations.push(temp);
				});
				RECOMMENDATIONS.nrc.forEach((recommendation) => {
					const temp = recommendation(identity.aggregates, category, newShoe, designedFor, userGenderID);
					if (!isNil(temp.copy)) newShoe.recommendations.push(temp);
				});
			}
			if (!isNil(identity.achievements)) {
				RECOMMENDATIONS.achievements.forEach((recommendation) => {
					const temp = recommendation(identity.achievements, designedFor, category, newShoe, userGenderID);
					if (temp.length > 0) {
						temp.forEach((rec) => {
							if (!isNil(rec.copy)) shoe.recommendations.push(rec);
						});
					}
				});
			}
			if (!isNil(identity.nrc_month) && workoutPrivacyAccepted) {
				RECOMMENDATIONS.month.forEach((recommendation) => {
					const temp = recommendation(
						identity.nrc_month,
						designedFor,
						identity.profile.preferences.distanceUnit || "METRIC",
						newShoe,
						userGenderID
					);
					if (!isNil(temp.copy)) newShoe.recommendations.push(temp);
				});
			}
			if (!isEmpty(identity.orders)) {
				RECOMMENDATIONS.purchases.forEach((recommendation) => {
					const temp = recommendation(
						identity.orders,
						category,
						newShoe,
						kingdom,
						designedFor,
						filterIDs,
						language,
						styles,
						userGenderID
					);
					if (!isNil(temp.copy)) newShoe.recommendations.push(temp);
				});
			}
			newShoe.recommendations = sortWith([ascend(prop("priority"))], newShoe.recommendations);
			const tempRecs = newShoe.recommendations.filter((rec) => rec.type === "nrc" || rec.type === "achievement");
			if (tempRecs.length > 0) {
				newShoe.recommendations = newShoe.recommendations.filter((rec) => rec.type !== "loggedRun");
			}
		}
	} else {
		newShoe.recommendations = recommendations;
	}

	return newShoe;
};
