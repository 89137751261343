import { pathOr, flatten, replace, find, propEq, isNil, isEmpty, paths, filter } from "ramda";
import i18n from "@/i18n";

import { shuffleArray } from "@/utils/math";
import { sortByPrioritizedTaxonomyIDs } from "@/utils/taxonomy";

const findInProductGroup = (id, data) => find(propEq("id", id))(pathOr([], ["product_groupings"], data));

const getTransparentImage = (url) => replace(/b_rgb:[A-Za-z0-9]{6}/, "b_transparent", url);

const getImageSize = (url, width = 900, cdnUrl) => {
	let string = url;
	if (!isNil(cdnUrl)) string = replace("https://res.cloudinary.com/", cdnUrl, string);
	string = replace(/\/upload\//, `/upload/w_${width}/q_auto:good/`, string);
	return string;
};

const getUGCCrop = (url, cdnUrl) => {
	let string = url;
	if (!isNil(cdnUrl)) string = replace("https://res.cloudinary.com/", cdnUrl, string);
	string = replace(/\/upload\//, `/upload/q_auto,c_fill,w_950,h_670,g_south/`, string);
	return string;
};

const getDetailVideo = (url, cdnUrl) => {
	let string = url;
	if (!isNil(cdnUrl)) string = replace("https://res.cloudinary.com/", cdnUrl, string);
	string = replace(/\/upload\//, `/upload/c_crop,h_640,w_856,g_south/q_auto:good/`, string);
	return string;
};

const getVerticalCompareVideo = (url, cdnUrl) => {
	let string = url;
	if (!isNil(cdnUrl)) string = replace("https://res.cloudinary.com/", cdnUrl, string);
	string = replace(/\/upload\//, `/upload/c_crop,h_752,w_640,g_south/q_auto:good/`, string);
	return string;
};

const getTrimmedImage = (url) => replace(/\/upload\//, `/upload/e_trim/`, url);

const getJpeg = (url) => replace(/.png/, ".jpg", url);

const createImageObject = (url, width = 900, cdnUrl) => {
	const square = url || "";
	const portrait = url || "";
	const fallback = url || "";

	return {
		square: !isNil(square) ? getImageSize(getTransparentImage(square), width, cdnUrl) : null,
		portrait: !isNil(portrait) ? getImageSize(getTransparentImage(portrait), width, cdnUrl) : null,
		fallback: !isNil(fallback) ? getImageSize(getTransparentImage(fallback), width, cdnUrl) : null,
	};
};

const formatBenefitStatement = (data) => {
	let langCopy = data.style_translatables?.filter((el) => el.lang === i18n.language);
	if (isEmpty(langCopy)) langCopy = data.style_translatables?.filter((el) => el.lang === "en");

	const reasonToBuy = find(propEq("source_path", "consumer_product.copy.reasonToBuy"), langCopy)?.val;
	if (!isNil(reasonToBuy)) return reasonToBuy;

	const productDescription = find(propEq("source_path", "consumer_product.copy.productDescription"), langCopy)?.val;
	const firstSentence = productDescription?.split(/(?<=[.!?])/g)[0];
	if (firstSentence?.length < 100) return firstSentence;

	const benefitStatement = find(
		propEq("source_path", "inside_track_cms_product.style_translatables.benefit_statement"),
		langCopy
	)?.val;

	return benefitStatement || "";
};

const formatContent = (data) => {
	return {
		name: data.product_name,
		tagline: formatBenefitStatement(data),
	};
};

const formatDesignedFor = (
	data,
	categoryID,
	surfaceID,
	runningID,
	danceID,
	lifestyleVal,
	prioritizedTaxonomyIDs = []
) => {
	let categoryArray = filter((tax) => tax.lang === i18n.language, data.taxonomy);
	if (isEmpty(categoryArray)) categoryArray = filter((tax) => tax.lang === "en", data.taxonomy);
	categoryArray = categoryArray.filter((el) => el.parent_id === categoryID);
	// Prioritize Running as category if it is in the shoe taxonomy
	const category =
		sortByPrioritizedTaxonomyIDs(categoryArray, prioritizedTaxonomyIDs)[0] ||
		filter((tax) => tax.lang === i18n.language, data.taxonomy).find((el) => el.parent_id === categoryID);
	let surface = filter((tax) => tax.lang === i18n.language, data.taxonomy);
	if (isEmpty(surface)) surface = filter((tax) => tax.lang === "en", data.taxonomy);
	surface = surface.find((el) => el.parent_id === surfaceID);
	if (category?.id === runningID) return `${surface ? surface.val : ""} ${category ? category.val : ""}`;
	if (category?.id === danceID) return lifestyleVal;
	return category.val;
};

const formatGridMeta = (data) => ({
	designed_for: pathOr(null, ["designed_for"], data),
	color_code: pathOr(null, ["style_color_code"], data),
	kingdom: pathOr(null, ["kingdom"], data),
	category: pathOr(null, ["category"], data),
});

const formatShoeMeta = (data, categoryID, surfaceID, runningID, danceID, lifestyleVal, prioritizedTaxonomyIDs = []) => {
	let categoryArray = filter((tax) => tax.lang === i18n.language, data.taxonomy);
	if (isEmpty(categoryArray)) categoryArray = filter((tax) => tax.lang === "en", data.taxonomy);
	categoryArray = categoryArray.filter((el) => el.parent_id === categoryID);
	const category = sortByPrioritizedTaxonomyIDs(categoryArray, prioritizedTaxonomyIDs)[0];
	return {
		designed_for: formatDesignedFor(
			data,
			categoryID,
			surfaceID,
			runningID,
			danceID,
			lifestyleVal,
			prioritizedTaxonomyIDs
		),
		product_type: pathOr(null, ["product_type"], data),
		color_code: pathOr(null, ["style_color_code"], data),
		kingdom: paths(
			[
				["kingdom", i18n.language],
				["kingdom", "en"],
			],
			data
		).find((el) => !isNil(el)),
		category: category?.id,
	};
};

const formatMedia = (data, imagemap, cdnUrl) => {
	let left = "";
	let zoomToe = null;
	// First priority is Aurora, if it is complete
	const auroraImages = pathOr([], ["media", "auroraCacheCarousel", "images"], data);
	if (!isEmpty(auroraImages)) {
		const imgLeft =
			auroraImages.filter((img) => new RegExp(`${imagemap.carouselDetail[1][0]}`, "g").test(img.image_url))[0] ||
			auroraImages.filter((img) => new RegExp(`${imagemap.carouselDetail[1][1]}`, "g").test(img.image_url))[0];
		left = imgLeft && imgLeft.image_url;
		const imgToe =
			auroraImages.filter((img) => new RegExp(`${imagemap.productGrouping[0][0]}`, "g").test(img.image_url))[0] ||
			auroraImages.filter((img) => new RegExp(`${imagemap.productGrouping[0][1]}`, "g").test(img.image_url))[0] ||
			auroraImages.filter((img) => new RegExp(`${imagemap.productGrouping[0][2]}`, "g").test(img.image_url))[0];
		zoomToe = imgToe && imgToe.image_url;
	}

	// Use larger image source, but prefer custom images
	const customImages = pathOr([], ["media", "customCarousel", "images"], data);
	const cacheImages = pathOr([], ["media", "cacheCarousel", "images"], data);
	const carousel = customImages.length >= cacheImages.length ? customImages : cacheImages;
	const customEpdp = pathOr([], ["media", "customNonCarousel", "images"], data);
	const cacheEpdp = pathOr([], ["media", "cacheNonCarousel", "images"], data);
	const epdp = customEpdp.length >= cacheEpdp.length ? customEpdp : cacheEpdp;

	if (isNil(zoomToe)) zoomToe = pathOr(null, [6, "image_url"], carousel) || pathOr(null, [3, "image_url"], carousel);

	return {
		left: createImageObject(left || pathOr(null, [0, "image_url"], carousel), 400, cdnUrl),
		zoom_toe: createImageObject(zoomToe, 400, cdnUrl),
		epdp: epdp?.map((img) => createImageObject(img.image_url, 900, cdnUrl)),
	};
};

const formatProductGrouping = (data, assets, cdnUrl) => {
	const productGroupingIds = data.product_groupings?.map((item) => item.id);
	return productGroupingIds?.map((id) => {
		const info = findInProductGroup(id, data);
		const custom = pathOr([], ["media", "customPG", "images"], info);
		const video = pathOr([], ["media", "customPG", "videos"], info);
		const customFormatted = custom.map((img) => createImageObject(img.image_url, 900, cdnUrl));
		let customArray = [];
		if (
			find((img) => /top_left/.test(img.portrait), customFormatted) &&
			(find((img) => /top_right/.test(img.portrait), customFormatted) ||
				find((img) => /right/.test(img.portrait), customFormatted)) &&
			find((img) => /bottom/.test(img.portrait), customFormatted) &&
			find((img) => /logo/.test(img.portrait), customFormatted)
		) {
			customArray = customFormatted;
		}
		if (!isEmpty(video)) customArray.push(...video);
		const specs = pathOr([], ["sport_pulse_snapshots"], info);
		let allCopy = info.product_grouping_translatables.filter((el) => el.lang === i18n.language);
		if (isEmpty(allCopy)) allCopy = info.product_grouping_translatables.filter((el) => el.lang === "en");

		return {
			id,
			assets: flatten([pathOr([], ["epdp"], assets), customArray]),
			meta: {
				name: find(
					propEq(
						"source_path",
						"inside_track_cms_product_grouping.product_grouping_translatables.display_name"
					),
					allCopy
				)?.val,
				type: pathOr(null, ["grouping_type"], info),
				pulse_filter: pathOr(null, ["pulse_filter"], info),
			},
			content: {
				heading: find(
					propEq("source_path", "inside_track_cms_product_grouping.product_grouping_translatables.heading"),
					allCopy
				)?.val,
				body: find(
					propEq("source_path", "inside_track_cms_product_grouping.product_grouping_translatables.body"),
					allCopy
				)?.val,
			},
			specs,
		};
	});
};

const formatDetail = (data) => [
	{
		heading: paths(
			[
				["detail_heading_1", i18n.language],
				["detail_heading_1", "en"],
			],
			data
		).find((el) => !isNil(el)),
		body: paths(
			[
				["detail_body_1", i18n.language],
				["detail_body_1", "en"],
			],
			data
		).find((el) => !isNil(el)),
	},
	{
		heading: paths(
			[
				["detail_heading_2", i18n.language],
				["detail_heading_2", "en"],
			],
			data
		).find((el) => !isNil(el)),
		body: paths(
			[
				["detail_body_2", i18n.language],
				["detail_body_2", "en"],
			],
			data
		).find((el) => !isNil(el)),
	},
];

const formatFeedback = (data, marketplace) => {
	const feedback = pathOr(null, ["consumer_feedback"], data);
	let reviews = pathOr([], ["reviews"], feedback);

	// Find Korean text in reviews and move to front
	const re = /[\u3131-\uD79D]/giu;
	let reviewsKO = reviews?.filter((review) => re.test(review.body));
	let reviewsNotKO = reviews?.filter((review) => !re.test(review.body));

	if (reviewsKO.length > 0) {
		reviewsKO = reviewsKO.filter((el) => !isNil(el.heading));
		reviewsKO = shuffleArray(reviewsKO);
	}
	if (reviewsNotKO.length > 0) {
		reviewsNotKO = reviewsNotKO.filter((el) => !isNil(el.heading));
		let reviewsMarket = reviewsNotKO.filter((el) => el.marketplace === marketplace);
		let reviewsUS = reviewsNotKO.filter((el) => el.marketplace === "US");
		reviewsMarket = shuffleArray(reviewsMarket);
		reviewsUS = shuffleArray(reviewsUS);
		reviewsNotKO = [...reviewsMarket, ...reviewsUS];
	}
	reviews = [...reviewsKO, ...reviewsNotKO];

	if (!isNil(feedback)) {
		return {
			...feedback,
			durability_rating: feedback.durability_rating ?? 1.5,
			avg_rating: Math.round(feedback.avg_rating * 10) / 10,
			reviews,
		};
	}

	return {
		avg_rating: 0,
		size_fidelity_rating: null,
		comfort_rating: null,
		durability_rating: null,
		reviews: [],
	};
};

const getProductGroupButtons = (arr) =>
	arr?.map(({ id, meta, assets = [] }) => ({
		id,
		name: pathOr(null, ["name"], meta),
		type: pathOr(null, ["type"], meta),
		hasMedia: assets.length > 2,
	}));

const formatLongDetails = (str) => {
	let newString = str;
	const lengthTest = i18n.language === "en" || i18n.language === "en-GB" ? 200 : 150;
	if (newString.length > lengthTest) {
		newString = "";
		const temp = str.split(".").filter((el) => !isEmpty(el));
		let i = 0;
		do {
			if (newString.length + `${temp[i]}.`.length < lengthTest) newString += `${temp[i]}.`;
			else break;
			i += 1;
		} while (newString.length < lengthTest);
	}
	return newString;
};

const formatSlides = (
	data,
	productGroupings,
	imagemap,
	categoryID,
	surfaceID,
	runningID,
	danceID,
	lifestyleVal,
	cdnUrl,
	prioritizedTaxonomyIDs = []
) => {
	const pixleeUgc = pathOr([], ["media", "pixleeUgc", "images"], data);
	const curalateUgc = pathOr([], ["media", "ugc", "images"], data);
	const videos = pathOr([], ["media", "cacheCarousel", "videos"], data);
	const ugc = pixleeUgc.length > 0 ? pixleeUgc : curalateUgc;
	// First priority is Aurora, if it is complete
	const auroraImages = pathOr([], ["media", "auroraCacheCarousel", "images"], data);

	let noDetail = false;
	const auroraSlides = [];

	if (!isEmpty(auroraImages)) {
		const aurora1 =
			auroraImages.filter((img) => new RegExp(`${imagemap.carouselCompare[0][0]}`, "g").test(img.image_url))[0] ||
			auroraImages.filter((img) => new RegExp(`${imagemap.carouselCompare[0][1]}`, "g").test(img.image_url))[0];
		const aurora2 =
			auroraImages.filter((img) => new RegExp(`${imagemap.carouselDetail[0][0]}`, "g").test(img.image_url))[0] ||
			auroraImages.filter((img) => new RegExp(`${imagemap.carouselDetail[0][1]}`, "g").test(img.image_url))[0] ||
			auroraImages.filter((img) => new RegExp(`${imagemap.carouselDetail[0][2]}`, "g").test(img.image_url))[0];
		const aurora3 =
			auroraImages.filter((img) => new RegExp(`${imagemap.carouselDetail[1][0]}`, "g").test(img.image_url))[0] ||
			auroraImages.filter((img) => new RegExp(`${imagemap.carouselDetail[1][1]}`, "g").test(img.image_url))[0];
		const aurora4 =
			auroraImages.filter((img) => new RegExp(`${imagemap.carouselDetail[2][0]}`, "g").test(img.image_url))[0] ||
			auroraImages.filter((img) => new RegExp(`${imagemap.carouselDetail[2][1]}`, "g").test(img.image_url))[0];
		auroraSlides.push(aurora1, aurora2, aurora3, aurora4);
		if (auroraSlides[1])
			noDetail = new RegExp(`${imagemap.carouselDetail[0][2]}`, "g").test(auroraSlides[1].image_url);
	}

	const customImages = pathOr([], ["media", "customCarousel", "images"], data);
	const cacheImages = pathOr([], ["media", "cacheCarousel", "images"], data);
	const images = customImages.length >= cacheImages.length ? customImages : cacheImages;

	const image0 = pathOr("", [0, "image_url"], images);
	const image1 = pathOr("", [1, "image_url"], images);
	const image2 = pathOr("", [2, "image_url"], images);
	const image3 = pathOr("", [3, "image_url"], images);
	const image4 = pathOr("", [4, "image_url"], images);
	const image5 = pathOr("", [5, "image_url"], images);
	const image7 = pathOr("", [7, "image_url"], images);
	const hasLimitedImages = images.length < 8;

	let slide1 = "";
	let slide2 = "";
	let slide3 = "";
	let slide4 = "";
	if (isNil(auroraSlides[0])) slide1 = hasLimitedImages ? image0 : image4;
	else slide1 = auroraSlides[0].image_url;
	if (isNil(auroraSlides[1])) slide2 = image7 || image5 || image3;
	else slide2 = auroraSlides[1].image_url;
	if (isNil(auroraSlides[2])) slide3 = image4 ? image0 : image2;
	else slide3 = auroraSlides[2].image_url;
	if (isNil(auroraSlides[3])) slide4 = image1;
	else slide4 = auroraSlides[3].image_url;

	let langCopy = data.style_translatables.filter((el) => el.lang === i18n.language);
	if (isEmpty(langCopy)) langCopy = data.style_translatables.filter((el) => el.lang === "en");

	const headingCopyPriority = [
		"consumer_product.copy.enhancedBenefits.0.header",
		"consumer_product.copy.enhancedBenefits.1.header",
		"consumer_product.copy.moreDescriptions.0.header",
		"consumer_product.copy.featuresAndBenefits.0.header",
		"inside_track_cms_product.style_translatables.detail_heading_1",
		"inside_track_cms_product.style_translatables.detail_heading_2",
	];
	const bodyCopyPriority = [
		"consumer_product.copy.enhancedBenefits.0.body.0",
		"consumer_product.copy.enhancedBenefits.1.body.0",
		"consumer_product.copy.moreDescriptions.0.body.0",
		"consumer_product.copy.moreDescriptions.0.body.1",
		"consumer_product.copy.featuresAndBenefits.0.body.0",
		"consumer_product.copy.featuresAndBenefits.0.body.1",
		"inside_track_cms_product.style_translatables.detail_body_1",
		"inside_track_cms_product.style_translatables.detail_body_2",
	];

	const detail = [
		{
			heading: "",
			body: "",
		},
		{
			heading: "",
			body: "",
		},
	];

	for (let i = 0; i < detail.length; i += 1) {
		let heading = "";
		let body = "";
		let index = 0;
		while (isEmpty(heading)) {
			if (isNil(headingCopyPriority[index])) {
				heading = "";
				break;
			}
			heading = find(propEq("source_path", headingCopyPriority[index]), langCopy)?.val || "";
			index += 1;
		}
		if (!isEmpty(heading)) headingCopyPriority.splice(index - 1, 1);
		index = 0;
		while (isEmpty(body)) {
			if (isNil(bodyCopyPriority[index])) {
				body = "";
				break;
			}
			body = formatLongDetails(find(propEq("source_path", bodyCopyPriority[index]), langCopy)?.val || "");
			index += 1;
		}
		if (!isEmpty(body)) bodyCopyPriority.splice(index - 1, 1);

		detail[i].heading = heading;
		detail[i].body = body;
	}

	const slides = [
		{
			id: 1,
			image: getImageSize(getTransparentImage(slide1), 900, cdnUrl),
			imageZIndex: "front",
			title: formatBenefitStatement(data),
			description: null,
			buttons: null,
			isImageSmall: true,
			isHighlighted: false,
			isAlignedBottom: false,
			isUgc: false,
		},
		{
			id: 2,
			image: noDetail
				? getImageSize(getTransparentImage(slide2), 1800, cdnUrl)
				: getJpeg(getImageSize(slide2, 900, cdnUrl)),
			imageZIndex: "back",
			title: formatDesignedFor(
				data,
				categoryID,
				surfaceID,
				runningID,
				danceID,
				lifestyleVal,
				prioritizedTaxonomyIDs
			),
			description: null,
			buttons: getProductGroupButtons(productGroupings),
			isImageSmall: false,
			isHighlighted: true,
			isAlignedBottom: true,
			isUgc: false,
		},
		{
			id: 3,
			image: getImageSize(getTransparentImage(slide3), 900, cdnUrl),
			imageZIndex: "front",
			title: detail[0].heading,
			description: detail[0].body,
			buttons: null,
			isImageSmall: true,
			isHighlighted: true,
			isAlignedBottom: false,
			isUgc: false,
		},
		{
			id: 4,
			image: getImageSize(getTransparentImage(slide4), 900, cdnUrl),
			imageZIndex: "front",
			title: detail[1].heading,
			description: detail[1].body,
			buttons: null,
			isImageSmall: true,
			isHighlighted: true,
			isAlignedBottom: false,
			isUgc: false,
		},
	];

	if (!isEmpty(videos)) {
		slides.splice(2, 0, {
			id: 5,
			video: getDetailVideo(videos[0].video_url, cdnUrl),
			videoVerticalCompare: getVerticalCompareVideo(videos[0].video_url, cdnUrl),
			imageZIndex: "back",
			title: null,
			description: null,
			buttons: null,
			isImageSmall: false,
			isHighlighted: false,
			isAlignedBottom: false,
			isUgc: false,
			isVideo: true,
		});
	}

	if (!isEmpty(ugc)) {
		slides.push({
			id: 6,
			image: null,
			images: [getJpeg(getUGCCrop(pathOr("", ["image_url"], ugc[0]), cdnUrl))],
			imageZIndex: "back",
			title: null,
			description: null,
			buttons: null,
			isImageSmall: false,
			isHighlighted: false,
			isAlignedBottom: false,
			isUgc: true,
		});
	}

	return slides;
};

const formatThumbnail = (data, cdnUrl) =>
	getTrimmedImage(getImageSize(pathOr("", ["thumbnail_url"], data), 200, cdnUrl));

const formatMembership = (data) => {
	if (!isNil(data)) return true;
	return false;
};

const formatPopularWith = (
	shoe,
	categoryID,
	runningID,
	athleticsID,
	surfaceID,
	everydayID,
	language,
	prioritizedTaxonomyIDs
) => {
	let designedFor = "";
	let categoryArray = filter((tax) => tax.lang === "en", shoe.taxonomy);
	categoryArray = categoryArray.filter((el) => el.parent_id === categoryID);
	// Prioritize Running as category if it is in the shoe taxonomy
	const category =
		sortByPrioritizedTaxonomyIDs(categoryArray, prioritizedTaxonomyIDs)[0] ||
		filter((tax) => tax.lang === "en", shoe.taxonomy)[0];
	const surface = filter((tax) => tax.lang === "en", shoe.taxonomy).filter((el) => el.parent_id === surfaceID)[0]
		?.val;

	const racingShoe = shoe.taxonomy.find((tax) => tax.id === athleticsID);
	const everydayShoe = shoe.taxonomy.find((tax) => tax.id === everydayID);
	if (racingShoe && !everydayShoe) designedFor = "Race Running";
	else if (everydayShoe && !racingShoe) designedFor = "Everyday Running";
	else if (surface === "Trail") designedFor = "Trail Running";

	if (!isNil(category) && !isEmpty(shoe.designed_for)) {
		if (category.val === "Soccer") return { text: "popular.soccer" };
		if (category.val === "Outdoor" || category.val === "Hiking") return { text: "popular.hiking" };
		if (category.val === "Skateboarding") return { text: "popular.skateboarding" };
		// if (category.val === "Jordan") {
		// 	if (shoe.designed_for.en === "Fashion") {
		// 		return "Sneakerheads, MJ fans and streetwear lovers";
		// 	}
		// 	if (shoe.designed_for.en === "Off the Court") {
		// 		return "Sneakerheads, MJ fans and streetwear lovers";
		// 	}
		// 	if (shoe.designed_for.en === "On the Court") {
		// 		return "Ballers, hardcourt wizards and basketball fans";
		// 	}
		// }
		if (category.val === "Lifesyle") return { text: "popular.lifestyle" };
		// if (category.val === "NSW Air Max") return "Sneakerheads, Nike fans and hypebeasts";
		if (category.val === "Basketball") return { text: "popular.basketball" };
		if (category.val === "Training & Gym") return { text: "popular.training" };
		// if (category.val === "Everyday") return "Sneakerheads, Nike fans and streetwear lovers";
		if (category.val === "Running") {
			if (designedFor === "Everyday Running") {
				return { text: "popular.running.everyday" };
			}
			if (designedFor === "Race Running") {
				return { text: "popular.running.race" };
			}
			if (designedFor === "Trail Running") {
				return { text: "popular.running.trail" };
			}
		}
	}

	return { text: "popular.default" };
};

const checkIsMissingData = (
	item,
	productGroupings,
	imagemap,
	categoryID,
	surfaceID,
	runningID,
	danceID,
	lifestyleVal,
	cdnUrl,
	prioritizedTaxonomyIDs = []
) => {
	const slides = formatSlides(
		item,
		productGroupings,
		imagemap,
		categoryID,
		surfaceID,
		runningID,
		danceID,
		lifestyleVal,
		cdnUrl,
		prioritizedTaxonomyIDs
	);
	return !isNil(slides) && slides.length < 4;
};

export const shoeFormatter = (
	item,
	imagemap,
	categoryID,
	surfaceID,
	runningID,
	danceID,
	lifestyleVal,
	athleticsID,
	everydayID,
	cdnUrl,
	marketplace,
	language,
	prioritizedTaxonomyIDs = []
) => {
	// console.log(item);
	const assets = formatMedia(item, imagemap, cdnUrl);
	const productGroupings = formatProductGrouping(item, assets, cdnUrl);
	const slides = formatSlides(
		item,
		productGroupings,
		imagemap,
		categoryID,
		surfaceID,
		runningID,
		danceID,
		lifestyleVal,
		cdnUrl,
		prioritizedTaxonomyIDs
	);
	let noDetail = false;
	if (!isNil(imagemap.carouselDetail))
		noDetail = new RegExp(`${imagemap.carouselDetail[0][2]}`, "g").test(slides[1].image);

	return {
		id: item.style_color_code,
		slides,
		content: formatContent(item),
		assets,
		meta: formatShoeMeta(item, categoryID, surfaceID, runningID, danceID, lifestyleVal, prioritizedTaxonomyIDs),
		productGroupings,
		detail: formatDetail(item),
		feedback: formatFeedback(item, marketplace),
		isMissingData: checkIsMissingData(
			item,
			productGroupings,
			imagemap,
			categoryID,
			surfaceID,
			runningID,
			danceID,
			lifestyleVal,
			cdnUrl,
			prioritizedTaxonomyIDs
		),
		missingDetailImages: noDetail,
		membership: formatMembership(item),
		recommendations: [],
		tempRecommendations: [],
		popularWith: formatPopularWith(
			item,
			categoryID,
			runningID,
			athleticsID,
			surfaceID,
			everydayID,
			language,
			prioritizedTaxonomyIDs
		),
	};
};

export const gridShoeFormatter = (item, cdnUrl) => ({
	id: item.style_color_code,
	name: paths(
		[
			["product_name", i18n.language],
			["product_name", "en"],
		],
		item
	).find((el) => !isNil(el)),
	meta: formatGridMeta(item),
	filters: item.taxonomy,
	thumbnail: formatThumbnail(item, cdnUrl),
	membership: formatMembership(item),
	recommendations: [],
});

export const gridShoesFormatter = (data, cdnUrl) => {
	return data?.map((item) => gridShoeFormatter(item, cdnUrl));
};
