import React from "react";
import cn from "classnames";
import { motion, AnimatePresence } from "framer-motion";

import GridBlock from "@/components/GridBlock";
import Image from "@/components/Image";

import { getFadeMoveXFull } from "@/animations";

import propTypes from "./ProductGroupingPartialsImage.propTypes";
import defaultProps from "./ProductGroupingPartialsImage.defaultProps";

const ProductGroupingPartialsImage = ({
	className = "",
	id,
	src,
	shouldShowBottom,
	shouldCover,
	delay = 0,
	horizontal = false,
}) => (
	<GridBlock id={id} className={className}>
		<AnimatePresence>
			<motion.div className="u-inset" initial="hidden" animate="show" variants={getFadeMoveXFull({ delay })}>
				<Image
					className={cn({
						"c-image--cover-bottom u-fill-space": shouldShowBottom,
						"c-image--cover u-fill-space": shouldCover,
						"u-fill-space": horizontal,
					})}
					src={src}
				/>
			</motion.div>
		</AnimatePresence>
	</GridBlock>
);

ProductGroupingPartialsImage.propTypes = propTypes;
ProductGroupingPartialsImage.defaultProps = defaultProps;

export default ProductGroupingPartialsImage;
