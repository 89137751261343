import React, { memo } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import Icon from "@/components/Icon";
import HighlightMembership from "@/components/HighlightMembership";
import { useQuery } from "@/hooks/Router";

// =============================================================================
// Root
// =============================================================================
const MembershipMenuNonMember = () => {
	const { t } = useTranslation();

	const query = useQuery();

	const qForm = query.get("form") || "rise";

	const direction = qForm === "vertical" ? "vh" : "vw";

	const size = qForm === "vertical" ? 2 : 1.4067;
	const grid = [0, size, size * 2, size * 3, size * 4, size * 5, size * 6];
	const duration = 5;
	const keyframes = [];
	for (let i = 0; i <= 1; i += 0.02) {
		keyframes.push(i.toFixed(2));
	}

	return (
		<motion.div
			initial={qForm === "vertical" ? { paddingLeft: "18.5vh" } : null}
			animate={
				qForm === "vertical"
					? {
							paddingLeft: [
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
								"18.5vh",
							],
					  }
					: null
			}
			transition={{ ease: [0, 1, 0, 1], duration, times: keyframes }}
		>
			<motion.div
				initial={{
					overflow: "hidden",
					position: "absolute",
					x: `${grid[1]}${direction}`,
					width: `${grid[0]}${direction}`,
				}}
				animate={{
					x: [
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
					],
					y: [
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
					],
					width: [
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
					],
				}}
				transition={{ ease: [0, 1, 0, 1], duration, times: keyframes }}
			>
				<Icon
					className={qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"}
					type="swoosh"
				/>
			</motion.div>
			<motion.div
				initial={{
					overflow: "hidden",
					position: "absolute",
					x: `${grid[1]}${direction}`,
					width: `${grid[0]}${direction}`,
				}}
				animate={{
					x: [
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
					],
					y: [
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
					],
					width: [
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
					],
				}}
				transition={{ ease: [0, 1, 0, 1], duration, times: keyframes }}
			>
				<Icon
					className={qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"}
					type="arrow-white"
				/>
			</motion.div>
			<motion.div
				initial={{
					overflow: "hidden",
					position: "absolute",
					x: `${grid[2]}${direction}`,
					width: `${grid[0]}${direction}`,
				}}
				animate={{
					x: [
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
						`${grid[2]}${direction}`,
					],
					y: [
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
					],
					width: [
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[0]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
						`${grid[1]}${direction}`,
					],
				}}
				transition={{ ease: [0, 1, 0, 1], duration, times: keyframes }}
			>
				<Icon
					className={qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"}
					type="qr-code-black"
				/>
			</motion.div>
			<HighlightMembership
				className={cn("s-dark")}
				title={t("membership.attract_4a")}
				delay={0.5}
				endDelay={2}
				grid={grid}
				typo={
					qForm === "vertical"
						? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
						: null
				}
				initial={[3, 0]}
				animate={[3, 0]}
				clipPath={[
					"inset(0px 100% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 100% 0px 0px)",
				]}
			/>
			<HighlightMembership
				className={cn("s-dark")}
				title={t("membership.attract_4b")}
				delay={0.5}
				endDelay={2}
				grid={grid}
				typo={
					qForm === "vertical"
						? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
						: null
				}
				initial={[5, 1]}
				animate={[5, 1]}
				clipPath={[
					"inset(0px 100% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 100% 0px 0px)",
				]}
			/>
			<HighlightMembership
				className={cn("s-dark")}
				title={t("membership.attract_5a")}
				delay={5}
				endDelay={2}
				grid={grid}
				typo={
					qForm === "vertical"
						? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
						: null
				}
				initial={[3, 0]}
				animate={[3, 0]}
				clipPath={[
					"inset(0px 100% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 100% 0px 0px)",
				]}
			/>
			<HighlightMembership
				className={cn("s-dark")}
				title={t("membership.attract_5b")}
				delay={5}
				endDelay={2}
				grid={grid}
				typo={
					qForm === "vertical"
						? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
						: null
				}
				initial={[5, 1]}
				animate={[5, 1]}
				clipPath={[
					"inset(0px 100% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 0% 0px 0px)",
					"inset(0px 20% 0px 0px)",
					"inset(0px 40% 0px 0px)",
					"inset(0px 60% 0px 0px)",
					"inset(0px 80% 0px 0px)",
					"inset(0px 100% 0px 0px)",
				]}
			/>
		</motion.div>
	);
};

export default memo(MembershipMenuNonMember);
