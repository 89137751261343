import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import { isLangKorean } from "@/utils/i18n";

import Typography from "@/components/Typography";
import ReviewStars from "@/components/ReviewStars";
import Codification from "@/components/Codification";

import { useLowGPU } from "@/store/ui/ui.hooks";

import { fadeMoveReverse } from "@/animations";

import defaultProps from "./Review.defaultProps";
import propTypes from "./Review.propTypes";


const CHARACTER_LIMIT_FOR_DESCRIPTION_AS_TITLE = 15;

// =============================================================================
// Root
// =============================================================================
const Review = ({
	className = "",
	title,
	description,
	author,
	date,
	rating,
	isSmallTitle,
	verified,
	descriptionCharacterLimit,
	titleCharacterLimit,
	size = "6xl",
	vertical,
}) => {
	const { t } = useTranslation();

    if (!title) {
        title = description;
        titleCharacterLimit = CHARACTER_LIMIT_FOR_DESCRIPTION_AS_TITLE;
    }

	const korean = Boolean(
		title?.match(/[\uac00-\ud7af]|[\u1100-\u11ff]|[\u3130-\u318f]|[\ua960-\ua97f]|[\ud7b0-\ud7ff]/g)
	);

    const titleLimit = korean ? titleCharacterLimit / 2 : titleCharacterLimit;

	const truncatedDescription =
		descriptionCharacterLimit !== null && description?.length > descriptionCharacterLimit
			? `${description?.substring(0, descriptionCharacterLimit)}...`
			: description;
	const truncatedTitle =
		titleLimit !== null && title?.length > titleLimit
			? `${title?.substring(0, titleLimit)}...`
			: title;

	const lowGPU = useLowGPU();

	let lineHeight = "";
	if (isSmallTitle && !korean) lineHeight = "2xl";
	else if (isSmallTitle && korean) lineHeight = "4xl";
	else if (korean) lineHeight = "8xl";
	else lineHeight = "6xl";

	return (
		<div
			className={cn("c-review", { "s-korean": korean, "u-stack-8": !vertical, "u-stack-4": vertical }, className)}
		>
			<Codification
				text={truncatedTitle}
				typography={{
					primary: true,
					forceBold: korean,
					size: isSmallTitle ? "xl" : size,
					lineHeightSize: lineHeight,
					tag: isSmallTitle ? "h3" : "h2",
					uppercase: true,
				}}
				characterSwitchAmount={4}
				characterNextTrigger={2}
				characterTimeout={20}
				lowGPU={lowGPU}
			/>

			<ReviewStars rating={rating} />

			<motion.div
				className="u-overflow-hidden"
				variants={fadeMoveReverse}
				initial="hidden"
				animate="show"
				exit="hidden"
			>
				<Typography className="c-review__description" tag="p" lineHeightSize={vertical ? "sm" : null}>
					{truncatedDescription}
				</Typography>

				<div className="u-mt-8">
					<Typography className="u-mb-6" tag="p" weight="bold" size="xs">
						{author}
					</Typography>

					<Typography className="u-mb-6" tag="p" size="xs">
						{date}
					</Typography>

					{verified && (
						<Typography className="u-color-contrast-low" tag="p" weight="normal" size="xs">
							{t("generic.verified_purchaser")}
						</Typography>
					)}
				</div>
			</motion.div>
		</div>
	);
};

Review.propTypes = propTypes.Review;
Review.defaultProps = defaultProps.Review;

export default Review;
