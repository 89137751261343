export const FILTER_KEYS = {
	CATEGORY: "category",
	KINGDOM: "kingdom",
	GENDER: "gender",
	DESIGNED_FOR: "designed_for",
	SURFACE: "surface",
	ACTIVITY: "activity",
};

export const GENDER_FILTER_ORDER = ["Unisex", "Women", "Men", "Girls", "Boys"];

export const CATEGORY_FILTERS = [
	"Soccer",
	"Golf",
	"Basketball",
	"Track & Field",
	"Training & Gym",
	"Skateboarding",
	"Running",
	"Tennis",
	"Hiking",
	"Lifestyle",
];

export const RUNNING_SURFACE_FILTERS = ["Road", "Trail", "Track"];

export const FOOTBALL_SURFACE_FILTERS = [
	"Grass",
	"Soft Ground",
	"Turf",
	"Artificial Grass",
	"Multi Ground",
	"Firm Ground",
	"Indoor Court",
];
