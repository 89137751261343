import React, { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { identity, isEmpty, propOr } from "ramda";
import cn from "classnames";

import Switch from "react-switch";

import Modal from "@/components/Modal";
import Typography from "@/components/Typography";
import Button from "@/components/Button";

import { useAdminPanelModal } from "@/store/ui/ui.hooks";

import { useQuery } from "@/hooks/Router";
import { getIdentity, setEndSession } from "@/store/membership/membership.actions";
import { setQAMode } from "@/store/ui/ui.actions";

// =============================================================================
// Root
// =============================================================================
const AdminPanel = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const query = useQuery();
	const location = useLocation();
	const { isOpen, toggleIsOpen } = useAdminPanelModal();
	const qForm = query.get("form") || "rise";

	const [values, setValues] = useState({
		left: query.get("left") || "",
		right: query.get("right") || "",
	});
	const [qrCode, setQRCode] = useState("");
	const [qaMode, setQAModeState] = useState(false);

	const handleChange = (event) => {
		const target = propOr(null, "target", event);
		const value = propOr(null, "value", target);
		const name = propOr(null, "name", target);

		setValues((prevValue) => ({
			...prevValue,
			[name]: value,
		}));
	};

	const handleQRSubmit = (e) => {
		e.preventDefault();
		toggleIsOpen();
		if (!isEmpty(identity)) dispatch(setEndSession(true));
		dispatch(getIdentity(qrCode));
		setQRCode("");
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (values.left && values.left !== "") {
			query.set("left", values.left);
		} else {
			query.delete("left");
		}

		if (values.right && values.right !== "") {
			query.set("right", values.right);
		} else {
			query.delete("right");
		}

		toggleIsOpen();
		history.push(`${location.pathname}?${query.toString()}`);
	};

	useEffect(() => {
		dispatch(setQAMode(qaMode));
	}, [qaMode]);

	return (
		<Modal
			className={cn("options", {
				vertical: qForm === "vertical",
				rise: qForm === "rise",
			})}
			isOpen={isOpen}
			onClose={toggleIsOpen}
		>
			<div className="u-pt-18 u-px-15 u-pb-9">
				<div className="u-flex u-col u-ai-center u-stack-10">
					<Typography className="u-max-width-100 u-text-c" tag="h3" primary uppercase size="2xl">
						{t("titles.admin_panel")}
					</Typography>

					<form className="c-form" onSubmit={handleSubmit}>
						<div className="c-form__fields">
							<div className="c-form__field">
								<label className="c-form__label" htmlFor="left-shoe-input">
									<Typography primary uppercase tag="p" size="md">
										{t("generic.left_shoe")}
									</Typography>
								</label>
								<input
									className="c-form__input"
									id="left-shoe-input"
									type="text"
									name="left"
									value={values.left}
									onChange={handleChange}
								/>
							</div>

							<div className="c-form__field">
								<label className="c-form__label" htmlFor="right-shoe-input">
									<Typography primary uppercase tag="p" size="md">
										{t("generic.right_shoe")}
									</Typography>
								</label>
								<input
									className="c-form__input"
									id="right-shoe-input"
									type="text"
									name="right"
									value={values.right}
									onChange={handleChange}
								/>
							</div>
						</div>
						<Button className="c-form__button c-button--rounded" type="submit">
							{t("buttons.submit")}
						</Button>
					</form>
				</div>

				<div className="u-flex u-col u-ai-center u-stack-10 u-mt-12">
					<Typography className="u-max-width-100 u-text-c" tag="h3" primary uppercase size="2xl">
						{t("titles.admin_qr_code")}
					</Typography>

					<form className="c-form" onSubmit={(e) => handleQRSubmit(e)}>
						<div className="c-form__fields">
							<div className="c-form__field">
								<input
									className="c-form__input u-width-180"
									id="qr-code-input"
									type="text"
									name="qr-code"
									value={qrCode}
									onChange={(e) => setQRCode(e.target.value)}
								/>
							</div>
						</div>
						<Button className="c-form__button c-button--rounded" type="submit">
							{t("buttons.submit")}
						</Button>
					</form>
				</div>

				<div className="u-flex u-col u-ai-center u-stack-10 u-mt-12">
					<form className="c-form" onSubmit={(e) => handleQRSubmit(e)}>
						<div className="c-form__fields">
							<div className="c-form__field">
								<>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
								<label htmlFor="iQAMode" className="u-flex u-mt-4">
									<Switch id="iQAMode" onChange={() => setQAModeState(!qaMode)} checked={qaMode} />
									<Typography className="u-text-c u-flex u-col u-ml-6" tag="span" size="md" primary>
										{t("titles.admin_qa_mode")}
									</Typography>
								</label>
							</div>
						</div>
					</form>
				</div>

				<div className="u-flex u-jc-end u-ai-center u-mt-12">
					<Typography className="u-text-c u-color-secondary-shade" size="2xs">
						{process.env.REACT_APP_VERSION}
					</Typography>

					<Modal.Close className="u-ml-6" onClose={toggleIsOpen}>
						{t("buttons.dismiss")}
					</Modal.Close>
				</div>
			</div>
		</Modal>
	);
};

export default memo(AdminPanel);
