import React, { memo } from "react";
import cn from "classnames";
import { isNil } from "ramda";
import { motion } from "framer-motion";

import Typography from "@/components/Typography";

import { fadeMove } from "@/animations";

import propTypes from "./Progress.propTypes";
import defaultProps from "./Progress.defaultProps";

// =============================================================================
// Root
// =============================================================================
const Progress = ({ title, minText, maxText, children, className = "" }) => {
	const renderText = (text, cName) => (
		<Typography className={cn("c-progress__text", cName)} size="3xs">
			{text}
		</Typography>
	);

	return (
		<div className={cn("c-progress", className)}>
			{!isNil(title) && (
				<motion.div variants={fadeMove} initial="hidden" animate="show">
					<Typography className="c-progress__text u-text-l u-mb-4" tag="h4" weight="bold">
						{title}
					</Typography>
				</motion.div>
			)}
			{children}
			<motion.div className="u-flex u-mt-2" variants={fadeMove} initial="hidden" animate="show">
				{!isNil(minText) && renderText(minText, "u-text-l")}
				{!isNil(maxText) && renderText(maxText, "u-text-r u-ml-auto")}
			</motion.div>
		</div>
	);
};

Progress.propTypes = propTypes;
Progress.defaultProps = defaultProps;

export default memo(Progress);
