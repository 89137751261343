import ko from "@/languages/ko.json";
import en from "@/languages/en.json";
import gb from "@/languages/gb.json";
import cn from "@/languages/cn.json";
import fr from "@/languages/fr.json";

export const ENGLISH = {
	NAME: "English",
	NATIVE: "English",
	CODE: "en-GB",
	TRANSLATIONS: gb,
};

export const AMERICAN = {
	NAME: "English",
	NATIVE: "English",
	CODE: "en",
	TRANSLATIONS: en,
};

export const KOREAN = {
	NAME: "Korean",
	NATIVE: "한국어",
	CODE: "ko",
	TRANSLATIONS: ko,
};

export const CHINESE = {
	NAME: "Chinese",
	NATIVE: "中文",
	CODE: "zh-Hans",
	TRANSLATIONS: cn,
};

export const FRENCH = {
	NAME: "French",
	NATIVE: "Français",
	CODE: "fr",
	TRANSLATIONS: fr,
};

export const LANGUAGES = [AMERICAN, ENGLISH, CHINESE, KOREAN, FRENCH];
