import React, { useState, useRef, memo } from "react";
import cn from "classnames";
import { motion, AnimatePresence } from "framer-motion";

import { moveYReverse, moveY } from "@/animations";

import Icon from "@/components/Icon";
import Typography from "@/components/Typography";
import useOnClickOutside from "@/hooks/ClickOutside";

import propTypes from "./FilterDropdown.propTypes";
import defaultProps from "./FilterDropdown.defaultProps";
import iconAnim from "./FilterDropdown.animations";

// =============================================================================
// Root
// =============================================================================
const FilterDropdown = ({ className = "", options, activeFilter, label, onChange, vertical = false }) => {
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef();

	useOnClickOutside(ref, () => setIsOpen(false));

	const toggleIsOpen = () => {
		setIsOpen(!isOpen);
	};

	const handleChange = (index) => {
		setIsOpen(false);

		onChange(index);
	};

	const getActiveOption = () => {
		const activeOption = options.find((option) => option.id === activeFilter);

		return activeOption ? activeOption.label : null;
	};

	return (
		<div ref={ref} className={cn("c-filter-dropdown", className)}>
			<div className="c-filter-dropdown__flyout">
				<AnimatePresence>
					{isOpen && (
						<motion.div
							className="c-filter-dropdown__items"
							variants={vertical ? moveY : moveYReverse}
							animate="show"
							initial="hidden"
							exit="hidden"
						>
							{options.map((option) => (
								<button
									type="button"
									className={cn("c-filter-dropdown__item u-text-l", {
										"u-font-w-bold": activeFilter === option.id,
									})}
									key={option.id}
									onClick={() => handleChange(option.id)}
								>
									{option.label}
								</button>
							))}
						</motion.div>
					)}
				</AnimatePresence>
			</div>

			<button type="button" className="c-filter-dropdown__trigger" onClick={toggleIsOpen}>
				<Typography tag="span" className="u-text-l">
					{getActiveOption() || label}
				</Typography>

				<motion.span
					variants={iconAnim}
					animate={isOpen ? "open" : "closed"}
					className="c-filter-dropdown__icon-wrapper"
				>
					<Icon type="chevron-right" className="c-filter-dropdown__icon" />
				</motion.span>
			</button>
		</div>
	);
};

FilterDropdown.propTypes = propTypes.FilterDropdown;
FilterDropdown.defaultProps = defaultProps.FilterDropdown;

export default memo(FilterDropdown);
