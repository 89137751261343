import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";

import { setLatestAction } from "@/store/ui/ui.actions";
import { useActiveSession } from "@/store/ui/ui.hooks";

// =============================================================================
// Root
// =============================================================================
const ReloadButton = () => {
	let resetTimeout = useRef(null);
	const [counter, setCounter] = useState(0);
	const dispatch = useDispatch();

	const activeSession = useActiveSession();

	const clearResetTimeout = () => {
		if (resetTimeout) {
			clearTimeout(resetTimeout);
			resetTimeout = null;
		}
	};

	const setResetTimeout = () => {
		resetTimeout = setTimeout(() => {
			setCounter(0);
			clearResetTimeout();
		}, 1000);
	};

	const onClick = () => {
		setCounter(counter + 1);
	};

	useEffect(() => {
		if (counter >= 5) {
			if (activeSession) dispatch(setLatestAction({ type: "session_end", id: uuidv4(), activeSession }));
			setTimeout(() => window.location.reload(), 1000);
		} else if (counter > 0) {
			setResetTimeout();
		}
		return () => {
			clearResetTimeout();
		};
	}, [counter]);

	// eslint-disable-next-line jsx-a11y/control-has-associated-label
	return <button className="c-reload-button" type="button" onClick={onClick} />;
};

export default ReloadButton;
