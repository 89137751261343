let preload = null;

const preloadImage = (url, imageLoadedCallback) => {
	preload = new Image();

	// Run callback when one image has been preloaded.
	preload.onload = imageLoadedCallback;
	preload.src = url;
};

const preloadImages = (images, allImagesLoadedCallback) => {
	let loadedCounter = 0;
	const totalLoadedNumber = images.length;

	images.forEach((image) => {
		preloadImage(image, () => {
			preload = null;
			loadedCounter += 1;

			if (loadedCounter === totalLoadedNumber) {
				// Run callback when all images have been preloaded.
				allImagesLoadedCallback();
			}
		});
	});
};

export default preloadImages;
