/* eslint-disable no-console */
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { NikeTableSession } from "rise-rfid";

import {
	BASIC_OPTIONS,
	LOW_LEVEL_OPTIONS,
	HIGH_LEVEL_OPTIONS,
	MID_LEVEL_OPTIONS,
} from "@/constants/nike-table-session";
import HIGH_LEVEL_EVENTS from "@/constants/rfid-events";

import { canLogTagEvents, logEvent } from "@/utils/logging";

export default () => {
	const dispatch = useDispatch();

	const session = useMemo(
		() =>
			new NikeTableSession(process.env.REACT_APP_LOCAL_RFID_URL, {
				...BASIC_OPTIONS,
				...LOW_LEVEL_OPTIONS,
				...MID_LEVEL_OPTIONS,
				...HIGH_LEVEL_OPTIONS,
			})
	);

	const addListener = ({ event, action, hasData }) => {
		session.on(event, (data) => {
			if (canLogTagEvents()) {
				if (hasData) {
					if (event === "tag_observe" || event === "tag_disappear") {
						logEvent(event, data?.id);
					} else if (event === "orientation_change") {
						logEvent(event, null, "red");
					} else {
						logEvent(event, data?.id, "green");
					}
				} else {
					logEvent(event, null, "blue");
				}
			}

			if (hasData) {
				dispatch(action(data));
			} else {
				dispatch(action);
			}
		});
	};

	useEffect(() => {
		HIGH_LEVEL_EVENTS.forEach((e) => addListener(e));

		session.connect();
	}, []);
};
