export const BASIC_OPTIONS = {
	autoReconnect: true,
	autoReconnectInterval: 1000,
	connectOnCreate: false,
};

export const LOW_LEVEL_OPTIONS = {
	onAntennaRssiThreshold: -50,
};

export const MID_LEVEL_OPTIONS = {};

// INFO: @IAN - Here you can change the timeouts being used in the NikeTableSession class.
// This is the order in which the statusses are being triggered:
// 5. SESSION: interaction_start
// 1. TAG: active
// 2. TAG: active_near
// 3. TAG: idle
// 4. TAG: observe
// 5. TAG: disappear
// 5. SESSION: interaction_stop
export const HIGH_LEVEL_OPTIONS = {
	orientationChangeDelay: 500,
	interactionStartTimeout: 1000, // This is the time between a tag going from no status to the session interaction_start status
	interactionEndTimeout: 86400000, // This is the time between a tag going from disappear status to the session interaction_stop status
	activeStatusTimeout: 500, // This is the time between a tag going from active to near_active status
	activeNearStatusTimeout: 50, // This is the time between a tag going from near_active to idle status
	idleStatusTimeout: 30000, // This is the time between a tag going from idle to observe status
};

// INFO: eg 'ID001', 'ID002',...
export const WHITELIST_TAGS = {
	whitelistedTags: [],
};
