const SHOES = [
	{
		epc: "33140BF46812A1E000000004",
		proxyEPC: "E31002274EFBDF4D7500003E",
		mockEpc: "ID0001",
		scc: null,
	},
	{
		epc: "33140BDF105289E000000001",
		proxyEPC: "E310020F8DFAE74D75000023",
		mockEpc: "ID0002",
		scc: null,
	},
	{
		epc: "33140BDF10526D6000000000",
		mockEpc: "ID0003",
		scc: null,
	},
	{
		epc: "33140BEA9C1817A000000000",
		mockEpc: "ID0004",
		scc: null,
	},
];

export default SHOES;
