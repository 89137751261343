import React, { useEffect } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { useQuery } from "@/hooks/Router";

import Index from "@/screens/Index";
import Place from "@/screens/Place";
import PlaceVertical from "@/screens/PlaceVertical";
import Compare from "@/screens/Compare";
import CompareVertical from "@/screens/CompareVertical";
import Error from "@/screens/Error";
import Reviews from "@/screens/Reviews";
import ReviewsVertical from "@/screens/ReviewsVertical";
import ProductGrouping from "@/screens/ProductGrouping";
import Detail from "@/screens/Detail";
import DetailVertical from "@/screens/DetailVertical";
import Setup from "@/screens/Setup";
import Privacy from "@/screens/Privacy";

import ROUTES from "@/constants/routes";

import useActivePage from "@/hooks/ActivePage";

import propTypes from "./Routing.propTypes";
import defaultProps from "./Routing.defaultProps";
import DetailHorizontal from "@/screens/DetailHorizontal";

const Routing = () => {
	const location = useLocation();
	const history = useHistory();
	const activePage = useActivePage();
	const query = useQuery();

	const qForm = query.get("form") || "rise";

	useEffect(() => {
		history.push({ pathname: activePage, search: location.search });
	}, [activePage]);

	const handleDetail = () => {
		switch (qForm) {
			case "vertical":
				return <DetailVertical />;
				break;

			case "horizontal":
				return <DetailHorizontal />;
				break;

			default:
				return <Detail />;
				break;
		}
	};

	const handlePlace = () => {
		switch (qForm) {
			case "vertical":
				return <PlaceVertical />;
				break;

			default:
				return <Place />;
				break;
		}
	};

	const handleCompare = () => {
		switch (qForm) {
			case "vertical":
				return <CompareVertical />;
				break;

			default:
				return <Compare />;
				break;
		}
	};

	const handleReviews = () => {
		switch (qForm) {
			case "vertical":
				return <ReviewsVertical />;
				break;

			default:
				return <Reviews />;
				break;
		}
	};

	return (
		<Switch location={location} key={location.pathname}>
			<Route exact path={ROUTES.Index}>
				<Index />
			</Route>

			<Route exact path={ROUTES.Detail}>
				{handleDetail()}
			</Route>

			<Route exact path={ROUTES.Place}>
				{handlePlace()}
			</Route>

			<Route exact path={ROUTES.Compare}>
				{handleCompare()}
			</Route>

			<Route exact path={ROUTES.Reviews}>
				{handleReviews()}
			</Route>

			<Route exact path={ROUTES.ProductGrouping}>
				<ProductGrouping />
			</Route>

			<Route exact path={ROUTES.Setup}>
				<Setup />
			</Route>

			<Route exact path={ROUTES.Privacy}>
				<Privacy />
			</Route>

			<Route>
				<Error />
			</Route>
		</Switch>
	);
};

Routing.propTypes = propTypes;
Routing.defaultProps = defaultProps;

export default Routing;
