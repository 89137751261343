import { DEFAULT_EASE, DEFAULT_DURATION } from "@/constants/transition";

const clipPath = {
	hidden: {
		clipPath: "inset(0px 100% 0px 0px)",
		x: "-2%",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION / 4,
			delay: 0,
		},
	},
	show: {
		clipPath: "inset(0px 0% 0px 0px)",
		x: "0%",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
			delay: DEFAULT_DURATION / 4,
		},
	},
};

export default clipPath;
