import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MasterGrid from "rise-grid-framework/src/Grid/GridPlayer";

import { setGridLoading } from "@/store/ui/ui.actions";

import GridContext from "./Grid.context";
import propTypes from "./Grid.propTypes";
import defaultProps from "./Grid.defaultProps";

const Grid = ({ children, canvas }) => {
	const dispatch = useDispatch();
	const [grid, setGrid] = useState(null);

	useEffect(async () => {
		const masterGrid = new MasterGrid(canvas, {
			color: 1,
			background: "transparent",
		});

		await masterGrid.setMasks([]);

		setGrid(masterGrid);
		dispatch(setGridLoading(false));
	}, []);

	return grid && <GridContext.Provider value={grid}>{children}</GridContext.Provider>;
};

Grid.propTypes = propTypes;
Grid.defaultProps = defaultProps;

export default Grid;
