import PropTypes from "prop-types";

const Image = {
	src: PropTypes.string,
	alt: PropTypes.string,
};

const Visual = {
	src: PropTypes.string,
	alt: PropTypes.string,
};

export default {
	Visual,
	Image,
};
