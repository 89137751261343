import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isEmpty, isNil } from "ramda";

import { FILTER_KEYS } from "@/constants/filters";

import { useQuery } from "@/hooks/Router";

import { getAllFilters, setFilter } from "@/store/filters/filters.actions";

import { useFilterIDs, useAllFilters, useActiveFilters } from "@/store/filters/filters.hooks";
import { useEndSession, useIdentity } from "@/store/membership/membership.hooks";
import { useEnabledLanguages } from "@/store/ui/ui.hooks";

export default () => {
	const dispatch = useDispatch();
	const query = useQuery();
	const filterIDs = useFilterIDs();
	const activeFilters = useActiveFilters();
	const { data: allFilters, loading: filtersLoading } = useAllFilters();
	const endSession = useEndSession();
	const languages = useEnabledLanguages();
	const { data: identity } = useIdentity();

	useEffect(() => {
		const category = query.get("default-category") || "";
		const gender = query.get("default-gender") || "";
		const surface = query.get("default-surface") || "";
		const activity = query.get("default-activity") || "";
		let categoryID = "";
		let genderID = "";
		let surfaceID = "";
		let activityID = "";

		if (!isEmpty(activeFilters) && !isEmpty(filterIDs.category)) {
			const enCategories = Object.values(filterIDs.category.find((el) => el.en).en);
			const enGenders = Object.values(filterIDs.gender.find((el) => el.en).en);
			const enSurfaces = Object.values(filterIDs.surface.find((el) => el.en).en);
			const enActivities = Object.values(filterIDs.activity.find((el) => el.en).en);

			if (isNil(activeFilters.category.activeFilter)) {
				if (!filtersLoading && !isEmpty(filterIDs.category) && !isEmpty(category)) {
					categoryID = enCategories.filter((item) => item.val === category)[0]?.id;
				} else categoryID = "";
			} else {
				categoryID = activeFilters.category.activeFilter;
			}
			if (isNil(activeFilters.gender.activeFilter)) {
				if (!filtersLoading && !isEmpty(filterIDs.gender) && !isEmpty(gender)) {
					genderID = enGenders.filter((item) => item.val === gender)[0]?.id;
				} else genderID = "";
			} else {
				genderID = activeFilters.gender.activeFilter;
			}
			if (isNil(activeFilters.surface.activeFilter)) {
				if (!filtersLoading && !isEmpty(filterIDs.surface) && !isEmpty(surface)) {
					surfaceID = enSurfaces.filter((item) => item.val === surface)[0]?.id;
				} else surfaceID = "";
			} else {
				surfaceID = activeFilters.surface.activeFilter;
			}
			if (isNil(activeFilters.activity.activeFilter)) {
				if (!filtersLoading && !isEmpty(filterIDs.activity) && !isEmpty(activity)) {
					activityID = enActivities.filter((item) => item.val === activity)[0]?.id;
				} else activityID = "";
			} else {
				activityID = activeFilters.activity.activeFilter;
			}
		}

		if (isEmpty(identity)) {
			if (!isEmpty(categoryID)) {
				dispatch(setFilter(FILTER_KEYS.CATEGORY, categoryID));
			}
			if (!isEmpty(genderID)) {
				dispatch(setFilter(FILTER_KEYS.GENDER, genderID));
			}
			if (!isEmpty(surfaceID)) {
				dispatch(setFilter(FILTER_KEYS.SURFACE, surfaceID));
			}
			if (!isEmpty(activityID)) {
				dispatch(setFilter(FILTER_KEYS.ACTIVITY, activityID));
			}
		}
	}, [filterIDs, allFilters, endSession]);

	useEffect(() => {
		if (!isEmpty(languages)) dispatch(getAllFilters(languages));
	}, [languages]);
};
