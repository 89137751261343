import React, { useEffect } from "react";
import { pathOr } from "ramda";

import useGrid from "@/hooks/Grid";
import { useLowGPU } from "@/store/ui/ui.hooks";

import technology2Grid from "@/assets/grids/technology_2.json";

import propTypes from "./Technology.propTypes";
import defaultProps from "./Technology.defaultProps";

import {
	ProductGroupingPartialsTitle,
	ProductGroupingPartialsDescription,
	ProductGroupingPartialsBack,
	ProductGroupingPartialsLogo,
	ProductGroupingPartialsImage,
} from "../Partials";

const Technology2 = ({ data = {}, lang }) => {
	const { isGridLoading, start: startGrid, end: endGrid } = useGrid();
	const lowGPU = useLowGPU();

	// #region LIFECYCLE HOOKS
	useEffect(() => {
		endGrid();
		startGrid(technology2Grid);
	}, [isGridLoading]);
	// #endregion LIFECYCLE HOOKS

	// #region RENDER
	return (
		<>
			<ProductGroupingPartialsLogo id="TechnologyRightTop" data={data} lowGPU={lowGPU} />

			<ProductGroupingPartialsDescription id="TechnologyRightBottom" data={data} />

			<ProductGroupingPartialsTitle id="TechnologyRightCenter" data={data} lang={lang} lowGPU={lowGPU} />

			<ProductGroupingPartialsImage
				id="TechnologyLeftPrimary"
				src={pathOr(null, ["assets", 0, "portrait"], data)}
				shouldShowBottom
			/>

			<ProductGroupingPartialsBack id="TechnologyNavA" />

			<ProductGroupingPartialsImage
				id="TechnologyLeftSecondary"
				src={pathOr(null, ["assets", 1, "portrait"], data)}
				shouldShowBottom
				delay={0.15}
			/>

			<ProductGroupingPartialsImage
				id="TechnologyLeftTop"
				src={pathOr(null, ["assets", 2, "portrait"], data)}
				shouldShowBottom
				delay={0.3}
			/>
		</>
	);
	// #endregion RENDER
};

Technology2.propTypes = propTypes;
Technology2.defaultProps = defaultProps;

export default Technology2;
