import React from "react";
import { motion } from "framer-motion";

import { animation, bottomAnimation } from "./PlacePartialsCircle.animations";
import defaultProps from "./PlacePartialsCircle.defaultProps";
import propTypes from "./PlacePartialsCircle.propTypes";

// TODO: temp fix until grid framework covers this
const PlacePartialsCircle = ({ type, animationTrigger }) => {
	const maskId = `${type}CircleMask`;

	const getPath = () => {
		switch (type) {
			case "left":
				return "M-.5,1740c430.78,0,780-349.22,780-780S430.28,180-.5,180";
			case "right":
				return "M960.5,180c-430.78,0-780,349.22-780,780s349.22,780,780,780";
			case "bottom":
				return "M3.71949e-05 1139C-198.822 1139 -360 977.823 -360 779C-360 580.178 -198.823 419 -2.57495e-05 419C198.822 419 360 580.177 360 779C360 977.822 198.823 1139 3.71949e-05 1139Z";
			default:
				return "M-.5,1740c430.78,0,780-349.22,780-780S430.28,180-.5,180";
		}
	};

	return (
		<svg
			width="100%"
			height="100%"
			viewBox={type === "bottom" ? "-276 297 550 482" : "0 0 960 1920"}
			style={{
				position: "absolute",
				top: 0,
				left: 0,
			}}
		>
			<mask id={maskId}>
				<motion.path
					d={getPath()}
					stroke="white"
					strokeWidth={type === "bottom" ? "240" : "360"}
					fill="transparent"
					strokeDasharray="2450"
					strokeDashoffset="-2450"
					initial="hidden"
					animate={animationTrigger}
					variants={type === "bottom" ? bottomAnimation : animation}
				/>
			</mask>

			<defs>
				<linearGradient id="myGradient" gradientTransform="rotate(90)">
					<stop offset="5%" stopColor="var(--accent-1-shade-color)" />
					<stop offset={type === "bottom" ? "35%" : "95%"} stopColor="var(--accent-1-base-color)" />
				</linearGradient>
			</defs>

			<rect
				width="960"
				x={type === "bottom" ? "-480" : "0"}
				height="1920"
				mask={`url(#${maskId})`}
				fill="url('#myGradient')"
			/>
		</svg>
	);
};

PlacePartialsCircle.defaultProps = defaultProps;
PlacePartialsCircle.propTypes = propTypes;

export default PlacePartialsCircle;
