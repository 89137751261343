const SliderNavigation = {
	slides: 6,
	step: 1,
	speed: 3,
	setCurrentSlide: null,
};

const SliderNavigationItem = {
	i: 0,
	step: 1,
	speed: 3,
	setCurrentSlide: null,
};

export default {
	SliderNavigation,
	SliderNavigationItem,
};
