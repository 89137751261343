// The number acts as a priority (which recommendations willl appear first in the
// recommendations array - only one recommmendation appears on the front-end),
// and also as a randomizer to remove recommendations from shoes (1 of X will
// receive the recommendation).

const RECOMMENDATION_PRIORITIES = {
	loggedActivity: 3,
	loggedTrainingPerWeek: 1,
	preferTraining: 1,
	recentAudioRun: 3,
	runTimesPerWeek: 1,
	runDistancePerWeek: 1,
	avgPace: 1,
	fastestPace: 1,
	runEveryWeek: 1,
	consecutiveWeeks: 1,
	boughtFranchise: 1,
	boughtCategory: 1,
	boughtKingdom: 4,
	boughtSameColor: 3,
	boughtSameShoe: 1,
	boughtSameShoeDiffColor: 1,
	achievements: 10,
	fallbacks: 20,
};
export default RECOMMENDATION_PRIORITIES;
