import i18n from "@/i18n";
import { find, propEq, filter, isNil, isEmpty } from "ramda";

import { sentryLogException } from "@/utils/sentry";

import {
	CATEGORY_FILTERS,
	GENDER_FILTER_ORDER,
	RUNNING_SURFACE_FILTERS,
	FOOTBALL_SURFACE_FILTERS,
} from "@/constants/filters";
import TAXONOMY_PRIORITIES from "@/constants/taxonomy_priorities";

import FiltersActionTypes from "./filters.action-types";

// eslint-disable-next-line import/prefer-default-export
export const getAllFilters = (languages) => (dispatch) => {
	dispatch({
		type: FiltersActionTypes.GET_ALL,
	});

	if (!isEmpty(languages)) {
		fetch(`${process.env.REACT_APP_API_URL}/taxonomy?lang=all&sourcePaths=consumer_product.taxonomyAttributes`)
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error ${response.status}`);
				}

				return response.json();
			})
			.then((data) => {
				const filters = {};

				const structure = {
					gender: {},
					kingdom: {},
					category: {},
					designed_for: {},
					surface: {},
					activities: [],
				};

				// Setup gender filters
				let genderFilters = [];
				const genderID = find(propEq("val", "Gender"), data)?.id;
				if (!isNil(genderID)) {
					genderFilters = filter((item) => item.parent_id === genderID, data);
				}
				// build sorted array of genders in english
				let enSortedGenders = [];
				for (let i = 0; i < GENDER_FILTER_ORDER.length; i += 1) {
					enSortedGenders.push(genderFilters.find((el) => el.val === GENDER_FILTER_ORDER[i]));
				}
				enSortedGenders = enSortedGenders.filter((el) => el !== undefined);
				let langGenders = filter((item) => item.lang === i18n.language, genderFilters);
				if (isEmpty(langGenders)) langGenders = filter((item) => item.lang === "en", genderFilters);
				let langSortedGenders = [];
				for (let i = 0; i < enSortedGenders.length; i += 1) {
					langSortedGenders.push(langGenders.find((el) => el.id === enSortedGenders[i].id));
				}
				langSortedGenders = langSortedGenders.filter((el) => el !== undefined);

				filters.gender = {
					label: i18n.t("filters.gender"),
					options: langSortedGenders.map((option) => ({
						id: option.id,
						label: option.val,
					})),
				};

				// Setup category filters
				let categoryFilters = [];
				const categoryID = find(propEq("val", "Sports"), data)?.id;
				const lifestyleID = find(propEq("val", "Lifestyle"), data)?.id;
				const footballID = find(propEq("val", "Soccer"), data)?.id;
				let langData = data.filter((item) => item.lang === i18n.language);
				if (isEmpty(langData)) langData = data.filter((item) => item.lang === "en");
				const lifestyle = find(propEq("id", lifestyleID), langData);
				if (!isNil(categoryID)) {
					categoryFilters = filter((item) => item.parent_id === categoryID, data);
				}
				// build sorted array of categories in english
				let enSortedCategories = [];
				for (let i = 0; i < CATEGORY_FILTERS.length; i += 1) {
					enSortedCategories.push(categoryFilters.find((el) => el.val === CATEGORY_FILTERS[i]));
				}
				enSortedCategories = enSortedCategories.filter((el) => el !== undefined);
				let langCategories = filter((item) => item.lang === i18n.language, categoryFilters);
				if (isEmpty(langCategories)) langCategories = filter((item) => item.lang === "en", categoryFilters);
				let langSortedCategories = [];
				for (let i = 0; i < enSortedCategories.length; i += 1) {
					langSortedCategories.push(langCategories.find((el) => el.id === enSortedCategories[i].id));
				}
				langSortedCategories = langSortedCategories.filter((el) => el !== undefined);
				filters.category = {
					label: i18n.t("filters.category"),
					options: langSortedCategories.map((option) => ({
						id: option.id,
						label: option.val,
					})),
				};

				// Setup surface filters
				let surfaceFilters = [];
				const surfaceID = find(propEq("val", "Surface"), data)?.id;
				if (!isNil(surfaceID)) {
					surfaceFilters = filter((item) => item.parent_id === surfaceID, data);
				}
				// separate out running and football surfaces
				let enRunningSurfaces = [];
				for (let i = 0; i < RUNNING_SURFACE_FILTERS.length; i += 1) {
					enRunningSurfaces.push(surfaceFilters.find((el) => el.val === RUNNING_SURFACE_FILTERS[i]));
				}
				enRunningSurfaces = enRunningSurfaces.filter((el) => el !== undefined);
				let langSurfaces = filter((item) => item.lang === i18n.language, surfaceFilters);
				if (isEmpty(langSurfaces)) langSurfaces = filter((item) => item.lang === "en", surfaceFilters);
				let langRunningSurfaces = [];
				for (let i = 0; i < enRunningSurfaces.length; i += 1) {
					langRunningSurfaces.push(langSurfaces.find((el) => el.id === enRunningSurfaces[i].id));
				}
				langRunningSurfaces = langRunningSurfaces.filter((el) => el !== undefined);
				filters.runningSurfaces = langRunningSurfaces;

				let enFootballSurfaces = [];
				for (let i = 0; i < FOOTBALL_SURFACE_FILTERS.length; i += 1) {
					enFootballSurfaces.push(surfaceFilters.find((el) => el.val === FOOTBALL_SURFACE_FILTERS[i]));
				}
				enFootballSurfaces = enFootballSurfaces.filter((el) => el !== undefined);
				let langFootballSurfaces = [];
				for (let i = 0; i < enFootballSurfaces.length; i += 1) {
					langFootballSurfaces.push(langSurfaces.find((el) => el.id === enFootballSurfaces[i].id));
				}
				langFootballSurfaces = langFootballSurfaces.filter((el) => el !== undefined);
				filters.footballSurfaces = langFootballSurfaces;

				filters.surface = {
					label: i18n.t("filters.surface"),
					options: langSurfaces.map((option) => ({
						id: option.id,
						label: option.val,
					})),
				};

				// Setup run type filters
				const everydayID = find(propEq("val", "Everyday Run"), data)?.id;

				// Setup Athletics filters
				let athleticsFilters = [];
				const athleticsID = find(propEq("val", "Athletics"), data)?.id;
				if (!isNil(athleticsID)) {
					athleticsFilters = filter((item) => item.parent_id === athleticsID, data);
				}
				let langAthleticsFilters = filter((item) => item.lang === i18n.language, athleticsFilters);
				if (isEmpty(langAthleticsFilters))
					langAthleticsFilters = filter((item) => item.lang === "en", athleticsFilters);
				filters.athletics = {
					label: i18n.t("filters.athletics"),
					options: langAthleticsFilters.map((option) => ({
						id: option.id,
						label: option.val,
					})),
				};

				// Setup run type filters
				let activityFilters = [];
				const activityID = find(propEq("val", "Training & Gym"), data)?.id;
				if (!isNil(activityID)) {
					activityFilters = filter((item) => item.parent_id === activityID, data);
				}
				let langActivityFilters = filter((item) => item.lang === i18n.language, activityFilters);
				if (isEmpty(langActivityFilters))
					langActivityFilters = filter((item) => item.lang === "en", activityFilters);
				filters.activity = {
					label: i18n.t("filters.activity"),
					options: langActivityFilters.map((option) => ({
						id: option.id,
						label: option.val,
					})),
				};

				const langs = languages.map((lang) => lang.CODE);
				if (!langs.includes("en")) langs.push("en");
				structure.category = langs.map((lang) => ({
					[lang]: { ...filter((item) => item.lang === lang, categoryFilters) },
				}));
				structure.gender = langs.map((lang) => ({
					[lang]: { ...filter((item) => item.lang === lang, genderFilters) },
				}));
				structure.surface = langs.map((lang) => ({
					[lang]: { ...filter((item) => item.lang === lang, surfaceFilters) },
				}));
				structure.activity = langs.map((lang) => ({
					[lang]: { ...filter((item) => item.lang === lang, activityFilters) },
				}));
				structure.categoryID = categoryID;
				structure.surfaceID = surfaceID;
				structure.athleticsID = athleticsID;
				structure.everydayID = everydayID;
				structure.footballID = footballID;
				structure.danceID = find(propEq("val", "Dance"), data)?.id;
				structure.lifestyleVal = lifestyle?.val;
				structure.lifestyleID = lifestyle?.id;
				structure.runningID = find(propEq("val", "Running"), data)?.id;
				structure.trainingID = find(propEq("val", "Training & Gym"), data)?.id;
				structure.prioritizedTaxonomyIDs = TAXONOMY_PRIORITIES.reduce((result, val) => {
					const taxonomy = find(propEq("val", val), data);
					if (taxonomy && taxonomy.id) {
						result.push(taxonomy.id);
					}

					return result;
				}, []);

				// Set filter data for dropdowns/ui
				dispatch({ type: FiltersActionTypes.GET_ALL_SUCCESS, data: filters });
				// Set filter IDs to match on shoes
				dispatch({ type: FiltersActionTypes.GET_FILTER_IDS_SUCCESS, data: structure });
			})
			.catch((error) => {
				sentryLogException("Shoe Filters", error);
				dispatch({
					type: FiltersActionTypes.GET_ALL_ERROR,
					error,
				});
			});
	}
};

export const setFilter =
	(key, filterId, runningID = null) =>
	(dispatch) => {
		dispatch({
			type: FiltersActionTypes.SET_FILTER,
			data: filterId,
			runningID,
			key,
		});
	};

export const clearActiveFilters = () => (dispatch) => {
	dispatch({
		type: FiltersActionTypes.CLEAR_ACTIVE_FILTERS,
	});
};
