import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { isNil, isEmpty } from "ramda";

import getAvailability from "@/utils/data/getAvailability";

import { SIDE } from "@/constants/rfid-tags";

import Typography from "@/components/Typography";
import Status from "@/components/Status";

import { useQtyLeft, useQtyRight, usePriceLeft, usePriceRight } from "@/store/shoes/shoes.hooks";
import { useStoreID } from "@/store/ui/ui.hooks";
import useActiveShoe from "@/hooks/ActiveShoe";

import AVAILABILITY from "@/constants/availability";
import CURRENCIES from "@/constants/currencies";

const RawAvailabilityStatus = ({ side, qtyRight, qtyLeft }) => {
	const { t } = useTranslation();

	const { left, right } = useActiveShoe();

	let availability = null;
	if (side === SIDE.Right && !isNil(right) && !isNil(qtyRight)) {
		availability = qtyRight;
	} else if (side === SIDE.Left && !isNil(left) && !isNil(qtyLeft)) {
		availability = qtyLeft;
	}

	if (isEmpty(availability)) return null;
	availability = getAvailability(availability);

	return (
		<Status
			label={t(`status.${[availability]}`)}
			warning={availability === AVAILABILITY.LIMITED}
			error={availability === AVAILABILITY.NONE}
			size="smmmd"
		/>
	);
};

const AvailabilityStatus = React.memo(RawAvailabilityStatus);

const RawAvailabilityBlock = ({ className, side, showStock, showPrice }) => {
	const { t } = useTranslation();
	const [price, setPrice] = useState(null);
	const priceLeft = usePriceLeft();
	const priceRight = usePriceRight();
	const qtyLeft = useQtyLeft();
	const qtyRight = useQtyRight();
	const { data: storeID } = useStoreID();
	const { left, right } = useActiveShoe();

	let title = null;
	let availability = null;
	let text = "";

	if (side === SIDE.Right && !isNil(right) && !isNil(qtyRight)) {
		availability = qtyRight;
	} else if (side === SIDE.Left && !isNil(left) && !isNil(qtyLeft)) {
		availability = qtyLeft;
	}

	useEffect(() => {
		text = "";
		if (side === SIDE.Right) {
			if (!isEmpty(storeID) && !isNil(storeID) && !isEmpty(priceRight))
				text = `${CURRENCIES[storeID.currencies[0]]}${priceRight}`;
			setPrice(text);
		} else if (side === SIDE.Left) {
			if (!isEmpty(storeID) && !isNil(storeID) && !isEmpty(priceLeft))
				text = `${CURRENCIES[storeID.currencies[0]]}${priceLeft}`;
			setPrice(text);
		}
	}, [qtyLeft, qtyRight, priceLeft, priceRight]);

	return (
		<div className={className}>
			{showPrice && !isEmpty(price) && (
				<Typography className="u-mr-10" size="smmmd">
					{price}
				</Typography>
			)}

			{showStock && <AvailabilityStatus side={side} qtyRight={qtyRight} qtyLeft={qtyLeft} />}
		</div>
	);
};

const ProductAvailabilityPrice = React.memo(RawAvailabilityBlock);

export default ProductAvailabilityPrice;
