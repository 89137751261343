const ShoesActionTypes = {
	GET_ALL: "@@shoes/GET_ALL",
	GET_ALL_SUCCESS: "@@shoes/GET_ALL_SUCCESS",
	GET_ALL_ERROR: "@@shoes/GET_ALL_ERROR",

	GET_SHOE_BY_SCC: "@@shoes/GET_SHOE_BY_SCC",
	GET_SHOE_BY_SCC_SUCCESS: "@@shoes/GET_SHOE_BY_SCC_SUCCESS",
	GET_SHOE_BY_SCC_ERROR: "@@shoes/GET_SHOE_BY_SCC_ERROR",

	GET_SCC_BY_EPC: "@@shoes/GET_SCC_BY_EPC",
	GET_SCC_BY_EPC_SUCCESS: "@@shoes/GET_SCC_BY_EPC_SUCCESS",
	GET_SCC_BY_EPC_ERROR: "@@shoes/GET_SCC_BY_EPC_ERROR",

	GET_SHOE_BY_EPC: "@@shoes/GET_SHOE_BY_EPC",
	GET_SHOE_BY_EPC_SUCCESS: "@@shoes/GET_SHOE_BY_EPC_SUCCESS",
	GET_SHOE_BY_EPC_ERROR: "@@shoes/GET_SHOE_BY_EPC_ERROR",

	GET_LOCATION: "@@shoes/GET_LOCATION",
	GET_LOCATION_SUCCESS: "@@shoes/GET_LOCATION_SUCCESS",
	GET_LOCATION_ERROR: "@@shoes/GET_LOCATION_ERROR",

	GET_INVENTORY: "@@shoes/GET_INVENTORY",
	GET_INVENTORY_SUCCESS: "@@shoes/GET_INVENTORY_SUCCESS",
	GET_INVENTORY_ERROR: "@@shoes/GET_INVENTORY_ERROR",

	GET_PRICE: "@@shoes/GET_PRICE",
	GET_PRICE_SUCCESS: "@@shoes/GET_PRICE_SUCCESS",
	GET_PRICE_ERROR: "@@shoes/GET_PRICE_ERROR",

	GET_IMAGEMAP: "@@shoes/GET_IMAGEMAP",
	GET_IMAGEMAP_SUCCESS: "@@shoes/GET_IMAGEMAP_SUCCESS",
	GET_IMAGEMAP_ERROR: "@@shoes/GET_IMAGEMAP_ERROR",

	SET_ACTIVE: "@@shoes/SET_ACTIVE",

	UPDATE_VISIBLE: "@@shoes/UPDATE_VISIBLE",
	UPDATE_DIGITAL: "@@shoes/UPDATE_DIGITAL",
	UPDATE_TEMPLATE: "@@shoes/UPDATE_TEMPLATE",

	CLEAR_ACTIVE: "@@shoes/CLEAR_ACTIVE",
	CLEAR_QTY: "@@shoes/CLEAR_QTY",
	CLEAR_PRICE: "@@shoes/CLEAR_PRICE",
};

export default ShoesActionTypes;
