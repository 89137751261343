import { DEFAULT_EASE, DEFAULT_DURATION } from "@/constants/transition";

const rotateInfinite = {
	show: {
		rotate: 360,
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION * 3,
			repeat: "Infinity",
		},
	},
};

export default rotateInfinite;
