import React, { memo } from "react";
import cn from "classnames";

import { useQuery } from "@/hooks/Router";

import Typography from "@/components/Typography";

import propTypes from "./Status.propTypes";
import defaultProps from "./Status.defaultProps";

// =============================================================================
// Root
// =============================================================================
const Status = ({ className = "", warning, error, label, size = "sm" }) => {
	const query = useQuery();

	if (query.get("show-stock") !== "true") {
		return <></>;
	}

	return (
		<div
			className={cn(className, "c-status", {
				"c-status--warning": warning,
				"c-status--error": error,
			})}
		>
			<div className="c-status__icon" />
			<Typography className="c-status__label" size={size}>
				{label}
			</Typography>
		</div>
	);
};

Status.propTypes = propTypes;
Status.defaultProps = defaultProps;

export default memo(Status);
