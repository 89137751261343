import { DEFAULT_EASE, DEFAULT_DURATION } from "@/constants/transition";

export const fade = {
	hidden: {
		opacity: 0,
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION / 2,
		},
	},

	show: {
		opacity: 1,
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION / 2,
		},
	},
};

export const getFade = (options) => ({
	...fade,
	hidden: {
		...fade.hidden,
		transition: {
			...fade.hidden.transition,
			...options,
		},
	},
	show: {
		...fade.show,
		transition: {
			...fade.show.transition,
			...options,
		},
	},
});
