import React, { useRef, useEffect, useState, Fragment } from "react";
import cn from "classnames";

import Typography from "@/components/Typography";

import getTitleParts from "@/utils/data/getTitleParts";
import { toTitleCase } from "@/utils/conversions";

import propTypes from "./TitleSplit.propTypes";
import defaultProps from "./TitleSplit.defaultProps";
import { useQuery } from "@/hooks/Router";

// =============================================================================
// Root
// =============================================================================
const TitleSplit = ({
	classNameWrapper = "",
	className = "",
	children = "",
	tag,
	size,
	lineHeightSize,
	weight,
	typeStyle,
	primary,
	uppercase = null,
}) => {
	const query = useQuery();

	const titleWrapperRef = useRef(null);
	const titleRef = useRef(null);
	const [parts, setParts] = useState([]);

	const qForm = query.get("form") || "rise";
	const hasPartsSet = parts.length > 0;

	useEffect(() => {
		const titleWrapperWidth = titleWrapperRef.current.offsetWidth;
		const titleWidth = titleRef.current.offsetWidth;

		const titleParts = getTitleParts(toTitleCase(children));
		setParts(titleParts);
	}, [children]);

	return (
		<div
			className={cn(classNameWrapper, {
				"is-hidden": !hasPartsSet,
			})}
			ref={titleWrapperRef}
		>
			<Typography
				primary={primary}
				uppercase={uppercase ?? qForm === "rise"}
				weight={weight}
				size={size}
				lineHeightSize={lineHeightSize}
				tag={tag}
				className={cn(className)}
				ref={titleRef}
				typeStyle={typeStyle}
			>
				{hasPartsSet
					? parts.map((part, index) => {
							const isLast = index === parts.length - 1;

							return isLast ? (
								part
							) : (
								// eslint-disable-next-line react/no-array-index-key
								<Fragment key={`title-split-${index}`}>
									{part}

									<br />
								</Fragment>
							);
					  })
					: children}
			</Typography>
		</div>
	);
};

TitleSplit.propTypes = propTypes;
TitleSplit.defaultProps = defaultProps;

export default TitleSplit;
