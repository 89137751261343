import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import Icon from "@/components/Icon";

import propTypes from "./ButtonIcon.propTypes";
import defaultProps from "./ButtonIcon.defaultProps";

// =============================================================================
// Root
// =============================================================================
const ButtonIcon = ({ className = "", type = "", icon, onClick, url }) => {
	if (url) {
		return (
			<Link to={url} className={cn("c-button-icon", className)}>
				<Icon className={cn(className)} type={icon} />
			</Link>
		);
	}

	if (onClick) {
		return (
			// eslint-disable-next-line react/button-has-type
			<button type={type} onClick={onClick} className={cn("c-button-icon", className)}>
				<Icon className={cn(className)} type={icon} />
			</button>
		);
	}

	return (
		<div className={cn("c-button-icon", className)}>
			<Icon className={cn(className)} type={icon} />
		</div>
	);
};

ButtonIcon.propTypes = propTypes.Button;
ButtonIcon.defaultProps = defaultProps.Button;

export default ButtonIcon;
