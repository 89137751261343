const ENABLE_TAG_EVENT_LOGGING = process.env.REACT_APP_ENABLE_TAG_EVENT_LOGGING === "true";

export const initTagEventLogging = () => {
	window.ENABLE_TAG_EVENT_LOGGING = ENABLE_TAG_EVENT_LOGGING;
};

export const canLogTagEvents = () => window.ENABLE_TAG_EVENT_LOGGING;

export const logEvent = (eventName, id = null, bgColor = "grey") => {
	const style = `background-color: ${bgColor}; color: white; font-weight: bold; padding: 4px`;
	const eventId = id ? ` => ${id}` : "";

	// eslint-disable-next-line no-console
	console.log(`%c EVENT TRIGGER: ${eventName}${eventId}`, style);
};
