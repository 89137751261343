import { isEmpty, isNil, pathOr, find } from "ramda";

export default (shoes, filters, activeFilters) => {
	const filterKeys = Object.keys(filters);

	const filteredShoes = shoes.filter((shoe) => {
		// Filter out shoes without thumbnail
		if (isNil(shoe?.thumbnail) || isEmpty(shoe.thumbnail)) {
			return false;
		}

		const currentFilters = [];
		for (let index = 0; index < filterKeys.length; index += 1) {
			const key = filterKeys[index];
			const filterId = pathOr(null, [key, "activeFilter"], activeFilters);
			const shoeFilters = pathOr(null, ["filters"], shoe);

			if (isNil(filterId) || isEmpty(filterId)) {
				currentFilters.push(true);
				continue; // eslint-disable-line no-continue
			}

			if (key === "gender") {
				// all unisex categoried shoes are true
				currentFilters.push(Boolean(find((el) => el === filterId, shoeFilters)));
				continue; // eslint-disable-line no-continue
			}

			if (key === "category" && filterId === "recommended" && shoe.recommendations.length > 0) {
				currentFilters.push(true);
				continue; // eslint-disable-line no-continue
			}

			currentFilters.push(Boolean(find((el) => el === filterId, shoeFilters)));
		}

		return currentFilters.every((activeFilter) => activeFilter === true);
	});

	return filteredShoes;
};
