import MembershipActionTypes from "./membership.action-types";
import MembershipInitialState from "./membership.initial-state";

const MembershipReducer = (state = MembershipInitialState(), action) => {
	switch (action.type) {
		default: {
			return state;
		}

		case MembershipActionTypes.GET_IDENTITY:
		case MembershipActionTypes.POST_PRIVACY: {
			return {
				...state,
				identity: {
					...state.identity,
					loading: true,
					error: null,
				},
			};
		}

		case MembershipActionTypes.GET_IDENTITY_SUCCESS: {
			return {
				...state,
				identity: {
					...state.identity,
					data: action.data,
					loading: false,
					error: null,
				},
			};
		}

		case MembershipActionTypes.POST_PRIVACY_SUCCESS: {
			return {
				...state,
				identity: {
					...state.identity,
					data: {
						...state.identity.data,
						profile: {
							...state.identity.data.profile,
							healthdata: {
								...state.identity.data.profile.healthdata,
								enhancedAcceptance: action.data,
							},
						},
					},
					loading: false,
					error: null,
				},
			};
		}

		case MembershipActionTypes.GET_IDENTITY_ERROR:
		case MembershipActionTypes.POST_PRIVACY_ERROR: {
			return {
				...state,
				identity: {
					...state.identity,
					loading: false,
					error: action.error,
				},
			};
		}

		case MembershipActionTypes.CLEAR_IDENTITY_ERROR: {
			return {
				...state,
				identity: {
					...state.identity,
					error: null,
				},
			};
		}

		case MembershipActionTypes.CLEAR_IDENTITY: {
			return {
				...state,
				identity: {
					...state.identity,
					data: {},
					error: null,
				},
				wishlistProducts: {
					...state.wishlistProducts,
					data: {},
					error: null,
				},
				wishlists: {
					...state.wishlists,
					data: {},
					error: null,
				},
			};
		}

		case MembershipActionTypes.GET_STYLES: {
			return {
				...state,
				styles: {
					...state.styles,
					loading: true,
					error: null,
				},
			};
		}

		case MembershipActionTypes.GET_STYLES_SUCCESS: {
			return {
				...state,
				styles: {
					...state.styles,
					data: action.data,
					loading: false,
					error: null,
				},
			};
		}

		case MembershipActionTypes.GET_STYLES_ERROR: {
			return {
				...state,
				styles: {
					...state.styles,
					loading: false,
					error: action.error,
				},
			};
		}

		case MembershipActionTypes.GET_WISHLISTS: {
			return {
				...state,
				wishlists: {
					...state.wishlists,
					loading: true,
					error: null,
				},
			};
		}

		case MembershipActionTypes.GET_WISHLISTS_SUCCESS: {
			return {
				...state,
				wishlists: {
					...state.wishlists,
					data: action.data,
					loading: false,
					error: null,
				},
			};
		}

		case MembershipActionTypes.GET_WISHLISTS_ERROR: {
			return {
				...state,
				wishlists: {
					...state.wishlists,
					loading: false,
					error: action.error,
				},
			};
		}

		case MembershipActionTypes.GET_WISHLIST_PRODUCTS: {
			return {
				...state,
				wishlistProducts: {
					...state.wishlistProducts,
					loading: true,
					error: null,
				},
			};
		}

		case MembershipActionTypes.GET_WISHLIST_PRODUCTS_SUCCESS: {
			return {
				...state,
				wishlistProducts: {
					...state.wishlistProducts,
					data: action.data,
					loading: false,
					error: null,
				},
			};
		}

		case MembershipActionTypes.GET_WISHLIST_PRODUCTS_ERROR: {
			return {
				...state,
				wishlistProducts: {
					...state.wishlistProducts,
					loading: false,
					error: action.error,
				},
			};
		}

		case MembershipActionTypes.SET_MEMBERSHIP_ACTIVE: {
			return {
				...state,
				membershipActive: action.data,
			};
		}

		case MembershipActionTypes.SET_SIMULATOR_ACTIVE: {
			return {
				...state,
				simulatorActive: action.data,
			};
		}

		case MembershipActionTypes.SET_MENU_MINIMIZED: {
			return {
				...state,
				menuMinimized: action.data,
			};
		}

		case MembershipActionTypes.SET_PRIVACY: {
			return {
				...state,
				privacy: action.data,
			};
		}

		case MembershipActionTypes.SET_END_SESSION: {
			return {
				...state,
				endSession: action.data,
			};
		}

		case MembershipActionTypes.SET_SEEN_WELCOME: {
			return {
				...state,
				seenWelcome: action.data,
			};
		}

		case MembershipActionTypes.SET_WELCOME_RAIL: {
			return {
				...state,
				welcomeRail: action.data,
			};
		}

		case MembershipActionTypes.SET_SHOW_STATS: {
			return {
				...state,
				showStats: action.data,
			};
		}
	}
};

export default MembershipReducer;
