import React, { memo, useState, useEffect } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

import Icon from "@/components/Icon";
import HighlightMembership from "@/components/HighlightMembership";
import Typography from "@/components/Typography";

import { useQuery } from "@/hooks/Router";
import { find, propEq } from "ramda";

// =============================================================================
// Root
// =============================================================================
const MembershipMenuIdentityStats = ({ name = "", nrc, duration = 10, delay = 0 }) => {
	const { t } = useTranslation();
	const query = useQuery();

	const [hideHighlights, setHideHighlights] = useState(false);
	const [hideMarquee, setHideMarquee] = useState(false);
	const [showIcons, setShowIcons] = useState(false);

	const qForm = query.get("form") || "rise";

	const direction = qForm === "vertical" ? "vh" : "vw";
	const delayMili = delay * 1000;
	const durationMili = duration * 1000;

	const size = qForm === "vertical" ? 2 : 1.4067;
	const grid = [0, size, size * 2, size * 3, size * 4, size * 5, size * 6];
	const keyframes = [];
	for (let i = 0; i <= 1; i += 0.02) {
		keyframes.push(i.toFixed(2));
	}
	const clipPathDefault = [
		"inset(0px 100% 0px 0px)",
		"inset(0px 80% 0px 0px)",
		"inset(0px 60% 0px 0px)",
		"inset(0px 40% 0px 0px)",
		"inset(0px 20% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 20% 0px 0px)",
		"inset(0px 40% 0px 0px)",
		"inset(0px 60% 0px 0px)",
		"inset(0px 80% 0px 0px)",
		"inset(0px 100% 0px 0px)",
	];

	useEffect(() => {
		const highlightTimer = setTimeout(() => {
			setHideHighlights(!hideHighlights);
		}, durationMili + delayMili - 400);
		const marqueeTimer = setTimeout(() => {
			setHideMarquee(!hideMarquee);
		}, durationMili + delayMili);
		const iconsTimer = setTimeout(() => {
			setShowIcons(true);
		}, delayMili + 350);
		const iconsOffTimer = setTimeout(() => {
			setShowIcons(false);
		}, durationMili + durationMili - 1000);

		return () => {
			clearTimeout(highlightTimer);
			clearTimeout(marqueeTimer);
			clearTimeout(iconsTimer);
			clearTimeout(iconsOffTimer);
		};
	}, []);

	return (
		<div style={{ maxWidth: "100vw" }}>
			{!hideMarquee && (
				<Marquee autoFill speed={75}>
					<motion.div
						className="u-flex u-ai-end u-ml-1"
						initial={{
							clipPath: "inset(0px 100% 0px 0px)",
						}}
						animate={{
							clipPath: clipPathDefault,
							transition: {
								duration: duration - 0.5,
								ease: [0, 1, 0, 1],
								delay,
							},
						}}
					>
						<Typography
							primary
							uppercase
							size={qForm === "vertical" ? "2lg" : "smmd"}
							lineHeightSize={qForm === "vertical" ? "3md" : null}
							tag="h2"
						>
							{t("membership.welcome")}
						</Typography>
					</motion.div>
					<div className="u-flex u-ai-end">
						<HighlightMembership
							className={cn("s-dark u-ml-1 no-absolute", { hidden: hideHighlights })}
							title={name}
							delay={delay + 0.3}
							endDelay={duration - 3.5}
							typo={
								qForm === "vertical"
									? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
									: null
							}
							grid={grid}
							end="block"
							initial={[0, 0]}
							animate={[0, 0]}
							clipPath={clipPathDefault}
						/>
					</div>
					<motion.div
						className={cn("u-flex u-ai-end u-ml-1", { hidden: !showIcons })}
						initial={{
							y: `${grid[1]}${direction}`,
						}}
					>
						<Icon
							className={
								qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
							}
							type="clock"
						/>
					</motion.div>
					<div className="u-flex u-ai-end">
						<HighlightMembership
							className={cn("s-dark u-ml-1 no-absolute", { hidden: hideHighlights })}
							title={t("membership.avg_pace")}
							delay={delay + 0.1}
							endDelay={duration - 3}
							typo={
								qForm === "vertical"
									? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
									: null
							}
							grid={grid}
							end="block"
							initial={[0, 1]}
							animate={[0, 1]}
							clipPath={clipPathDefault}
						/>
					</div>
					<motion.div
						className="u-flex u-ai-end u-ml-1"
						initial={{
							clipPath: "inset(0px 100% 0px 0px)",
							y: `${grid[1]}${direction}`,
						}}
						animate={{
							clipPath: clipPathDefault,
							transition: {
								duration: duration - 2,
								ease: [0, 1, 0, 1],
								delay: delay + 0.5,
							},
						}}
					>
						<Typography
							primary
							uppercase
							size={qForm === "vertical" ? "2lg" : "smmd"}
							lineHeightSize={qForm === "vertical" ? "3md" : null}
							tag="h2"
						>
							{nrc[0]?.maxes.find((el) => el.metric === "pace")?.value.toFixed(2)}
						</Typography>
					</motion.div>
					<motion.div
						className={cn("u-flex u-ai-end u-ml-1", { hidden: !showIcons })}
						initial={{
							y: `${grid[0]}${direction}`,
						}}
					>
						<Icon
							className={
								qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
							}
							type="running-shoe-black"
						/>
					</motion.div>
					<motion.div
						className="u-flex u-ai-end u-ml-1"
						initial={{
							clipPath: "inset(0px 100% 0px 0px)",
						}}
						animate={{
							clipPath: clipPathDefault,
							transition: {
								duration: duration - 0.5,
								ease: [0, 1, 0, 1],
								delay,
							},
						}}
					>
						<Typography
							primary
							uppercase
							size={qForm === "vertical" ? "2lg" : "smmd"}
							lineHeightSize={qForm === "vertical" ? "3md" : null}
							tag="h2"
						>
							{t("membership.miles_ran")}
						</Typography>
					</motion.div>
					<div className="u-flex u-ai-end">
						<HighlightMembership
							className={cn("s-dark u-ml-1 no-absolute", { hidden: hideHighlights })}
							title={nrc[0]?.maxes.find((el) => el.metric === "distance")?.value.toFixed(0)}
							delay={delay + 0.3}
							endDelay={duration - 3.5}
							typo={
								qForm === "vertical"
									? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
									: null
							}
							grid={grid}
							end="block"
							initial={[0, 0]}
							animate={[0, 0]}
							clipPath={clipPathDefault}
						/>
					</div>
					<motion.div
						className={cn("u-flex u-ai-end u-ml-1", { hidden: !showIcons })}
						initial={{
							y: `${grid[1]}${direction}`,
						}}
					>
						<Icon
							className={
								qForm === "vertical" ? "c-icon-membership--size-member" : "c-icon-membership--size-9"
							}
							type="elevation"
						/>
					</motion.div>
					<div className="u-flex u-ai-end">
						<HighlightMembership
							className={cn("s-dark u-ml-1 no-absolute", { hidden: hideHighlights })}
							title={t("membership.avg_elevation")}
							delay={delay + 0.1}
							endDelay={duration - 3}
							typo={
								qForm === "vertical"
									? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
									: null
							}
							grid={grid}
							end="block"
							initial={[0, 1]}
							animate={[0, 1]}
							clipPath={clipPathDefault}
						/>
					</div>
					<motion.div
						className="u-flex u-ai-end u-ml-1"
						initial={{
							clipPath: "inset(0px 100% 0px 0px)",
							y: `${grid[1]}${direction}`,
						}}
						animate={{
							clipPath: clipPathDefault,
							transition: {
								duration: duration - 2,
								ease: [0, 1, 0, 1],
								delay: delay + 0.5,
							},
						}}
					>
						<Typography
							primary
							uppercase
							size={qForm === "vertical" ? "2lg" : "smmd"}
							lineHeightSize={qForm === "vertical" ? "3md" : null}
							tag="h2"
						>
							{nrc[0]?.maxes.find((el) => el.metric === "ascent")?.value.toFixed(0)}
						</Typography>
					</motion.div>
				</Marquee>
			)}
		</div>
	);
};

export default memo(MembershipMenuIdentityStats);
