export const WL_REGIONS = {
	EU: [
		"AT",
		"GB",
		"DK",
		"FI",
		"FR",
		"DE",
		"IE",
		"IT",
		"LU",
		"SE",
		"ES",
		"NL",
		"BE",
		"CZ",
		"GR",
		"HU",
		"PL",
		"PT",
		"SI",
	],
	CN: ["CN", "HK"],
	JP: ["JP"],
	MX: ["MX"],
	KR: ["KR"],
	US: ["US"],
	XP: [
		"CH",
		"NO",
		"CA",
		"BG",
		"CL",
		"SA",
		"TR",
		"AE",
		"RU",
		"PR",
		"IL",
		"SK",
		"HR",
		"RO",
		"EG",
		"MA",
		"ZA",
		"AU",
		"NZ",
		"PE",
		"UA",
		"BY",
		"KZ",
		"EC",
		"RS",
		"DZ",
		"CR",
		"LT",
		"EE",
		"LV",
		"MO",
		"AR",
		"NG",
		"KE",
		"QA",
		"MK",
		"BA",
		"AL",
	],
	XA: ["IN", "TW", "SG", "MY", "TH", "VN", "ID", "PH"],
};

export const WL_COUNTRIES = {
	AT: "EU",
	GB: "EU",
	DK: "EU",
	FI: "EU",
	FR: "EU",
	DE: "EU",
	IE: "EU",
	IT: "EU",
	LU: "EU",
	SE: "EU",
	US: "US",
	ES: "EU",
	NL: "EU",
	BE: "EU",
	CZ: "EU",
	GR: "EU",
	HU: "EU",
	PL: "EU",
	PT: "EU",
	SI: "EU",
	CN: "CN",
	JP: "JP",
	HK: "CN",
	CH: "XP",
	NO: "XP",
	CA: "XP",
	BG: "XP",
	CL: "XP",
	MX: "MX",
	SA: "XP",
	TR: "XP",
	AE: "XP",
	RU: "XP",
	PR: "XP",
	IL: "XP",
	SK: "XP",
	HR: "XP",
	RO: "XP",
	EG: "XP",
	MA: "XP",
	ZA: "XP",
	AU: "XP",
	NZ: "XP",
	IN: "XA",
	TW: "XA",
	SG: "XA",
	MY: "XA",
	TH: "XA",
	VN: "XA",
	ID: "XA",
	PH: "XA",
	PE: "XP",
	UA: "XP",
	BY: "XP",
	KZ: "XP",
	EC: "XP",
	RS: "XP",
	DZ: "XP",
	CR: "XP",
	LT: "XP",
	EE: "XP",
	LV: "XP",
	MO: "XA",
	AR: "XP",
	NG: "XP",
	KE: "XP",
	QA: "XP",
	MK: "XP",
	BA: "XP",
	AL: "XP",
	KR: "KR",
};
