import { DEFAULT_EASE, DEFAULT_DURATION } from "@/constants/transition";

export const move = {
	hidden: {
		y: 100,
		x: 100,
		opacity: 0,
		transition: {
			duration: DEFAULT_DURATION,
			ease: DEFAULT_EASE,
		},
	},
	show: {
		y: 0,
		x: 0,
		opacity: 1,
		transition: {
			duration: DEFAULT_DURATION,
			ease: DEFAULT_EASE,
		},
	},
};

export const moveY = {
	hidden: {
		y: "-100%",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
	show: {
		y: "0%",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
};

export const moveYReverse = {
	hidden: {
		y: "100%",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
	show: {
		y: "0%",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
};

export const moveX = {
	hidden: {
		x: "-100%",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},

	show: {
		x: "0%",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
};

export const moveXReverse = {
	hidden: {
		x: "100%",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},

	show: {
		x: "0%",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
};

export const getMove = (options) => ({
	...move,
	hidden: {
		...move.hidden,
		transition: {
			...move.hidden.transition,
			...options,
		},
	},
	show: {
		...move.show,
		transition: {
			...move.show.transition,
			...options,
		},
	},
});

export const getMoveY = (options) => ({
	...moveY,
	hidden: {
		...moveY.hidden,
		transition: {
			...moveY.hidden.transition,
			...options,
		},
	},
	show: {
		...moveY.show,
		transition: {
			...moveY.show.transition,
			...options,
		},
	},
});
