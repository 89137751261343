import { pathOr } from "ramda";

import { sentryLogException } from "@/utils/sentry";

import UIActionTypes from "./ui.action-types";

export const setLanguageModal = (isOpen) => (dispatch) => {
	dispatch({
		type: UIActionTypes.SET_LANGUAGE_MODAL,
		data: { isOpen },
	});
};

export const setResetModal = (isOpen) => (dispatch) => {
	dispatch({
		type: UIActionTypes.SET_RESET_MODAL,
		data: { isOpen },
	});
};

export const setAttractModal = (isOpen) => (dispatch) => {
	dispatch({
		type: UIActionTypes.SET_ATTRACT_MODAL,
		data: { isOpen },
	});
};

export const setShoeNotFoundModal =
	(isOpen, data = {}) =>
	(dispatch) => {
		dispatch({
			type: UIActionTypes.SET_SHOE_NOT_FOUND_MODAL,
			data: { isOpen, data },
		});
	};

export const setOptionsModal = (isOpen) => (dispatch) => {
	dispatch({
		type: UIActionTypes.SET_OPTIONS_MODAL,
		data: { isOpen },
	});
};

export const setMembershipModal = (isOpen) => (dispatch) => {
	dispatch({
		type: UIActionTypes.SET_MEMBERSHIP_MODAL,
		data: { isOpen },
	});
};

export const setGridLoading = (loading) => (dispatch) => {
	dispatch({
		type: UIActionTypes.SET_GRID_LOADING,
		data: loading,
	});
};

export const setShoesModal = (isOpen, data) => (dispatch) => {
	dispatch({
		type: UIActionTypes.SET_SHOES_MODAL,
		data: { isOpen, data },
	});
};

export const setAdminPanelModal = (isOpen) => (dispatch) => {
	dispatch({
		type: UIActionTypes.SET_ADMIN_PANEL_MODAL,
		data: { isOpen },
	});
};

export const setFlippedOrientation = (flipped) => (dispatch) => {
	dispatch({
		type: UIActionTypes.SET_ORIENTATION,
		data: flipped,
	});
};

export const setEnabledLanguages = (languages) => (dispatch) => {
	dispatch({
		type: UIActionTypes.SET_ENABLED_LANGUAGES,
		data: languages,
	});
};

export const setScreensaverTrigged = (active) => (dispatch) => {
	dispatch({
		type: UIActionTypes.SET_SCREENSAVER_TIMER_ACTIVE,
		data: active,
	});
};

export const setLowGPU = (active) => (dispatch) => {
	dispatch({
		type: UIActionTypes.SET_LOW_GPU,
		data: active,
	});
};

export const setActiveSession = (active) => (dispatch) => {
	dispatch({
		type: UIActionTypes.SET_ACTIVE_SESSION,
		data: active,
	});
};

export const setReloadTrigger = (active) => (dispatch) => {
	dispatch({
		type: UIActionTypes.SET_RELOAD_TRIGGER,
		data: active,
	});
};

export const setAttractTrigger = (active) => (dispatch) => {
	dispatch({
		type: UIActionTypes.SET_ATTRACT_TRIGGER,
		data: active,
	});
};

export const setQAMode = (active) => (dispatch) => {
	dispatch({
		type: UIActionTypes.SET_QA_MODE,
		data: active,
	});
};

export const setLatestAction = (action) => (dispatch) => {
	dispatch({
		type: UIActionTypes.SET_LATEST_ACTION,
		data: action,
	});
};

export const getAllStores = () => (dispatch) => {
	dispatch({
		type: UIActionTypes.GET_ALL_STORES,
	});

	fetch(`${process.env.REACT_APP_SP_API_URL}/public/rise/stores`)
		.then((response) => {
			if (!response.ok) {
				throw new Error(`HTTP error ${response.status}`);
			}

			return response.json();
		})
		.then((data) => {
			const stores = data.filter((store) => pathOr(false, ["json_data", "has_inside_track"], store));
			dispatch({ type: UIActionTypes.GET_ALL_STORES_SUCCESS, data: stores });
		})
		.catch((error) => {
			sentryLogException("Stores API", error);
			dispatch({
				type: UIActionTypes.GET_ALL_STORES_ERROR,
				error,
			});
		});
};

export const getAnalyticsTypes = () => (dispatch) => {
	dispatch({
		type: UIActionTypes.GET_ANALYTICS_TYPES,
	});

	fetch(`${process.env.REACT_APP_API_URL}/interaction_definition`)
		.then((response) => {
			if (!response.ok) {
				throw new Error(`HTTP error ${response.status}`);
			}

			return response.json();
		})
		.then((data) => {
			dispatch({ type: UIActionTypes.GET_ANALYTICS_TYPES_SUCCESS, data });
		})
		.catch((error) => {
			sentryLogException("Analytics Types", error);
			dispatch({
				type: UIActionTypes.GET_ANALYTICS_TYPES_ERROR,
				error,
			});
		});
};

export const getStoreID =
	(store, marketplace = "US") =>
	(dispatch) => {
		dispatch({
			type: UIActionTypes.GET_STORE_ID,
		});

		const myHeaders = new Headers();
		const apiKey = process.env.REACT_APP_SPORTPULSE_STORE_KEY;
		myHeaders.append("x-api-key", apiKey);

		const requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		const url = `${process.env.REACT_APP_SP_API_URL}/private`;

		fetch(`${url}/store_views/storeNumber/${store}?marketplace=${marketplace}`, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error ${response.status}`);
				}

				return response.json();
			})
			.then((data) => {
				dispatch({ type: UIActionTypes.GET_STORE_ID_SUCCESS, data });
			})
			.catch((error) => {
				// console.log(error);
				sentryLogException("Store ID Error", error);
				dispatch({
					type: UIActionTypes.GET_STORE_ID_ERROR,
					error,
				});
			});
	};
