import React from "react";
import { pathOr } from "ramda";
import { motion, AnimatePresence } from "framer-motion";

import GridBlock from "@/components/GridBlock";
import Typography from "@/components/Typography";

import { getFadeMove } from "@/animations";

import propTypes from "./ProductGroupingPartialsDescription.propTypes";
import defaultProps from "./ProductGroupingPartialsDescription.defaultProps";

const ProductGroupingPartialsDescription = ({ data, id, lineHeightSize }) => (
	<GridBlock id={id} className="u-p-16 u-flex u-jc-center u-ai-center">
		<AnimatePresence>
			<motion.div initial="hidden" animate="show" variants={getFadeMove({ delay: 0.6 })}>
				<Typography size="2xs" lineHeightSize={lineHeightSize}>
					{pathOr("", ["content", "body"], data)}
				</Typography>
			</motion.div>
		</AnimatePresence>
	</GridBlock>
);

ProductGroupingPartialsDescription.propTypes = propTypes;
ProductGroupingPartialsDescription.defaultProps = defaultProps;

export default ProductGroupingPartialsDescription;
