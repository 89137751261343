import PRFREQUENCYTHREE_ACTIVITIES_IN_WEEK_ACHIEVED from "@/assets/achievement_icons/PR.FREQUENCY.THREE_ACTIVITIES_IN_WEEK_ACHIEVED.jpg";
import PRFREQUENCYFIVE_ACTIVITIES_IN_WEEK_ACHIEVED from "@/assets/achievement_icons/PR.FREQUENCY.FIVE_ACTIVITIES_IN_WEEK_ACHIEVED.jpg";
import PRFREQUENCYSEVEN_ACTIVITIES_IN_WEEK_ACHIEVED from "@/assets/achievement_icons/PR.FREQUENCY.SEVEN_ACTIVITIES_IN_WEEK_ACHIEVED.jpg";
import PRSTREAKTHREE_MONTHS from "@/assets/achievement_icons/PR.STREAK.THREE_MONTHS.jpg";
import PRSTREAKSIX_MONTHS from "@/assets/achievement_icons/PR.STREAK.SIX_MONTHS.jpg";
import PRSTREAKNINE_MONTHS from "@/assets/achievement_icons/PR.STREAK.NINE_MONTHS.jpg";
import PRSTREAKTWELVE_MONTHS from "@/assets/achievement_icons/PR.STREAK.TWELVE_MONTHS.jpg";
import PRSTREAKTHREE_WEEKS from "@/assets/achievement_icons/PR.STREAK.THREE_WEEKS.jpg";
import PRSTREAKFOUR_WEEKS from "@/assets/achievement_icons/PR.STREAK.FOUR_WEEKS.jpg";
import PRSTREAKFIVE_WEEKS from "@/assets/achievement_icons/PR.STREAK.FIVE_WEEKS.jpg";
import PRSTREAKSIX_WEEKS from "@/assets/achievement_icons/PR.STREAK.SIX_WEEKS.jpg";
import RUNNINGBRANDJDIS_5K from "@/assets/achievement_icons/RUNNING.BRAND.JDIS_5K.jpg";
import RUNNINGBRANDJDIS_10K from "@/assets/achievement_icons/RUNNING.BRAND.JDIS_10K.jpg";
import NTC from "@/assets/achievement_icons/NTC.jpg";
import SWOOSH from "@/assets/icons/swoosh.svg";

const ACHIEVEMENT_ICONS = [
	{
		name: "PR.FREQUENCY.THREE_ACTIVITIES_IN_WEEK_ACHIEVED",
		src: PRFREQUENCYTHREE_ACTIVITIES_IN_WEEK_ACHIEVED,
	},
	{
		name: "PR.FREQUENCY.FIVE_ACTIVITIES_IN_WEEK_ACHIEVED",
		src: PRFREQUENCYFIVE_ACTIVITIES_IN_WEEK_ACHIEVED,
	},
	{
		name: "PR.FREQUENCY.SEVEN_ACTIVITIES_IN_WEEK_ACHIEVED",
		src: PRFREQUENCYSEVEN_ACTIVITIES_IN_WEEK_ACHIEVED,
	},
	{
		name: "PR.STREAK.THREE_MONTHS",
		src: PRSTREAKTHREE_MONTHS,
	},
	{
		name: "PR.STREAK.SIX_MONTHS",
		src: PRSTREAKSIX_MONTHS,
	},
	{
		name: "PR.STREAK.NINE_MONTHS",
		src: PRSTREAKNINE_MONTHS,
	},
	{
		name: "PR.STREAK.TWELVE_MONTHS",
		src: PRSTREAKTWELVE_MONTHS,
	},
	{
		name: "PR.STREAK.THREE_WEEKS",
		src: PRSTREAKTHREE_WEEKS,
	},
	{
		name: "PR.STREAK.FOUR_WEEKS",
		src: PRSTREAKFOUR_WEEKS,
	},
	{
		name: "PR.STREAK.FIVE_WEEKS",
		src: PRSTREAKFIVE_WEEKS,
	},
	{
		name: "PR.STREAK.SIX_WEEKS",
		src: PRSTREAKSIX_WEEKS,
	},
	{
		name: "RUNNING.BRAND.JDIS_5K",
		src: RUNNINGBRANDJDIS_5K,
	},
	{
		name: "RUNNING.BRAND.JDIS_10K",
		src: RUNNINGBRANDJDIS_10K,
	},
	{
		name: "NTC",
		src: NTC,
	},
	{
		name: "SWOOSH",
		src: SWOOSH,
	},
];

export default ACHIEVEMENT_ICONS;
