const FiltersActionTypes = {
	GET_ALL: "@@filters/GET_ALL",
	GET_ALL_SUCCESS: "@@filters/GET_ALL_SUCCESS",
	GET_ALL_ERROR: "@@filters/GET_ALL_ERROR",

	GET_FILTER_IDS: "@@filters/GET_FILTER_IDS",
	GET_FILTER_IDS_SUCCESS: "@@filters/GET_FILTER_IDS_SUCCESS",
	GET_FILTER_IDS_ERROR: "@@filters/GET_FILTER_IDS_ERROR",

	SET_FILTER: "@@filters/SET_FILTER",
	CLEAR_ACTIVE_FILTERS: "@@filter/CLEAR_ACTIVE_FILTERS",
};

export default FiltersActionTypes;
