import { isNil } from "ramda";

const setAnalytics = (event, details) => {
	if (process.env.REACT_APP_LOCATION !== "china") {
		if (!isNil(details)) {
			window.dataLayer.push({
				event,
				...details,
			});
			return;
		}

		window.dataLayer.push({
			event,
		});
	}
};

export default setAnalytics;
