/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import cn from "classnames";
import { isEmpty } from "ramda";

import Icon from "@/components/Icon";
import Codification from "@/components/Codification";
import Button, { ButtonLanguage } from "@/components/Button";
import GridBlock from "@/components/GridBlock";
import Typography from "@/components/Typography";
import { MembershipMenu } from "@/components/Membership";

import { useEnabledLanguages, useLowGPU } from "@/store/ui/ui.hooks";
import { useIdentity, useStyles } from "@/store/membership/membership.hooks";
import { setShoesModal } from "@/store/ui/ui.actions";
import { useAllShoes } from "@/store/shoes/shoes.hooks";
import { useFilterIDs, useAllFilters } from "@/store/filters/filters.hooks";

import placeVerticalGrid from "@/assets/grids/placeVertical.json";

import useGrid from "@/hooks/Grid";

import { fadeMove } from "@/animations";

import PlacePartialsCircle from "../Place/Partials";

import setAnalytics from "@/utils/analytics";
import { isLangFrench } from "@/utils/i18n";

const PlaceVertical = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const languages = useEnabledLanguages();
	const lowGPU = useLowGPU();
	const { isGridLoading, start: startGrid, end: endGrid, shouldRerenderGrid } = useGrid();
	const { data: identity, loading } = useIdentity();
	const { data: shoes, loading: shoesLoading } = useAllShoes();
	const { loading: filtersLoading } = useAllFilters();
	const { loading: stylesLoading } = useStyles();
	const filterIDs = useFilterIDs();

	const [animationTrigger, setAnimationTrigger] = useState("show");
	const [contentAnimationCompleted, setContentAnimationCompleted] = useState(false);

	// #region FUNCTIONS
	const onContentAnimationComplete = () => {
		setContentAnimationCompleted(true);
	};
	// #endregion FUNCTIONS

	// #region LIFECYCLE HOOKS
	useEffect(() => {
		endGrid();
		startGrid(placeVerticalGrid);
	}, [isGridLoading]);

	useEffect(() => {
		setAnimationTrigger("show");
		setAnalytics("place", { member: !isEmpty(identity) });
	}, []);
	// #endregion LIFECYCLE HOOKS

	// #region RENDER
	return (
		!shouldRerenderGrid &&
		!isGridLoading && (
			<div className="place">
				<GridBlock id="PlaceCenter" className="u-flex u-col u-jc-center u-ai-center">
					<motion.div
						variants={fadeMove}
						initial="hidden"
						animate="show"
						exit="hidden"
						onAnimationComplete={onContentAnimationComplete}
					>
						{/*
							u-mb-25 and u-pt-25 makes sure that inside track text is
							centered vertically in the middle of the page
						*/}
						<div className="u-stack-10 u-text-c u-mb-22">
							<Codification
								className="s-english"
								text={t("generic.name").replace(" ", "\n")}
								tag="h1"
								typography={{ primary: true, uppercase: true, size: "9xl", lineHeightSize: "9xl" }}
								characterSwitchAmount={6}
								characterNextTrigger={3}
								characterTimeout={30}
								lowGPU={lowGPU}
							/>
						</div>
						<AnimatePresence>
							{animationTrigger === "show" && (
								<motion.div
									className="u-flex u-jc-center u-ai-start u-col-4 u-inline-10"
									variants={fadeMove}
									initial="hidden"
									animate="show"
									exit="hidden"
								>
									<div className="u-flex u-jc-center u-ai-center">
										<Button
											onClick={() => {
												if (
													!loading &&
													!shoesLoading &&
													!stylesLoading &&
													!filtersLoading &&
													!isEmpty(filterIDs.category) &&
													!isEmpty(shoes)
												)
													dispatch(setShoesModal(true));
											}}
											className={cn(
												"c-button--rounded c-button--inverse c-button--float c-button--large",
												{
													"u-mr-10": languages.length > 1,
												}
											)}
										>
											<Button.Text>
												{loading
													? t("buttons.personalizing")
													: shoesLoading ||
													  stylesLoading ||
													  isEmpty(filterIDs.category) ||
													  isEmpty(shoes)
													? t("buttons.loading")
													: t("buttons.explore")}
											</Button.Text>
										</Button>
										{languages.length > 1 ? <ButtonLanguage /> : null}
									</div>
								</motion.div>
							)}
						</AnimatePresence>
					</motion.div>
				</GridBlock>
				<GridBlock id="PlaceCenterLeft" className="u-flex u-col u-jc-center u-ai-center">
					<div className={isLangFrench() ? "u-max-width-110 u-text-c" : "u-max-width-90 u-text-c"}>
						<Icon type="phone-scan" className="c-icon--size-14 u-mb-8" />
						<Typography className="u-mb-6" primary uppercase size="2md">
							{t("forms.vertical.placeCenterLeftTitle")}
						</Typography>
						<Typography size="smmmd" lineHeightSize="sm">
							{t("forms.vertical.placeCenterLeftCopy")}
						</Typography>
					</div>
				</GridBlock>
				<GridBlock id="PlaceCenterRight" className="u-flex u-col u-jc-center u-ai-center">
					<div className={isLangFrench() ? "u-max-width-110 u-text-c" : "u-max-width-90 u-text-c"}>
						<Icon type="shoe-place" className="c-icon--size-14 u-mb-8" />
						<Typography className="u-mb-6" primary uppercase size="2md">
							{t("forms.vertical.placeCenterRightTitle")}
						</Typography>
						<Typography size="smmmd" lineHeightSize="sm">
							{t("forms.vertical.placeCenterRightCopy")}
						</Typography>
					</div>
				</GridBlock>
				<GridBlock id="PlaceBottom" className="u-flex u-col u-jc-center u-ai-center">
					<PlacePartialsCircle type="bottom" animationTrigger={animationTrigger} />
				</GridBlock>
				<GridBlock id="" className="u-flex u-col u-jc-center u-ai-center">
					<MembershipMenu />
				</GridBlock>
			</div>
		)
	);
	// #endregion RENDER
};

export default PlaceVertical;
