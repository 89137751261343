import { DEFAULT_EASE, DEFAULT_DURATION } from "@/constants/transition";

export const scale = {
	hidden: {
		scale: 0,
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},

	show: {
		scale: 1,
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},

	exit: {
		scale: 0,
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION / 2,
			delay: DEFAULT_DURATION / 4,
		},
	},
};

export const scaleX = {
	hidden: {
		scaleX: 0,
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION / 2,
		},
	},

	show: {
		scaleX: 1,
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
};

export const getScaleX = (options) => ({
	...scaleX,
	hidden: {
		...scaleX.hidden,
		transition: {
			...scaleX.hidden.transition,
			...options,
		},
	},
	show: {
		...scaleX.show,
		transition: {
			...scaleX.show.transition,
			...options,
		},
	},
});

export const getScale = (options) => ({
	...scale,
	hidden: {
		...scale.hidden,
		transition: {
			...scale.hidden.transition,
			...options,
		},
	},
	show: {
		...scale.show,
		transition: {
			...scale.show.transition,
			...options,
		},
	},
});

export const getScaleWithProps = (props) => ({
	...scale,
	hidden: {
		...scale.hidden,
		...props,
	},
	show: {
		...scale.show,
		...props,
	},
	exit: {
		...scale.show,
		...props,
	},
});
