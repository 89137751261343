import PropTypes from "prop-types";

const Block = {
	url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onClick: PropTypes.func,
};

export default {
	Block,
};
