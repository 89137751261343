import React from "react";
import { motion } from "framer-motion";
import cn from "classnames";

import { getFade, getWidth } from "@/animations";

import Button, { MotionButton } from "../Button.component";
import defaultProps from "../Button.defaultProps";
import propTypes from "../Button.propTypes";

// =============================================================================
// Root
// =============================================================================
const Button2 = ({
	className = "",
	children,
	type = "",
	url,
	onClick,
	delay = 0,
	fromRight = false,
	horizontal = false,
}) => (
	<div>
		<MotionButton
			url={url}
			type={type}
			className={cn(
				"c-button--rounded u-overflow-hidden u-text-nowrap u-inline-flex u-ai-center u-inline-5",
				className
			)}
			onClick={onClick}
			animate="show"
			initial="hidden"
			variants={getWidth(fromRight, delay)}
		>
			<motion.span variants={getFade({ delay })}>
				<Button.Text size={horizontal ? "3xs" : "2xs"}>{children}</Button.Text>
			</motion.span>
		</MotionButton>
	</div>
);

Button2.propTypes = propTypes;
Button2.defaultProps = defaultProps;

export default Button2;
