const RFIDActionTypes = {
	SET_ORIENTATION: "@@rfid/SET_ORIENTATION",
	SET_IS_SESSION: "@@rfid/SET_IS_SESSION",
	SET_IS_INTERACTION: "@@rfid/SET_IS_INTERACTION",
	SET_INTERACTION_LOCK: "@@rfid/SET_INTERACTION_LOCK",

	SET_404_LIST: "@@rfid/SET_404_LIST",
	ADD_SCC_TO_404_LIST: "@@rfid/ADD_SCC_TO_404_LIST",
	ADD_EPC_TO_404_LIST: "@@rfid/ADD_EPC_TO_404_LIST",

	UPDATE_TAGS: "@@rfid/UPDATE_TAGS",
	REMOVE_TAG: "@@rfid/REMOVE_TAG",
	CLEAR_TAGS: "@@rfid/CLEAR_TAGS",
};

export default RFIDActionTypes;
