export default {
	code: null,
	slides: [],
	handleSetProductGroupings: null,
	handlePrev: null,
	handleNext: null,
	totalSlides: 0,
	currentSlide: 0,
	speed: 0,
	isDetail: false,
	showAllUgc: true,
};
