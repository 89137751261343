import React, { useRef, useEffect } from "react";
import cn from "classnames";

import defaultProps from "./Video.defaultProps";
import propTypes from "./Video.proptypes";

// =============================================================================
// Root
// =============================================================================
const Video = ({ videoAsset, className = "" }) => {
	const video = useRef(null);
	const time = useRef(0);
	const timer = useRef(0);

	const handleVideoEnded = () => {
		video.current.play();
	};

	useEffect(() => {
		const $el = video.current;

		if ($el) {
			video.current.addEventListener("ended", handleVideoEnded);
		}

		timer.current = setInterval(() => {
			time.current += 1;
		}, 1000);

		return () => {
			if ($el) {
				$el.removeEventListener("ended", handleVideoEnded);

				clearInterval(timer.current);
			}
		};
	}, []);

	return (
		<div className={cn("u-fill-space c-video", className)}>
			<video ref={video} className="u-fill-space" autoPlay muted playsInline src={videoAsset} />
		</div>
	);
};

Video.propTypes = propTypes.Video;
Video.defaultProps = defaultProps.Video;

export default Video;
