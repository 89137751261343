import { pathOr } from "ramda";

import AVAILABILITY from "@/constants/availability";

const getAvailability = (product) => {
	let availability = AVAILABILITY.NONE;

	if (product >= 5) {
		availability = AVAILABILITY.AVAILABLE;
	}
	if (product > 0 && product < 5) {
		availability = AVAILABILITY.LIMITED;
	}

	return availability;
};

export default getAvailability;
