import { sort } from "ramda";


/**
 * Sort taxonomies by taking into the account the priority.
 *
 * @param {[]} taxonomies - Taxonomies to sort.
 * @param {string[]} prioritizedTaxonomyIDs - Prioritised list of taxonomies (lowest priority must be at the beginning, the highest priority - at the end).
 * @return {[]} Storted array of taxonomies, where most prioritized items are at the beginning of the array.
 */
export function sortByPrioritizedTaxonomyIDs(taxonomies, prioritizedTaxonomyIDs = []) {
  return sort((a, b) => {
    const indexA = prioritizedTaxonomyIDs.indexOf(a.id);
    const indexB = prioritizedTaxonomyIDs.indexOf(b.id);

    if (indexA > indexB) {
      return -1;
    } else if (indexA < indexB) {
      return 1;
    } else {
      return 0;
    }
  }, taxonomies);
}
