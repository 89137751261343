import { useState, useEffect } from "react";
import { equals, isEmpty, isNil } from "ramda";
import { useDispatch } from "react-redux";

import ROUTES from "@/constants/routes";

import { useIsDetail, useIsCompare, useIsInactive, useActiveShoes } from "@/store/shoes/shoes.hooks";
import { useAllTags, useInteractionLock } from "@/store/rfid/rfid.hooks";
import { useIdentity } from "@/store/membership/membership.hooks";

import { setScreensaverTrigged } from "@/store/ui/ui.actions";
import { useScreensaverTrigged } from "@/store/ui/ui.hooks";
import { checkIsStatusActive, checkIsStatusObserve } from "@/utils/rfid";

import { useQuery } from "@/hooks/Router";
import useNetwork from "@/hooks/Network";

export default () => {
	const [page, setPage] = useState(ROUTES.Index);

	const query = useQuery();
	const isDetail = useIsDetail();
	const isCompare = useIsCompare();
	const isInactive = useIsInactive();
	const networkOnline = useNetwork();
	const tags = useAllTags();
	const isScreensaverTrigged = useScreensaverTrigged();
	const { left, right } = useActiveShoes();
	const { data: identity, loading } = useIdentity();
	const interactionLock = useInteractionLock();

	const dispatch = useDispatch();

	const checkAndSetPage = (newPage) => {
		if (!equals(page, newPage)) {
			setPage(newPage);
		}
	};

	useEffect(() => {
		const activeTags = tags.filter((tag) => checkIsStatusActive(tag));
		const isObserveEnabled = equals(query.get("observe"), "true");
		const observeTags = isObserveEnabled ? tags.filter((tag) => checkIsStatusObserve(tag)) : [];
		const qStore = query.get("store") || null;
		const qScreensaver = query.get("screensaver") || null;

		if (!networkOnline) {
			return;
		}

		if (isNil(qStore)) {
			checkAndSetPage(ROUTES.Setup);
			return;
		}

		if (isCompare) {
			checkAndSetPage(ROUTES.Compare);
			return;
		}

		if (isDetail) {
			checkAndSetPage(ROUTES.Detail);
			return;
		}

		// If screensaver is inactive and no shoes (digital or physical) are on the table, go to place
		if (!qScreensaver && isNil(left) && isNil(right)) {
			checkAndSetPage(ROUTES.Place);
			return;
		}

		// If screensaver is active and screensaver is not triggered and no interaction lock
		if (qScreensaver && !isScreensaverTrigged && !interactionLock) {
			if (!equals(page, ROUTES.Index)) {
				checkAndSetPage(ROUTES.Place);
				return;
			}
			if (equals(page, ROUTES.Index)) {
				if (isEmpty(activeTags) && !isEmpty(observeTags)) {
					checkAndSetPage(ROUTES.Place);
					return;
				}
				if (!isEmpty(identity) || loading) {
					checkAndSetPage(ROUTES.Place);
					return;
				}
				return;
			}
		}

		// If screensaver is triggered and we are on the index page, reset screensaver to false
		if (qScreensaver && isScreensaverTrigged) {
			if (equals(page, ROUTES.Index)) dispatch(setScreensaverTrigged(false));
		}

		if (qScreensaver && !isEmpty(observeTags) && isNil(left) && isNil(right)) {
			checkAndSetPage(ROUTES.Place);
			return;
		}

		if (!isEmpty(identity) || loading) {
			checkAndSetPage(ROUTES.Place);
			return;
		}

		if (qScreensaver && interactionLock) {
			checkAndSetPage(ROUTES.Place);
			return;
		}

		// DEFAULT TO ATTRACT IF NO CONDITIONS
		checkAndSetPage(ROUTES.Index);
	}, [isDetail, isCompare, isInactive, tags, query, left, right, loading]);

	return page;
};
