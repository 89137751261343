import PropTypes from "prop-types";

const FilterDropdown = {
	activeOption: PropTypes.string,
	options: PropTypes.array,
	label: PropTypes.string,
	onChange: PropTypes.func,
};

export default {
	FilterDropdown,
};
