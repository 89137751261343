import PropTypes from "prop-types";
import imagePropTypes from "@/components/Image/Image.propTypes";

const ShoePreview = {
	title: PropTypes.string,
	url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	isDimmed: PropTypes.bool,
	...imagePropTypes.Image,
};

export default {
	ShoePreview,
};
