const ShoeHero = {};

const Background = {
	src: null,
	animation: "isNext",
	speed: 5,
	isSmall: false,
	isUgc: false,
};

const Video = {
	src: null,
	animation: "isNext",
	speed: 5,
};

const Content = {
	animation: "default",
};

export default {
	Content,
	Background,
	ShoeHero,
	Video,
};
