import React, { memo, useEffect, useState, useRef } from "react";
import cn from "classnames";
import { motion, AnimatePresence } from "framer-motion";

import Typography from "@/components/Typography";
import Codification from "@/components/Codification";

import { useLowGPU } from "@/store/ui/ui.hooks";

import { clipPath } from "@/animations";

import { isLangFrench } from "@/utils/i18n";

import propTypes from "./Highlight.propTypes";
import defaultProps from "./Highlight.defaultProps";

// =============================================================================
// Root
// =============================================================================
const Highlight = ({
	className = "",
	title,
	description,
	tagline,
	isVisible,
	recommendation = false,
	detail = false,
	personalizing = false,
	verticalDetail = false,
	verticalCompare = false,
	personalizingVertical = false,
	timeout = 20,
	small = false,
}) => {
	const [isVisibleState, setIsVisibleState] = useState(isVisible);
	const lowGPU = useLowGPU();
	const [resetHighlight, setResetHighlight] = useState(true);

	const resetTimer = useRef(null);

	let typography = {};
	if (recommendation) typography = { primary: true, size: "2xs", lineHeightSize: "3xs", tag: "h2", uppercase: true };
	else if (detail) typography = { primary: true, size: "lg", tag: "span", uppercase: true };
	else if (personalizing) typography = { primary: true, size: "smmd", tag: "h2", uppercase: true };
	else if (personalizingVertical)
		typography = { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true };
	else if (verticalDetail && !small && !isLangFrench)
		typography = { primary: true, size: "5xl", tag: "h2", uppercase: true };
	else if (verticalDetail && !small && isLangFrench)
		typography = { primary: true, size: "5xl", lineHeightSize: "6xl", tag: "h2", uppercase: true };
	else if (verticalDetail && small) typography = { primary: true, size: "4xl", tag: "h2", uppercase: true };
	else if (verticalCompare && !isLangFrench)
		typography = { primary: true, size: "lg", lineHeightSize: "md", tag: "h2", uppercase: true };
	else if (verticalCompare && isLangFrench)
		typography = { primary: true, size: "lg", lineHeightSize: "lg", tag: "h2", uppercase: true };
	else typography = { primary: true, size: "xl", tag: "h2", uppercase: true };

	useEffect(() => {
		// Render the background one frame after content has been rendered.
		// In this way, the background knows how big it should become according to the size of the content.
		// When in doubt, setTimeout();
		const timer = setTimeout(() => {
			setIsVisibleState(isVisible);
		}, 50);

		return () => {
			clearTimeout(timer);
		};
	}, [isVisible]);

	useEffect(() => {
		return () => {
			clearTimeout(resetTimer.current);
			resetTimer.current = null;
		};
	}, []);

	return (
		<div
			className={cn("c-highlight", className, {
				"c-highlight-recommendation": recommendation || detail,
				"c-highlight-membership": personalizing,
				"c-highlight-personalizing-vertical c-highlight-membership": personalizingVertical,
				"is-hidden": !isVisibleState,
			})}
		>
			<AnimatePresence>
				{isVisibleState && !recommendation && !detail ? (
					<div className="c-highlight__bg" />
				) : (
					<div className="c-highlight__bg" />
				)}
			</AnimatePresence>

			{tagline && isVisible && (
				<Typography className="c-highlight__tagline" size="3xs" lineHeightSize="default">
					{tagline}
				</Typography>
			)}

			<AnimatePresence initial={false}>
				{title && isVisible && (
					<motion.div variants={clipPath} animate="show" exit="hidden" className="c-highlight__title">
						<div className="c-highlight__title-codification">
							{resetHighlight && (
								<Codification
									text={title}
									typography={typography}
									characterSwitchAmount={4}
									characterNextTrigger={2}
									characterTimeout={timeout}
									lowGPU={lowGPU}
									isStatic={recommendation || detail}
									onEnded={() => {
										if (personalizing) {
											resetTimer.current = setTimeout(() => {
												setResetHighlight(false);
												setResetHighlight(true);
											}, 750);
										}
									}}
								/>
							)}
						</div>

						<Typography
							className="c-highlight__title-mask"
							primary
							size={typography.size}
							tag="h2"
							uppercase
						>
							<Codification text={title} typography={typography} lowGPU={lowGPU} isStatic />
						</Typography>
					</motion.div>
				)}
			</AnimatePresence>

			<AnimatePresence>
				{description && isVisible && (
					<motion.div variants={clipPath} initial="hidden" animate="show" exit="hidden">
						<Typography
							className="c-highlight__desc"
							tag="p"
							size="2xs"
							lineHeightSize={verticalDetail || verticalCompare ? "xs" : null}
						>
							{description}
						</Typography>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

Highlight.propTypes = propTypes;
Highlight.defaultProps = defaultProps;

export default memo(Highlight);
