import { pathOr, find, propEq, isNil, isEmpty } from "ramda";

import { SIDE } from "@/constants/rfid-tags";
import SHOES from "@/constants/mock-data";

export const checkIsDigital = (data) => pathOr(false, ["isDigital"], data);

export const flipSides = (isFlipped, side) => {
	const isLeft = side === SIDE.Left;

	if (isFlipped) {
		return isLeft ? SIDE.Right : SIDE.Left;
	}

	return isLeft ? SIDE.Left : SIDE.Right;
};

export const checkDetail = (data) => pathOr(false, ["detail"], data);

export const getSCC = (data, defaultValue = "") => pathOr(defaultValue, ["scc"], data);

export const getEPC = (data) => pathOr("", ["epc"], data);

export const getVisibleItem = (data1, data2) => find(propEq("isVisible", true))([data1, data2]);

export const getDetail = (data) => pathOr(null, ["detail"], data);

export const handleMockEPC = (query, epc) => {
	const isDemoMode = query.get("demo");

	if (isDemoMode && !isEmpty(epc)) {
		let qMockEpcs = query.get("mock-epcs") || null;
		const tempShoes = [...SHOES];

		if (!isNil(qMockEpcs)) {
			qMockEpcs = qMockEpcs.split(",");
			tempShoes.forEach((shoe) => {
				qMockEpcs.forEach((mockEpc) => {
					const [iEpc, iScc] = mockEpc.split(":");
					if (shoe.mockEpc === iEpc) {
						shoe.scc = iScc; // eslint-disable-line no-param-reassign
					} else {
						tempShoes.push({ epc: iEpc, scc: iScc });
					}
				});
			});
		}

		const demoShoe = tempShoes.find((shoe) => shoe.epc === epc || shoe.mockEpc === epc || shoe.proxyEPC === epc);
		if (demoShoe !== undefined)
			return isNil(demoShoe.scc) ? { epc: getEPC(demoShoe), scc: null } : { epc: null, scc: getSCC(demoShoe) };

		return { epc: getEPC(demoShoe), scc: null };
	}

	return { epc, scc: null };
};
