import PropTypes from "prop-types";

const Review = {
	title: PropTypes.string,
	description: PropTypes.string,
	author: PropTypes.string,
	date: PropTypes.string,
	rating: PropTypes.number,
	isSmallTitle: PropTypes.bool,
	verified: PropTypes.bool,
	descriptionCharacterLimit: PropTypes.number,
};

export default {
	Review,
};
