// INFO: @IAN, add banned epc/scc codes here.

export const BANNED_SCC = [
	// DAYBREAK
	"CV2179-262",
];

export const BANNED_EPC = [
	// "33140BEA9C181AE000000001", //MOCK PROXY TO REAL » 33140BEA9C181AE000000001
	// "33140BB6382DD6E000000000",
	// "33143606E82608E000000005",
	// "33140BEA9807E6A000000000",
	// "33140BD1E80674A000000000",
	// "33140BE63850C9E000000003",
	// "3314360108516E2000000002",
	// "33143626FC2D856000000000",
	// "33140BDF105573E000000003",
	// "33140BE63409B06000000000",
	// "33140BC9FC42B8A000000003",
	// "33140BEA9807EE2000000000",
	// "33140BC9FC445D6000000004",
	// "33140BDB900F04A000000002",
];

// INFO: @IAN, add banned product groupings here. When added, we'll check if there's enough content for the technology/franchise page; if not, the button/label won't be shown
export const BANNED_PRODUCT_GROUPING_BUTTONS = [];
