import { isNil } from "ramda";

import { FILTER_KEYS } from "@/constants/filters";

import FiltersActionTypes from "./filters.action-types";
import FiltersInitialState from "./filters.initial-state";

const FiltersReducer = (state = FiltersInitialState(), action) => {
	switch (action.type) {
		case FiltersActionTypes.GET_FILTER_IDS:
		case FiltersActionTypes.GET_ALL: {
			return {
				...state,
				loading: true,
				error: null,
			};
		}

		case FiltersActionTypes.GET_ALL_SUCCESS: {
			return {
				...state,
				all: {
					...action.data,
				},
				loading: false,
				error: null,
			};
		}

		case FiltersActionTypes.GET_FILTER_IDS_SUCCESS: {
			return {
				...state,
				ids: {
					...action.data,
				},
				loading: false,
				error: null,
			};
		}

		case FiltersActionTypes.SET_FILTER: {
			const designedForState = state.activeFilters[FILTER_KEYS.DESIGNED_FOR];
			// Clear designed_for filter when category is not running (id: 1) and it's active
			const resetDesignedFor =
				action.key === FILTER_KEYS.CATEGORY &&
				action.data !== action.runningID &&
				!isNil(designedForState.activeFilter);

			return {
				...state,
				activeFilters: {
					...state.activeFilters,
					[action.key]: {
						...state.all[action.key],
						activeFilter: action.data,
					},
					...(resetDesignedFor && {
						[FILTER_KEYS.DESIGNED_FOR]: {
							...designedForState,
							activeFilter: null,
						},
					}),
				},
			};
		}

		case FiltersActionTypes.CLEAR_ACTIVE_FILTERS: {
			return {
				...state,
				activeFilters: { gender: {}, category: {}, designed_for: {}, surface: {}, activity: {} },
			};
		}

		case FiltersActionTypes.GET_FILTER_IDS_ERROR:
		case FiltersActionTypes.GET_ALL_ERROR: {
			return {
				all: {},
				loading: false,
				error: action.error,
			};
		}

		default: {
			return state;
		}
	}
};

export default FiltersReducer;
