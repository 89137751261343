import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export const setupSentry = () => {
	if (process.env.NODE_ENV !== "development" && process.env.REACT_APP_LOCATION !== "china") {
		Sentry.init({
			dsn: "https://708598735116429c8db5272006226e13@o926465.ingest.sentry.io/5939386",
			integrations: [new Integrations.BrowserTracing()],

			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: 1.0,
		});
	}
};

export const sentryLogApiResponseError = (response) => {
	const scope = new Sentry.Scope();
	scope.setTag("source", "Inside Track");
	scope.setTag("type", "API Call");
	scope.setTag("domain", window.location.origin);
	scope.setContext("response", response);

	Sentry.captureException(
		`Api Response Received: ${response.status} - ${response.request.method?.toUpperCase()} ${response.request.url}`,
		() => scope
	);
};

export const sentryLogError = (type, product, message) => {
	const scope = new Sentry.Scope();
	scope.setTag("source", "Inside Track");
	scope.setTag("type", type);
	scope.setTag("domain", window.location.origin);

	for (let i = 0; i < product.length; i += 1) {
		const id = product[i];
		scope.setTag(id.name, id.value);
	}

	Sentry.captureMessage(message, () => scope);
};

export const sentryLogMessage = (type, message) => {
	const scope = new Sentry.Scope();
	scope.setTag("source", "Inside Track");
	scope.setTag("type", type);
	scope.setTag("domain", window.location.origin);

	Sentry.captureMessage(message, () => scope);
};

export const sentryLogException = (type, err) => {
	const scope = new Sentry.Scope();
	scope.setTag("source", "Inside Track");
	scope.setTag("type", type);
	scope.setTag("domain", window.location.origin);

	Sentry.captureException(err, () => scope);
};
