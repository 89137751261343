import React, { useEffect, useState } from "react";
import { pathOr, isEmpty } from "ramda";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import cn from "classnames";
import { v4 as uuidv4 } from "uuid";
import { wrap } from "popmotion";

import { Button1 } from "@/components/Button";
import GridBlock from "@/components/GridBlock";
import ShoeHeading from "@/components/ShoeHeading";
import Review from "@/components/Review";
import ReviewStars from "@/components/ReviewStars";
import Typography from "@/components/Typography";
import Progress from "@/components/Progress";
import ProgressBar from "@/components/ProgressBar";
import SliderNavigation from "@/components/SliderNavigation";
import ShoePickerNavigation from "@/components/ShoePickerNavigation";

import SWIPE_TRESHOLD from "@/constants/events";
import AMOUNT_PER_VIEW from "@/constants/reviews";

import { useActiveShoe } from "@/store/shoes/shoes.hooks";
import { useFlippedOrientation } from "@/store/ui/ui.hooks";
import { useIdentity } from "@/store/membership/membership.hooks";

import useGrid from "@/hooks/Grid";

import reviewsGrid from "@/assets/grids/reviews.json";
import reviewsHorizontalGrid from "@/assets/grids/reviewsHorizontal.json";

import swipePower from "@/utils/slider/swipePower";
import setAnalytics from "@/utils/analytics";

import { fadeMove, slideFull } from "@/animations";
import { setLatestAction } from "@/store/ui/ui.actions";
import { useQuery } from "@/hooks/Router";
import { isLangKorean } from "@/utils/i18n";

const dayjs = require("dayjs");

const Reviews = () => {
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const query = useQuery();

	const { isGridLoading, start: startGrid, shouldRerenderGrid } = useGrid();
	const { detail: activeShoe = null } = useActiveShoe() || {};
	const history = useHistory();
	const [[page, direction], setPage] = useState([0, 0]);
	const isFlipped = useFlippedOrientation();
	const { data: identity } = useIdentity();

	const qForm = query.get("form") || "rise";

	const comfortRating = pathOr(null, ["feedback", "comfort_rating"], activeShoe);
	const durabilityRating = pathOr(null, ["feedback", "durability_rating"], activeShoe);
	const sizeRating = pathOr(null, ["feedback", "size_fidelity_rating"], activeShoe);

	let reviews = pathOr([], ["feedback", "reviews"], activeShoe);
	reviews = reviews.slice(0, 40);

	const count = reviews.length;
	const totalPages = Math.ceil(count / AMOUNT_PER_VIEW);
	const pageIndex = wrap(0, totalPages, page);
	const start = pageIndex * AMOUNT_PER_VIEW;
	const end = start + AMOUNT_PER_VIEW > count ? count : start + AMOUNT_PER_VIEW;
	const formattedReviews = reviews.slice(start, end);

	const formatRating = (rating, ratingData) => (rating === null ? rating : { ...ratingData, value: rating });

	const ratings = [
		formatRating(comfortRating, {
			gridBlockId: "ReviewsBottom2",
			title: t("generic.performance"),
			minText: t("generic.uncomfortable"),
			maxText: t("generic.very_comfortable"),
		}),
		formatRating(durabilityRating, {
			gridBlockId: "ReviewsBottom3",
			title: t("generic.durability"),
			minText: t("generic.not_durable"),
			maxText: t("generic.very_durable"),
		}),
		formatRating(sizeRating, {
			gridBlockId: "ReviewsBottom4",
			title: t("generic.size"),
			minText: t("generic.runs_small"),
			maxText: t("generic.runs_large"),
		}),
	].filter((rating) => !!rating);

	// #region FUNCTIONS
	const handlePage = (newDirection) => {
		let newPage = page + newDirection;

		if (newPage < 0) newPage = totalPages - 1;
		if (newPage > totalPages - 1) newPage = 0;

		setPage([newPage, newDirection]);
	};

	const handleDragEnd = (e, { offset, velocity }) => {
		if (count <= AMOUNT_PER_VIEW) {
			return;
		}

		let swipe = swipePower(offset.x, velocity.x);

		if (isFlipped) {
			swipe = -swipe;
		}

		if (swipe < -SWIPE_TRESHOLD) {
			handlePage(1);
		} else if (swipe > SWIPE_TRESHOLD) {
			handlePage(-1);
		}
	};
	// #endregion FUNCTIONS

	// #region LIFECYCLE HOOKS
	useEffect(() => {
		if (qForm === "horizontal") startGrid(reviewsHorizontalGrid);
		else startGrid(reviewsGrid);

		// Analytics
		if (activeShoe) {
			setAnalytics("product", { product_id: activeShoe.id, member: !isEmpty(identity) });
		}
	}, [activeShoe]);

	useEffect(() => {
		setAnalytics("reviews", { member: !isEmpty(identity) });
		dispatch(setLatestAction({ type: "pageview_product_reviews", id: uuidv4(), firstScc: activeShoe?.id }));
	}, []);
	// #endregion FUNCTIONS

	// #region RENDER
	return (
		!shouldRerenderGrid &&
		!isGridLoading &&
		activeShoe && (
			<>
				<GridBlock
					className={cn({ "u-flex u-ai-end u-pb-4 u-pl-2": qForm === "horizontal" })}
					id="ReviewsTopLeft"
				>
					<ShoeHeading>
						<ShoeHeading.Image src={activeShoe.assets.left.square} />

						<ShoeHeading.Title
							className={cn({ "u-flex u-as-end": qForm === "horizontal" })}
							primary={qForm !== "horizontal"}
							weight={qForm !== "horizontal" ? "normal" : "bold"}
							size={qForm !== "horizontal" ? "md" : "smmmd"}
							lineHeightSize={qForm !== "horizontal" ? "md" : "sm"}
						>
							{activeShoe.content.name}
						</ShoeHeading.Title>
					</ShoeHeading>
				</GridBlock>

				<GridBlock id="ReviewsContent">
					<motion.div
						drag="x"
						onDragEnd={handleDragEnd}
						dragElastic={1}
						initial="enter"
						animate="center"
						exit="exit"
						key={page}
						custom={isFlipped ? -direction : direction}
						variants={slideFull}
						transformTemplate={({ x }) => {
							const xTransform = parseFloat(x, 10);
							return `translate3d(${isFlipped ? -xTransform : xTransform}px, 0, 0)`;
						}}
						dragConstraints={{ left: 0, right: 0 }}
						className="u-flex u-fill-height"
						transition={{
							x: { type: "spring", bounce: 0 },
							opacity: { duration: 0.2 },
						}}
					>
						{formattedReviews.map(
							({ heading, body, author, review_date, rating, verified_purchaser: verified }, index) => {
								const isFirst = index === 0;

								return (
									<Review
										className={cn("u-px-6 u-max-height-150", {
											"u-width-50%": index === 0,
											"u-width-25%": index !== 0,
											"u-mt-2": isLangKorean() && qForm !== "vertical",
										})}
										// eslint-disable-next-line react/no-array-index-key
										key={`${review_date}-${index}`}
										isSmallTitle={!isFirst}
										title={heading}
										description={body}
										author={author}
										date={dayjs(review_date).locale(i18n.language).format("MMMM DD, YYYY")}
										rating={rating}
										verified={verified}
										descriptionCharacterLimit={isFirst ? 300 : 600}
										titleCharacterLimit={40}
									/>
								);
							}
						)}
					</motion.div>
				</GridBlock>

				<GridBlock
					id="ReviewsBottom1"
					className="c-block--inset-y u-flex u-col u-jc-center u-ai-start u-stack-3 u-px-6 u-idle"
				>
					<motion.div
						variants={fadeMove}
						initial="hidden"
						animate="show"
						className={cn({ "u-mt-auto": qForm !== "horizontal" })}
					>
						<Typography weight="bold" size="xs">
							{t("generic.average_rating")}
						</Typography>
					</motion.div>

					<ReviewStars rating={activeShoe.feedback.avg_rating} showLabel />

					{qForm !== "horizontal" && (
						<Button1
							className="c-button--rounded u-flex u-ai-center u-inline-2 u-mt-auto u-interactive"
							onClick={() => history.goBack()}
							icon="chevron-left"
							iconPosition="left"
						>
							{t("buttons.back")}
						</Button1>
					)}
				</GridBlock>

				{ratings.map(({ gridBlockId, title, minText, maxText, value = null } = {}) => {
					return (
						<GridBlock id={gridBlockId} key={gridBlockId} className="u-flex u-jc-center u-ai-center u-idle">
							<Progress title={title} minText={minText} maxText={maxText}>
								<ProgressBar value={value} min={0} max={3} />
							</Progress>
						</GridBlock>
					);
				})}

				{qForm === "horizontal" && (
					<GridBlock id="ReviewsBottomBarLeft" className="u-pl-5 u-flex u-jc-start u-ai-center">
						<Button1
							className="c-button--rounded c-button--large u-flex u-ai-center u-inline-2 u-interactive"
							onClick={() => history.goBack()}
							icon="chevron-left"
							iconPosition="left"
						>
							{t("buttons.back")}
						</Button1>
					</GridBlock>
				)}

				<GridBlock id="ReviewsBottomBarCenter" className="u-flex u-jc-center u-ai-center">
					{totalPages <= 15 ? (
						<SliderNavigation
							slides={totalPages}
							speed={0}
							step={page}
							setCurrentSlide={(newPage) => {
								setPage([newPage, direction]);
							}}
						/>
					) : null}
				</GridBlock>

				{qForm === "horizontal" && (
					<GridBlock id="ReviewsBottomBarRight" className="u-pl-5 u-flex u-jc-start u-ai-center">
						<ShoePickerNavigation
							className="u-flex-1"
							slides={totalPages}
							speed={0}
							step={page}
							setCurrentSlide={(newPage) => {
								if (newPage > page) handlePage(1);
								else handlePage(-1);
							}}
						/>
					</GridBlock>
				)}
			</>
		)
	);
	// #endregion RENDER
};

export default Reviews;
