import { useSelector } from "react-redux";

import { ORIENTATION, SIDE } from "@/constants/rfid-tags";

import {
	selectOrientation,
	selectIsInteraction,
	selectAllTags,
	selectBySide,
	selectIdleTags,
	selectInteractionLock,
	select404List,
} from "./rfid.selectors";

export const useIsInteraction = () => useSelector((state) => selectIsInteraction(state));

export const useAllTags = () => useSelector((state) => selectAllTags(state));

export const useHotspotLeft = () => useSelector((state) => selectBySide(state, SIDE.Left));

export const useHotspotRight = () => useSelector((state) => selectBySide(state, SIDE.Right));

export const useIdleTags = () => useSelector((state) => selectIdleTags(state));

export const useRFIDOrientation = () => useSelector((state) => selectOrientation(state));

export const useIsOrientationNormal = () => {
	const v = useSelector((state) => selectOrientation(state));
	return v === ORIENTATION.Normal;
};

export const useInteractionLock = () => useSelector((state) => selectInteractionLock(state));

export const use404List = () => useSelector((state) => select404List(state));
