import { useState, useEffect } from "react";
import { isNil } from "ramda";

import { useActiveShoes } from "@/store/shoes/shoes.hooks";

import { checkIsDigital, getVisibleItem, getDetail } from "@/utils/shoes";

export default () => {
	const [activeShoe, setActiveShoe] = useState(null);
	const [isRight, setIsRight] = useState(false);
	const [isCompareMode, setIsCompareMode] = useState(false);
	const [isDigital, setIsDigital] = useState(false);

	const { left, right } = useActiveShoes();

	useEffect(() => {
		// INFO: Both shoes are active = compare
		if (!isNil(left) && !isNil(right)) {
			const shoe = getVisibleItem(left, right);
			setIsCompareMode(true);
			setActiveShoe(getDetail(shoe));
			setIsRight(right.isVisible);
			setIsDigital(checkIsDigital(shoe));

			return;
		}

		// INFO: Only left shoes is active = detail
		if (!isNil(left) && isNil(right)) {
			setIsRight(false);
			setActiveShoe(getDetail(left));
			setIsCompareMode(false);
			setIsDigital(checkIsDigital(left));
		}

		// INFO: Only right shoes is active = detail
		if (!isNil(right) && isNil(left)) {
			setIsRight(true);
			setActiveShoe(getDetail(right));
			setIsCompareMode(false);
			setIsDigital(checkIsDigital(right));
		}
	}, [left, right]);

	return {
		left,
		right,
		activeShoe,
		isRight,
		isCompareMode,
		isDigital,
	};
};
