import { pathOr, equals, isNil } from "ramda";

export const selectAll = (state) => pathOr([], ["shoes", "all", "data"], state);

export const selectAllLoading = (state) => pathOr(true, ["shoes", "all", "loading"], state);

export const selectAllError = (state) => pathOr("", ["shoes", "all", "error"], state);

export const selectActive = (state) => pathOr([], ["shoes", "active"], state);

export const selectIsDetail = (state) => {
	const left = pathOr(null, ["shoes", "active", "left"], state);
	const right = pathOr(null, ["shoes", "active", "right"], state);

	return (!isNil(left) && isNil(right)) || (!isNil(right) && isNil(left));
};

export const selectIsCompare = (state) => {
	const left = pathOr(null, ["shoes", "active", "left"], state);
	const right = pathOr(null, ["shoes", "active", "right"], state);
	const leftDigital = pathOr(false, ["isDigital"], left);
	const rightDigital = pathOr(false, ["isDigital"], right);

	const bothActive = !isNil(left) && !isNil(right);
	const isCombo = !equals(leftDigital, rightDigital);
	const isDigital = leftDigital && rightDigital;
	const isPhysical = !leftDigital && !rightDigital;

	return bothActive && (isCombo || isDigital || isPhysical);
};

export const selectIsInactive = (state) => {
	const left = pathOr(null, ["shoes", "active", "left"], state);
	const right = pathOr(null, ["shoes", "active", "right"], state);

	return isNil(left) && isNil(right);
};

export const selectLocation = (state) => pathOr([], ["shoes", "location"], state);

export const selectImagemap = (state) => pathOr([], ["shoes", "imagemap", "data"], state);

export const selectQuantity = (state, side) => pathOr([], ["shoes", "qty", side], state);

export const selectPrice = (state, side) => pathOr("", ["shoes", "price", side], state);
