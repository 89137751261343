import PropTypes from "prop-types";

const ShoePickerNavigation = {
	step: PropTypes.number,
	setCurrentSlide: PropTypes.func,
};

export default {
	ShoePickerNavigation,
};
