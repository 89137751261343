import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import Codification from "@/components/Codification";

import { useLowGPU } from "@/store/ui/ui.hooks";

import defaultProps from "./DetailPartialsPlaceShoe.defaultProps";
import propTypes from "./DetailPartialsPlaceShoe.propTypes";

const DetailPartialsPlaceShoe = ({ isRight }) => {
	const { t } = useTranslation();
	const lowGPU = useLowGPU();

	const placeShoeCodificationTimeout = useRef(null);
	const [shouldCodifyPlaceShoe, setShouldCodifyPlaceShoe] = useState(true);

	const clearPlaceShoeCodificationTimeout = () => {
		if (placeShoeCodificationTimeout.current) {
			clearTimeout(placeShoeCodificationTimeout.current);
			placeShoeCodificationTimeout.current = null;
			setShouldCodifyPlaceShoe(true);
		}
	};

	const setPlaceShoeCodificationTimeout = () => {
		placeShoeCodificationTimeout.current = setTimeout(() => {
			if (!placeShoeCodificationTimeout.current) {
				return;
			}

			clearPlaceShoeCodificationTimeout();
			setShouldCodifyPlaceShoe(false);
			setShouldCodifyPlaceShoe(true);
		}, 5000);
	};

	useEffect(() => {
		setPlaceShoeCodificationTimeout();

		return () => {
			clearPlaceShoeCodificationTimeout();
		};
	}, []);

	return (
		(shouldCodifyPlaceShoe && (
			<Codification
				tag="h3"
				className={!isRight && "u-text-r"}
				align={isRight ? "left" : "right"}
				text={t("titles.place_to_compare")}
				typography={{ primary: true, size: "md" }}
				characterSwitchAmount={4}
				characterNextTrigger={2}
				characterTimeout={20}
				onEnded={setPlaceShoeCodificationTimeout}
				lowGPU={lowGPU}
			/>
		)) ||
		null
	);
};

DetailPartialsPlaceShoe.defaultProps = defaultProps;
DetailPartialsPlaceShoe.propTypes = propTypes;

export default DetailPartialsPlaceShoe;
