const FilterDropdown = {
	options: [],
	activeOption: "",
	label: "",
	onChange: () => null,
};

export default {
	FilterDropdown,
};
