import fetchIntercept from "fetch-intercept";

import { sentryLogApiResponseError } from "@/utils/sentry";

const handleResponse = (response) => {
	const statusCode = response.status;

	if (statusCode && statusCode >= 400) {
		sentryLogApiResponseError(response);
	}

	return response;
};

const setupInterceptor = () => {
	fetchIntercept.register({
		response: handleResponse,
	});
};

export default setupInterceptor;
