export const fontSizeToPixel = {
	"9xl": 240,
	"8xl": 150,
	"7xl": 118,
	"6xl": 100,
	"5xl": 85,
	"4xl": 68,
	"3xl": 63,
	"2xl": 56,
	xl: 50,
	"2lg": 42,
	lg: 36,
	"2md": 33,
	md: 30,
	smmd: 28,
	smmmd: 24,
	sm: 20,
	xs: 18,
	"2xs": 16,
	"3xs": 14,
	"4xs": 10,
};

export const lineHeightToPixel = {
	"9xl": 200,
	"8xl": 139,
	"7xl": 130,
	"6xl": 100,
	"5xl": 85,
	"45xl": 79,
	"4xl": 71,
	"3xl": 63,
	"2xl": 56,
	xl: 55,
	lg: 42,
	"3md": 39,
	"2md": 38,
	md: 33,
	mdsm: 30,
	smmd: 27,
	sm: 24,
	xs: 20,
	"2xs": 16,
};

export const isLandscape = {
	rise: true,
	vertical: false,
	horizontal: true,
};

export const borders = {
	borderWidth: 1,
	radiusDefault: 4,
	radiusSmall: 2,
	radiusLarge: 20,
};

export const shadows = {
	boxShadowY: 4,
	boxShadowBlur: 16,
};

export const block = {
	space: 14,
	horizontalSpace: 12,
};

export const shoeHero = {
	space: 8,
};

export const generic = [
	-1455, -155, -75, -2, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 23, 24, 25, 26, 28, 30, 31,
	32, 36, 39, 40, 42, 44, 48, 50, 53, 54, 56, 60, 64, 67, 70, 75, 80, 84, 96, 120, 128, 140, 142, 145, 170, 185, 189,
	200, 210, 216, 240, 290, 307, 330, 348, 350, 380, 408, 428, 445, 480, 515, 565, 590, 600, 602, 625, 680, 703, 709,
	713, 722, 786, 802, 895,
];
