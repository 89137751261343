import { setIsSession, setIsInteraction, updateTags, setOrientation, removeTag } from "@/store/rfid/rfid.actions";

// INFO: Only high level events are important
// export const LOW_LEVEL_EVENTS = ["tag_off_antenna", "tag_near_antenna", "tag_on_antenna"];
// export const MID_LEVEL_EVENTS = ["tag_off_table", "tag_near_table", "tag_on_hotspot", "orientation_change"];

const HIGH_LEVEL_EVENTS = [
	{
		event: "orientation_change",
		hasData: true,
		action: (d) => setOrientation(d),
	},
	// {
	// 	event: "session_start",
	// 	hasData: false,
	// 	action: setIsSession(true),
	// },
	// {
	// 	event: "session_stop",
	// 	hasData: false,
	// 	action: setIsSession(false),
	// },
	// {
	// 	event: "interaction_start",
	// 	hasData: false,
	// 	action: setIsInteraction(true),
	// },
	// {
	// 	event: "interaction_stop",
	// 	hasData: false,
	// 	action: setIsInteraction(false),
	// },
	{
		event: "tag_active",
		hasData: true,
		action: (d) => updateTags(d, d.id),
	},
	// {
	// 	event: "tag_active_near",
	// 	hasData: true,
	// 	action: (d) => updateTags(d, d.id),
	// },
	// {
	// 	event: "tag_observe",
	// 	hasData: true,
	// 	action: (d) => updateTags(d, d.id),
	// },
	{
		event: "tag_idle",
		hasData: true,
		action: (d) => updateTags(d, d.id),
	},
	// {
	// 	event: "tag_disappear",
	// 	hasData: true,
	// 	action: (d) => removeTag(d.id),
	// },
];

export default HIGH_LEVEL_EVENTS;
