import { useEffect } from "react";
import { isNil } from "ramda";

import { useQuery } from "@/hooks/Router";

import setAnalytics from "@/utils/analytics";

export default () => {
	const query = useQuery();
	let tableId = query.get("table-id") || null;

	if (isNil(tableId)) {
		tableId = "ETW Test";
	}

	useEffect(() => {
		if (!isNil(tableId) && tableId !== "") {
			setAnalytics("table_ID", { table: tableId });
		}
	}, []);
};
