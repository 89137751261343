import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { isEmpty, equals, isNil } from "ramda";
import { v4 as uuidv4 } from "uuid";

import ROUTES from "@/constants/routes";
import { SIDE } from "@/constants/rfid-tags";

import { useQuery } from "@/hooks/Router";
import setAnalytics from "@/utils/analytics";

import { useIdentity, useEndSession, usePrivacy } from "@/store/membership/membership.hooks";
import {
	clearIdentity,
	setEndSession,
	setMenuMinimized,
	getIdentity,
	setPrivacy,
	setSeenWelcome,
	setShowStats,
} from "@/store/membership/membership.actions";
import {
	setResetModal,
	setShoesModal,
	setLanguageModal,
	setOptionsModal,
	setActiveSession,
	setShoeNotFoundModal,
	setLatestAction,
	setMembershipModal,
} from "@/store/ui/ui.actions";
import { useAdminPanelModal, useShoeNotFoundModal, useActiveSession, useMembershipModal } from "@/store/ui/ui.hooks";
import { clearActive, clearQty, clearPrice } from "@/store/shoes/shoes.actions";
import { clearActiveFilters } from "@/store/filters/filters.actions";

import { random } from "@/utils/math";

export default () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const history = useHistory();
	const query = useQuery();

	const { isOpen: adminPanelOpen } = useAdminPanelModal();
	const privacy = usePrivacy();
	const { data: identity, loading, error } = useIdentity();
	const endSession = useEndSession();
	const { data: modalData } = useShoeNotFoundModal();
	const activeSession = useActiveSession();
	const { toggleIsOpen: toggleIsOpenMembership, isOpen } = useMembershipModal();

	const [qrCode, setQrCode] = useState("");
	const [string, setString] = useState("");
	const [stringEnd, setStringEnd] = useState(false);

	const sessionStartTimer = useRef(null);
	const sessionEndTimer = useRef(null);

	const qSessionReload = equals(query.get("session-reload"), "true");

	const setupPageOpen = location.pathname === ROUTES.Setup;

	const onKey = (e) => {
		if (e.key !== "Enter" && e.key !== "Tab") {
			setString((prevString) => prevString.concat(e.key));
		} else if (e.key === "Enter") {
			setStringEnd(true);
		}
	};

	useEffect(() => {
		if (stringEnd && !adminPanelOpen && !setupPageOpen) {
			const barcode = /^[0-9]+$/.test(string);
			if (barcode) {
				dispatch(setShoeNotFoundModal(true, { type: "barcode_scan" }));
				setString("");
				setStringEnd(false);
			} else {
				setQrCode(string);
				setString("");
				setStringEnd(false);
			}
		}
	}, [string, stringEnd]);

	useEffect(() => {
		if (!isEmpty(qrCode) && !loading) {
			if (!isEmpty(identity)) {
				history.push({ pathname: ROUTES.Place, search: location.search });
				dispatch(setEndSession(true));
			} else {
				dispatch(getIdentity(qrCode));
				setQrCode("");
				const tempSession = uuidv4();
				dispatch(setActiveSession(tempSession));
				dispatch(setLatestAction({ type: "session_start", id: uuidv4(), activeSession: tempSession }));
				setAnalytics("session_id", { session_id: random(1, 100000000) });
				setAnalytics("member_scan");
				sessionStartTimer.current = setTimeout(() => {
					dispatch(setLatestAction({ type: "memberpass_scan", id: uuidv4() }));
				}, 200);
			}
		}
	}, [qrCode, identity]);

	useEffect(() => {
		if (!isEmpty(identity))
			if (!privacy) {
				if (
					identity.profile &&
					identity.profile.healthdata &&
					!identity.profile.healthdata.enhancedAcceptance
				) {
					dispatch(setShoeNotFoundModal(true, { type: "privacy_intercept" }));
					dispatch(setMembershipModal(false));
				}

				const workoutPrivacyAccepted =
					identity.profile && identity.profile.healthdata && identity.profile.healthdata.enhancedAcceptance;

				const ntc = [];
				const nrc = [];

				if (identity.aggregates && workoutPrivacyAccepted) {
					if (identity.aggregates.run && identity.aggregates.run.activity[0].count > 0)
						nrc.push(identity.aggregates.run.activity[0]);
					if (identity.aggregates.basketball && identity.aggregates.basketball.activity[0].count > 0)
						ntc.push(identity.aggregates.basketball.activity[0]);
					if (identity.aggregates.hiit && identity.aggregates.hiit.activity[0].count > 0)
						ntc.push(identity.aggregates.hiit.activity[0]);
					if (identity.aggregates.hiking && identity.aggregates.hiking.activity[0].count > 0)
						ntc.push(identity.aggregates.hiking.activity[0]);
					if (identity.aggregates.training && identity.aggregates.training.activity[0].count > 0)
						ntc.push(identity.aggregates.training.activity[0]);

					if (
						!isNil(nrc[0]?.maxes.find((el) => el.metric === "pace")) &&
						!isNil(nrc[0]?.maxes.find((el) => el.metric === "ascent")) &&
						!isNil(nrc[0]?.maxes.find((el) => el.metric === "distance"))
					) {
						dispatch(setShowStats(true));
					} else {
						dispatch(setShowStats(false));
					}
				} else {
					dispatch(setShowStats(false));
				}
			}
	}, [identity]);

	useEffect(() => {
		if (!isEmpty(error)) {
			dispatch(setShoeNotFoundModal(true, { type: "identity_error" }));
			if (isOpen) toggleIsOpenMembership();
		}
	}, [error]);

	useEffect(() => {
		if (endSession) {
			if (activeSession) {
				setAnalytics("session_expiration", { member: !isEmpty(identity) });
				setAnalytics("session_end", { member: !isEmpty(identity) });
				dispatch(setLatestAction({ type: "session_end", id: uuidv4(), activeSession }));
				dispatch(setActiveSession(false));
			}

			setTimeout(() => {
				dispatch(setResetModal(false));
				dispatch(setEndSession(false));
			}, 200);

			dispatch(setShoesModal(false));
			dispatch(setLanguageModal(false));
			dispatch(setOptionsModal(false));

			dispatch(clearActive(SIDE.Left));
			dispatch(clearActive(SIDE.Right));
			dispatch(clearQty(SIDE.Left));
			dispatch(clearQty(SIDE.Right));
			dispatch(clearPrice(SIDE.Left));
			dispatch(clearPrice(SIDE.Right));
			dispatch(setMenuMinimized(false));
			dispatch(setShowStats(null));
			dispatch(clearActiveFilters());
			dispatch(setSeenWelcome(false));
			dispatch(setShoeNotFoundModal(false, { type: "not_found", side: "left" }));
			dispatch(setPrivacy(false));
			dispatch(clearIdentity());

			query.delete(SIDE.Left);
			query.delete(SIDE.Right);

			if (qSessionReload) {
				setAnalytics("window_reload", { member: !isEmpty(identity) });
				if (activeSession) dispatch(setLatestAction({ type: "session_end", id: uuidv4(), activeSession }));
				setTimeout(() => window.location.reload(), 500);
			} else if (modalData.type === "network_offline") {
				history.push({ pathname: ROUTES.Index, search: location.search });
			} else {
				history.push({ pathname: ROUTES.Place, search: location.search });
			}
		}
	}, [endSession]);

	useEffect(() => {
		// Setup manual console import
		window.importMemberData = (data) => {
			setTimeout(() => {
				history.push({ pathname: ROUTES.Place, search: location.search });
				dispatch(setEndSession(true));
				dispatch(getIdentity("", null, data));
			}, 1000);
		};
		document.addEventListener("keypress", onKey, false);

		return () => {
			document.removeEventListener("keypress", onKey, false);
			if (!isNil(sessionStartTimer.current)) {
				clearTimeout(sessionStartTimer.current);
				sessionStartTimer.current = null;
			}
			if (!isNil(sessionEndTimer.current)) {
				clearTimeout(sessionEndTimer.current);
				sessionEndTimer.current = null;
			}
		};
	}, []);
};
