import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import ROUTES from "@/constants/routes";

// =============================================================================
// Root
// =============================================================================
const SetupButton = () => {
	const history = useHistory();
	const location = useLocation();

	let resetTimeout = useRef(null);
	const [counter, setCounter] = useState(0);

	const clearResetTimeout = () => {
		if (resetTimeout) {
			clearTimeout(resetTimeout);
			resetTimeout = null;
		}
	};

	const setResetTimeout = () => {
		resetTimeout = setTimeout(() => {
			setCounter(0);
			clearResetTimeout();
		}, 1000);
	};

	const onClick = () => {
		setCounter(counter + 1);
	};

	useEffect(() => {
		if (counter >= 5) {
			history.push({
				pathname: ROUTES.Setup,
				search: location.search,
			});
		} else if (counter > 0) {
			setResetTimeout();
		}
		return () => {
			clearResetTimeout();
		};
	}, [counter]);

	// eslint-disable-next-line jsx-a11y/control-has-associated-label
	return <button className="c-setup-button" type="button" onClick={onClick} />;
};

export default SetupButton;
