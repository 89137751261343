import React from "react";
import cn from "classnames";
import { motion } from "framer-motion";

import { map } from "@/utils/math";
import { DEFAULT_EASE, DEFAULT_DURATION } from "@/constants/transition";

import propTypes from "./ProgressBar.propTypes";
import defaultProps from "./ProgressBar.defaultProps";

// =============================================================================
// Root
// =============================================================================
const ProgressBar = ({ className = "", value, min, max }) => {
	const x = map(value, min, max, -100, 0);

	return (
		<div className={cn("c-progress-bar", className)}>
			<motion.div
				className="c-progress-bar__value"
				initial={{ x: "-100%" }}
				animate={{ x: `${x}%` }}
				transition={{ type: DEFAULT_EASE, duration: DEFAULT_DURATION }}
			/>
		</div>
	);
};

ProgressBar.propTypes = propTypes;

ProgressBar.defaultProps = defaultProps;

export default ProgressBar;
