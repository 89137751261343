import React from "react";
import { pathOr } from "ramda";
import { motion, AnimatePresence } from "framer-motion";
import cn from "classnames";

import { isLangKorean } from "@/utils/i18n";

import GridBlock from "@/components/GridBlock";
import Codification from "@/components/Codification";
import Typography from "@/components/Typography";

import { fadeMove, fadeMoveKo } from "@/animations";

import propTypes from "./ProductGroupingPartialsTitleDesc.propTypes";
import defaultProps from "./ProductGroupingPartialsTitleDesc.defaultProps";

const ProductGroupingPartialsTitleDesc = ({
	data,
	id,
	lang,
	lowGPU,
	horizontal,
	vertical = false,
	size = "4xl",
	className,
	lineHeightSize,
}) => (
	<GridBlock id={id}>
		<AnimatePresence>
			<motion.div
				className={className}
				initial="hidden"
				animate="show"
				variants={lang === "ko" ? fadeMoveKo : fadeMove}
			>
				<Codification
					text={pathOr("", ["content", "heading"], data)}
					className="u-mb-16"
					typography={{
						primary: true,
						size: size,
						tag: "h1",
						uppercase: true,
						lineHeightSize: !isLangKorean() ? "xl" : "",
					}}
					characterSwitchAmount={4}
					characterNextTrigger={2}
					characterTimeout={20}
					lowGPU={lowGPU}
					extend={vertical}
				/>
				<Typography size="sm" lineHeightSize={lineHeightSize} className="u-mr-8">
					{pathOr("", ["content", "body"], data)}
				</Typography>
			</motion.div>
		</AnimatePresence>
	</GridBlock>
);

ProductGroupingPartialsTitleDesc.propTypes = propTypes;
ProductGroupingPartialsTitleDesc.defaultProps = defaultProps;

export default ProductGroupingPartialsTitleDesc;
