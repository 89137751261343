import React, { forwardRef } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import { Link } from "react-router-dom";

import Icon from "@/components/Icon";
import Typography from "@/components/Typography";
import IconPropTypes from "@/components/Icon/Icon.propTypes";
import IconDefaultProps from "@/components/Icon/Icon.defaultProps";
import TypographyPropTypes from "@/components/Typography/Typography.propTypes";
import TypographyDefaultProps from "@/components/Typography/Typography.defaultProps";

import defaultProps from "./Button.defaultProps";
import propTypes from "./Button.propTypes";

// =============================================================================
// Root
// =============================================================================
const Button = forwardRef(({ className = "", children, type = "", url, onClick, style = {} }, ref) => {
	const rootClassName = cn("c-button", className);

	const MotionLink = motion(Link, { forwardMotionProps: true });

	if (url) {
		return (
			<MotionLink className={rootClassName} to={url} ref={ref}>
				{children}
			</MotionLink>
		);
	}

	return (
		<button
			// eslint-disable-next-line react/button-has-type
			type={type}
			style={style}
			className={cn(rootClassName, { highlight: type === "highlight" })}
			onClick={onClick}
			ref={ref}
		>
			<span className="c-button__inner u-inline-2">{children}</span>
		</button>
	);
});

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

// =============================================================================
// Children
// =============================================================================
const ButtonText = (props) => <Typography primary={false} size="2xs" weight="normal" tag="span" {...props} />;

ButtonText.propTypes = TypographyPropTypes;
ButtonText.defaultProps = TypographyDefaultProps;
Button.Text = ButtonText;

const ButtonIcon = (props) => <Icon {...props} />;

ButtonIcon.propTypes = IconPropTypes;
ButtonIcon.defaultProps = IconDefaultProps;
Button.Icon = ButtonIcon;

export const MotionButton = motion(Button, { forwardMotionProps: true });

export default Button;
