import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import store from "@/store";
import Icons from "@/components/Icons";
import App from "@/App";

import { setupSentry } from "@/utils/sentry";
import { initTagEventLogging } from "@/utils/logging";

import "@/styles/main.scss";

setupSentry();
initTagEventLogging();

ReactDOM.render(
	<React.StrictMode>
		<Icons />

		<BrowserRouter>
			<Provider store={store}>
				<App />
			</Provider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);
