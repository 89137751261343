import { pathOr, equals, innerJoin, filter, propEq, includes, propOr } from "ramda";

import { STATUS } from "@/constants/rfid-tags";

export const checkIsStatusNear = (tag) => pathOr("", ["status"], tag) === STATUS.Near;

export const checkIsStatusActive = (tag) => pathOr("", ["status"], tag) === STATUS.Active;

export const checkIsStatusObserve = (tag) => pathOr("", ["status"], tag) === STATUS.Observe;

export const checkIsStatusIdle = (tag) => pathOr("", ["status"], tag) === STATUS.Idle;

export const getIdleTags = (data) => filter(propEq("status", STATUS.Idle))(data);

export const getActiveCompareTags = (data) =>
	innerJoin((tag, status) => equals(tag.status, status), data, [STATUS.Active]);

export const hasStatusToIgnore = (data) => includes(propOr(null, "status", data), [STATUS.Idle]);

export const getTagId = (data, defautlValue = null) => pathOr(defautlValue, ["id"], data);
