import React, { useEffect, useState, useMemo } from "react";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { isNil, pathOr, propEq, find, isEmpty, equals } from "ramda";

import { SIDE } from "@/constants/rfid-tags";
import ROUTES from "@/constants/routes";
import CURRENCIES from "@/constants/currencies";
import AVAILABILITY from "@/constants/availability";

import Button, { Button2 } from "@/components/Button";
import Typography from "@/components/Typography";
import SliderNavigation from "@/components/SliderNavigation";
import SliderShoeHero from "@/components/SliderShoeHero";
import Codification from "@/components/Codification";
import GridBlock from "@/components/GridBlock";
import ButtonIcon from "@/components/ButtonIcon";
import Block from "@/components/Block";
import ReviewStars from "@/components/ReviewStars";
import Status from "@/components/Status";
import { MembershipMenu } from "@/components/Membership";
import Icon from "@/components/Icon";
import FavoritesButton from "@/components/FavoritesButton";
import Image from "@/components/Image";
import ShoeHeading from "@/components/ShoeHeading";

import { useFlippedOrientation, useOptionsModal, useLowGPU, useStoreID } from "@/store/ui/ui.hooks";
import {
	useAllShoes,
	useActiveShoes,
	useQtyLeft,
	useQtyRight,
	usePriceLeft,
	usePriceRight,
} from "@/store/shoes/shoes.hooks";
import { updateVisible, clearActive, clearQty, clearPrice } from "@/store/shoes/shoes.actions";
import { removeTag } from "@/store/rfid/rfid.actions";
import { useMembershipActive, useIdentity, useWelcomeRail } from "@/store/membership/membership.hooks";

import useSlider from "@/hooks/Slider";
import useGrid from "@/hooks/Grid";
import { useQuery } from "@/hooks/Router";

import compareGrid from "@/assets/grids/compare.json";
import compareGridMembership from "@/assets/grids/compare_membership.json";
import compareGridMembershipNone from "@/assets/grids/compare_membership_none.json";
import compareHorizontalGrid from "@/assets/grids/compareHorizontal.json";
import compareHorizontalGridMembership from "@/assets/grids/compare_membershipHorizontal.json";
import compareHorizontalGridMembershipNone from "@/assets/grids/compare_membership_noneHorizontal.json";

import { flipSides } from "@/utils/shoes";
import getAvailability from "@/utils/data/getAvailability";
import { getTechnologyStyleBlock } from "@/utils/data/productGroupings";
import setAnalytics from "@/utils/analytics";
import { sentryLogException } from "@/utils/sentry";

const RawAvailabilityStatus = ({ direction, isFlipped }) => {
	const { t } = useTranslation();

	const qtyLeft = useQtyLeft();
	const qtyRight = useQtyRight();
	const { left, right } = useActiveShoes();

	const isRight = direction === "right";

	let availability = null;
	if (!isFlipped) {
		if (isRight && !isNil(right) && !isNil(qtyRight)) {
			availability = qtyRight;
		} else if (!isRight && !isNil(left) && !isNil(qtyLeft)) {
			availability = qtyLeft;
		}
	} else if (isFlipped) {
		if (!isRight && !isNil(left) && !isNil(qtyRight)) {
			availability = qtyLeft;
		} else if (isRight && !isNil(right) && !isNil(qtyLeft)) {
			availability = qtyRight;
		}
	}

	if (isEmpty(availability)) return null;
	availability = getAvailability(availability);

	return (
		<Status
			label={t(`status.${[availability]}`)}
			warning={availability === AVAILABILITY.LIMITED}
			error={availability === AVAILABILITY.NONE}
		/>
	);
};

const AvailabilityStatus = React.memo(RawAvailabilityStatus);

const RawBlock1 = ({
	className,
	blockHeaderClassName,
	blockContentClassName,
	name,
	direction,
	isFlipped,
	lowGPU,
	showPrice,
	showStock,
	isFavoritesEnabled,
	qForm,
	identity,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const priceLeft = usePriceLeft();
	const priceRight = usePriceRight();
	const { data: storeID } = useStoreID();
	const { left, right } = useActiveShoes();
	const shoes = useActiveShoes();

	const [shoePriceLeft, setShoePriceLeft] = useState(null);
	const [shoePriceRight, setShoePriceRight] = useState(null);

	const isRight = direction === "right";

	let price = null;
	let shoe = null;

	if (!isFlipped) {
		if (isRight && !isNil(right)) {
			price = shoePriceRight;
			shoe = right;
		} else if (!isRight && !isNil(left)) {
			price = shoePriceLeft;
			shoe = left;
		}
	} else if (isFlipped) {
		if (!isRight && !isNil(left)) {
			price = shoePriceLeft;
			shoe = right;
		} else if (isRight && !isNil(right)) {
			price = shoePriceRight;
			shoe = left;
		}
	}

	let text = "";
	if (!isEmpty(price) && !isEmpty(storeID)) text = `${CURRENCIES[storeID?.currencies[0]]}${price}`;

	const handleShowDetail = (tempDirection) => {
		dispatch(updateVisible(false, tempDirection === SIDE.Right ? SIDE.Left : SIDE.Right));
		dispatch(updateVisible(true, tempDirection));
		history.push({ pathname: ROUTES.Detail, search: location.search });
	};

	const handleRemove = (side) => {
		dispatch(removeTag(pathOr("", ["epc"], shoes[side])));

		dispatch(clearActive(flipSides(isFlipped, side)));
		dispatch(clearQty(flipSides(isFlipped, side)));
		dispatch(clearPrice(flipSides(isFlipped, side)));
	};

	const renderRemoveShoeButton = (side) => {
		const hasRemoveButton = side === SIDE.Left ? left.isDigital : right.isDigital;

		if (!hasRemoveButton) {
			return null;
		}

		return (
			<Button2
				className="c-button--rounded c-button--outline"
				onClick={() => handleRemove(side)}
				delay={0}
				horizontal={qForm === "horizontal"}
			>
				{t("buttons.remove_shoe")}
			</Button2>
		);
	};

	useEffect(() => {
		if (!isNil(priceLeft)) setShoePriceLeft(priceLeft);
		if (!isNil(priceRight)) setShoePriceRight(priceRight);
	}, [priceLeft, priceRight]);

	return !isNil(shoe) ? (
		<Block className={cn("c-block--floating-content", className)}>
			<Block.Header className={blockHeaderClassName}>
				{qForm === "horizontal" ? (
					<ShoeHeading className={cn("u-fill-width u-inline-2")}>
						<ShoeHeading.Title size="smmd">{shoe.detail.content.name}</ShoeHeading.Title>
					</ShoeHeading>
				) : (
					<Codification
						text={name}
						typography={{ primary: true, size: "md", tag: "span", uppercase: true }}
						characterSwitchAmount={4}
						characterNextTrigger={2}
						characterTimeout={20}
						lowGPU={lowGPU}
					/>
				)}
			</Block.Header>

			<Block.Content className={cn("u-text-c u-flex u-ai-center u-inline-16", blockContentClassName)}>
				{!isEmpty(price) && showPrice && (
					<Typography uppercase size="sm">
						{text}
					</Typography>
				)}

				{showStock && <AvailabilityStatus direction={direction} isFlipped={isFlipped} />}
			</Block.Content>

			<Block.Footer className="u-flex u-inline-5">
				<Button className="c-button--rounded" onClick={() => handleShowDetail(direction)}>
					<Button.Text size={qForm === "horizontal" ? "3xs" : "2xs"}>{t("buttons.more_details")}</Button.Text>
				</Button>

				{renderRemoveShoeButton(flipSides(isFlipped, direction))}
				{isFavoritesEnabled && !isEmpty(identity) && <FavoritesButton shoe={shoe} iconOnly page="compare" />}
			</Block.Footer>
		</Block>
	) : null;
};

const Block1 = React.memo(RawBlock1);

const Compare = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const query = useQuery();
	const isFlipped = useFlippedOrientation();
	const { data: allShoes, loading } = useAllShoes();
	const { data: identity, error: identityError } = useIdentity();
	const { toggleIsOpen } = useOptionsModal();
	const { left, right } = useActiveShoes();
	const lowGPU = useLowGPU();
	const membershipActive = useMembershipActive();
	const { isGridLoading, start: startGrid, end: endGrid, shouldRerenderGrid } = useGrid();
	const welcomeRail = useWelcomeRail();
	const {
		slider,
		currentSlide,
		initSlider,
		speed,
		handlePrev,
		handleNext,
		updateCurrentSlide,
		handleDestroy,
		direction: globalDirection,
	} = useSlider({
		speed: 10,
	});

	const [showAllUgc, setShowAllUgc] = useState(false);
	const [showRail, setShowRail] = useState(false);
	const [gridShoeLeft, setGridShoeLeft] = useState(null);
	const [gridShoeRight, setGridShoeRight] = useState(null);
	const [recommendationCopyLeft, setRecommendationCopyLeft] = useState("");
	const [recommendationCopyRight, setRecommendationCopyRight] = useState("");

	const leftId = pathOr(null, ["scc"], left);
	const rightId = pathOr(null, ["scc"], right);
	const hasLeftData = !isNil(pathOr(null, ["detail"], left));
	const hasRightData = !isNil(pathOr(null, ["detail"], right));

	const showStock = query.get("show-stock") || null;
	const showPrice = query.get("show-price") || false;
	const isFavoritesEnabled = equals(query.get("favorites"), "true");
	const qForm = query.get("form") || "rise";

	// #region FUNCTIONS
	const handleShowUgc = () => {
		// Only show UGC slides when both shoes have UGC data
		const leftActiveSlides = pathOr([], ["detail", "slides"], left);
		const rightActiveSlides = pathOr([], ["detail", "slides"], right);

		const lastLeftSlide = leftActiveSlides[leftActiveSlides.length - 1];
		const lastRightSlide = rightActiveSlides[rightActiveSlides.length - 1];

		if (
			lastLeftSlide &&
			lastLeftSlide.images &&
			lastLeftSlide.images.length > 1 &&
			lastRightSlide &&
			lastRightSlide.images &&
			lastRightSlide.images.length > 1
		) {
			// INFO (Katia) -  Only Show All UGC when both shoes have 2 images, otherwise show 1.
			setShowAllUgc(true);
		} else {
			setShowAllUgc(false);
		}

		initSlider({ slides: Math.min(leftActiveSlides.length, rightActiveSlides.length) });
	};

	const handleSetCurrentSlide = (i) => {
		updateCurrentSlide(i);
	};

	const handleReviewsPage = (direction) => {
		dispatch(updateVisible(false, flipSides(isFlipped, direction === SIDE.Right ? SIDE.Left : SIDE.Right)));
		dispatch(updateVisible(true, flipSides(isFlipped, direction)));
		history.push({ pathname: ROUTES.Reviews, search: location.search });
	};

	const handleSetProductGroupings = (id, direction) => {
		dispatch(updateVisible(false, flipSides(isFlipped, direction === SIDE.Right ? SIDE.Left : SIDE.Right)));
		dispatch(updateVisible(true, flipSides(isFlipped, direction)));

		history.push({
			pathname: ROUTES.ProductGrouping,
			search: location.search,
			state: { id, side: direction === SIDE.Right ? SIDE.Right : SIDE.Left },
		});
	};
	// #endregion FUNCTIONS

	// #region LIFECYCLE HOOKS
	useEffect(() => {
		if (!isNil(left) && !isNil(right)) {
			setAnalytics("product", { product_id: leftId, member: !isEmpty(identity) });
			setAnalytics("product", { product_id: rightId, member: !isEmpty(identity) });
			setAnalytics("products_compared", {
				compare_product_ids: `${leftId}, ${rightId}`,
				member: !isEmpty(identity),
			});

			// Reset slide to the first one
			handleShowUgc();
			updateCurrentSlide(0);
		}
	}, [left, right]);

	useEffect(() => {
		try {
			if (left && gridShoeLeft && gridShoeLeft.recommendations.length > 0) {
				left.detail.tempRecommendations = gridShoeLeft.recommendations;
			} else if (left) {
				left.detail.tempRecommendations = left.detail.recommendations;
			}
			if (right && gridShoeRight && gridShoeRight.recommendations.length > 0) {
				right.detail.tempRecommendations = gridShoeRight.recommendations;
			} else if (right) {
				right.detail.tempRecommendations = right.detail.recommendations;
			}

			if (
				left &&
				right &&
				gridShoeLeft &&
				gridShoeLeft.recommendations.length > 0 &&
				gridShoeRight &&
				gridShoeRight.recommendations.length > 0
			) {
				left.detail.tempRecommendations = left.detail.tempRecommendations.filter(
					(leftItem) => leftItem.copy !== right.detail.tempRecommendations[0].copy
				);
			} else if (
				left &&
				right &&
				gridShoeLeft &&
				gridShoeLeft.recommendations.length === 0 &&
				gridShoeRight &&
				gridShoeRight.recommendations.length === 0
			) {
				left.detail.tempRecommendations = left.detail.recommendations;
				right.detail.tempRecommendations = right.detail.recommendations;
			}
		} catch (e) {
			if (left && !isNil(left.detail)) left.detail.tempRecommendations = [];
			if (right && !isNil(right.detail)) right.detail.tempRecommendations = [];

			sentryLogException("Compare", e);
		}
	}, [gridShoeLeft, gridShoeRight, left, right]);

	useEffect(() => {
		if (!isNil(leftId)) {
			const membershipShoe = find(propEq("id", leftId))(allShoes);
			if (!isEmpty(identity)) {
				setGridShoeLeft(membershipShoe);
			}
		}
		if (!isNil(rightId)) {
			const membershipShoe = find(propEq("id", rightId))(allShoes);
			if (!isEmpty(identity)) {
				setGridShoeRight(membershipShoe);
			}
		}
	}, [allShoes, left, right]);

	useEffect(() => {
		setShowRail(false);
		let showRailTimer = null;

		const reloadTimer = setTimeout(() => {
			endGrid();
			let grid = qForm === "horizontal" ? compareHorizontalGrid : compareGrid;
			if (membershipActive && !isNil(left) && !isNil(right)) {
				if (!isEmpty(identity))
					grid = qForm === "horizontal" ? compareHorizontalGridMembership : compareGridMembership;
				else grid = qForm === "horizontal" ? compareHorizontalGridMembershipNone : compareGridMembershipNone;
			}
			startGrid(grid);

			try {
				if (left && !isNil(left.detail)) {
					// let recommendationIntervalLeft = null;
					// if (left.detail.tempRecommendations.length > 1) {
					// 	setRecommendationCopyLeft(left.detail.tempRecommendations[0].copy);
					// 	let count = 1;
					// 	recommendationIntervalLeft = setInterval(() => {
					// 		setRecommendationCopyLeft(left.detail.tempRecommendations[count].copy);
					// 		count += 1;
					// 		if (count === left.detail.tempRecommendations.length) {
					// 			count = 0;
					// 		}
					// 	}, 3000);
					// } else
					if (left.detail.tempRecommendations.length > 0) {
						setRecommendationCopyLeft(left.detail.tempRecommendations[0].copy);
					}
				}
				if (right && !isNil(right.detail)) {
					// let recommendationIntervalRight = null;
					// if (!isNil(right.detail.tempRecommendations) && right.detail.tempRecommendations.length > 1) {
					// 	setRecommendationCopyRight(right.detail.tempRecommendations[0].copy);
					// 	let count = 1;
					// 	recommendationIntervalRight = setInterval(() => {
					// 		setRecommendationCopyRight(right.detail.tempRecommendations[count].copy);
					// 		count += 1;
					// 		if (count === right.detail.tempRecommendations.length) {
					// 			count = 0;
					// 		}
					// 	}, 3000);
					// } else
					if (right.detail.tempRecommendations.length > 0)
						setRecommendationCopyRight(right.detail.tempRecommendations[0].copy);
				}
			} catch (e) {
				if (left && !isNil(left.detail)) left.detail.tempRecommendations = [];
				if (right && !isNil(right.detail)) right.detail.tempRecommendations = [];

				sentryLogException("Compare", e);
			}
		}, 10);

		if (!isEmpty(identity)) {
			showRailTimer = setTimeout(() => {
				setShowRail(true);
			}, 1000);
		}

		return () => {
			clearTimeout(reloadTimer);
			clearTimeout(showRailTimer);
		};
	}, [language, gridShoeLeft, gridShoeRight, identity]);

	useEffect(() => {
		setAnalytics("compare", { member: !isEmpty(identity) });
		return () => {
			handleDestroy();
		};
	}, []);
	// #endregion LIFECYCLE HOOKS

	// #region RENDER
	const renderRecommendationHighlight = useMemo(() => (recommendation, popular = false) => {
		const copy = t(recommendation.text, recommendation.data ?? null);
		const newCopy = copy.split(" ");
		const highlight = newCopy.splice(-2);
		let string = "";
		if (!popular) string += t("recommendations.because");
		string += newCopy.join(" ");
		string += " ";
		string += highlight.join(" ");
		const size = qForm === "horizontal" ? "md" : "lg";
		const lineHeightSize = qForm === "horizontal" ? "smmd" : "md";
		return (
			<>
				<Codification
					text={string}
					typography={{ primary: true, size, lineHeightSize, uppercase: true }}
					characterSwitchAmount={4}
					characterNextTrigger={2}
					characterTimeout={10}
					lowGPU={lowGPU}
					className={cn({ "u-mt-5": qForm === "horizontal" })}
				/>
			</>
		);
	});

	const hero = useMemo(() => (code, missingDetailImages, slides, direction) => (
		<SliderShoeHero
			horizontal={qForm === "horizontal"}
			code={code}
			showAllUgc={showAllUgc}
			slides={slides}
			missingDetailImages={missingDetailImages}
			handleSetProductGroupings={(id) => handleSetProductGroupings(id, direction)}
			currentSlide={currentSlide}
			handlePrev={handlePrev}
			handleNext={handleNext}
			speed={speed}
			direction={globalDirection}
		/>
	));

	const block2 = useMemo(() => (data, direction) => (
		<Block
			className="c-block--floating-content c-block--inset"
			onClick={!isNil(pathOr(null, ["feedback", "reviews", 0], data)) ? () => handleReviewsPage(direction) : null}
		>
			<Block.Header>
				<Typography tag="p" size={qForm === "horizontal" ? "3xs" : "2xs"}>
					{data.feedback.reviews.length} {t("generic.reviews")}
				</Typography>
			</Block.Header>

			<Block.Content
				className={`c-block--inset u-flex u-ai-center ${
					isEmpty(identity) ? "u-jc-center" : "u-jc-left"
				} u-fill-width`}
			>
				<Codification
					text={data.feedback.avg_rating ? `${data.feedback.avg_rating} / 5` : t("generic.no_reviews")}
					typography={{
						primary: true,
						size: data.feedback.avg_rating && isEmpty(identity) ? "3xl" : "lg",
						uppercase: true,
					}}
					className={cn({ "u-mt-2": qForm === "horizontal" && !isEmpty(identity) })}
					characterSwitchAmount={4}
					characterNextTrigger={2}
					characterTimeout={20}
					lowGPU={lowGPU}
				/>
			</Block.Content>

			<Block.Footer className="u-flex u-jc-between u-ac-end u-mt-0">
				<ReviewStars
					className="u-ai-end"
					rating={data.feedback.avg_rating}
					size={qForm === "horizontal" ? 4 : 3}
					space={1}
				/>

				{!isNil(pathOr(null, ["feedback", "reviews", 0], data)) && (
					<ButtonIcon className="c-icon--size-3" icon="plus" />
				)}
			</Block.Footer>
		</Block>
	));

	const block3 = useMemo(() => (productGrouping, asset, direction) => {
		const hasDetail = pathOr([], ["assets"], productGrouping).length > 2;

		if (isNil(productGrouping)) {
			return <></>;
		}

		const background =
			qForm === "horizontal"
				? productGrouping.assets?.find((img) => /bottom/.test(img.portrait))?.portrait || asset
				: asset;
		const logo = productGrouping.assets?.find((img) => /logo/.test(img.portrait))?.portrait;

		return (
			<Block
				className="s-visual c-block--floating-content c-block--inset"
				onClick={hasDetail ? () => handleSetProductGroupings(productGrouping.id, direction) : null}
			>
				<Block.Background
					className={cn("c-block__image c-image--cover compare-page", {
						"no-detail": !hasDetail,
						recommended: !isEmpty(identity),
					})}
					src={background}
				/>

				<Block.Header className="u-m-0">
					<Typography tag="span" size={qForm === "horizontal" ? "3xs" : "2xs"}>
						{t("titles.technology_style")}
					</Typography>
				</Block.Header>

				<Block.Content className="u-flex u-jc-center u-ai-center u-text-c u-fill-width">
					{qForm === "horizontal" && logo ? (
						<Image className="c-product-style__logo" src={logo} />
					) : (
						<Codification
							className={cn({ "u-mt-2": qForm === "horizontal" })}
							text={pathOr("", ["meta", "name"], productGrouping)}
							typography={{ primary: true, size: "md", uppercase: true }}
							characterSwitchAmount={4}
							characterNextTrigger={2}
							characterTimeout={20}
							lowGPU={lowGPU}
						/>
					)}
				</Block.Content>

				<Block.Footer className="u-flex u-jc-end u-ac-end">
					{hasDetail && <ButtonIcon className="c-icon--size-3" icon="plus" />}
				</Block.Footer>
			</Block>
		);
	});

	const block4 = useMemo(() => (recommendation, popularWith, side) => {
		return (
			<Block className="c-block--inset c-block--floating-content">
				{recommendation ? (
					<Block.Header>
						<Typography tag="p" size={qForm === "horizontal" ? "3xs" : "2xs"}>
							{t("titles.recommended_for_you")}
						</Typography>
					</Block.Header>
				) : (
					<Block.Header>
						<Typography tag="p" size={qForm === "horizontal" ? "3xs" : "2xs"}>
							{t("titles.popular_with")}
						</Typography>
					</Block.Header>
				)}

				<Block.Content
					className={cn("c-block--inset u-flex u-jc-start u-ai-center recommendation", {
						recommendation,
					})}
				>
					{recommendation
						? renderRecommendationHighlight(
								side === "left" ? recommendationCopyLeft : recommendationCopyRight
						  )
						: renderRecommendationHighlight(popularWith, true)}
				</Block.Content>
			</Block>
		);
	});

	return (
		!shouldRerenderGrid &&
		!isGridLoading &&
		!loading && (
			<div className="compare">
				{hasLeftData && (
					<GridBlock id="CompareContentLeft">
						{hero(
							pathOr(null, ["meta", "color_code"], left.detail),
							left.detail.missingDetailImages,
							left.detail.slides,
							SIDE.Left
						)}
					</GridBlock>
				)}

				{hasRightData && (
					<GridBlock className="right" id="CompareContentRight">
						{hero(
							pathOr(null, ["meta", "color_code"], right.detail),
							right.detail.missingDetailImages,
							right.detail.slides,
							SIDE.Right
						)}
					</GridBlock>
				)}

				{hasLeftData && (
					<>
						<GridBlock id="CompareNavA">
							<Block1
								className="c-block--inset u-pl-7"
								blockHeaderClassName=""
								blockContentClassName="u-pl-7"
								name={left.detail.content.name}
								direction={isFlipped ? SIDE.Right : SIDE.Left}
								isFlipped={isFlipped}
								lowGPU={lowGPU}
								showPrice={showPrice}
								showStock={showStock}
								isFavoritesEnabled={isFavoritesEnabled && !isEmpty(identity)}
								qForm={qForm}
								identity={identity}
							/>
						</GridBlock>

						<GridBlock id="CompareNavB">{block2(left.detail, SIDE.Left)}</GridBlock>

						<GridBlock id="CompareNavC">
							{block3(
								getTechnologyStyleBlock(pathOr([], ["productGroupings"], left.detail), qForm),
								pathOr(null, ["assets", "zoom_toe", "square"], left.detail),
								SIDE.Left
							)}
						</GridBlock>
					</>
				)}

				{hasLeftData && !isEmpty(identity) && (
					<GridBlock id="CompareNavD">
						{block4(left.detail.tempRecommendations[0], left.detail.popularWith, SIDE.Left)}
					</GridBlock>
				)}

				{hasRightData && (
					<>
						<GridBlock id="CompareNavE">
							<Block1
								className="c-block--inset u-pl-7"
								blockHeaderClassName=""
								blockContentClassName="u-pl-7"
								name={right.detail.content.name}
								direction={isFlipped ? SIDE.Left : SIDE.Right}
								isFlipped={isFlipped}
								lowGPU={lowGPU}
								showPrice={showPrice}
								showStock={showStock}
								isFavoritesEnabled={isFavoritesEnabled && !isEmpty(identity)}
								qForm={qForm}
								identity={identity}
							/>
						</GridBlock>

						<GridBlock id="CompareNavF">{block2(right.detail, SIDE.Right)}</GridBlock>

						<GridBlock id="CompareNavG">
							{block3(
								getTechnologyStyleBlock(pathOr([], ["productGroupings"], right.detail), qForm),
								pathOr(null, ["assets", "zoom_toe", "square"], right.detail),
								SIDE.Right
							)}
						</GridBlock>
					</>
				)}

				{hasRightData && !isEmpty(identity) && (
					<GridBlock id="CompareNavH">
						{block4(right.detail.tempRecommendations[0], right.detail.popularWith, SIDE.Right)}
					</GridBlock>
				)}

				<GridBlock id="CompareBottomLeft" className="u-flex u-ai-end">
					<div
						onClick={toggleIsOpen}
						onKeyDown={toggleIsOpen}
						role="button"
						tabIndex={0}
						className="u-inline-flex u-ai-end u-jc-end u-p-1"
					>
						<Icon className="c-icon-membership--size-9" type="options-menu" />
						<Icon className="c-icon-membership--size-9" type="caret-up" />
					</div>
				</GridBlock>

				<GridBlock id="CompareBottomCenter" className="u-flex u-jc-center u-ai-center">
					<SliderNavigation
						setCurrentSlide={handleSetCurrentSlide}
						slides={pathOr(0, ["totalSlides"], slider)}
						step={currentSlide}
						speed={speed}
					/>
				</GridBlock>

				{membershipActive && (
					<GridBlock id="CompareBottomRight">
						<MembershipMenu />
					</GridBlock>
				)}
			</div>
		)
	);
	// #endregion RENDER
};

export default Compare;
