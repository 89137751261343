import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { pathOr, isEmpty } from "ramda";
import { useTranslation } from "react-i18next";

import { setFlippedOrientation } from "@/store/ui/ui.actions";
import { useFlippedOrientation } from "@/store/ui/ui.hooks";
import { useAllFilters } from "@/store/filters/filters.hooks";

import useNetwork from "@/hooks/Network";
import { useQuery } from "@/hooks/Router";

import ROUTES from "@/constants/routes";
import { pixels } from "@/utils/math";
import { fontSizeToPixel, isLandscape } from "@/constants/pixel-sizes";

import AttractLoop from "@/components/AttractLoop";
import Typography from "@/components/Typography";
import Icon from "@/components/Icon";

import { useIdentity } from "@/store/membership/membership.hooks";

import setAnalytics from "@/utils/analytics";

const Index = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const isFlipped = useFlippedOrientation();
	const { t } = useTranslation();
	const query = useQuery();
	const { error: filterError } = useAllFilters();

	const { data: identity } = useIdentity();

	const networkOnline = useNetwork();

	const [isBlurred, setIsBlurred] = useState(false);

	const qForm = query.get("form") || "rise";

	useEffect(() => {
		setAnalytics("index", { member: !isEmpty(identity) });
	}, []);

	// #region FUNCTIONS
	const handleTapVideo = (e) => {
		const yPos = pathOr(0, ["clientY"], e);
		const center = window.innerHeight / 2;

		if (qForm !== "vertical") {
			if (!isFlipped && yPos <= center) {
				dispatch(setFlippedOrientation(true));
			} else if (isFlipped && yPos >= center) {
				dispatch(setFlippedOrientation(false));
			}
		}

		if (!networkOnline || !isEmpty(filterError)) {
			setIsBlurred(true);
			setTimeout(() => setIsBlurred(false), 5000);
		} else {
			history.push({ pathname: ROUTES.Place, search: location.search });
		}

		if (isBlurred) setIsBlurred(false);
	};
	// #endregion FUNCTIONS

	return (
		<button type="button" className="u-fill-space index-page" onClick={handleTapVideo}>
			<AttractLoop isVisible isBlurred={isBlurred} />
			<span className="info-tag">
				{(!networkOnline || !isEmpty(filterError)) && !isBlurred ? (
					<div className="u-flex u-ai-center u-jc-end">
						<p
							style={{
								fontSize: pixels(fontSizeToPixel["2xs"], isLandscape[qForm]),
							}}
							className="u-font-f-secondary u-font-w-normal u-pr-2"
						>
							{t("generic.no_internet")}
						</p>
						<Icon className="c-icon-membership--size-9 u-ml-2" type="offline" />
					</div>
				) : null}
			</span>
			{isBlurred ? (
				<div className="u-fill-space u-flex u-jc-center u-ai-center u-col copy">
					<Typography className="u-width-180" primary uppercase tag="h1" size="4xl">
						{t("titles.no_internet")}
					</Typography>
					<span className="copy-tag u-width-90 u-mt-8">
						{isBlurred ? t("descriptions.no_internet") : null}
					</span>
				</div>
			) : null}
		</button>
	);
	// #endregion RENDER
};

export default Index;
