const ROUTES = {
	Index: "/",
	Place: "/place",
	Detail: "/detail",
	Compare: "/compare",
	Reviews: "/reviews",
	ProductGrouping: "/product-grouping",
	Setup: "/setup",
	Privacy: "/privacy",
};

export default ROUTES;
