// INFO: This function will make sure titles are being rendered on
// two balanced lines instead of one very long line.
// (Check Figma files for design reference)
const getTitleParts = (children) => {
	const words = children.split(" ");
	const amount = words.length;
	const middleIndex = Math.ceil(amount * 0.5);
	const firstPart = words.slice(0, middleIndex).join(" ");
	const secondPart = words.slice(middleIndex, amount).join(" ");

	return firstPart ? [firstPart, secondPart] : [secondPart];
};

export default getTitleParts;
