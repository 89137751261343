const RFIDInitialState = () => ({
	orientation: "normal",
	orientationIsSet: false,
	isSession: false,
	isInteraction: false,
	interactionLock: false,
	tags: [],
	fourList: { epcs: [], sccs: [] },
});

export default RFIDInitialState;
