import React, { memo, useEffect, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { propOr, isEmpty } from "ramda";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import Switch from "react-switch";

import Modal from "@/components/Modal";
import Typography from "@/components/Typography";
import Countdown from "@/components/Countdown";
import Image from "@/components/Image";
import Button from "@/components/Button";

import {
	setPrivacy,
	setEndSession,
	setSeenWelcome,
	postMemberPrivacyUpdate,
	clearIdentityError,
} from "@/store/membership/membership.actions";

import { useShoeNotFoundModal } from "@/store/ui/ui.hooks";
import { useIdentity } from "@/store/membership/membership.hooks";
import { setMembershipModal, setShoeNotFoundModal } from "@/store/ui/ui.actions";

import setAnalytics from "@/utils/analytics";

import imgCalendar from "@/assets/images/calendar.png";
import imgPlace from "@/assets/images/place.png";
import imgShoe from "@/assets/images/shoe.png";

import ROUTES from "@/constants/routes";
import { useQuery } from "@/hooks/Router";

// =============================================================================
// Root
// =============================================================================
const ShoeNotFound = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const location = useLocation();
	const query = useQuery();

	const { isOpen, data, toggleIsOpen } = useShoeNotFoundModal();
	const { data: identity } = useIdentity();

	const qForm = query.get("form") || "rise";

	const [workout, setWorkout] = useState(false);

	const closeFunction = () => {
		toggleIsOpen();
		if (data.type === "identity_error") {
			setAnalytics("member_scan_error");
			dispatch(clearIdentityError());
		}
	};

	useEffect(() => {
		if (isOpen) {
			setAnalytics("show_modal", { modal_type: data.type, member: !isEmpty(identity) });
			setWorkout(false);
		}
	}, [isOpen]);

	return (
		<Modal
			className={cn("options", {
				vertical: qForm === "vertical",
				horizontal: qForm === "horizontal",
			})}
			isOpen={isOpen}
			onClose={closeFunction}
		>
			<div
				className={cn("u-pt-18 u-px-15 u-pb-9 u-flex u-jc-center", {
					"u-pb-18": !propOr(null, "isDigital", data),
					"u-col u-ai-center": data.type !== "not_found",
				})}
			>
				<div
					className={cn("u-flex u-col u-ai-center", {
						"u-max-width-160": data.type === "network_offline" || data.type === "end_session",
						"u-stack-10": data.type !== "qr_code_download",
					})}
				>
					<Typography
						className={cn("u-text-c", {
							"u-max-width-70": data.type === "not_found",
							"u-max-width-100": data.type === "privacy_intercept",
							"u-max-width-130": data.type === "qr_code_download",
							"u-max-width-160": data.type === "add_to_favorites",
						})}
						tag="h3"
						primary
						uppercase
						size="2xl"
					>
						{t(`titles.${data.type}`)}
					</Typography>

					<Typography
						className={cn("u-text-c", {
							"u-max-width-120": data.type === "not_found",
							"u-max-width-200 u-mb-4 u-mt-2": data.type === "privacy_intercept",
						})}
						size={data.type === "privacy_intercept" ? "sm" : "md"}
					>
						{data.type === "not_found"
							? t(`descriptions.${data.type}_${data.side}`)
							: t(`descriptions.${data.type}`)}
					</Typography>
				</div>

				{data.type === "network_offline" && (
					<>
						<Countdown
							className=""
							isOpen={isOpen}
							duration={Number(process.env.REACT_APP_RESET_COUNTDOWN_TIME) || 10}
							onFinished={() => {
								toggleIsOpen();
								setAnalytics("network_offline", { member: !isEmpty(identity) });
								dispatch(setEndSession(true));
							}}
						/>
						<div className="u-flex u-col u-mt-12">
							<Modal.Close
								className="u-ml-auto"
								onClose={() => {
									toggleIsOpen();
									setAnalytics("network_offline", { member: !isEmpty(identity) });
									dispatch(setEndSession(true));
								}}
							>
								{t(`buttons.${data.type}`)}
							</Modal.Close>
						</div>
					</>
				)}

				{data.type === "end_session" && (
					<>
						<Countdown
							className=""
							isOpen={isOpen}
							duration={Number(process.env.REACT_APP_RESET_COUNTDOWN_TIME) || 10}
							onFinished={() => {
								toggleIsOpen();
								dispatch(setEndSession(true));
							}}
						/>
						<div className="u-flex u-row u-mt-12">
							<Modal.Close
								className="u-ml-auto u-mr-3 u-px-3"
								onClose={() => {
									toggleIsOpen();
									dispatch(setEndSession(true));
								}}
							>
								{t(`buttons.${data.type}_now`)}
							</Modal.Close>
							<Modal.Close
								className="u-ml-auto c-button--outline u-px-12"
								onClose={() => {
									toggleIsOpen();
								}}
							>
								{t(`buttons.${data.type}_dismiss`)}
							</Modal.Close>
						</div>
					</>
				)}

				{data.type === "add_to_favorites" && (
					<>
						<div className="u-flex u-row u-mt-12">
							<Button
								className="c-button c-button--rounded u-ml-auto u-mr-3 u-px-3"
								onClick={() => {
									dispatch(setShoeNotFoundModal(true, { type: "qr_code_download" }));
								}}
							>
								{t(`buttons.${data.type}_next`)}
							</Button>
							<Modal.Close
								className="u-ml-auto c-button--outline u-px-12"
								onClose={() => {
									toggleIsOpen();
								}}
							>
								{t(`buttons.dismiss`)}
							</Modal.Close>
						</div>
					</>
				)}

				{data.type === "qr_code_download" && (
					<>
						<div className="u-flex u-col u-ai-center">
							<Image
								src="https://nikeqr.io/?url=https%3A%2F%2Fnike.app.link%2Fwf1Qw90uV8"
								className="u-max-width-36 u-my-7"
							/>
							<Modal.Close
								className="u-ml-auto c-button--rounded u-px-12"
								onClose={() => {
									toggleIsOpen();
								}}
							>
								{t(`buttons.dismiss`)}
							</Modal.Close>
						</div>
					</>
				)}

				{data.type === "privacy_intercept" && (
					<>
						<div className="u-flex u-row u-mt-8 u-max-width-300">
							<div className="u-flex u-row u-ai-center u-max-width-80">
								<Image className="c-image--privacy" src={imgCalendar} />
								<Typography
									className={cn("u-text-l u-flex")}
									lineHeight
									lineHeightSize="smmd"
									size="sm"
								>
									{t(`descriptions.${data.type}_plans`)}
								</Typography>
							</div>
							<div className="u-flex u-row u-ai-center u-ml-10 u-max-width-80">
								<Image className="c-image--privacy" src={imgPlace} />
								<Typography
									className={cn("u-text-l u-flex")}
									lineHeight
									lineHeightSize="smmd"
									size="sm"
								>
									{t(`descriptions.${data.type}_events`)}
								</Typography>
							</div>
							<div className="u-flex u-row u-ai-center u-ml-10 u-max-width-80">
								<Image className="c-image--privacy" src={imgShoe} />
								<Typography
									className={cn("u-text-l u-flex")}
									lineHeight
									lineHeightSize="smmd"
									size="sm"
								>
									{t(`descriptions.${data.type}_gear`)}
								</Typography>
							</div>
						</div>
						<div className="u-flex u-row u-mt-8 u-max-width-130">
							<Typography
								className={cn("u-text-c u-color-secondary-tint")}
								lineHeight
								lineHeightSize="xs"
								size="2xs"
							>
								{t(`descriptions.${data.type}_legal`)}{" "}
								<Link
									to={`${ROUTES.Privacy}${location.search}`}
									style={{ textDecoration: "underline" }}
									className="u-color-primary-base"
								>
									{t(`descriptions.${data.type}_more`)}
								</Link>
							</Typography>
						</div>
						<div className="u-flex u-row u-mt-12">
							<>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
							<label htmlFor="iWorkout" className="u-flex u-mt-2 u-ai-center">
								<Typography className="u-text-c u-flex u-col u-mr-6" tag="span" size="sm">
									Use My Workout Info
								</Typography>
								<Switch id="iWorkout" onChange={() => setWorkout(!workout)} checked={workout} />
							</label>
							<Button
								className="c-button c-button--rounded u-px-9"
								style={{ position: "fixed", right: "3vw", bottom: "3.7vw" }}
								onClick={() => {
									toggleIsOpen();
									dispatch(setPrivacy(true));
									dispatch(
										postMemberPrivacyUpdate(
											workout,
											identity.upmId ? identity.upmId : identity.profile?.upmId
										)
									);
									dispatch(setSeenWelcome(false));
									dispatch(setMembershipModal(false));
								}}
							>
								{t(`buttons.${data.type}_accept`)}
							</Button>
						</div>
					</>
				)}

				{data.type === "identity_error" && (
					<>
						<div className="u-flex u-row u-mt-12">
							<Modal.Close
								className="u-ml-auto c-button--outline u-mr-3 u-px-12"
								onClose={() => {
									toggleIsOpen();
									setAnalytics("member_scan_error");
									dispatch(clearIdentityError());
								}}
							>
								{t(`buttons.${data.type}`)}
							</Modal.Close>
						</div>
					</>
				)}

				{propOr(null, "isDigital", data) && (
					<div className="u-flex u-col u-mt-12">
						<Modal.Close className="u-ml-auto" onClose={toggleIsOpen}>
							{t(`buttons.${data.type}`)}
						</Modal.Close>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default memo(ShoeNotFound);
