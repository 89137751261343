import React from "react";
import cn from "classnames";
import { motion, AnimatePresence } from "framer-motion";

import GridBlock from "@/components/GridBlock";
import VideoBlock from "@/components/Video";

import { getFadeMoveXFull } from "@/animations";

import propTypes from "./ProductGroupingPartialsVideo.propTypes";
import defaultProps from "./ProductGroupingPartialsVideo.defaultProps";

const ProductGroupingPartialsVideo = ({ className = "", id, src, shouldShowBottom, shouldCover, delay = 0 }) => (
	<GridBlock id={id} className={className}>
		<AnimatePresence>
			<motion.div className="u-inset" initial="hidden" animate="show" variants={getFadeMoveXFull({ delay })}>
				<VideoBlock
					className={cn({
						"c-image--cover-bottom u-fill-space": shouldShowBottom,
						"c-image--cover u-fill-space": shouldCover,
					})}
					videoAsset={src}
				/>
			</motion.div>
		</AnimatePresence>
	</GridBlock>
);

ProductGroupingPartialsVideo.propTypes = propTypes;
ProductGroupingPartialsVideo.defaultProps = defaultProps;

export default ProductGroupingPartialsVideo;
