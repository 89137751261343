import React, { useEffect } from "react";
import { pathOr } from "ramda";

import useGrid from "@/hooks/Grid";
import { useLowGPU } from "@/store/ui/ui.hooks";

import franchise2Grid from "@/assets/grids/franchise_2.json";

import propTypes from "./Franchise.propTypes";
import defaultProps from "./Franchise.defaultProps";

import {
	ProductGroupingPartialsTitle,
	ProductGroupingPartialsDescription,
	ProductGroupingPartialsBack,
	ProductGroupingPartialsLogo,
	ProductGroupingPartialsImage,
} from "../Partials";

const Franchise2 = ({ data, lang }) => {
	const { isGridLoading, start: startGrid, end: endGrid, shouldRerenderGrid } = useGrid();
	const lowGPU = useLowGPU();

	// #region LIFECYCLE HOOKS
	useEffect(() => {
		endGrid();
		startGrid(franchise2Grid);
	}, [isGridLoading]);
	// #endregion LIFECYCLE HOOKS

	// #region RENDER
	return (
		!shouldRerenderGrid &&
		!isGridLoading && (
			<>
				<ProductGroupingPartialsDescription id="FranchiseRightTop" data={data} />

				<ProductGroupingPartialsImage
					id="FranchiseRightBottom"
					src={pathOr(null, ["assets", 0, "portrait"], data)}
					shouldShowBottom
				/>

				<ProductGroupingPartialsTitle id="FranchiseRightCenter" data={data} lang={lang} lowGPU={lowGPU} />

				<ProductGroupingPartialsImage
					id="FranchiseLeftPrimary"
					src={pathOr(null, ["assets", 1, "portrait"], data)}
					shouldShowBottom
					delay={0.15}
				/>

				<ProductGroupingPartialsBack id="FranchiseNavA" />

				<ProductGroupingPartialsImage
					id="FranchiseNavB"
					src={pathOr(null, ["assets", 2, "portrait"], data)}
					shouldShowBottom
					delay={0.3}
				/>

				<ProductGroupingPartialsLogo id="FranchiseLeftCenter" data={data} lowGPU={lowGPU} />
			</>
		)
	);
	// #endregion RENDER
};

Franchise2.propTypes = propTypes;
Franchise2.defaultProps = defaultProps;

export default Franchise2;
