import { useState, useEffect } from "react";

import Slider from "@/utils/slider/Slider";

export default ({ speed = 3 } = {}) => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [direction, setDirection] = useState(1);
	const [slider, setSlider] = useState(null);

	const updateCurrentSlide = (index) => {
		if (slider) {
			slider.setCurrentSlide(index);
			slider.destroy();
		}
	};

	const handleDestroy = () => {
		if (slider) {
			slider.setCurrentSlide(0);
			slider.destroy();
		}

		setSlider(null);
	};

	useEffect(() => {
		return () => {
			handleDestroy();
		};
	}, []);

	const initSlider = ({ slides }) => {
		setSlider(
			new Slider({
				totalSlides: slides,
				callbackCurrent: setCurrentSlide,
				speed: speed * 1000,
			})
		);
	};

	const handlePrev = () => {
		setDirection(-1);

		if (slider) {
			slider.handlePrev();
			slider.destroy();
		}
	};

	const handleNext = () => {
		setDirection(1);

		if (slider) {
			slider.handleNext();
			slider.destroy();
		}
	};

	return {
		slider,
		speed,
		currentSlide,
		direction,
		initSlider,
		updateCurrentSlide,
		handlePrev,
		handleNext,
		handleDestroy,
	};
};
