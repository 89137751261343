const UIActionTypes = {
	SET_LANGUAGE_MODAL: "@@ui/SET_LANGUAGE_MODAL",
	SET_RESET_MODAL: "@@ui/SET_RESET_MODAL",
	SET_ATTRACT_MODAL: "@@ui/SET_ATTRACT_MODAL",
	SET_GRID_LOADING: "@@ui/SET_GRID_LOADING",
	SET_SHOES_MODAL: "@@ui/SET_SHOES_MODAL",
	SET_OPTIONS_MODAL: "@@ui/SET_OPTIONS_MODAL",
	SET_MEMBERSHIP_MODAL: "@@ui/SET_MEMBERSHIP_MODAL",
	SET_ORIENTATION: "@@ui/SET_ORIENTATION",
	SET_SHOE_NOT_FOUND_MODAL: "@@ui/SET_SHOE_NOT_FOUND_MODAL",
	SET_ADMIN_PANEL_MODAL: "@@ui/SET_ADMIN_PANEL_MODAL",
	SET_ENABLED_LANGUAGES: "@@ui/SET_ENABLED_LANGUAGES",
	SET_SCREENSAVER_TIMER_ACTIVE: "@@ui/SET_SCREENSAVER_TIMER_ACTIVE",
	SET_LOW_GPU: "@@ui/SET_LOW_GPU",
	SET_ACTIVE_SESSION: "@@ui/SET_ACTIVE_SESSION",
	SET_RELOAD_TRIGGER: "@@ui/SET_RELOAD_TRIGGER",
	SET_ATTRACT_TRIGGER: "@@ui/SET_ATTRACT_TRIGGER",
	SET_QA_MODE: "@@ui/SET_QA_MODE",
	SET_LATEST_ACTION: "@@ui/SET_LATEST_ACTION",

	GET_ANALYTICS_TYPES: "@@ui/GET_ANALYTICS_TYPES",
	GET_ANALYTICS_TYPES_SUCCESS: "@@ui/GET_ANALYTICS_TYPES_SUCCESS",
	GET_ANALYTICS_TYPES_ERROR: "@@ui/GET_ANALYTICS_TYPES_ERROR",

	GET_ALL_STORES: "@@shoes/GET_ALL_STORES",
	GET_ALL_STORES_SUCCESS: "@@shoes/GET_ALL_STORES_SUCCESS",
	GET_ALL_STORES_ERROR: "@@shoes/GET_ALL_STORES_ERROR",

	GET_STORE_ID: "@@shoes/GET_STORE_ID",
	GET_STORE_ID_SUCCESS: "@@shoes/GET_STORE_ID_SUCCESS",
	GET_STORE_ID_ERROR: "@@shoes/GET_STORE_ID_ERROR",
};

export default UIActionTypes;
