import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { isNil, pathOr, isEmpty } from "ramda";

import Codification from "@/components/Codification";
import ButtonIcon from "@/components/ButtonIcon";
import Block from "@/components/Block";
import Typography from "@/components/Typography";
import Image from "@/components/Image";

import { useLowGPU } from "@/store/ui/ui.hooks";

import { useQuery } from "@/hooks/Router";

import { getTechnologyStyleBlock } from "@/utils/data/productGroupings";

const ProductStyle = ({ productGroupings, activeShoe, identity, style, side = null, handleSetProductGroupings }) => {
	const { t } = useTranslation();
	const lowGPU = useLowGPU();
	const query = useQuery();

	const qForm = query.get("form") || "rise";

	const productGrouping = getTechnologyStyleBlock(productGroupings, qForm);
	const id = pathOr(null, ["id"], productGrouping);
	const assets = pathOr([], ["assets"], productGrouping);
	const label = pathOr("", ["meta", "name"], productGrouping);
	const hasDetail = Boolean(productGrouping && assets.length > 2);

	const background =
		assets?.find((img) => /bottom/.test(img.portrait))?.portrait || activeShoe?.assets.zoom_toe.square;
	const logo = assets?.find((img) => /logo/.test(img.portrait))?.portrait;

	if (!isNil(productGrouping))
		return (
			<Block
				onClick={hasDetail ? () => handleSetProductGroupings(id, side) : null}
				className="s-visual u-fill-space c-block--inset c-block--floating-content"
			>
				<Block.Background
					className={cn("c-block__image detail-page c-image--cover", {
						"no-detail": activeShoe.missingDetailImages,
						recommended: !isEmpty(identity),
					})}
					src={background}
				/>

				<Block.Header className="u-mt-0">
					<Typography tag="p" size="2xs">
						{style ? t("titles.technology_style") : t("titles.technology")}
					</Typography>
				</Block.Header>

				<Block.Content className="u-flex u-jc-center u-ai-center u-text-c">
					{logo ? (
						<Image className="c-product-style__logo" src={logo} />
					) : (
						<Codification
							text={label}
							typography={{ primary: true, size: "md", uppercase: true }}
							characterSwitchAmount={4}
							characterNextTrigger={2}
							characterTimeout={20}
							lowGPU={lowGPU}
						/>
					)}
				</Block.Content>

				<Block.Footer className="u-flex u-jc-end u-ac-end">
					{hasDetail && <ButtonIcon className="c-icon--size-3" icon="plus" />}
				</Block.Footer>
			</Block>
		);
	else return null;
};

export default React.memo(ProductStyle);
