import PropTypes from "prop-types";

const codification = {
	tag: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "div", "p"]),
	text: PropTypes.string,
	typography: PropTypes.object,
	reverse: PropTypes.bool,
	start: PropTypes.bool,
	characterSwitchAmount: PropTypes.number,
	characterNextTrigger: PropTypes.number,
	characterTimeout: PropTypes.number,
	align: PropTypes.oneOf(["left", "right"]),
	delay: PropTypes.number,
	onEnded: PropTypes.func,
	isStatic: PropTypes.bool,
	lowGPU: PropTypes.bool,
};

const line = {
	words: PropTypes.array,
	index: PropTypes.number,
};

const word = {
	characters: PropTypes.array,
	index: PropTypes.number,
	isLastWordOnLine: PropTypes.bool,
};

const character = {
	text: PropTypes.string,
	index: PropTypes.number,
};

export default {
	codification,
	line,
	word,
	character,
};
