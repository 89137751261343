import React from "react";
import cn from "classnames";

import ImageComp from "@/components/Image";
import TitleSplit from "@/components/TitleSplit";
import imagePropTypes from "@/components/Image/Image.propTypes";
import imageDefaultProps from "@/components/Image/Image.defaultProps";

// =============================================================================
// Root
// =============================================================================
const ShoeHeading = ({ className = "", children }) => {
	return <div className={cn("c-shoe-heading u-inline-12 u-flex", className)}>{children}</div>;
};

// =============================================================================
// Children
// =============================================================================
const Title = ({ className = "", size = "md", weight = "normal", children = "", primary = true, lineHeightSize }) => (
	<TitleSplit
		classNameWrapper={cn(className, "c-shoe-heading__title-wrapper")}
		className="c-shoe-heading__title"
		size={size}
		lineHeightSize={lineHeightSize ?? null}
		weight={weight}
		tag="h2"
		primary={primary}
	>
		{children}
	</TitleSplit>
);

const Image = ({ className = "", ...props }) => (
	<ImageComp className={cn("c-shoe-heading__image c-image--cover", className)} {...props}>
		<ImageComp.Visual className="c-shoe-heading__visual" {...props} />
	</ImageComp>
);

Image.propTypes = imagePropTypes.Image;
Image.defaultProps = imageDefaultProps.Image;

ShoeHeading.Title = Title;
ShoeHeading.Image = Image;

export default ShoeHeading;
