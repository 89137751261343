import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { isEmpty, isNil } from "ramda";
import { motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";

import Button from "@/components/Button";
import Typography from "@/components/Typography";
import Icon from "@/components/Icon";

import { WL_COUNTRIES } from "@/constants/wishlist_regions";

import { setShoeNotFoundModal, setLatestAction } from "@/store/ui/ui.actions";
import { useIdentity, useWishlistProducts, useWishlists } from "@/store/membership/membership.hooks";
import { removeProductFromWishlist, addProductToWishlist } from "@/store/membership/membership.actions";

import { fadeMove } from "@/animations";

// =============================================================================
// Root
// =============================================================================
const FavoritesButton = ({
	iconOnly = false,
	shoe = null,
	vertical = false,
	page,
	isDigital = false,
	isCompareMode = false,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const wishlists = useWishlists();

	const favorites = useWishlistProducts();
	const { data: identity } = useIdentity();

	const merchGroup = WL_COUNTRIES[wishlists?.country?.toUpperCase()];

	const [product, setProduct] = useState(null);
	const [active, setActive] = useState(!isNil(product));
	const [loading, setLoading] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [shoeSCC, setShoeSCC] = useState("");

	const onClick = async () => {
		if (!loading) {
			let success = false;

			if (isEmpty(identity)) {
				dispatch(setShoeNotFoundModal(true, { type: "add_to_favorites" }));
			} else {
				if (active) {
					//remove product to local and remote wishlists
					const newFavorites = favorites.filter((product) => product.styleColor !== shoeSCC);
					if (!isNil(product)) {
						setLoading(true);
						success = await dispatch(removeProductFromWishlist(identity.upmId, product.id, newFavorites));
						setLoading(false);
					}
				} else {
					//add product to local and remote wishlists
					if (!isNil(merchGroup)) {
						setLoading(true);
						success = await dispatch(
							addProductToWishlist(identity.upmId, wishlists.id, merchGroup, shoeSCC)
						);
						if (success) dispatch(setLatestAction({ type: "favorite", id: uuidv4(), firstScc: shoeSCC }));
						setLoading(false);
					}
				}

				if (success) {
					setShowToast(true);
					setTimeout(() => setShowToast(false), 2000);
					setActive(!active);
				} else dispatch(setShoeNotFoundModal(true, { type: "wishlist_error" }));
			}
		}
	};

	useEffect(() => {
		if (!isEmpty(favorites)) {
			const temp = favorites.filter((product) => product.styleColor === shoeSCC)[0];
			setProduct(temp);
			setActive(!isNil(temp));
		}
	}, [favorites, shoeSCC]);

	useEffect(() => {
		if (!isNil(shoe?.scc)) setShoeSCC(shoe.scc);
		else if (!isNil(shoe?.id)) setShoeSCC(shoe.id);
	}, [shoe]);

	return !isEmpty(wishlists) ? (
		<div
			className={cn("u-flex", page, {
				"u-flex-2 u-as-end u-jc-end": iconOnly && !vertical,
				"u-flex-2 u-as-end": iconOnly && vertical,
				isDigital: isDigital,
				isCompareMode: isCompareMode,
			})}
		>
			{showToast && (
				<motion.div
					className="c-favorites--toast"
					variants={fadeMove}
					initial="hidden"
					animate="show"
					exit="exit"
				>
					<Typography tag="p" size="3xs">
						{active ? t("buttons.added_to_favorites") : t("buttons.removed_from_favorites")}
					</Typography>
					<Icon className="c-icon c-icon--size-6" type="bubble-caret" />
				</motion.div>
			)}
			<Button
				onClick={() => onClick()}
				className={cn("c-button--rounded c-favorites c-button--outline", {
					"c-favorites__icon-only": iconOnly,
				})}
			>
				{!iconOnly && <Button.Text size={vertical ? "2xs" : "3xs"}>{t("buttons.favorite")}</Button.Text>}
				<div
					className={cn("c-icon", {
						"c-icon--size-4": !vertical,
						"c-icon--size-6": vertical,
					})}
				>
					<svg>
						<use xlinkHref={active ? "#heart-filled" : "#heart"} />
					</svg>
				</div>
			</Button>
		</div>
	) : null;
};

export default FavoritesButton;
