import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { pathOr, isNil } from "ramda";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import cn from "classnames";

import Modal from "@/components/Modal";
import Button, { ButtonLanguage } from "@/components/Button";
import Image from "@/components/Image";
import Typography from "@/components/Typography";
import TitleSplit from "@/components/TitleSplit";

import { SIDE } from "@/constants/rfid-tags";

import { useQuery } from "@/hooks/Router";

import { clearActive, clearQty, clearPrice } from "@/store/shoes/shoes.actions";
import { removeTag } from "@/store/rfid/rfid.actions";
import { setFlippedOrientation, setShoesModal, setLatestAction } from "@/store/ui/ui.actions";
import { useOptionsModal, useFlippedOrientation, useEnabledLanguages, useActiveSession } from "@/store/ui/ui.hooks";
import { useActiveShoes } from "@/store/shoes/shoes.hooks";

import { flipSides, getEPC } from "@/utils/shoes";

import { rotateInfinite } from "@/animations";

// =============================================================================
// Root
// =============================================================================
const Options = () => {
	const { t } = useTranslation();
	const { isOpen, toggleIsOpen } = useOptionsModal();
	const dispatch = useDispatch();
	const isFlipped = useFlippedOrientation();
	const { left, right } = useActiveShoes();
	const languages = useEnabledLanguages();
	const query = useQuery();
	const location = useLocation();
	const history = useHistory();
	const activeSession = useActiveSession();

	const qForm = query.get("form") || "rise";

	const removeTagIfExists = (side) => {
		const epc = getEPC(side === SIDE.Left ? left : right);
		dispatch(removeTag(epc));
	};

	const handleOrientation = () => {
		dispatch(setFlippedOrientation(!isFlipped));
	};

	const handleSelectShoe = (side) => {
		removeTagIfExists(side);
		dispatch(setShoesModal(true, { side }));
	};

	const handleRemoveShoe = (side) => {
		removeTagIfExists(side);

		query.delete(flipSides(isFlipped, side));
		history.push(`${location.pathname}/?${query.toString()}`);

		dispatch(clearActive(flipSides(isFlipped, side)));
		dispatch(clearQty(flipSides(isFlipped, side)));
		dispatch(clearPrice(flipSides(isFlipped, side)));
	};

	const handleReload = () => {
		if (activeSession) dispatch(setLatestAction({ type: "session_end", id: uuidv4(), activeSession }));
		setTimeout(() => window.location.reload(), 1000);
	};

	useEffect(() => {
		if (!left && !right && isOpen) {
			toggleIsOpen();
		}
	}, [left, right]);

	const getDetail = (data) => pathOr(null, ["detail"], data);

	const renderEmptyShoe = (side) => {
		return (
			<div className={cn("u-flex u-flex-1", { "u-as-end": qForm === "vertical" })}>
				<div className="c-options__placeholder u-col u-stack-8">
					<Typography className="u-text-preline" tag="h3" primary uppercase size="lg">
						{t("generic.place_shoe")}
					</Typography>

					<Button onClick={() => handleSelectShoe(side)} className="c-button--rounded">
						{t("buttons.select_shoe")}
					</Button>
				</div>
			</div>
		);
	};

	const renderShoe = (data, side) => {
		return qForm !== "vertical" ? (
			<div className="u-flex u-ai-center u-flex-1 u-inline-7">
				<Image className="c-modal__image c-image--cover" src={data.detail.assets.left.square} />

				<div className="u-flex u-col u-stack-8">
					<TitleSplit className="u-max-width-80" size="lg" tag="h3" primary>
						{data.detail.content.name}
					</TitleSplit>

					<div className="u-flex u-inline-4 u-fill-width u-ai-center u-height-11">
						{data.isDigital ? (
							<>
								<Button className="c-button--rounded" onClick={() => handleSelectShoe(side)}>
									{t("buttons.another_shoe")}
								</Button>

								{data.isDigital && (
									<Button
										onClick={() => handleRemoveShoe(side)}
										className="c-button--rounded c-button--outline"
									>
										{t("buttons.remove_shoe")}
									</Button>
								)}
							</>
						) : (
							<Typography size="2xs">{t("generic.remove_shoe")}</Typography>
						)}
					</div>
				</div>
			</div>
		) : (
			<div className="u-flex u-ai-center u-flex-1 u-col u-ai-start">
				<div className="u-flex u-row u-ai-center u-as-start">
					<Image className="c-modal__image c-image--cover" src={data.detail.assets.left.square} />

					<div className="u-flex u-col u-mt-8 u-ml-4">
						<TitleSplit
							className="u-max-width-80"
							tag="h3"
							primary={false}
							uppercase={false}
							size="smmmd"
							weight="medium"
							lineHeightSize="smmd"
						>
							{data.detail.content.name}
						</TitleSplit>
					</div>
				</div>
				<div className="u-flex u-inline-4 u-fill-width u-ai-center">
					{data.isDigital ? (
						<>
							<Button className="c-button--rounded" onClick={() => handleSelectShoe(side)}>
								{t("buttons.another_shoe")}
							</Button>

							{data.isDigital && (
								<Button
									onClick={() => handleRemoveShoe(side)}
									className="c-button--rounded c-button--outline"
								>
									{t("buttons.remove_shoe")}
								</Button>
							)}
						</>
					) : (
						<Typography size="2xs">{t("generic.remove_shoe")}</Typography>
					)}
				</div>
			</div>
		);
	};

	return (
		<Modal
			className={cn("options", {
				vertical: qForm === "vertical",
				rise: qForm === "rise",
			})}
			isOpen={isOpen}
			onClose={toggleIsOpen}
		>
			<div>
				<div
					className={cn("u-flex u-ai-center u-jc-center u-pb-24 u-pt-18 u-px-16", {
						"": qForm === "vertical",
						"u-inline-16": qForm !== "vertical",
					})}
				>
					{!isNil(getDetail(left)) ? renderShoe(left, SIDE.Left) : renderEmptyShoe(SIDE.Left)}
					{!isNil(getDetail(right)) ? renderShoe(right, SIDE.Right) : renderEmptyShoe(SIDE.Right)}
				</div>

				<div
					className={cn("u-grid u-cols-3 u-border-t u-ai-center u-pl-4 u-pr-10", {
						"u-py-36": qForm !== "vertical",
						"u-py-75": qForm === "vertical",
					})}
				>
					<div className="u-col-start-1">
						<Button onClick={handleReload} className="c-button--ghost">
							<Button.Icon type="reload" className="c-icon--size-6" />

							<Button.Text>{t("buttons.restart")}</Button.Text>
						</Button>
					</div>

					<div className="u-col-start-2 u-mx-auto">
						<div className="u-flex u-jc-center u-inline-7">
							{qForm !== "vertical" ? (
								<Button
									className="c-button--ghost u-inline-flex u-jc-center u-ai-center u-inline-3 u-p-4"
									onClick={handleOrientation}
								>
									<motion.div variants={rotateInfinite} animate="show">
										<Button.Icon type="refresh" className="c-icon--size-6" />
									</motion.div>

									<Button.Text>{t("buttons.orientation")}</Button.Text>
								</Button>
							) : null}
							{languages.length > 1 ? (
								<ButtonLanguage withShadow={false} vertical={qForm === "vertical"} />
							) : null}
						</div>
					</div>

					<Button className="u-ml-auto c-button--rounded" onClick={toggleIsOpen}>
						{t("buttons.dismiss")}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default memo(Options);
