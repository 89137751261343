import { pathOr } from "ramda";

// eslint-disable-next-line import/prefer-default-export
export const selectAll = (state) => pathOr([], ["filters", "all"], state);

export const selectIsLoading = (state) => pathOr([], ["filters", "loading"], state);

export const selectError = (state) => pathOr([], ["filters", "error"], state);

export const selectFilterIDs = (state) => pathOr([], ["filters", "ids"], state);

export const selectActiveFilters = (state) => pathOr([], ["filters", "activeFilters"], state);
