import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { propEq, find, isEmpty } from "ramda";

import ACHIEVEMENT_ICONS from "@/constants/achievement_icons";

import Codification from "@/components/Codification";
import Block from "@/components/Block";
import Typography from "@/components/Typography";
import Image from "@/components/Image";
import Icon from "@/components/Icon";

import { useLowGPU } from "@/store/ui/ui.hooks";

import { useQuery } from "@/hooks/Router";

import defaultProps from "./Recommendations.defaultProps";
import propTypes from "./Recommendations.propTypes";

import { random } from "@/utils/math";

// =============================================================================
// Root
// =============================================================================
const Recommendations = ({ title, icons, recommendations, popularWith }) => {
	const { t } = useTranslation();
	const lowGPU = useLowGPU();
	const query = useQuery();

	const qForm = query.get("form") || "rise";

	const renderRecommendationHighlight = useMemo(() => (recommendation) => {
		const copy = t(recommendation.text, recommendation.data ?? null);
		const newCopy = copy.split(" ");
		const highlight = newCopy.splice(-2);
		let string = "";
		if (!popularWith) string += t("recommendations.because");
		string += newCopy.join(" ");
		string += " ";
		string += highlight.join(" ");
		return (
			<>
				<Codification
					text={string}
					typography={
						qForm === "vertical"
							? { primary: true, size: "lg", lineHeightSize: "md", uppercase: true }
							: { primary: true, size: "lg", uppercase: true }
					}
					characterSwitchAmount={4}
					characterNextTrigger={2}
					characterTimeout={10}
					lowGPU={lowGPU}
				/>
			</>
		);
	});

	const renderRecommendationIcons = useMemo(() => (recommendations) => {
		let swoosh = 0;
		let nrc = 0;
		let ntc = 0;
		let loggedRun = 0;
		let training = 0;
		const achievements = [];
		recommendations.forEach((rec) => {
			if (rec.type === "purchase") swoosh += 1;
			else if (rec.type === "nrc") nrc += 1;
			else if (rec.type === "ntc") ntc += 1;
			else if (rec.type === "training") training += 1;
			else if (rec.type === "loggedRun") loggedRun += 1;
			else if (rec.type === "achievement") achievements.push(rec);
		});
		return (
			<div className="u-flex">
				{swoosh > 0 ? <Icon className="c-icon-recommendation--size-1" type="swoosh" /> : null}
				{nrc > 0 ? <Icon className="c-icon-recommendation--size-1" type="nrc-black" /> : null}
				{loggedRun > 0 ? <Icon className="c-icon-recommendation--size-1" type="nrc-black" /> : null}
				{ntc > 0 ? <Icon className="c-icon-recommendation--size-1" type="ntc-white" /> : null}
				{training > 0 ? <Icon className="c-icon-recommendation--size-1" type="ntc-white" /> : null}
				{achievements.map((ach) => (
					<Image
						className="u-recommendation-width-1"
						key={`${ach.icon}${random(1, 10000)}`}
						src={find(propEq("name", ach.icon))(ACHIEVEMENT_ICONS).src}
					/>
				))}
			</div>
		);
	});

	return (
		<Block className="c-block--inset c-block--floating-content">
			<Block.Header>
				<Typography tag="p" size="2xs" lineHeightSize="2xs">
					{title}
				</Typography>
			</Block.Header>
			<Block.Content className="c-block--inset u-flex u-jc-start u-ai-center">
				<Typography primary tag="span" size="lg" uppercase lineHeightSize="md">
					{!isEmpty(recommendations) && renderRecommendationHighlight(recommendations)}
				</Typography>
			</Block.Content>

			{!popularWith && <Block.Footer>{renderRecommendationIcons(icons)}</Block.Footer>}
		</Block>
	);
};

Recommendations.propTypes = propTypes.Recommendations;
Recommendations.defaultProps = defaultProps.Recommendations;

export default React.memo(Recommendations);
