import React from "react";
import cn from "classnames";

import defaultProps from "./Display.defaultProps";
import propTypes from "./Display.propTypes";

/**
 * Component that holds the aspect ratio of the screen, this way you can use
 * percentages within the screen.
 *
 * Use this whenever you are rendering screens that dont have this Display
 * component as a parent. eg Modals.
 */
const Display = ({ className = "", children, debug }) => (
	<div className={cn("c-display", className)}>
		<div className="c-display__container">{children}</div>

		{debug && <div className="c-display__offscreen" />}
	</div>
);

Display.defaultProps = defaultProps;

Display.propTypes = propTypes;

export default Display;
