const MembershipInitialState = () => ({
	identity: {
		data: {},
		loading: false,
		error: null,
	},
	styles: {
		data: {},
		loading: false,
		error: null,
	},
	wishlists: {
		data: {},
		loading: false,
		error: null,
	},
	wishlistProducts: {
		data: {},
		loading: false,
		error: null,
	},
	membershipActive: null,
	simulatorActive: false,
	menuMinimized: false,
	privacy: false,
	endSession: false,
	welcomeRail: false,
	seenWelcome: false,
	showStats: null,
});

export default MembershipInitialState;
