const MembershipActionTypes = {
	GET_IDENTITY: "@@membership/GET_IDENTITY",
	GET_IDENTITY_SUCCESS: "@@membership/GET_IDENTITY_SUCCESS",
	GET_IDENTITY_ERROR: "@@membership/GET_IDENTITY_ERROR",
	CLEAR_IDENTITY_ERROR: "@@membership/CLEAR_IDENTITY_ERROR",

	CLEAR_IDENTITY: "@@membership/CLEAR_IDENTITY",

	GET_STYLES: "@@membership/GET_STYLES",
	GET_STYLES_SUCCESS: "@@membership/GET_STYLES_SUCCESS",
	GET_STYLES_ERROR: "@@membership/GET_STYLES_ERROR",

	GET_WISHLISTS: "@@membership/GET_WISHLISTS",
	GET_WISHLISTS_SUCCESS: "@@membership/GET_WISHLISTS_SUCCESS",
	GET_WISHLISTS_ERROR: "@@membership/GET_WISHLISTS_ERROR",

	CREATE_WISHLIST: "@@membership/CREATE_WISHLIST",

	GET_WISHLIST_PRODUCTS: "@@membership/GET_WISHLIST_PRODUCTS",
	GET_WISHLIST_PRODUCTS_SUCCESS: "@@membership/GET_WISHLIST_PRODUCTS_SUCCESS",
	GET_WISHLIST_PRODUCTS_ERROR: "@@membership/GET_WISHLIST_PRODUCTS_ERROR",

	REMOVE_PRODUCT_FROM_WISHLIST: "@@membership/REMOVE_PRODUCT_FROM_WISHLIST",
	REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS: "@@membership/REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS",
	REMOVE_PRODUCT_FROM_WISHLIST_ERROR: "@@membership/REMOVE_PRODUCT_FROM_WISHLIST_ERROR",

	ADD_PRODUCT_TO_WISHLIST: "@@membership/ADD_PRODUCT_TO_WISHLIST",
	ADD_PRODUCT_TO_WISHLIST_SUCCESS: "@@membership/ADD_PRODUCT_TO_WISHLIST_SUCCESS",
	ADD_PRODUCT_TO_WISHLIST_ERROR: "@@membership/ADD_PRODUCT_TO_WISHLIST_ERROR",

	POST_PRIVACY: "@@membership/POST_PRIVACY",
	POST_PRIVACY_SUCCESS: "@@membership/POST_PRIVACY_SUCCESS",
	POST_PRIVACY_ERROR: "@@membership/POST_PRIVACY_ERROR",

	SET_MEMBERSHIP_ACTIVE: "@@membership/SET_MEMBERSHIP_ACTIVE",
	SET_SIMULATOR_ACTIVE: "@@membership/SET_SIMULATOR_ACTIVE",
	SET_MENU_MINIMIZED: "@@membership/SET_MENU_MINIMIZED",

	SET_PRIVACY: "@@membership/SET_PRIVACY",
	SET_END_SESSION: "@@membership/SET_END_SESSION",
	SET_SEEN_WELCOME: "@@membership/SET_SEEN_WELCOME",
	SET_WELCOME_RAIL: "@@membership/SET_WELCOME_RAIL",
	SET_SHOW_STATS: "@@membership/SET_SHOW_STATS",
};

export default MembershipActionTypes;
