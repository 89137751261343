class Slider {
	currentSlide = 0;

	totalSlides = 0;

	speed = 1000; // ms

	autoplay = true;

	progress = 0;

	// Called when updating the currentSlide
	callbackCurrent = null;

	interval = null;

	constructor({ dispatch, totalSlides, speed, autoplay, callbackCurrent }) {
		this.totalSlides = totalSlides;

		if (dispatch) {
			this.dispatch = dispatch;
		}

		if (speed) {
			this.speed = speed;
		}

		if (autoplay) {
			this.autoplay = true;
		}

		if (callbackCurrent) {
			this.callbackCurrent = callbackCurrent;
		}

		if (this.autoplay) {
			this.handleAutoplay();
		}
	}

	/**
	 * Functions
	 */
	destroy = () => {
		if (this.interval) {
			clearInterval(this.interval);
			this.interval = null;
		}
	};

	handleAutoplay = () => {
		this.interval = setInterval(this.handleNext, this.speed);
	};

	handleNext = () => {
		let nextSlide = this.currentSlide + 1;

		if (nextSlide === this.totalSlides) {
			nextSlide = 0;
		}

		this.setCurrentSlide(nextSlide);
	};

	handlePrev = () => {
		let prevSlide = this.currentSlide - 1;

		if (prevSlide < 0) {
			prevSlide = this.totalSlides - 1;
		}

		this.setCurrentSlide(prevSlide);
	};

	/**
	 * Setters
	 */
	setCurrentSlide = (index) => {
		this.currentSlide = index;
		this.callbackCurrent(this.currentSlide);
	};
}

export default Slider;
