export default {
	tag: "p",
	primary: false,
	weight: "normal",
	size: "2xs",
	uppercase: false,
	lineHeight: true,
	typeStyle: null,
	forceBold: false,
};
