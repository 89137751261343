import React, { memo } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import Image from "@/components/Image";
import Typography from "@/components/Typography";
import Highlight from "@/components/Highlight";

import propTypes from "./ShoePreview.propTypes";
import defaultProps from "./ShoePreview.defaultProps";

// =============================================================================
// Root
// =============================================================================
const ShoePreview = ({
	className = "",
	children,
	title = "",
	url = "",
	recommendations = [],
	recommendedFilter = false,
	index,
	horizontal = true,
	...imageProps
}) => {
	const rootClassName = cn(
		"c-shoe-preview u-flex",
		{
			"u-pt-1": index > 5 && horizontal,
			"u-pt-10": (index <= 5 && horizontal) || (index <= 2 && !horizontal),
			"u-pt-8": index > 2 && !horizontal,
		},
		className
	);

	const renderImage = () => {
		return (
			<Image
				className={cn("c-shoe-preview__image_container u-width-48 u-height-31")}
				imageClassName="c-shoe-preview__image"
				{...imageProps}
			/>
		);
	};

	const renderFallback = () => (
		<div className={cn("c-shoe-preview__image_container c-shoe-preview__image--fallback")} />
	);

	const renderRecommended = () => (
		<div className="u-px-0">
			<Highlight className={cn("s-dark")} title="FOR YOU" recommendation />
		</div>
	);

	const renderText = () => (
		<div className="u-flex u-col u-stack-3 u-px-0 u-width-48">
			<Typography className="c-shoe-preview__title" tag="h2" size="xs" lineHeightSize="smmd">
				{title}
			</Typography>

			{children}
		</div>
	);

	const renderBlock = () => (
		<div className={rootClassName}>
			{imageProps.src ? renderImage() : renderFallback()}
			{renderText()}
			{recommendations.length > 0 ? renderRecommended() : null}
		</div>
	);

	const renderLink = () => (
		<Link to={url} className={rootClassName}>
			{imageProps.src ? renderImage() : renderFallback()}
			{renderText()}
		</Link>
	);

	return url === "" ? renderBlock() : renderLink();
};

ShoePreview.propTypes = propTypes.ShoePreview;
ShoePreview.defaultProps = defaultProps.ShoePreview;

export default memo(ShoePreview);
