import { pathOr } from "ramda";

export const selectLanguageModal = (state) => pathOr(false, ["ui", "modals", "langSelector"], state);

export const selectResetModal = (state) => pathOr(false, ["ui", "modals", "reset"], state);

export const selectAttractModal = (state) => pathOr(false, ["ui", "modals", "attract"], state);

export const selectGridLoading = (state) => pathOr(true, ["ui", "grid", "loading"], state);

export const selectShoesModal = (state) => pathOr(false, ["ui", "modals", "shoes"], state);

export const selectOptionsModal = (state) => pathOr(false, ["ui", "modals", "options"], state);

export const selectMembershipModal = (state) => pathOr(false, ["ui", "modals", "membership"], state);

export const selectShoeNotFoundModal = (state) => pathOr(false, ["ui", "modals", "shoeNotFound"], state);

export const selectFlippedOrientation = (state) => pathOr(true, ["ui", "flippedOrientation"], state);

export const selectAdminPanelModal = (state) => pathOr(false, ["ui", "modals", "adminPanel"], state);

export const selectEnabledLanguages = (state) => pathOr(false, ["ui", "languages"], state);

export const selectScreensaverTrigged = (state) => pathOr(false, ["ui", "screensaverTrigged"], state);

export const selectLowGPU = (state) => pathOr(false, ["ui", "lowGPU"], state);

export const selectQAMode = (state) => pathOr(false, ["ui", "qaMode"], state);

export const selectLatestAction = (state) => pathOr(false, ["ui", "latestAction"], state);

export const selectActiveSession = (state) => pathOr(false, ["ui", "activeSession"], state);

export const selectReloadTrigger = (state) => pathOr(false, ["ui", "reloadTrigger"], state);

export const selectAttractTrigger = (state) => pathOr(false, ["ui", "attractTrigger"], state);

export const selectStores = (state) => pathOr([], ["ui", "stores", "data"], state);

export const selectAnalyticsTypes = (state) => pathOr(false, ["ui", "analyticsTypes", "data"], state);

export const selectAnalyticsTypesLoading = (state) => pathOr(false, ["ui", "analyticsTypes", "loading"], state);

export const selectAnalyticsTypesError = (state) => pathOr(false, ["ui", "analyticsTypes", "error"], state);

export const selectStoresLoading = (state) => pathOr(false, ["ui", "stores", "loading"], state);

export const selectStoresError = (state) => pathOr(false, ["ui", "stores", "error"], state);

export const selectStoreID = (state) => pathOr(false, ["ui", "storeID", "data"], state);

export const selectStoreIDLoading = (state) => pathOr(false, ["ui", "storeID", "loading"], state);

export const selectStoreIDError = (state) => pathOr(false, ["ui", "storeID", "error"], state);
