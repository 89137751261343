/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "ramda";

import Typography from "@/components/Typography";
import Display from "@/components/Display";
import TopBar from "@/components/TopBar";

import { useIdentity } from "@/store/membership/membership.hooks";

import setAnalytics from "@/utils/analytics";

// TODO: update content according to design
const Error = () => {
	const { t } = useTranslation();

	const { data: identity } = useIdentity();

	useEffect(() => {
		setAnalytics("error", { member: !isEmpty(identity) });
	}, []);

	return (
		<Display className="u-fill-height">
			<div className="u-fill-height u-grid u-cols-4 u-rows-6">
				<TopBar className="u-col-4" flipVisible />

				<div className="u-col-2 u-col-start-2 u-row-4 u-row-start-2 u-flex u-col u-jc-center u-ai-center">
					<div className="p-place__center">
						<Typography className="u-text-c u-flex u-col u-mb-22" tag="h1" size="8xl" uppercase primary>
							{t("error.title")}
						</Typography>
						<Typography className="u-text-c" tag="h2" size="md" uppercase primary>
							{t("error.subtitle")}
						</Typography>
					</div>
				</div>
			</div>
		</Display>
	);
};

export default Error;
