export const map = (value, low1, high1, low2, high2) => low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);

export const random = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const pixels = (px, landscape) => `${px * 0.0521}${landscape ? "vw" : "vh"}`;

export const shuffleArray = (array) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
};
