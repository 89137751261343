import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxThunk from "redux-thunk";

import setupInterceptor from "@/utils/api/interceptor";
import logger from "redux-logger";

import UIReducer from "./ui/ui.reducer";
import ShoesReducer from "./shoes/shoes.reducer";
import FiltersReducer from "./filters/filters.reducer";
import RFIDReducer from "./rfid/rfid.reducer";
import MembershipReducer from "./membership/membership.reducer";

setupInterceptor();

const rootReducer = combineReducers({
	rfid: RFIDReducer,
	ui: UIReducer,
	shoes: ShoesReducer,
	filters: FiltersReducer,
	membership: MembershipReducer,
});

let middleware = [];
if (process.env.NODE_ENV === "development") {
	middleware = [...middleware, reduxThunk, logger];
} else {
	middleware = [...middleware, reduxThunk];
}

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
