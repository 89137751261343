import React from "react";
import cn from "classnames";

import defaultProps from "./BottomBar.defaultProps";
import propTypes from "./BottomBar.propTypes";

// =============================================================================
// Root
// =============================================================================
const BottomBar = ({ className = "", children }) => (
	<div className={cn("c-bottom-bar u-grid u-ai-center u-cols-8", className)}>{children}</div>
);

BottomBar.propTypes = propTypes.BottomBar;
BottomBar.defaultProps = defaultProps.BottomBar;

// =============================================================================
// Children
// =============================================================================
const BottomBarLeft = ({ className = "", children }) => (
	<div className={cn("u-col-start-1 u-col-1", className)}>{children}</div>
);

BottomBar.Left = BottomBarLeft;

const BottomBarCenter = ({ className = "", children }) => (
	<div className={cn("u-col-start-2 u-col-6", className)}>{children}</div>
);

BottomBar.Center = BottomBarCenter;

const BottomBarRight = ({ className = "", children }) => (
	<div className={cn("u-col-start-7 u-col-1", className)}>{children}</div>
);

BottomBar.Right = BottomBarRight;

export default BottomBar;
