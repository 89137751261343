import React, { memo, useState, useEffect } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

import Icon from "@/components/Icon";
import HighlightMembership from "@/components/HighlightMembership";
import Typography from "@/components/Typography";

import { useQuery } from "@/hooks/Router";
import { isNil } from "ramda";

// =============================================================================
// Root
// =============================================================================
const MembershipMenuIdentityRail = ({ name = "", year, duration = 10, hideWelcomeMenu, startMinimizeTimeout }) => {
	const { t } = useTranslation();
	const query = useQuery();
	const [leftCount, setLeftCount] = useState(0);
	const [middleCount, setMiddleCount] = useState(0);
	const [rightCount, setRightCount] = useState(0);
	const [hideHighlights, setHideHighlights] = useState(false);
	const [hideMarquee, setHideMarquee] = useState(false);

	const qForm = query.get("form") || "rise";

	const direction = qForm === "vertical" ? "vh" : "vw";
	const durationMili = duration * 1000;
	const hideIcons = duration - 2;

	const size = qForm === "vertical" ? 2 : 1.4067;
	const grid = [0, size, size * 2, size * 3, size * 4, size * 5, size * 6];
	const keyframes = [];
	for (let i = 0; i <= 1; i += 0.02) {
		keyframes.push(i.toFixed(2));
	}
	const clipPathDefault = [
		"inset(0px 100% 0px 0px)",
		"inset(0px 80% 0px 0px)",
		"inset(0px 60% 0px 0px)",
		"inset(0px 40% 0px 0px)",
		"inset(0px 20% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 0% 0px 0px)",
		"inset(0px 20% 0px 0px)",
		"inset(0px 40% 0px 0px)",
		"inset(0px 60% 0px 0px)",
		"inset(0px 80% 0px 0px)",
		"inset(0px 100% 0px 0px)",
	];

	useEffect(() => {
		let leftInterval = null;
		let middleInterval = null;
		let rightInterval = null;
		let leftCounter = 0;
		let middleCounter = 0;
		let rightCounter = 0;

		const leftTimer = setTimeout(() => {
			leftInterval = setInterval(() => {
				leftCounter += 1;
				setLeftCount(leftCounter);
			}, 750);
		}, 1500);
		const middleTimer = setTimeout(() => {
			middleInterval = setInterval(() => {
				middleCounter += 1;
				setMiddleCount(middleCounter);
			}, 750);
		}, 1600);
		const rightTimer = setTimeout(() => {
			rightInterval = setInterval(() => {
				rightCounter += 1;
				setRightCount(rightCounter);
			}, 750);
		}, 1700);
		const highlightTimer = setTimeout(() => {
			setHideHighlights(!hideHighlights);
		}, durationMili - 750);
		const marqueeTimer = setTimeout(() => {
			setHideMarquee(!hideMarquee);
			if (!isNil(hideWelcomeMenu)) hideWelcomeMenu();
		}, durationMili);

		startMinimizeTimeout();

		return () => {
			clearTimeout(leftTimer);
			clearInterval(leftInterval);
			clearTimeout(middleTimer);
			clearInterval(middleInterval);
			clearTimeout(rightTimer);
			clearInterval(rightInterval);
			clearTimeout(highlightTimer);
			clearTimeout(marqueeTimer);
		};
	}, []);

	return (
		<div style={{ maxWidth: "100vw" }}>
			{!hideMarquee && (
				<Marquee autoFill speed={75}>
					<motion.div
						className="u-flex u-ai-end u-ml-1"
						initial={{
							clipPath: "inset(0px 100% 0px 0px)",
						}}
						animate={{
							clipPath: clipPathDefault,
							transition: {
								duration: duration - 1.2,
								ease: [0, 1, 0, 1],
							},
						}}
					>
						<Typography
							primary
							uppercase
							size={qForm === "vertical" ? "2lg" : "smmd"}
							lineHeightSize={qForm === "vertical" ? "3md" : null}
							tag="h2"
						>
							{t("membership.welcome")}
						</Typography>
					</motion.div>
					<div className="u-flex u-ai-end">
						<HighlightMembership
							className={cn("s-dark u-ml-1 no-absolute", { hidden: hideHighlights })}
							title={name}
							delay={0.7}
							endDelay={duration - 3.75}
							typo={
								qForm === "vertical"
									? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
									: null
							}
							grid={grid}
							end="block"
							initial={[0, 0]}
							animate={[0, 0]}
							clipPath={clipPathDefault}
						/>
						<motion.div
							initial={{
								overflow: "hidden",
								position: "relative",
								visibility: "hidden",
								y: `${grid[0]}${direction}`,
							}}
							animate={{
								y: [
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
								],
								visibility: "visible",
							}}
							transition={{ ease: [0, 1, 0, 1], delay: 1, duration: duration - 2.25, times: keyframes }}
						>
							{leftCount % 2 === 0 && leftCount <= hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type="basketball-white"
								/>
							)}
							{leftCount % 2 !== 0 && leftCount <= hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type="soccer-white"
								/>
							)}
							{leftCount > hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type={null}
								/>
							)}
						</motion.div>
						<motion.div
							initial={{
								overflow: "hidden",
								position: "relative",
								visibility: "hidden",
								y: `${grid[0]}${direction}`,
							}}
							animate={{
								y: [
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
								],
								visibility: "visible",
							}}
							transition={{ ease: [0, 1, 0, 1], delay: 1.1, duration: duration - 2.25, times: keyframes }}
						>
							{middleCount % 2 === 0 && middleCount <= hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type="baseball-black"
								/>
							)}
							{middleCount % 2 !== 0 && middleCount <= hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type="shoe-black"
								/>
							)}
							{middleCount > hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type={null}
								/>
							)}
						</motion.div>
						<motion.div
							initial={{
								overflow: "hidden",
								position: "relative",
								visibility: "hidden",
								y: `${grid[0]}${direction}`,
							}}
							animate={{
								y: [
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
								],
								visibility: "visible",
							}}
							transition={{ ease: [0, 1, 0, 1], delay: 1.2, duration: duration - 2.25, times: keyframes }}
						>
							{rightCount % 2 === 0 && rightCount <= hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type="ntc-white"
								/>
							)}
							{rightCount % 2 !== 0 && rightCount <= hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type="nrc-white"
								/>
							)}
							{rightCount > hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type={null}
								/>
							)}
						</motion.div>
					</div>
					<motion.div
						className="u-flex u-ai-end u-ml-1"
						initial={{
							clipPath: "inset(0px 100% 0px 0px)",
						}}
						animate={{
							clipPath: clipPathDefault,
							transition: {
								duration: duration - 1.2,
								ease: [0, 1, 0, 1],
							},
						}}
					>
						<Typography
							primary
							uppercase
							size={qForm === "vertical" ? "2lg" : "smmd"}
							lineHeightSize={qForm === "vertical" ? "3md" : null}
							tag="h2"
						>
							{t("membership.member_since")}
						</Typography>
					</motion.div>
					<div className="u-flex u-ai-end">
						<HighlightMembership
							className={cn("s-dark u-ml-1 no-absolute", { hidden: hideHighlights })}
							title={year}
							delay={0.7}
							endDelay={duration - 3.75}
							typo={
								qForm === "vertical"
									? { primary: true, size: "2lg", lineHeightSize: "3md", tag: "h2", uppercase: true }
									: null
							}
							grid={grid}
							end="block"
							initial={[0, 0]}
							animate={[0, 0]}
							clipPath={clipPathDefault}
						/>

						<motion.div
							initial={{
								overflow: "hidden",
								position: "relative",
								visibility: "hidden",
								y: `${grid[0]}${direction}`,
							}}
							animate={{
								y: [
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
								],
								visibility: "visible",
							}}
							transition={{ ease: [0, 1, 0, 1], delay: 1, duration: duration - 2.25, times: keyframes }}
						>
							{leftCount % 2 === 0 && leftCount <= hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type="basketball-black"
								/>
							)}
							{leftCount % 2 !== 0 && leftCount <= hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type="soccer-black"
								/>
							)}
							{leftCount > hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type={null}
								/>
							)}
						</motion.div>
						<motion.div
							initial={{
								overflow: "hidden",
								position: "relative",
								visibility: "hidden",
								y: `${grid[0]}${direction}`,
							}}
							animate={{
								y: [
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
								],
								visibility: "visible",
							}}
							transition={{ ease: [0, 1, 0, 1], delay: 1.1, duration: duration - 2.25, times: keyframes }}
						>
							{middleCount % 2 === 0 && middleCount <= hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type="baseball-white"
								/>
							)}
							{middleCount % 2 !== 0 && middleCount <= hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type="shoe-white"
								/>
							)}
							{middleCount > hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type={null}
								/>
							)}
						</motion.div>
						<motion.div
							initial={{
								overflow: "hidden",
								position: "relative",
								visibility: "hidden",
								y: `${grid[0]}${direction}`,
							}}
							animate={{
								y: [
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[1]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
									`${grid[0]}${direction}`,
								],
								visibility: "visible",
							}}
							transition={{ ease: [0, 1, 0, 1], delay: 1.2, duration: duration - 2.25, times: keyframes }}
						>
							{rightCount % 2 === 0 && rightCount <= hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type="ntc-black"
								/>
							)}
							{rightCount % 2 !== 0 && rightCount <= hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type="nrc-black"
								/>
							)}
							{rightCount > hideIcons && (
								<Icon
									className={
										qForm === "vertical"
											? "c-icon-membership--size-member"
											: "c-icon-membership--size-9"
									}
									type={null}
								/>
							)}
						</motion.div>
					</div>
				</Marquee>
			)}
		</div>
	);
};

export default memo(MembershipMenuIdentityRail);
