import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { pathOr, isNil, propEq, find, isEmpty } from "ramda";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { useOptionsModal } from "@/store/ui/ui.hooks";
import { useActiveShoe } from "@/store/shoes/shoes.hooks";
import { updateTemplate } from "@/store/shoes/shoes.actions";
import { useIdentity } from "@/store/membership/membership.hooks";
import { setLatestAction } from "@/store/ui/ui.actions";

import { random } from "@/utils/math";
import setAnalytics from "@/utils/analytics";

import PRODUCT_GROUPING_TYPES from "@/constants/product-groupings";

import BottomBar from "@/components/BottomBar";
import Button from "@/components/Button";

import Franchise from "./Franchise";
import Technology from "./Technology";
import Horizontal from "./Horizontal";
import Vertical from "./Vertical";
import { useQuery } from "@/hooks/Router";

const ProductGrouping = () => {
	const query = useQuery();

	const [productGrouping, setProductGrouping] = useState(null);
	const [allProductGroupings, setAllProductGroupings] = useState([]);
	const [templateIndex, setTemplateIndex] = useState(null);

	const { t, i18n } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();

	const { toggleIsOpen } = useOptionsModal();
	const activeShoe = useActiveShoe();
	const { data: identity } = useIdentity();

	const qForm = query.get("form") || "rise";

	// #region FUNCTIONS
	const getTemplates = () => {
		if (qForm === "horizontal") return Horizontal;
		else if (qForm === "vertical") return Vertical;

		const type = pathOr(null, ["meta", "type"], productGrouping);

		switch (type) {
			case PRODUCT_GROUPING_TYPES.FRANCHISE:
				return Franchise;
			case PRODUCT_GROUPING_TYPES.TECHNOLOGY:
				return Technology;
			default:
				return [];
		}
	};

	const getProductGrouping = () => {
		const idFromHistoryState = pathOr(null, ["location", "state", "id"], history);
		const allProductGroupings = pathOr([], ["detail", "productGroupings"], activeShoe);
		const currentProductGrouping = find(propEq("id", idFromHistoryState))(allProductGroupings);
		if (isNil(currentProductGrouping)) history.goBack();

		setAllProductGroupings(allProductGroupings);
		setProductGrouping(currentProductGrouping);
	};
	// #endregion FUNCTIONS

	// #region LIFECYCLE HOOKS
	useEffect(() => {
		if (isNil(pathOr(null, ["meta", "type"], productGrouping))) {
			return;
		}

		const activeShoeTemplate = pathOr(null, ["template"], activeShoe);

		if (isNil(activeShoeTemplate)) {
			const sideFromHistoryState = pathOr(null, ["location", "state", "side"], history);
			// const templates = getTemplates();
			const i = 0;

			dispatch(updateTemplate(i, sideFromHistoryState));
			setTemplateIndex(i);

			return;
		}

		setTemplateIndex(activeShoeTemplate);
	}, [productGrouping]);

	useEffect(() => {
		if (!isNil(activeShoe)) {
			setAnalytics("product", { product_id: activeShoe.scc, member: !isEmpty(identity) });
		}
	}, [activeShoe]);

	useEffect(() => {
		setAnalytics("product_grouping", { member: !isEmpty(identity) });
		dispatch(
			setLatestAction({ type: "pageview_product_grouping_details", id: uuidv4(), firstScc: activeShoe?.scc })
		);
	}, []);
	// #endregion LIFECYCLE HOOKS

	const renderTemplate = () => {
		const templates = getTemplates();
		let Template = null;
		if (qForm === "horizontal" || qForm === "vertical") Template = templates;
		else Template = !isNil(templateIndex) ? templates[templateIndex] : null;

		if (isNil(productGrouping) && activeShoe) {
			getProductGrouping();
		}

		return (
			Template && (
				<Template data={productGrouping} allProductGroupings={allProductGroupings} lang={i18n.language} />
			)
		);
	};

	// #region RENDER
	return (
		<div className="product-grouping">
			{activeShoe && renderTemplate()}

			{qForm === "rise" && (
				<BottomBar>
					<BottomBar.Left>
						<Button
							className="c-button--ghost u-inline-flex u-ai-center u-jc-center u-inline-1"
							onClick={toggleIsOpen}
						>
							<Button.Icon className="c-icon--size-6" type="options" />

							<Button.Text>{t("buttons.options")}</Button.Text>
						</Button>
					</BottomBar.Left>
				</BottomBar>
			)}
		</div>
	);
	// #endregion RENDER
};

export default ProductGrouping;
