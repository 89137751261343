/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "ramda";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import Typography from "@/components/Typography";
import Display from "@/components/Display";
import Button from "@/components/Button";

import { useIdentity } from "@/store/membership/membership.hooks";
import { setShoeNotFoundModal } from "@/store/ui/ui.actions";

import setAnalytics from "@/utils/analytics";

// TODO: update content according to design
const Privacy = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();

	const { data: identity } = useIdentity();

	useEffect(() => {
		setAnalytics("privacy", { member: !isEmpty(identity) });
		dispatch(setShoeNotFoundModal(false, { type: "privacy_intercept" }));
	}, []);

	return (
		<Display className="u-fill-space">
			<div className="u-fill-space" style={{ textAlign: "center" }}>
				{/* <Typography className="u-text-c u-flex u-col u-mt-18 u-mb-8" tag="h1" size="xl" uppercase primary>
					{t("titles.privacy_more")}
				</Typography> */}
				<iframe
					title="Nike Privacy"
					style={{ width: "50vw", height: "100vh" }}
					src="https://agreementservice.svs.nike.com/rest/agreement?requestType=redirect&agreementType=DataLearnMore&country=GB&language=en&uxId=com.nike.commerce.nikedotcom.web"
				/>
				<Button
					className="c-button c-button--rounded u-px-9"
					style={{ position: "fixed", right: "5vw", bottom: "5vw" }}
					onClick={() => {
						history.goBack();
						dispatch(setShoeNotFoundModal(true, { type: "privacy_intercept" }));
					}}
				>
					{t(`buttons.dismiss`)}
				</Button>
			</div>
		</Display>
	);
};

export default Privacy;
