import { equals, isEmpty, head, includes, pathOr } from "ramda";

import PRODUCT_GROUPING_TYPES from "@/constants/product-groupings";
import { BANNED_PRODUCT_GROUPING_BUTTONS } from "@/constants/banned";

export const isTechnology = (type) => equals(type, PRODUCT_GROUPING_TYPES.TECHNOLOGY);

export const isFranchise = (type) => equals(type, PRODUCT_GROUPING_TYPES.FRANCHISE);

export const getTechnologyStyleBlock = (arr, form = "rise") => {
	const franchise = arr.filter((productGrouping) => isFranchise(pathOr(null, ["meta", "type"], productGrouping)));
	const technologies = arr.filter((productGrouping) => isTechnology(pathOr(null, ["meta", "type"], productGrouping)));

	if (form === "rise" && !isEmpty(franchise) && head(franchise).assets.length > 2) {
		return head(franchise);
	}

	return head(technologies);
};

export const getProductGroupingSliderButtons = (arr, num) => {
	const notBannedButtons = arr.filter(({ id }) => !includes(id, BANNED_PRODUCT_GROUPING_BUTTONS));
	// const franchise = notBannedButtons.filter(({ type }) => isFranchise(type));
	const technologies = notBannedButtons.filter(({ type }) => isTechnology(type));

	return technologies.filter((item) => item.hasMedia).slice(0, num);
};
