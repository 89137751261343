import { DEFAULT_EASE, DEFAULT_DURATION } from "@/constants/transition";

const getWidth = (fromRight, delay) => ({
	show: {
		width: "100%",
		opacity: 1,
		float: fromRight ? "right" : "left",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
			delay,
		},
	},
	hidden: {
		width: 0,
		opacity: 0,
		float: fromRight ? "right" : "left",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
			delay: delay + 0.2,
		},
	},
});

export default getWidth;
