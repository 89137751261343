import { DEFAULT_EASE, DEFAULT_DURATION } from "@/constants/transition";

export const slide = {
	active: {
		x: "0%",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},

	isPrev: {
		x: "-105%",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},

	isNext: {
		x: "105%",
		transition: {
			ease: DEFAULT_EASE,
			duration: DEFAULT_DURATION,
		},
	},
};

export const slideDelay = {
	initial: {
		x: "16px",
		transition: {
			ease: "linear",
		},
	},

	animate: {
		x: "-16px",
		transition: {
			ease: "linear",
		},
	},
};

export const slideFull = {
	enter: (direction) => {
		return {
			x: direction > 0 ? 2000 : -2000,
			opacity: 0,
		};
	},
	center: {
		zIndex: 1,
		x: 0,
		opacity: 1,
	},
	exit: (direction) => {
		return {
			zIndex: 0,
			x: direction < 0 ? 2000 : -2000,
			opacity: 0,
		};
	},
};

export const getSlideDelay = (options) => ({
	...slideDelay,
	initial: {
		...slideDelay.initial,
		transition: {
			...slideDelay.initial.transition,
			...options,
		},
	},
	animate: {
		...slideDelay.animate,
		transition: {
			...slideDelay.animate.transition,
			...options,
		},
	},
});
