// =============================================================================
// Detail
//
// Version 1: Shoe left, aligned left
// Version 2: Shoe right, aligned right
// Version 3: 2 shoes (compare mode), no CTA
//
// =============================================================================
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { pathOr, isNil, isEmpty, find, propEq, equals } from "ramda";
import { AnimatePresence, motion } from "framer-motion";
import { useHistory, useLocation } from "react-router-dom";
import cn from "classnames";
import { v4 as uuidv4 } from "uuid";

import { SIDE } from "@/constants/rfid-tags";
import ROUTES from "@/constants/routes";
import ACHIEVEMENT_ICONS from "@/constants/achievement_icons";

import ShoeHeading from "@/components/ShoeHeading";
import { Button2 } from "@/components/Button";
import ButtonIcon from "@/components/ButtonIcon";
import Block from "@/components/Block";
import Typography from "@/components/Typography";
import ReviewStars from "@/components/ReviewStars";
import Image from "@/components/Image";
import SliderNavigation from "@/components/SliderNavigation";
import Codification from "@/components/Codification";
import GridBlock from "@/components/GridBlock";
import SliderShoeHero from "@/components/SliderShoeHero";
import { MembershipMenu } from "@/components/Membership";
import Icon from "@/components/Icon";
import HorizontalAvailability from "@/components/HorizontalAvailability";

import { updateVisible, clearActive, clearQty, clearPrice } from "@/store/shoes/shoes.actions";
import { removeTag } from "@/store/rfid/rfid.actions";
import { setLatestAction, setShoesModal } from "@/store/ui/ui.actions";

import { useAllShoes, useActiveShoes, useTableLocation } from "@/store/shoes/shoes.hooks";
import { useFlippedOrientation, useOptionsModal, useLowGPU } from "@/store/ui/ui.hooks";
import { useFilterIDs } from "@/store/filters/filters.hooks";
import { useMembershipActive, useIdentity, useWelcomeRail } from "@/store/membership/membership.hooks";

import useSlider from "@/hooks/Slider";
import useActiveShoe from "@/hooks/ActiveShoe";
import useGrid from "@/hooks/Grid";
import { useQuery } from "@/hooks/Router";

import detailLeftGrid from "@/assets/grids/detail_left.json";
import detailRightGrid from "@/assets/grids/detail_right.json";
import detailLeftGridMembership from "@/assets/grids/detail_left_membership.json";
import detailRightGridMembership from "@/assets/grids/detail_right_membership.json";

import { fadeMove } from "@/animations";

import getPulseData from "@/utils/data/getPulseData";
import { flipSides } from "@/utils/shoes";
import formatNumber from "@/utils/number";
import { getTechnologyStyleBlock } from "@/utils/data/productGroupings";
import setAnalytics from "@/utils/analytics";
import { isLangKorean } from "@/utils/i18n";
import { random } from "@/utils/math";
import { sentryLogException } from "@/utils/sentry";

import DetailPartialsPlaceShoe from "./Partials";

const Detail = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const query = useQuery();
	const isFlipped = useFlippedOrientation();
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const { toggleIsOpen } = useOptionsModal();
	const { data: allShoes, loading } = useAllShoes();
	const lowGPU = useLowGPU();
	const filterIDs = useFilterIDs();
	const activeShoes = useActiveShoes();
	const membershipActive = useMembershipActive();
	const tableLocation = useTableLocation();
	const welcomeRail = useWelcomeRail();

	const { data: identity, error: identityError } = useIdentity();
	const city = tableLocation.data.json_meta || { sportpulse_id: null };

	const { isGridLoading, start: startGrid, end: endGrid, shouldRerenderGrid } = useGrid();
	const { activeShoe, isRight, isCompareMode, isDigital, left, right } = useActiveShoe();
	const {
		slider,
		currentSlide,
		initSlider,
		speed,
		handlePrev,
		handleNext,
		updateCurrentSlide,
		handleDestroy,
		direction,
	} = useSlider({ speed: 10 });

	const [slides, setSlides] = useState([]);
	const [isQR, setisQR] = useState(false);
	const [productGroupings, setProductGroupings] = useState([]);
	const [showSportPulse, setShowSportPulse] = useState(false);
	const [avgPace, setAvgPace] = useState(null);
	const [totalDistance, setTotalDistance] = useState(null);
	const [gridShoe, setGridShoe] = useState(null);
	const [recommendationCopy, setRecommendationCopy] = useState("");
	const [showRail, setShowRail] = useState(false);

	const showStock = query.get("show-stock") || null;
	const showPrice = query.get("show-price") || null;
	const isFavoritesEnabled = equals(query.get("favorites"), "true");
	const qForm = query.get("form") || "rise";

	// #region FUNCTIONS
	const handleSetProductGroupings = (id) => {
		const side = isRight ? SIDE.Right : SIDE.Left;
		history.push({
			pathname: ROUTES.ProductGrouping,
			search: location.search,
			state: { id, side: flipSides(isFlipped, side) },
		});
	};

	const handleSetQR = () => {
		setisQR(!isQR);
		if (!isQR) dispatch(setLatestAction({ type: "show_nike_app_download_qr", id: uuidv4() }));
	};

	const handleBackToCompare = () => {
		history.goBack();
	};

	const getTechnologyStyleId = () => {
		if ((!isNil(totalDistance) && !isNil(avgPace) && showSportPulse) || !isEmpty(identity)) {
			return isRight ? "DetailRightNavH" : "DetailLeftNavH";
		}

		if (isRight) {
			return "DetailRightNavF";
		}

		return "DetailLeftNavE";
	};

	const getReviewsId = () => {
		if (!isEmpty(productGroupings)) {
			return isRight ? "DetailRightNavD" : "DetailLeftNavD";
		}

		if (isRight) {
			return "DetailRightNavF";
		}

		return "DetailLeftNavC";
	};

	const handleSetCurrentSlide = (i) => {
		updateCurrentSlide(i);
	};

	const handleRemove = (side) => {
		const trueSide = flipSides(isFlipped, side);
		dispatch(removeTag(pathOr("", ["epc"], isRight ? right : left)));
		dispatch(clearQty(trueSide));
		dispatch(clearPrice(trueSide));
		dispatch(clearActive(trueSide));

		const otherShoe = activeShoes[side === SIDE.Left ? SIDE.Right : SIDE.Left];
		if (!isNil(otherShoe)) {
			dispatch(updateVisible(true, trueSide === SIDE.Left ? SIDE.Right : SIDE.Left));
		}
	};

	const checkShowSportPulse = () => {
		if (process.env.REACT_APP_LOCATION === "china") return false;
		let performance = false;

		try {
			const category = pathOr(null, ["meta", "category"], activeShoe);
			performance = category === filterIDs.runningID; // || category === filterIDs.trainingID;
		} catch (e) {
			sentryLogException("No Performance for Language", e);
		}
		// console.log(performance, activeShoe);

		return performance;
	};
	// #endregion FUNCTIONS

	// #region LIFECYCLE HOOKS
	useEffect(() => {
		if (activeShoe && activeShoe.slides && activeShoe.slides.length > 0) {
			initSlider({ slides: activeShoe.slides.length - 1 });

			// On detail the first slide is removed, uninteresting data
			const detailSlides = [...activeShoe.slides];
			detailSlides.shift();

			setSlides(detailSlides);
		}

		return () => {
			handleDestroy();
		};
	}, [activeShoe, language]);

	useEffect(() => {
		if (gridShoe && gridShoe.recommendations.length > 0) {
			activeShoe.recommendations = gridShoe.recommendations;
		}
	}, [gridShoe]);

	useEffect(() => {
		if (isNil(activeShoe)) {
			return null;
		}

		let recommendationInterval = null;

		setAnalytics("product", { product_id: activeShoe.id, member: !isEmpty(identity) });
		setProductGroupings(pathOr([], ["productGroupings"], activeShoe));
		setShowSportPulse(checkShowSportPulse());
		setAvgPace(getPulseData(activeShoe, "avg_pace"));
		setTotalDistance(getPulseData(activeShoe, "total_distance"));

		if (activeShoe && activeShoe.recommendations.length > 0) {
			activeShoe.tempRecommendations = activeShoe.recommendations.slice(0, 3);
		}

		if (activeShoe && activeShoe.tempRecommendations.length > 1) {
			setRecommendationCopy(activeShoe.tempRecommendations[0].copy);
			let count = 1;
			recommendationInterval = setInterval(() => {
				if (activeShoe.tempRecommendations[count])
					setRecommendationCopy(activeShoe.tempRecommendations[count].copy);
				count += 1;
				if (count === activeShoe.tempRecommendations.length) {
					count = 0;
				}
			}, 3000);
		} else if (activeShoe && activeShoe.tempRecommendations.length > 0)
			setRecommendationCopy(activeShoe.tempRecommendations[0].copy);

		return () => {
			clearInterval(recommendationInterval);
		};
	}, [activeShoe, gridShoe]);

	useEffect(() => {
		if (!isNil(activeShoe)) {
			const membershipShoe = find(propEq("id", activeShoe.id))(allShoes);
			if (!isEmpty(identity)) {
				setGridShoe(membershipShoe);
			}
		}
	}, [allShoes]);

	useEffect(() => {
		setShowRail(false);
		let showRailTimer = null;

		const reloadTimer = setTimeout(() => {
			endGrid();
			let grid = detailLeftGrid;
			if (membershipActive) {
				grid = isRight ? detailRightGridMembership : detailLeftGridMembership;
			} else {
				grid = isRight ? detailRightGrid : detailLeftGrid;
			}
			startGrid(grid);
		}, 10);

		if (!isEmpty(identity)) {
			showRailTimer = setTimeout(() => {
				setShowRail(true);
				clearTimeout(showRailTimer);
			}, 1000);
		}

		return () => {
			clearTimeout(reloadTimer);
		};
	}, [isRight, language, identity, gridShoe, activeShoe]);

	useEffect(() => {
		setAnalytics("detail", { member: !isEmpty(identity) });
	}, []);
	// #endregion LIFECYCLE HOOKS

	// #region RENDER
	const renderSelectShoesButton = useMemo(() => (side, label, fromRight = false) => (
		<Button2 fromRight={fromRight} onClick={() => dispatch(setShoesModal(true, { side }))} delay={0.5}>
			{label}
		</Button2>
	));

	const renderProductGroupingBlock = useMemo(() => () => {
		const productGrouping = getTechnologyStyleBlock(productGroupings, qForm);
		const id = pathOr(null, ["id"], productGrouping);
		const assets = pathOr([], ["assets"], productGrouping);
		const label = pathOr("", ["meta", "name"], productGrouping);
		const hasDetail = Boolean(productGrouping && assets.length > 2);

		return (
			hasDetail && (
				<GridBlock id={getTechnologyStyleId()}>
					<Block
						onClick={hasDetail ? () => handleSetProductGroupings(id) : null}
						className="s-visual u-fill-space c-block--inset c-block--floating-content"
					>
						<Block.Background
							className={cn("c-block__image c-image--cover detail-page", {
								"no-detail": activeShoe.missingDetailImages,
								recommended: !isEmpty(identity),
							})}
							src={activeShoe.assets.zoom_toe.square}
						/>

						<Block.Header className="u-mt-0">
							<Typography tag="p" size="2xs">
								{t("titles.technology_style")}
							</Typography>
						</Block.Header>

						<Block.Content className="u-flex u-jc-center u-ai-center u-text-c">
							<Codification
								text={label}
								typography={{ primary: true, size: "md", uppercase: true }}
								characterSwitchAmount={4}
								characterNextTrigger={2}
								characterTimeout={20}
								lowGPU={lowGPU}
							/>
						</Block.Content>

						<Block.Footer className="u-flex u-jc-end u-ac-end">
							{hasDetail && <ButtonIcon className="c-icon--size-3" icon="plus" />}
						</Block.Footer>
					</Block>
				</GridBlock>
			)
		);
	});

	const renderRecommendationHighlight = useMemo(() => (recommendation, popular = false) => {
		const copy = t(recommendation.text, recommendation.data ?? null);
		const newCopy = copy.split(" ");
		const highlight = newCopy.splice(-2);
		let string = "";
		if (!popular) string += t("recommendations.because");
		string += newCopy.join(" ");
		string += " ";
		string += highlight.join(" ");
		return (
			<>
				<Codification
					text={string}
					typography={{ primary: true, size: "lg", uppercase: true }}
					characterSwitchAmount={4}
					characterNextTrigger={2}
					characterTimeout={10}
					lowGPU={lowGPU}
				/>
			</>
		);
	});

	const renderRecommendationIcons = useMemo(() => (recommendations) => {
		let swoosh = 0;
		let nrc = 0;
		let ntc = 0;
		let loggedRun = 0;
		let training = 0;
		const achievements = [];
		recommendations.forEach((rec) => {
			if (rec.type === "purchase") swoosh += 1;
			else if (rec.type === "nrc") nrc += 1;
			else if (rec.type === "ntc") ntc += 1;
			else if (rec.type === "training") training += 1;
			else if (rec.type === "loggedRun") loggedRun += 1;
			else if (rec.type === "achievement") achievements.push(rec);
		});
		return (
			<div className="u-flex">
				{swoosh > 0 ? <Icon className="c-icon-recommendation--size-1" type="swoosh" /> : null}
				{nrc > 0 ? <Icon className="c-icon-recommendation--size-1" type="nrc-black" /> : null}
				{loggedRun > 0 ? <Icon className="c-icon-recommendation--size-1" type="nrc-black" /> : null}
				{ntc > 0 ? <Icon className="c-icon-recommendation--size-1" type="ntc-white" /> : null}
				{training > 0 ? <Icon className="c-icon-recommendation--size-1" type="ntc-white" /> : null}
				{achievements.map((ach) => (
					<Image
						className="u-recommendation-width-1"
						key={`${ach.icon}${random(1, 10000)}`}
						src={find(propEq("name", ach.icon))(ACHIEVEMENT_ICONS).src}
					/>
				))}
			</div>
		);
	});

	return (
		!shouldRerenderGrid &&
		!isGridLoading &&
		!loading &&
		!isNil(activeShoe) && (
			<>
				{!isCompareMode && (
					<>
						<GridBlock
							id={isRight ? "DetailRightPlaceLeftTop" : "DetailLeftPlaceRightTop"}
							className={cn("u-flex u-ai-end u-jc-end u-pb-5", {
								"u-text-r u-pr-7": !isRight,
								"u-pl-7": isRight,
							})}
						>
							<DetailPartialsPlaceShoe isRight={isRight} />
						</GridBlock>

						<GridBlock
							id={isRight ? "DetailRightPlaceLeftBottom" : "DetailLeftPlaceRightBottom"}
							className={cn("u-flex u-ai-start u-pt-5", {
								"u-jc-end u-pr-7": !isRight,
								"u-pl-7": isRight,
							})}
						>
							{renderSelectShoesButton(
								isRight ? SIDE.Left : SIDE.Right,
								t("buttons.find_a_shoe"),
								!isRight
							)}
						</GridBlock>
					</>
				)}

				<GridBlock
					id={isRight ? "DetailRightContentRightTop" : "DetailLeftContentLeftTop"}
					className={cn("u-flex u-ai-start", {
						"u-jc-end": isRight,
						"u-pl-7 u-pr-6": !isRight,
						"u-pl-6 u-pr-7": isRight,
					})}
				>
					<ShoeHeading
						className={cn("u-fill-width u-inline-2", {
							"u-row-reverse": isRight,
						})}
					>
						<ShoeHeading.Image src={activeShoe.assets.left.square} />

						<ShoeHeading.Title className={cn({ "u-text-r": isRight })}>
							{activeShoe.content.name}
						</ShoeHeading.Title>
					</ShoeHeading>
				</GridBlock>

				<GridBlock
					id={isRight ? "DetailRightContentRightBody" : "DetailLeftContentLeftBody"}
					className={cn("c-shoe-tagline u-stack-4 u-flex u-col", {
						"u-pl-8 u-pr-7 u-ai-end": isRight,
						"u-pl-7 u-pr-8 u-ai-start": !isRight,
					})}
				>
					{isNil(activeShoe.content.tagline) || isEmpty(activeShoe.content.tagline) ? (
						<div className="u-min-height-90" />
					) : (
						<Codification
							className={cn("u-min-height-90", isRight && "u-text-r")}
							align={isRight ? "right" : "left"}
							text={activeShoe.content.tagline}
							typography={
								isLangKorean()
									? {
											primary: true,
											size: "3xl",
											tag: "span",
											typeStyle: {
												fontSize: "2.4vw",
											},
									  }
									: {
											primary: true,
											size: "3xl",
											tag: "span",
									  }
							}
							lowGPU={lowGPU}
							characterSwitchAmount={4}
							characterNextTrigger={2}
							characterTimeout={20}
						/>
					)}

					{isDigital && (
						<Button2
							onClick={() => handleRemove(isRight ? SIDE.Right : SIDE.Left)}
							className="c-button--rounded c-button--outline"
							delay={0}
						>
							{t("buttons.remove_shoe")}
						</Button2>
					)}
				</GridBlock>

				<GridBlock
					className="detail-slider"
					id={(isRight && "DetailRightContentLeftCompare") || "DetailLeftContentRightCompare"}
				>
					<SliderShoeHero
						code={activeShoe.meta.color_code}
						slides={slides}
						handleSetProductGroupings={handleSetProductGroupings}
						handlePrev={handlePrev}
						handleNext={handleNext}
						currentSlide={currentSlide}
						speed={speed}
						direction={direction}
						isDetail
						missingDetailImages={activeShoe.missingDetailImages}
					/>
				</GridBlock>

				<GridBlock id={isRight ? "DetailRightNavA" : "DetailLeftNavA"}>
					<HorizontalAvailability
						isFlipped={isFlipped}
						showStock={showStock}
						showPrice={showPrice}
						lowGPU={lowGPU}
						isFavoritesEnabled={isFavoritesEnabled && !isEmpty(identity)}
						handleBackToCompare={handleBackToCompare}
					/>
				</GridBlock>

				{!isNil(avgPace) && showSportPulse && (
					<GridBlock id={isRight ? "DetailRightNavC" : "DetailLeftNavC"}>
						<Block className="c-block--inset" onClick={handleSetQR}>
							<Block.Header>
								<Typography tag="p" size="2xs">
									{t("titles.nike_run_club")}
								</Typography>
							</Block.Header>

							<Block.Content className="u-flex u-jc-center u-ai-center u-text-c u-pos-relative">
								<AnimatePresence>
									{isQR && (
										<motion.div
											className="c-block__content-inner"
											variants={fadeMove}
											initial="hidden"
											animate="show"
											exit="exit"
										>
											<Image
												src={
													city.sportpulse_id !== "Seoul"
														? "https://nikeqr.io/?url=https%3A%2F%2Fnikerunning.sng.link%2FA6sko%2Fq213%3F_dl%3Dnikerunclub%253A%252F%252Fx-callback-url%252Faudioguidedrun%252Flanding%26_smtype%3D3%26cp%3Dwpcn"
														: "https://nikeqr.io/?url=https%3A%2F%2Fwww.nike.com%2Fkr%2Fko_kr%2Fc%2Frunning%2Faudio-guide-run"
												}
												className="u-px-13"
											/>
										</motion.div>
									)}
								</AnimatePresence>
								<AnimatePresence initial={false}>
									{!isQR && (
										<motion.div
											className="c-block__content-inner"
											variants={fadeMove}
											initial="hidden"
											animate="show"
											exit="exit"
										>
											<Codification
												text={avgPace}
												typography={{
													primary: true,
													size: "3xl",
													tag: "span",
													uppercase: true,
												}}
												characterSwitchAmount={4}
												characterNextTrigger={2}
												characterTimeout={20}
												lowGPU={lowGPU}
											/>
										</motion.div>
									)}
								</AnimatePresence>
							</Block.Content>

							<Block.Footer className="u-pos-relative">
								<AnimatePresence>
									{isQR && (
										<motion.div
											className="c-block__footer-inner"
											variants={fadeMove}
											initial="hidden"
											animate="show"
											exit="exit"
										>
											<Typography tag="p" size="2xs" className="u-text-nowrap">
												{t("titles.nike_run_club_qr")}
											</Typography>
										</motion.div>
									)}
								</AnimatePresence>
								<AnimatePresence initial={false}>
									{!isQR && (
										<motion.div
											className="c-block__footer-inner"
											variants={fadeMove}
											initial="hidden"
											animate="show"
											exit="exit"
										>
											<Typography tag="p" size="2xs">
												{t("generic.average_pace")}
											</Typography>
										</motion.div>
									)}
								</AnimatePresence>
							</Block.Footer>
						</Block>
					</GridBlock>
				)}

				<GridBlock id={getReviewsId()}>
					<Block
						url={
							!isNil(pathOr(null, ["feedback", "reviews", 0], activeShoe))
								? { pathname: ROUTES.Reviews, search: location.search }
								: null
						}
						className="c-block--inset c-block--floating-content"
					>
						<Block.Header>
							<Typography tag="p" size="2xs">
								{activeShoe.feedback.reviews.length} {t("generic.reviews")}
							</Typography>
						</Block.Header>

						<Block.Content className="u-flex u-jc-center u-ai-center u-text-c">
							{activeShoe.feedback && (
								<Codification
									text={
										activeShoe.feedback.avg_rating
											? `${activeShoe.feedback.avg_rating} / 5`
											: t("generic.no_reviews")
									}
									typography={{
										primary: true,
										size: "3xl",
										tag: "span",
									}}
									characterSwitchAmount={4}
									characterNextTrigger={2}
									characterTimeout={20}
									lowGPU={lowGPU}
								/>
							)}
						</Block.Content>

						<Block.Footer className="u-flex u-jc-between u-ac-end">
							<ReviewStars
								className="u-ai-end"
								rating={activeShoe.feedback.avg_rating}
								size={3}
								space={1}
							/>

							{!isNil(pathOr(null, ["feedback", "reviews", 0], activeShoe)) && (
								<ButtonIcon className="c-icon--size-3" icon="plus" />
							)}
						</Block.Footer>
					</Block>
				</GridBlock>

				{!isNil(totalDistance) && showSportPulse && isEmpty(identity) && (
					<GridBlock id={isRight ? "DetailRightNavF" : "DetailLeftNavE"}>
						<Block className="c-block--inset c-block--floating-content">
							<Block.Header>
								<Typography tag="p" size="2xs">
									{t("titles.nike_run_club")}
								</Typography>
							</Block.Header>

							<Block.Content className="u-flex u-jc-center u-ai-center u-text-c">
								<Codification
									text={formatNumber(totalDistance)}
									typography={{ primary: true, size: "3xl", tag: "span", uppercase: true }}
									characterSwitchAmount={4}
									characterNextTrigger={2}
									characterTimeout={20}
									lowGPU={lowGPU}
								/>
							</Block.Content>

							<Block.Footer>
								<Typography tag="p" size="2xs">
									{t("generic.miles_run_today")}
								</Typography>
							</Block.Footer>
						</Block>
					</GridBlock>
				)}

				{activeShoe.tempRecommendations.length > 0 && (
					<GridBlock id={isRight ? "DetailRightNavF" : "DetailLeftNavE"}>
						<Block className="c-block--inset c-block--floating-content">
							<Block.Header>
								<Typography tag="p" size="2xs">
									{t("titles.recommended_for_you")}
								</Typography>
							</Block.Header>

							<Block.Content className="c-block--inset u-flex u-jc-start u-ai-center">
								<Typography primary tag="span" size="lg" uppercase lineHeightSize="md">
									{activeShoe.tempRecommendations[0] &&
										renderRecommendationHighlight(recommendationCopy)}
								</Typography>
							</Block.Content>

							<Block.Footer>{renderRecommendationIcons(activeShoe.tempRecommendations)}</Block.Footer>
						</Block>
					</GridBlock>
				)}

				{activeShoe.tempRecommendations.length === 0 && !isEmpty(identity) && (
					<GridBlock id={isRight ? "DetailRightNavF" : "DetailLeftNavE"}>
						<Block className="c-block--inset c-block--floating-content">
							<Block.Header>
								<Typography tag="p" size="2xs">
									{t("titles.popular_with")}
								</Typography>
							</Block.Header>
							<Block.Content className="c-block--inset u-flex u-jc-start u-ai-center">
								<Typography primary tag="span" size="lg" uppercase lineHeightSize="md">
									{renderRecommendationHighlight(activeShoe.popularWith, true)}
								</Typography>
							</Block.Content>
						</Block>
					</GridBlock>
				)}

				{!isEmpty(productGroupings) && renderProductGroupingBlock()}

				<GridBlock id={isRight ? "DetailRightBottomLeft" : "DetailLeftBottomLeft"} className="u-flex u-ai-end">
					<div
						onClick={toggleIsOpen}
						onKeyDown={toggleIsOpen}
						role="button"
						tabIndex={0}
						className="u-inline-flex u-ai-end u-jc-end u-p-1"
					>
						<Icon className="c-icon-membership--size-9" type="options-menu" />
						<Icon className="c-icon-membership--size-9" type="caret-up" />
					</div>
				</GridBlock>

				<GridBlock
					id={isRight ? "DetailRightBottomCenter" : "DetailLeftBottomCenter"}
					className="u-flex u-jc-center u-ai-center"
				>
					<SliderNavigation
						setCurrentSlide={handleSetCurrentSlide}
						slides={pathOr(0, ["totalSlides"], slider)}
						step={currentSlide}
						speed={speed}
					/>
				</GridBlock>

				{membershipActive && welcomeRail && (
					<GridBlock id={isRight ? "DetailRightBottomRight" : "DetailLeftBottomRight"}>
						<MembershipMenu />
					</GridBlock>
				)}
			</>
		)
	);
	// #endregion RENDER
};

export default Detail;
