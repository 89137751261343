import ShoesActionTypes from "./shoes.action-types";
import ShoesInitialState from "./shoes.initial-state";

const ShoesReducer = (state = ShoesInitialState(), action) => {
	switch (action.type) {
		case ShoesActionTypes.GET_ALL: {
			return {
				...state,
				all: {
					...state.all,
					loading: true,
					error: null,
				},
			};
		}

		case ShoesActionTypes.GET_ALL_SUCCESS: {
			return {
				...state,
				all: {
					...state.all,
					data: action.data,
					loading: false,
					error: null,
				},
			};
		}

		case ShoesActionTypes.GET_ALL_ERROR: {
			return {
				...state,
				all: {
					...state.all,
					loading: false,
					error: action.error,
				},
			};
		}

		case ShoesActionTypes.GET_INVENTORY:
		case ShoesActionTypes.GET_SHOE_BY_EPC:
		case ShoesActionTypes.GET_SHOE_BY_SCC: {
			return {
				...state,
				active: {
					...state.active,
					loading: true,
					error: null,
				},
			};
		}

		case ShoesActionTypes.GET_SHOE_BY_EPC_SUCCESS:
		case ShoesActionTypes.GET_SHOE_BY_SCC_SUCCESS: {
			const { side, data } = action;

			return {
				...state,
				active: {
					...state.active,
					[side]: {
						...state.active[side],
						detail: data,
					},
					loading: false,
					error: null,
				},
			};
		}

		case ShoesActionTypes.GET_SCC_BY_EPC_SUCCESS: {
			return {
				...state,
				active: {
					...state.active,
					loading: false,
					error: null,
				},
			};
		}

		case ShoesActionTypes.GET_INVENTORY_ERROR:
		case ShoesActionTypes.GET_SHOE_BY_EPC_ERROR:
		case ShoesActionTypes.GET_SHOE_BY_SCC_ERROR:
		case ShoesActionTypes.GET_SCC_BY_EPC_ERROR: {
			return {
				...state,
				active: {
					...state.active,
					loading: false,
					error: action.error,
				},
			};
		}

		case ShoesActionTypes.GET_LOCATION: {
			return {
				...state,
				location: {
					...state.location,
					loading: true,
					error: null,
				},
			};
		}

		case ShoesActionTypes.GET_LOCATION_SUCCESS: {
			return {
				...state,
				location: {
					...state.location,
					data: action.data,
					loading: false,
					error: null,
				},
			};
		}

		case ShoesActionTypes.GET_LOCATION_ERROR: {
			return {
				...state,
				location: {
					...state.location,
					loading: false,
					error: action.error,
				},
			};
		}

		case ShoesActionTypes.SET_ACTIVE: {
			const { side, data } = action;

			return {
				...state,
				active: {
					...state.active,
					[side]: {
						...state.active[side],
						...data,
					},
				},
			};
		}

		case ShoesActionTypes.GET_INVENTORY_SUCCESS: {
			const { side, data } = action;

			return {
				...state,
				qty: {
					...state.qty,
					[side]: data,
				},
			};
		}

		case ShoesActionTypes.GET_PRICE_SUCCESS: {
			const { side, data } = action;

			return {
				...state,
				price: {
					...state.price,
					[side]: data,
				},
			};
		}

		case ShoesActionTypes.UPDATE_VISIBLE: {
			const { side, data } = action;

			return {
				...state,
				active: {
					...state.active,
					[side]: {
						...state.active[side],
						isVisible: data,
					},
				},
			};
		}

		case ShoesActionTypes.UPDATE_DIGITAL: {
			const { side, data } = action;

			return {
				...state,
				active: {
					...state.active,
					[side]: {
						...state.active[side],
						isDigital: data,
					},
				},
			};
		}

		case ShoesActionTypes.UPDATE_TEMPLATE: {
			const { side, data } = action;

			return {
				...state,
				active: {
					...state.active,
					[side]: {
						...state.active[side],
						template: data,
					},
				},
			};
		}

		case ShoesActionTypes.CLEAR_ACTIVE: {
			return {
				...state,
				active: {
					...state.active,
					[action.side]: null,
				},
			};
		}

		case ShoesActionTypes.CLEAR_QTY: {
			return {
				...state,
				qty: {
					...state.qty,
					[action.side]: null,
				},
			};
		}

		case ShoesActionTypes.CLEAR_PRICE: {
			return {
				...state,
				price: {
					...state.price,
					[action.side]: null,
				},
			};
		}

		case ShoesActionTypes.GET_IMAGEMAP: {
			return {
				...state,
				imagemap: {
					...state.imagemap,
					loading: true,
					error: null,
				},
			};
		}

		case ShoesActionTypes.GET_IMAGEMAP_SUCCESS: {
			return {
				...state,
				imagemap: {
					...state.imagemap,
					loading: false,
					data: action.data,
				},
			};
		}

		case ShoesActionTypes.GET_IMAGEMAP_ERROR: {
			return {
				...state,
				imagemap: {
					...state.imagemap,
					loading: false,
					error: action.error,
				},
			};
		}

		default: {
			return state;
		}
	}
};

export default ShoesReducer;
