import React, { useContext, useEffect, useRef, useState, memo } from "react";
import cn from "classnames";

import { GridContext } from "@/providers/Grid";

import propTypes from "./GridBlock.propTypes";
import defaultProps from "./GridBlock.defaultProps";

const GridBlock = ({ className, children, id, type, layout, style = {} }) => {
	const gridContext = useContext(GridContext);
	const gridBlockRef = useRef(null);
	const initialWidth = useRef(null);
	const initialHeight = useRef(null);
	const [isMaskSet, setIsMaskSet] = useState(false);

	const updateTransform = ({ left: x, top: y }) => {
		gridBlockRef.current.style.transform = `translate3d(${x}px, ${y}px, 0)`;
	};

	const updateWidth = ({ width: variableWidth }) => {
		if (layout) {
			gridBlockRef.current.style.width = `${variableWidth}px`;
		} else {
			if (initialWidth.current) {
				return;
			}

			const { width: fixedWidth } = gridContext.masks.getMaskBounds(id);

			gridBlockRef.current.style.width = `${fixedWidth}px`;
			initialWidth.current = fixedWidth;
		}
	};

	const updateHeight = ({ height: variableHeight }) => {
		if (layout) {
			gridBlockRef.current.style.height = `${variableHeight}px`;
		} else {
			if (initialHeight.current) {
				return;
			}

			const { height: fixedHeight } = gridContext.masks.getMaskBounds(id);

			gridBlockRef.current.style.height = `${fixedHeight}px`;
			initialHeight.current = fixedHeight;
		}
	};

	const updateClipPath = ({ width: variableWidth, height: variableHeight }) => {
		gridBlockRef.current.style.clipPath = `polygon(0% 0%, ${variableWidth}px 0%, ${variableWidth}px ${variableHeight}px, 0% ${variableHeight}px)`;
	};

	const onUpdateMask = (position) => {
		const boundingRect = position.toBoundingRect();

		if (gridBlockRef.current) {
			updateTransform(boundingRect);
			updateWidth(boundingRect);
			updateHeight(boundingRect);
			updateClipPath(boundingRect);
		}

		if (!isMaskSet) {
			setIsMaskSet(true);
		}
	};

	useEffect(() => {
		gridContext.addListener(type, onUpdateMask, id);

		return () => {
			gridContext.removeListener(type, id);
			gridContext.masks.pause();
			setIsMaskSet(false);
		};
	}, [id]);

	return (
		<div ref={gridBlockRef} style={style} className={cn("c-grid-block", className)}>
			{isMaskSet && children}
		</div>
	);
};

GridBlock.propTypes = propTypes;
GridBlock.defaultProps = defaultProps;

export default memo(GridBlock);
