import { DEFAULT_EASE, DEFAULT_DURATION } from "@/constants/transition";

const transition = { ease: DEFAULT_EASE, duration: DEFAULT_DURATION / 6 };

const iconAnim = {
	open: { scale: 0, opacity: 0, transition },
	closed: { scale: 1, opacity: 1, transition },
};

export default iconAnim;
