import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isNil, equals, find, propEq, isEmpty, pathOr } from "ramda";
import { v4 as uuidv4 } from "uuid";

import {
	setActive,
	clearActive,
	clearQty,
	clearPrice,
	getShoeBySCC,
	getShoeByEPC,
	fetchShoeInventory,
	fetchShoePrice,
	fetchSCCByEPC,
	updateVisible,
} from "@/store/shoes/shoes.actions";
import { useAllShoes, useTableLocation, useActiveShoes, useImagemap } from "@/store/shoes/shoes.hooks";
import { useHotspotLeft, useHotspotRight, use404List } from "@/store/rfid/rfid.hooks";
import { setLatestAction, setShoeNotFoundModal, setShoesModal, setActiveSession } from "@/store/ui/ui.actions";
import { useShoeNotFoundModal, useFlippedOrientation, useStoreID, useActiveSession } from "@/store/ui/ui.hooks";
import { useFilterIDs } from "@/store/filters/filters.hooks";
import { useMembershipActive, useIdentity, useStyles } from "@/store/membership/membership.hooks";

import { useQuery } from "@/hooks/Router";

import { flipSides, handleMockEPC } from "@/utils/shoes";
import setAnalytics from "@/utils/analytics";
import { removeTag } from "@/store/rfid/rfid.actions";
import { SIDE } from "@/constants/rfid-tags";

export default () => {
	const query = useQuery();
	const dispatch = useDispatch();
	const hotspotLeft = useHotspotLeft();
	const hotspotRight = useHotspotRight();
	const tableLocation = useTableLocation();
	const { isOpen: isShoeNotFoundModalOpen, data: modalData } = useShoeNotFoundModal();
	const { data: allShoes, loading: shoesLoading } = useAllShoes();
	const activeShoes = useActiveShoes();
	const isFlipped = useFlippedOrientation();
	const imagemap = useImagemap();
	const { data: identity } = useIdentity();
	const { data: styles } = useStyles();
	const membershipActive = useMembershipActive();
	const filterIDs = useFilterIDs();
	const fourList = use404List();
	const { data: storeID } = useStoreID();
	const activeSession = useActiveSession();

	const store = query.get("store") || null;
	const showStock = query.get("show-stock") || false;
	const showPrice = query.get("show-price") || false;
	const isDemoMode = query.get("demo") || false;
	const useNewEPC = equals(query.get("new-epc"), "true");
	const qCdnUrl = query.get("cdn-url") || null;

	const setRecommendations = (shoe) => {
		const membershipShoe = find(propEq("id", shoe.scc))(allShoes);
		if (!isNil(membershipShoe)) shoe.detail.recommendations = membershipShoe.recommendations; // eslint-disable-line no-param-reassign
	};

	const handleRemove = (side) => {
		dispatch(removeTag(pathOr("", ["epc"], activeShoes[side === SIDE.Left ? SIDE.Right : SIDE.Left])));
		dispatch(clearQty(side));
		dispatch(clearPrice(side));
		dispatch(clearActive(side));

		const otherShoe = activeShoes[side === SIDE.Left ? SIDE.Right : SIDE.Left];
		if (!isNil(otherShoe)) {
			dispatch(updateVisible(true, side === SIDE.Left ? SIDE.Right : SIDE.Left));
		}
	};

	const checkAndSetActive = (shoe, side) => {
		setAnalytics("product_placed", { product_id: shoe.scc, member: !isEmpty(identity) });
		setRecommendations(shoe);
		const otherShoe = activeShoes[flipSides(isFlipped, side === SIDE.Left ? SIDE.Right : SIDE.Left)];
		if (shoe.epc === otherShoe?.epc) {
			handleRemove(side === SIDE.Left ? SIDE.Right : SIDE.Left);
		}
		dispatch(setActive(shoe, side));
		dispatch(setShoesModal(false));
		if (showStock && !isEmpty(storeID)) dispatch(fetchShoeInventory(storeID, shoe.scc, side));
		if (showPrice && !isEmpty(storeID)) dispatch(fetchShoePrice(storeID, shoe.scc, side));
		if (!activeSession) {
			const tempSession = uuidv4();
			dispatch(setActiveSession(tempSession));
			dispatch(setLatestAction({ type: "session_start", id: uuidv4(), activeSession: tempSession }));
		}
		setTimeout(() => dispatch(setLatestAction({ type: "place", id: uuidv4(), firstScc: shoe?.scc })), 200);
	};

	const handleBadEPC = (side, EPC, tag) => {
		dispatch(clearActive(side));
		dispatch(clearQty(side));
		dispatch(clearPrice(side));
		dispatch(setShoeNotFoundModal(true, { type: "not_found", side: flipSides(isFlipped, tag.side) }));
		setAnalytics("product_not_found", { missing_product_epc: EPC, member: !isEmpty(identity) });
	};

	const getActiveShoe = async (tag) => {
		const city = tableLocation.data.json_meta || { sportpulse_id: null };
		const marketplace = tableLocation.data.json_meta?.inside_track?.marketplace;
		let shoe = null;

		if (isDemoMode) {
			// INFO - Mock EPC when demo mode
			const demo = handleMockEPC(query, tag.id);

			if (useNewEPC) {
				if (!isNil(demo.scc)) {
					shoe = await dispatch(
						getShoeBySCC(
							demo.scc,
							"cache",
							store,
							city,
							imagemap,
							membershipActive,
							identity,
							filterIDs,
							[],
							styles,
							fourList,
							marketplace,
							qCdnUrl
						)
					);
				} else {
					const scc = await dispatch(fetchSCCByEPC(demo.epc, fourList));
					shoe = await dispatch(
						getShoeBySCC(
							scc,
							"cache",
							store,
							city,
							imagemap,
							membershipActive,
							identity,
							filterIDs,
							[],
							styles,
							fourList,
							marketplace,
							qCdnUrl
						)
					);
				}
			} else {
				shoe = !isNil(demo.scc)
					? await dispatch(
							getShoeBySCC(
								demo.scc,
								"cache",
								store,
								city,
								imagemap,
								membershipActive,
								identity,
								filterIDs,
								[],
								styles,
								fourList,
								marketplace,
								qCdnUrl
							)
					  )
					: await dispatch(
							getShoeByEPC(
								demo.epc,
								store,
								city,
								imagemap,
								membershipActive,
								identity,
								filterIDs,
								[],
								styles,
								fourList,
								qCdnUrl,
								marketplace
							)
					  );
			}
		} else if (useNewEPC) {
			const scc = await dispatch(fetchSCCByEPC(tag.id, fourList));
			shoe = await dispatch(
				getShoeBySCC(
					scc,
					"cache",
					store,
					city,
					imagemap,
					membershipActive,
					identity,
					filterIDs,
					[],
					styles,
					fourList,
					marketplace,
					qCdnUrl
				)
			);
		} else {
			shoe = await dispatch(
				getShoeByEPC(
					tag.id,
					store,
					city,
					imagemap,
					membershipActive,
					identity,
					filterIDs,
					[],
					styles,
					fourList,
					qCdnUrl,
					marketplace
				)
			);
		}

		return shoe;
	};

	useEffect(() => {
		if (isShoeNotFoundModalOpen && modalData.type === "not_found") {
			dispatch(setShoeNotFoundModal(false, { type: "not_found", side: "left" }));
		}

		if (shoesLoading || isEmpty(imagemap) || isEmpty(storeID) || isEmpty(allShoes)) {
			return;
		}

		if (hotspotRight?.status === "idle") {
			dispatch(removeTag(hotspotRight.id));
		}

		if (isNil(hotspotRight) && !isNil(activeShoes[flipSides(isFlipped, SIDE.Right)])) {
			dispatch(setActive({ ...activeShoes[flipSides(isFlipped, SIDE.Right)], isDigital: true }, SIDE.Right));
			return;
		}

		if (hotspotRight && hotspotRight.status !== "active_near") {
			// if tag is received and not active, and there is a shoe in state for that tag.side, then update the associated shoe with digital = true
			if (hotspotRight.status !== "active" && !isNil(activeShoes[flipSides(isFlipped, hotspotRight.side)])) {
				dispatch(
					setActive(
						{ ...activeShoes[flipSides(isFlipped, hotspotRight.side)], isDigital: true },
						hotspotRight.side
					)
				);
				return;
			}
			if (hotspotRight.status === "active") {
				getActiveShoe(hotspotRight).then((shoe) => {
					// INFO - Remove bad EPC
					if (isNil(shoe) && hotspotRight.status === "active") {
						handleBadEPC(hotspotRight.side, hotspotRight.id, hotspotRight);
					}

					if (!isNil(shoe)) {
						const newShoe = {
							epc: hotspotRight.id,
							scc: shoe.meta.color_code,
							detail: shoe,
							isDigital: hotspotRight.status !== "active",
							isVisible: true,
							template: null,
						};

						checkAndSetActive(newShoe, hotspotRight.side);
					}
				});
			}
		}
	}, [hotspotRight, shoesLoading, imagemap, storeID, identity]);

	useEffect(() => {
		if (isShoeNotFoundModalOpen && modalData.type === "not_found") {
			dispatch(setShoeNotFoundModal(false, { type: "not_found", side: "left" }));
		}

		if (shoesLoading || isEmpty(imagemap) || isEmpty(storeID) || isEmpty(allShoes)) {
			return;
		}

		if (hotspotLeft?.status === "idle") {
			dispatch(removeTag(hotspotLeft.id));
		}

		if (isNil(hotspotLeft) && !isNil(activeShoes[flipSides(isFlipped, SIDE.Left)])) {
			dispatch(setActive({ ...activeShoes[flipSides(isFlipped, SIDE.Left)], isDigital: true }, SIDE.Left));
			return;
		}

		if (hotspotLeft && hotspotLeft.status !== "active_near") {
			// if tag is received and not active, and there is a shoe in state for that tag.side, then update the associated shoe with digital = true
			if (hotspotLeft.status !== "active" && !isNil(activeShoes[flipSides(isFlipped, hotspotLeft.side)])) {
				dispatch(
					setActive(
						{ ...activeShoes[flipSides(isFlipped, hotspotLeft.side)], isDigital: true },
						hotspotLeft.side
					)
				);
				return;
			}
			if (hotspotLeft.status === "active") {
				getActiveShoe(hotspotLeft).then((shoe) => {
					// INFO - Remove bad EPC
					if (isNil(shoe) && hotspotLeft.status === "active") {
						handleBadEPC(hotspotLeft.side, hotspotLeft.id, hotspotLeft);
					}

					if (!isNil(shoe)) {
						const newShoe = {
							epc: hotspotLeft.id,
							scc: shoe.meta.color_code,
							detail: shoe,
							isDigital: hotspotLeft.status !== "active",
							isVisible: true,
							template: null,
						};

						checkAndSetActive(newShoe, hotspotLeft.side);
					}
				});
			}
		}
	}, [hotspotLeft, shoesLoading, imagemap, storeID, identity]);
};
