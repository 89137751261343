import { useDispatch, useSelector } from "react-redux";

import { setAdminPanelModal, setOptionsModal, setShoeNotFoundModal, setMembershipModal } from "./ui.actions";
import {
	selectLanguageModal,
	selectResetModal,
	selectAttractModal,
	selectGridLoading,
	selectShoesModal,
	selectFlippedOrientation,
	selectOptionsModal,
	selectMembershipModal,
	selectShoeNotFoundModal,
	selectAdminPanelModal,
	selectEnabledLanguages,
	selectScreensaverTrigged,
	selectLowGPU,
	selectStores,
	selectStoresLoading,
	selectStoresError,
	selectActiveSession,
	selectStoreID,
	selectStoreIDLoading,
	selectStoreIDError,
	selectReloadTrigger,
	selectAttractTrigger,
	selectQAMode,
	selectLatestAction,
	selectAnalyticsTypes,
	selectAnalyticsTypesLoading,
	selectAnalyticsTypesError,
} from "./ui.selectors";

export const useLanguageModal = () => useSelector((state) => selectLanguageModal(state));

export const useResetModal = () => useSelector((state) => selectResetModal(state));

export const useAttractModal = () => useSelector((state) => selectAttractModal(state));

export const useShoeNotFoundModal = () => {
	const dispatch = useDispatch();

	const { isOpen, data } = useSelector((state) => selectShoeNotFoundModal(state));

	const toggleIsOpen = () => dispatch(setShoeNotFoundModal(!isOpen, { type: "privacy_intercept" }));

	return { isOpen, toggleIsOpen, data };
};

export const useIsGridLoading = () => useSelector((state) => selectGridLoading(state));

export const useShoesModal = () => useSelector((state) => selectShoesModal(state));

export const useAdminPanelModal = () => {
	const dispatch = useDispatch();

	const { isOpen } = useSelector((state) => selectAdminPanelModal(state));
	const toggleIsOpen = () => dispatch(setAdminPanelModal(!isOpen));

	return { isOpen, toggleIsOpen };
};

export const useOptionsModal = () => {
	const dispatch = useDispatch();

	const { isOpen } = useSelector((state) => selectOptionsModal(state));
	const toggleIsOpen = () => dispatch(setOptionsModal(!isOpen));

	return { isOpen, toggleIsOpen };
};

export const useMembershipModal = () => {
	const dispatch = useDispatch();

	const { isOpen } = useSelector((state) => selectMembershipModal(state));
	const toggleIsOpen = () => dispatch(setMembershipModal(!isOpen));

	return { isOpen, toggleIsOpen };
};

export const useFlippedOrientation = () => useSelector((state) => selectFlippedOrientation(state));

export const useEnabledLanguages = () => useSelector((state) => selectEnabledLanguages(state));

export const useScreensaverTrigged = () => useSelector((state) => selectScreensaverTrigged(state));

export const useLowGPU = () => useSelector((state) => selectLowGPU(state));

export const useActiveSession = () => useSelector((state) => selectActiveSession(state));

export const useReloadTrigger = () => useSelector((state) => selectReloadTrigger(state));

export const useAttractTrigger = () => useSelector((state) => selectAttractTrigger(state));

export const useQAMode = () => useSelector((state) => selectQAMode(state));

export const useLatestAction = () => useSelector((state) => selectLatestAction(state));

export const useAnalyticsTypes = () => {
	const data = useSelector((state) => selectAnalyticsTypes(state));
	const loading = useSelector((state) => selectAnalyticsTypesLoading(state));
	const error = useSelector((state) => selectAnalyticsTypesError(state));

	return { data, loading, error };
};

export const useAllStores = () => {
	const data = useSelector((state) => selectStores(state));
	const loading = useSelector((state) => selectStoresLoading(state));
	const error = useSelector((state) => selectStoresError(state));

	return { data, loading, error };
};

export const useStoreID = () => {
	const data = useSelector((state) => selectStoreID(state));
	const loading = useSelector((state) => selectStoreIDLoading(state));
	const error = useSelector((state) => selectStoreIDError(state));

	return { data, loading, error };
};
