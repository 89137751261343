import React from "react";
import cn from "classnames";
import { createPortal } from "react-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch } from "react-redux";

import { fade, moveYReverse } from "@/animations";

import Button from "@/components/Button";

import propTypes from "./Modal.propTypes";
import defaultProps from "./Modal.defaultProps";

// =============================================================================
// Root
// =============================================================================
const Modal = ({ className = "", children, isOpen = false, onClose }) => {
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(onClose);
	};

	return createPortal(
		<AnimatePresence>
			{isOpen && (
				<div className={cn("c-modal", className)}>
					<motion.div
						className="c-modal__overlay"
						onClick={handleClose}
						variants={fade}
						initial="hidden"
						animate="show"
						exit="hidden"
					/>

					<motion.div
						className="c-modal__content"
						variants={moveYReverse}
						initial="hidden"
						animate="show"
						exit="hidden"
						transformTemplate={({ y }) => `translateY(${y})`}
					>
						{children}
					</motion.div>
				</div>
			)}
		</AnimatePresence>,
		document.body
	);
};

Modal.propTypes = propTypes.Modal;
Modal.defaultProps = defaultProps.Modal;

// =============================================================================
// Children
// =============================================================================
const Close = ({ className = "", children, onClose }) => {
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(onClose);
	};

	return (
		<Button className={cn("c-button--rounded", className)} onClick={handleClose}>
			{children}
		</Button>
	);
};

Close.propTypes = propTypes.Modal;
Close.defaultProps = defaultProps.Modal;

Modal.Close = Close;

export default Modal;
