import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { pathOr, isNil, isEmpty, find, propEq, equals } from "ramda";
import { useHistory, useLocation } from "react-router-dom";

import { SIDE } from "@/constants/rfid-tags";
import ROUTES from "@/constants/routes";

import Button from "@/components/Button";
import SliderNavigation from "@/components/SliderNavigation";
import Codification from "@/components/Codification";
import GridBlock from "@/components/GridBlock";
import SliderShoeHero from "@/components/SliderShoeHero";
import { MembershipMenu } from "@/components/Membership";
import Icon from "@/components/Icon";
import PlaceDetail from "@/components/PlaceDetail";
import ProductStyle from "@/components/ProductStyle";
import FavoritesButton from "@/components/FavoritesButton";
import Recommendations from "@/components/Recommendations";
import Image from "@/components/Image";

import { useAllShoes, useActiveShoes } from "@/store/shoes/shoes.hooks";
import { useFlippedOrientation, useOptionsModal, useLowGPU } from "@/store/ui/ui.hooks";
import { useFilterIDs } from "@/store/filters/filters.hooks";
import { useMembershipActive, useIdentity, useWelcomeRail } from "@/store/membership/membership.hooks";

import { updateVisible, clearActive, clearQty, clearPrice } from "@/store/shoes/shoes.actions";
import { removeTag } from "@/store/rfid/rfid.actions";

import useSlider from "@/hooks/Slider";
import useActiveShoe from "@/hooks/ActiveShoe";
import useGrid from "@/hooks/Grid";
import { useQuery } from "@/hooks/Router";

import detailVerticalGrid from "@/assets/grids/detailVertical.json";
import detailVerticalGridNoGrouping from "@/assets/grids/detailVerticalNoGrouping.json";
import detailVerticalGridMembership from "@/assets/grids/detailVerticalMembership.json";
import detailVerticalGridMembershipNoGrouping from "@/assets/grids/detailVerticalMembershipNoGrouping.json";

import setAnalytics from "@/utils/analytics";
import { isLangKorean } from "@/utils/i18n";
import ProductReviews from "@/components/ProductReviews";
import ProductSportPulse from "@/components/ProductSportPulse";
import ProductAvailabilityPrice from "@/components/ProductAvailabilityPrice";

const DetailVertical = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const query = useQuery();
	const isFlipped = useFlippedOrientation();
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const { toggleIsOpen } = useOptionsModal();
	const { data: allShoes, loading } = useAllShoes();
	const lowGPU = useLowGPU();
	const filterIDs = useFilterIDs();
	const { left, right } = useActiveShoes();
	const shoes = { left, right };
	const membershipActive = useMembershipActive();
	const welcomeRail = useWelcomeRail();

	const { data: identity, error: identityError } = useIdentity();

	const { isGridLoading, start: startGrid, end: endGrid, shouldRerenderGrid } = useGrid();
	const { activeShoe, isRight, isCompareMode, isDigital } = useActiveShoe();
	const {
		slider,
		currentSlide,
		initSlider,
		speed,
		handlePrev,
		handleNext,
		updateCurrentSlide,
		handleDestroy,
		direction,
	} = useSlider({ speed: 10 });

	const [slides, setSlides] = useState([]);
	const [productGroupings, setProductGroupings] = useState([]);
	const [technologies, setTechnologies] = useState([]);
	const [gridShoe, setGridShoe] = useState(null);
	const [recommendationCopy, setRecommendationCopy] = useState("");
	const [showRail, setShowRail] = useState(false);
	const [gridLayout, setGridLayout] = useState(3);

	const showStock = query.get("show-stock") || null;
	const showPrice = query.get("show-price") || null;
	const isFavoritesEnabled = equals(query.get("favorites"), "true");
	const qForm = query.get("form") || "rise";

	// #region FUNCTIONS
	const handleSetProductGroupings = (id) => {
		const side = isRight ? SIDE.Right : SIDE.Left;
		history.push({
			pathname: ROUTES.ProductGrouping,
			search: location.search,
			state: { id, side },
		});
	};

	const handleSetCurrentSlide = (i) => {
		updateCurrentSlide(i);
	};

	const handleRemove = (side) => {
		dispatch(removeTag(pathOr("", ["epc"], shoes[side])));
		dispatch(clearQty(side));
		dispatch(clearPrice(side));
		dispatch(clearActive(side));

		const otherShoe = shoes[side === SIDE.Left ? SIDE.Right : SIDE.Left];
		if (!isNil(otherShoe)) {
			dispatch(updateVisible(true, side === SIDE.Left ? SIDE.Right : SIDE.Left));
		}
	};

	const checkShowSportPulse = () => {
		if (process.env.REACT_APP_LOCATION === "china") return false;
		let performance = false;

		try {
			const category = pathOr(null, ["meta", "category"], activeShoe);
			performance = category === filterIDs.runningID; // || category === filterIDs.trainingID;
		} catch (e) {
			sentryLogException("No Performance for Language", e);
		}
		// console.log(performance, activeShoe);

		return performance;
	};
	// #endregion FUNCTIONS

	// #region LIFECYCLE HOOKS
	useEffect(() => {
		if (activeShoe && activeShoe.slides && activeShoe.slides.length > 0) {
			initSlider({ slides: activeShoe.slides.length - 1 });

			// On detail the first slide is removed, uninteresting data
			const detailSlides = [...activeShoe.slides];
			detailSlides.shift();

			setSlides(detailSlides);
		}

		return () => {
			handleDestroy();
		};
	}, [activeShoe, language]);

	useEffect(() => {
		if (gridShoe && gridShoe.recommendations.length > 0) {
			activeShoe.recommendations = gridShoe.recommendations;
		}
	}, [gridShoe]);

	useEffect(() => {
		if (isNil(activeShoe)) {
			return null;
		}

		let recommendationInterval = null;

		setAnalytics("product", { product_id: activeShoe.id, member: !isEmpty(identity) });
		setProductGroupings(pathOr([], ["productGroupings"], activeShoe));

		if (activeShoe && activeShoe.recommendations.length > 0) {
			activeShoe.tempRecommendations = activeShoe.recommendations.slice(0, 3);
		}

		if (activeShoe && activeShoe.tempRecommendations.length > 1) {
			setRecommendationCopy(activeShoe.tempRecommendations[0].copy);
			let count = 1;
			recommendationInterval = setInterval(() => {
				if (activeShoe.tempRecommendations[count])
					setRecommendationCopy(activeShoe.tempRecommendations[count].copy);
				count += 1;
				if (count === activeShoe.tempRecommendations.length) {
					count = 0;
				}
			}, 3000);
		} else if (activeShoe && activeShoe.tempRecommendations.length > 0)
			setRecommendationCopy(activeShoe.tempRecommendations[0].copy);

		return () => {
			clearInterval(recommendationInterval);
		};
	}, [activeShoe, gridShoe]);

	useEffect(() => {
		if (!isNil(activeShoe)) {
			const membershipShoe = find(propEq("id", activeShoe.id))(allShoes);
			if (!isEmpty(identity)) {
				setGridShoe(membershipShoe);
			}
		}
	}, [allShoes]);

	useEffect(() => {
		setTechnologies(productGroupings.filter((el) => el.meta.type === "technology"));
	}, [productGroupings]);

	useEffect(() => {
		if (membershipActive && !isEmpty(identity)) {
			if (!checkShowSportPulse()) setGridLayout(3);
			else setGridLayout(4);
		} else if (!checkShowSportPulse()) setGridLayout(2);
		else setGridLayout(3);
	}, [isRight, language, identity, gridShoe, activeShoe, technologies]);

	useEffect(() => {
		setShowRail(false);
		let showRailTimer = null;

		const reloadTimer = setTimeout(() => {
			endGrid();
			let grid = detailVerticalGrid;
			switch (gridLayout) {
				case 2:
					grid = detailVerticalGridNoGrouping;
					break;

				case 3:
					grid = detailVerticalGrid;
					break;

				case 4:
					grid = detailVerticalGridMembership;
					break;

				default:
					break;
			}
			startGrid(grid);
		}, 10);

		if (!isEmpty(identity)) {
			showRailTimer = setTimeout(() => {
				setShowRail(true);
				clearTimeout(showRailTimer);
			}, 1000);
		}

		return () => {
			clearTimeout(reloadTimer);
			clearTimeout(showRailTimer);
		};
	}, [gridLayout, activeShoe]);

	useEffect(() => {
		setAnalytics("detail", { member: !isEmpty(identity) });
	}, []);
	// #endregion LIFECYCLE HOOKS

	// #region RENDER
	return (
		!shouldRerenderGrid &&
		!isGridLoading && (
			<>
				<GridBlock id="PlaceSlider" className="u-flex u-col u-jc-end u-ai-center detail-slider">
					<SliderShoeHero
						code={activeShoe?.meta.color_code}
						slides={slides}
						handleSetProductGroupings={handleSetProductGroupings}
						handlePrev={handlePrev}
						handleNext={handleNext}
						currentSlide={currentSlide}
						speed={speed}
						direction={direction}
						isDetail
						vertical
						missingDetailImages={activeShoe?.missingDetailImages}
						small={activeShoe?.content.tagline.length >= 65}
					/>
					<div className="u-mb-20">
						<SliderNavigation
							setCurrentSlide={handleSetCurrentSlide}
							slides={pathOr(0, ["totalSlides"], slider)}
							step={currentSlide}
							speed={speed}
						/>
					</div>
				</GridBlock>
				<GridBlock id="PlaceContent" className="c-block--base">
					<div className="u-flex u-col u-jc-between u-ai-between u-p-22 u-fill-space">
						{isNil(activeShoe?.content.tagline) || isEmpty(activeShoe?.content.tagline) ? (
							<div className="u-min-height-90" />
						) : (
							<Codification
								className="u-max-width-300"
								align="left"
								text={activeShoe?.content.tagline}
								typography={{
									primary: true,
									size:
										activeShoe?.content.tagline.length <= 55
											? "5xl"
											: activeShoe?.content.tagline.length <= 70
											? "4xl"
											: activeShoe?.content.tagline.length <= 85
											? "3xl"
											: "2xl",
									lineHeightSize:
										activeShoe?.content.tagline.length <= 55
											? "5xl"
											: activeShoe?.content.tagline.length <= 70
											? "4xl"
											: activeShoe?.content.tagline.length <= 85
											? "3xl"
											: "2xl",
									tag: "span",
								}}
								lowGPU={lowGPU}
								characterSwitchAmount={4}
								characterNextTrigger={2}
								characterTimeout={20}
							/>
						)}
						<div className="u-flex u-row u-ai-center u-mt-12">
							<ProductAvailabilityPrice
								className="u-flex u-row u-ai-center u-mr-10 u-py-6 u-width-105"
								side={isRight ? SIDE.Right : SIDE.Left}
								isFlipped={isFlipped}
								showStock={showStock}
								showPrice={showPrice}
								lowGPU={lowGPU}
								isFavoritesEnabled={isFavoritesEnabled && !isEmpty(identity)}
							/>
							<div className="u-flex u-row u-ai-center">
								{isCompareMode && (
									<Button className="c-button--rounded u-mr-5" onClick={() => history.goBack()}>
										<Button.Text>{t("buttons.back_compare")}</Button.Text>
									</Button>
								)}
								{isDigital && (
									<Button
										onClick={() => handleRemove(isRight ? SIDE.Right : SIDE.Left)}
										className="c-button--rounded c-button--outline u-mr-5"
									>
										<Button.Text size="2xs">{t("buttons.remove_shoe")}</Button.Text>
									</Button>
								)}
								{!isEmpty(identity) && (
									<FavoritesButton
										shoe={activeShoe}
										vertical={qForm === "vertical"}
										page="detail"
										iconOnly={isDigital || isCompareMode}
										isDigital={isDigital}
										isCompareMode={isCompareMode}
									/>
								)}
							</div>
						</div>
					</div>
				</GridBlock>
				{technologies.length === 0 && (
					<GridBlock id="PlaceMiddleLeft" className="u-flex u-col u-jc-end u-ai-center">
						<Image className="c-image--no-grouping" src={activeShoe?.slides[1].image} />
					</GridBlock>
				)}
				{technologies.length > 0 && (
					<GridBlock id="PlaceMiddleLeft" className="u-flex u-col u-jc-end u-ai-center">
						<ProductStyle
							productGroupings={productGroupings}
							activeShoe={activeShoe}
							identity={identity}
							side={isRight ? SIDE.Right : SIDE.Left}
							handleSetProductGroupings={handleSetProductGroupings}
							style
						/>
					</GridBlock>
				)}
				<GridBlock
					id={
						gridLayout === 2
							? "PlaceMiddleRight"
							: gridLayout === 4
							? "PlaceMiddleLeftRight"
							: "PlaceMiddleRightLeft"
					}
					className="u-flex u-col u-jc-end u-ai-center"
				>
					<ProductReviews activeShoe={activeShoe} direction={isRight ? SIDE.Right : SIDE.Left} />
				</GridBlock>
				{checkShowSportPulse() && (
					<GridBlock
						id={membershipActive && !isEmpty(identity) ? "PlaceMiddleRightLeft" : "PlaceMiddleRight"}
						className="u-flex u-col u-jc-end u-ai-center"
					>
						<ProductSportPulse activeShoe={activeShoe} />
					</GridBlock>
				)}
				{membershipActive && !isEmpty(identity) ? (
					<GridBlock id="PlaceMiddleRight" className="u-flex u-col u-jc-end u-ai-center">
						<Recommendations
							title={
								activeShoe?.tempRecommendations.length > 0
									? t("titles.recommended_for_you")
									: t("titles.popular_with")
							}
							icons={activeShoe?.tempRecommendations}
							recommendations={
								activeShoe?.tempRecommendations.length > 0
									? recommendationCopy
									: activeShoe?.popularWith
							}
							popularWith={activeShoe?.tempRecommendations.length === 0}
						/>
					</GridBlock>
				) : null}
				<GridBlock id="DetailTopLeft" className="u-flex u-col u-jc-end u-ai-center">
					<PlaceDetail side="left" />
				</GridBlock>
				<GridBlock id="DetailTopRight" className="u-flex u-col u-jc-end u-ai-center">
					<PlaceDetail side="right" />
				</GridBlock>
				<GridBlock className="u-flex u-ai-center u-p-1" id="PlaceBottom">
					<div
						onClick={toggleIsOpen}
						onKeyDown={toggleIsOpen}
						role="button"
						tabIndex={0}
						className="options-button u-p-1"
						style={!welcomeRail ? { zIndex: 10 } : null}
					>
						<Icon className="c-icon-membership--size-9" type="options-menu" />
						<Icon className="c-icon-membership--size-9" type="caret-up" />
					</div>

					{membershipActive && !welcomeRail && (
						<div
							className="u-flex u-ai-start u-jc-start u-fill-space u-mt--20"
							style={{ height: "fit-content" }}
						>
							<MembershipMenu />
						</div>
					)}
					{membershipActive && welcomeRail && showRail && (
						<div style={{}} className="u-fill-space u-p-1">
							<MembershipMenu />
						</div>
					)}
				</GridBlock>
			</>
		)
	);
	// #endregion RENDER
};

export default DetailVertical;
