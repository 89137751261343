const Review = {
	title: "placeholder",
	description: "placeholder",
	author: "placeholder",
	date: "placeholder",
	rating: 1,
	isSmallTitle: false,
	verified: false,
	descriptionCharacterLimit: null,
};

export default {
	Review,
};
