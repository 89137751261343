const codification = {
	text: "",
	tag: "div",
	typography: {},
	reverse: false,
	start: true,
	characterSwitchAmount: 12,
	characterNextTrigger: 6,
	characterTimeout: 30,
	align: "left",
	delay: 0,
	onEnded: () => null,
	isStatic: false,
	lowGPU: false,
};

const line = {
	words: [],
	index: 0,
};

const word = {
	characters: [],
	index: 0,
	isLastWordOnLine: false,
};

const character = {
	text: "",
	index: 0,
};

export default {
	codification,
	line,
	word,
	character,
};
