import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { isNil } from "ramda";

import { toTitleCase } from "@/utils/conversions";

import Button from "@/components/Button";
import ShoeHeading from "@/components/ShoeHeading";
import Typography from "@/components/Typography";

import { useActiveShoes } from "@/store/shoes/shoes.hooks";
import { setShoesModal } from "@/store/ui/ui.actions";

import defaultProps from "./PlaceDetail.defaultProps";
import propTypes from "./PlaceDetail.propTypes";
import { useIdentity } from "@/store/membership/membership.hooks";
import { useQuery } from "@/hooks/Router";

// =============================================================================
// Root
// =============================================================================
const PlaceDetail = ({ side }) => {
	const { t } = useTranslation();
	const query = useQuery();
	const dispatch = useDispatch();
	const { left, right } = useActiveShoes();
	const { data: identity, loading } = useIdentity();
	const shoes = { left, right };
	const [hasActiveShoe, setHasActiveShoe] = useState(false);

	useEffect(() => {
		if (!isNil(shoes[side])) {
			setHasActiveShoe(true);
		}
	}, [left, right]);

	return (
		<div className="detail u-fill-space u-flex u-jc-start u-row u-ai-center u-text-c u-px-10 u-mt-8">
			{hasActiveShoe ? (
				<div className="u-row u-flex">
					<ShoeHeading className={cn("")}>
						{!isNil(shoes[side]) ? (
							<ShoeHeading.Image src={shoes[side]?.detail.assets.left.square} />
						) : null}
					</ShoeHeading>
					<Typography
						className="u-text-l u-ml-2 u-mt-3 u-max-width-65"
						primary={false}
						size="smmmd"
						weight="medium"
						lineHeightSize="mdsm"
						tag="span"
					>
						{shoes[side] ? toTitleCase(shoes[side].detail.content.name) : null}
					</Typography>
				</div>
			) : (
				<Typography
					className="u-text-l u-ml-2 u-mt-3 u-max-width-36 u-mr-20"
					primary={false}
					size="smmmd"
					weight="medium"
					lineHeightSize="smmd"
					tag="span"
				>
					{t("forms.vertical.place").replace("&", "\n")}
				</Typography>
			)}
			{isNil(shoes[side]) ? (
				<Button
					onClick={() => {
						if (!loading) dispatch(setShoesModal(true));
					}}
					className="c-button--rounded c-button--float u-mt-6"
				>
					<Button.Text size="2xs">{t("forms.vertical.placeButton")}</Button.Text>
				</Button>
			) : null}
		</div>
	);
};

PlaceDetail.propTypes = propTypes.TopBar;
PlaceDetail.defaultProps = defaultProps.TopBar;

export default React.memo(PlaceDetail);
