import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import ROUTES from "@/constants/routes";

const useRouter = () => {
	const history = useHistory();

	useEffect(() => {
		const unlisten = history.listen((location) => {
			if (location.pathname !== ROUTES.Place && location.state && location.state.grab) {
				history.replace({ pathname: location.pathname, search: location.search, state: {} });
			}
		});

		return () => {
			unlisten();
		};
	});
};

export default useRouter;
