import React, { useEffect, useState } from "react";
import { pathOr, isEmpty } from "ramda";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import cn from "classnames";
import { v4 as uuidv4 } from "uuid";
import { wrap } from "popmotion";

import Button from "@/components/Button";
import GridBlock from "@/components/GridBlock";
import ShoeHeading from "@/components/ShoeHeading";
import Review from "@/components/Review";
import ReviewStars from "@/components/ReviewStars";
import Typography from "@/components/Typography";
import Progress from "@/components/Progress";
import ProgressBar from "@/components/ProgressBar";
import ShoePickerNavigation from "@/components/ShoePickerNavigation";

import SWIPE_TRESHOLD from "@/constants/events";
import AMOUNT_PER_VIEW from "@/constants/reviews";

import { useActiveShoe } from "@/store/shoes/shoes.hooks";
import { useFlippedOrientation } from "@/store/ui/ui.hooks";
import { useIdentity } from "@/store/membership/membership.hooks";

import useGrid from "@/hooks/Grid";

import reviewsVerticalGrid from "@/assets/grids/reviewsVertical.json";

import swipePower from "@/utils/slider/swipePower";
import setAnalytics from "@/utils/analytics";

import { fadeMove, slideFull } from "@/animations";
import { setLatestAction } from "@/store/ui/ui.actions";
import { useQuery } from "@/hooks/Router";

const dayjs = require("dayjs");

const ReviewsVertical = () => {
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const query = useQuery();

	const { isGridLoading, start: startGrid, end: endGrid, shouldRerenderGrid } = useGrid();
	const { detail: activeShoe = null } = useActiveShoe() || {};
	const history = useHistory();
	const [[page, direction], setPage] = useState([0, 0]);
	const isFlipped = useFlippedOrientation();
	const { data: identity } = useIdentity();

	const qForm = query.get("form") || "rise";

	const comfortRating = pathOr(null, ["feedback", "comfort_rating"], activeShoe);
	const durabilityRating = pathOr(null, ["feedback", "durability_rating"], activeShoe);
	const sizeRating = pathOr(null, ["feedback", "size_fidelity_rating"], activeShoe);

	let reviews = pathOr([], ["feedback", "reviews"], activeShoe);
	reviews = reviews.slice(0, 40);

	const count = reviews.length;
	const totalPages = Math.ceil(count / AMOUNT_PER_VIEW);
	const pageIndex = wrap(0, totalPages, page);
	const start = pageIndex * AMOUNT_PER_VIEW;
	const end = start + AMOUNT_PER_VIEW > count ? count : start + AMOUNT_PER_VIEW;
	const formattedReviews = reviews.slice(start, end);

	const formatRating = (rating, ratingData) => (rating === null ? rating : { ...ratingData, value: rating });

	const ratings = [
		formatRating(comfortRating, {
			gridBlockId: "ReviewsTopRightTop",
			title: t("generic.performance"),
			minText: t("generic.uncomfortable"),
			maxText: t("generic.very_comfortable"),
		}),
		formatRating(durabilityRating, {
			gridBlockId: "ReviewsTopRightBottom",
			title: t("generic.durability"),
			minText: t("generic.not_durable"),
			maxText: t("generic.very_durable"),
		}),
		formatRating(sizeRating, {
			gridBlockId: "ReviewsTopLeftBottom",
			title: t("generic.size"),
			minText: t("generic.runs_small"),
			maxText: t("generic.runs_large"),
		}),
	].filter((rating) => !!rating);

	// #region FUNCTIONS
	const handlePage = (newDirection) => {
		let newPage = page + newDirection;

		if (newPage < 0) newPage = totalPages - 1;
		if (newPage > totalPages - 1) newPage = 0;

		setPage([newPage, newDirection]);
	};

	const handleDragEnd = (e, { offset, velocity }) => {
		if (count <= AMOUNT_PER_VIEW) {
			return;
		}

		let swipe = swipePower(offset.x, velocity.x);

		if (isFlipped) {
			swipe = -swipe;
		}

		if (swipe < -SWIPE_TRESHOLD) {
			handlePage(1);
		} else if (swipe > SWIPE_TRESHOLD) {
			handlePage(-1);
		}
	};
	// #endregion FUNCTIONS

	// #region LIFECYCLE HOOKS
	useEffect(() => {
		endGrid();
		startGrid(reviewsVerticalGrid);

		// Analytics
		if (activeShoe) {
			setAnalytics("product", { product_id: activeShoe.id, member: !isEmpty(identity) });
		}
	}, [activeShoe, page, direction]);

	useEffect(() => {
		setAnalytics("reviews", { member: !isEmpty(identity) });
		dispatch(setLatestAction({ type: "pageview_product_reviews", id: uuidv4(), firstScc: activeShoe?.id }));
	}, []);
	// #endregion FUNCTIONS

	// #region RENDER
	return (
		!shouldRerenderGrid &&
		!isGridLoading &&
		activeShoe && (
			<div className="reviews">
				<GridBlock className="u-flex u-ai-end" id="ReviewsTop">
					<div className="u-flex u-fill-width u-row u-mb-16 u-jc-between" style={{ height: "fit-content" }}>
						<ShoeHeading>
							<ShoeHeading.Image src={activeShoe.assets.left.square} />

							<ShoeHeading.Title
								className="u-mt-4"
								size="smmmd"
								lineHeightSize="sm"
								weight="normal"
								primary={false}
							>
								{activeShoe.content.name}
							</ShoeHeading.Title>
						</ShoeHeading>
						<div className="u-flex u-row u-ai-end">
							<ShoePickerNavigation
								className="u-flex-1 u-mr-5"
								slides={totalPages}
								speed={0}
								step={page}
								setCurrentSlide={(newPage) => {
									if (newPage > page) handlePage(1);
									else handlePage(-1);
								}}
							/>
							<div>
								<Button
									className="c-button--rounded u-flex u-ai-center u-mr-12"
									onClick={() => history.goBack()}
									icon="chevron-left"
									iconPosition="left"
								>
									{t("buttons.back")}
								</Button>
							</div>
						</div>
					</div>
				</GridBlock>

				<GridBlock id="ReviewsCenter">
					<motion.div
						drag="x"
						onDragEnd={handleDragEnd}
						dragElastic={1}
						initial="enter"
						animate="center"
						exit="exit"
						key={page}
						custom={isFlipped ? -direction : direction}
						variants={slideFull}
						transformTemplate={({ x }) => {
							const xTransform = parseFloat(x, 10);
							return `translate3d(${isFlipped ? -xTransform : xTransform}px, 0, 0)`;
						}}
						dragConstraints={{ left: 0, right: 0 }}
						className="u-flex u-fill-height u-col"
						transition={{
							x: { type: "spring", bounce: 0 },
							opacity: { duration: 0.2 },
						}}
					>
						{formattedReviews.map(
							({ heading, body, author, review_date, rating, verified_purchaser: verified }, index) => {
								const isFirst = index === 0;
								const position = index % 3;

								return (
									<div key={index}>
										<GridBlock
											id={
												position === 0
													? "ReviewsCenterTop"
													: position === 1
													? "ReviewsCenterLeft"
													: "ReviewsCenterRight"
											}
										>
											<Review
												className={cn("u-overflow-hidden u-px-12 u-pt-16 u-fill-height", {
													"u-flex-2": index === 0,
													"u-flex-1": index !== 0,
												})}
												// eslint-disable-next-line react/no-array-index-key
												key={`${review_date}-${index}`}
												isSmallTitle={!isFirst}
												title={heading}
												vertical
												size="7xl"
												description={body}
												author={author}
												date={dayjs(review_date).locale(i18n.language).format("MMMM DD, YYYY")}
												rating={rating}
												verified={verified}
												descriptionCharacterLimit={600}
												titleCharacterLimit={isFirst ? 25 : 40}
											/>
										</GridBlock>
									</div>
								);
							}
						)}
					</motion.div>
				</GridBlock>

				<GridBlock id="ReviewsTopLeftTop" className="u-flex u-col u-jc-center u-ai-start u-p-11 u-idle">
					<motion.div variants={fadeMove} initial="hidden" animate="show" className="u-mb-8">
						<Typography weight="bold" size="xs">
							{t("generic.average_rating")}
						</Typography>
					</motion.div>

					<ReviewStars rating={activeShoe.feedback.avg_rating} showLabel />
				</GridBlock>

				{ratings.map(({ gridBlockId, title, minText, maxText, value = null } = {}) => {
					return (
						<GridBlock id={gridBlockId} key={gridBlockId} className="u-flex u-jc-center u-ai-center u-idle">
							<Progress title={title} minText={minText} maxText={maxText}>
								<ProgressBar value={value} min={0} max={3} />
							</Progress>
						</GridBlock>
					);
				})}
			</div>
		)
	);
	// #endregion RENDER
};

export default ReviewsVertical;
