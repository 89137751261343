import React, { useRef, useEffect, useState } from "react";
import cn from "classnames";
import { motion } from "framer-motion";
import { pathOr } from "ramda";

import Image from "@/components/Image";
import VideoBlock from "@/components/Video";
import Icon from "@/components/Icon";

import { getSlideDelay } from "@/animations";

import moveContent from "./ShoeHero.animations";
import defaultProps from "./ShoeHero.defaultProps";
import propTypes from "./ShoeHero.propTypes";

// =============================================================================
// Root
// =============================================================================
const ShoeHero = ({ className = "", children }) => (
	<motion.div layout className={cn("c-shoe-hero", className)}>
		{children}
	</motion.div>
);

ShoeHero.propTypes = propTypes.ShoeHero;
ShoeHero.defaultProps = defaultProps.ShoeHero;

// =============================================================================
// Children
// =============================================================================
const Background = ({ className = "", src, speed, isSmall, missingDetailImages, isUgc = false }) => (
	<div className={cn("c-shoe-hero__bg", className)}>
		<div
			className={cn("c-shoe-hero__bg-inner", {
				"c-shoe-hero__bg-inner--small": isSmall,
				"c-shoe-hero__bg-ugc": isUgc,
			})}
		>
			{isSmall && <Image className="c-image--cover" src={src} />}
			{!isSmall && (
				<motion.div
					className="u-fill-space"
					variants={getSlideDelay({ duration: speed })}
					initial="initial"
					animate="animate"
				>
					<Image className={cn("c-image--cover", { "no-detail": missingDetailImages })} src={src} />
				</motion.div>
			)}
		</div>
	</div>
);

Background.propTypes = propTypes.Background;
Background.defaultProps = defaultProps.Background;

ShoeHero.Background = Background;

const Video = ({ className = "", src, speed }) => (
	<div className={cn("c-shoe-hero__bg", className)}>
		<div className="c-shoe-hero__bg-inner">
			<motion.div
				className="u-fill-space"
				variants={getSlideDelay({ duration: speed })}
				initial="initial"
				animate="animate"
			>
				<VideoBlock className="c-image--cover" videoAsset={src} />
			</motion.div>
		</div>
	</div>
);

Video.propTypes = propTypes.Video;
Video.defaultProps = defaultProps.Video;

ShoeHero.Video = Video;

const ShoeHeroIcon = ({ className = "", icon }) => (
	<Icon className={cn("c-shoe-hero__icon c-icon--size-6 c-shoe-hero__icon--default", className)} type={icon} />
);

ShoeHeroIcon.propTypes = propTypes.Icon;
ShoeHeroIcon.defaultProps = defaultProps.Icon;

ShoeHero.Icon = ShoeHeroIcon;

const Images = ({ className = "", images, speed }) => {
	const container = useRef();
	const [hasMaxWidth, setHasMaxWidth] = useState(false);

	useEffect(() => {
		if (container.current) {
			const { width } = container.current.getBoundingClientRect();
			if (width > 1920) {
				setHasMaxWidth(true);
			}
		}
	}, [container]);

	return (
		<div className={cn("c-shoe-hero__images", className)} ref={container}>
			<div className="c-shoe-hero__image-big">
				<div className="c-shoe-hero__image-inner">
					<motion.div
						className="u-fill-space"
						variants={getSlideDelay({ duration: speed })}
						initial="initial"
						animate="animate"
					>
						<Image className="c-image--cover" src={pathOr(null, [0], images)} />
					</motion.div>
				</div>

				<ShoeHero.Icon className="c-shoe-hero__icon--big" icon="instagram" />
			</div>
			<div
				className={cn("c-shoe-hero__image-small", {
					"c-shoe-hero__image-small--max": hasMaxWidth,
				})}
			>
				<div className="c-shoe-hero__image-inner">
					<motion.div
						className="u-fill-space"
						variants={getSlideDelay({ duration: speed })}
						initial="initial"
						animate="animate"
					>
						<Image className="c-image--cover" src={pathOr(null, [1], images)} />
					</motion.div>
				</div>
				<ShoeHero.Icon className="c-shoe-hero__icon--small" icon="instagram" />
			</div>
		</div>
	);
};

Images.propTypes = propTypes.Images;
Images.defaultProps = defaultProps.Images;

ShoeHero.Images = Images;

const Heading = ({ className = "", children }) => {
	const headingRef = useRef();
	const timeoutRef = useRef();
	const [widthClass, setWidthClass] = useState();

	useEffect(() => {
		if (headingRef.current) {
			timeoutRef.current = setTimeout(() => {
				if (headingRef.current) {
					const { height } = headingRef.current.getBoundingClientRect();

					if (height > 325) {
						setWidthClass("c-shoe-hero__heading--full");
					} else if (height > 300) {
						setWidthClass("c-shoe-hero__heading--large");
					} else if (height > 275) {
						setWidthClass("c-shoe-hero__heading--medium");
					}
				}
			}, 100);
		}

		return () => {
			clearTimeout(timeoutRef.current);
		};
	}, [headingRef.current]);

	return (
		<div className={cn("c-shoe-hero__heading", widthClass, className)} ref={headingRef}>
			{children}
		</div>
	);
};

ShoeHero.Heading = Heading;

const Content = ({ className = "", children, animation }) => (
	<motion.div className={cn("c-shoe-hero__content", className)} variants={moveContent} animate={animation}>
		{children}
	</motion.div>
);

ShoeHero.Content = Content;
Content.propTypes = propTypes.Content;
Content.defaultProps = defaultProps.Content;

export default ShoeHero;
