const Modal = {
	isOpen: false,
	onClose: () => {},
};

const Close = {
	onClose: () => {},
};

export default {
	Modal,
	Close,
};
