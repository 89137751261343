import { setFlippedOrientation } from "@/store/ui/ui.actions";

import RFIDActionTypes from "./rfid.action-types";

export const setOrientation = (data) => (dispatch) => {
	dispatch({
		type: RFIDActionTypes.SET_ORIENTATION,
		data,
	});
};

export const setIsSession = (data) => (dispatch) => {
	dispatch({
		type: RFIDActionTypes.SET_IS_SESSION,
		data,
	});
};

export const setInteractionLock = (data) => (dispatch) => {
	dispatch({
		type: RFIDActionTypes.SET_INTERACTION_LOCK,
		data,
	});
};

export const setIsInteraction = (data) => (dispatch) => {
	dispatch({
		type: RFIDActionTypes.SET_IS_INTERACTION,
		data,
	});
};

export const updateTags = (data, id) => (dispatch) => {
	dispatch({
		type: RFIDActionTypes.UPDATE_TAGS,
		id,
		data,
	});
};

export const removeTag = (id) => (dispatch) => {
	dispatch({
		type: RFIDActionTypes.REMOVE_TAG,
		id,
	});
};

export const clearTags = () => (dispatch) => {
	dispatch({
		type: RFIDActionTypes.CLEAR_TAGS,
	});
};

export const set404List = (data) => (dispatch) => {
	dispatch({
		type: RFIDActionTypes.SET_404_LIST,
		data,
	});
};

export const addSCCTo404List = (data) => (dispatch) => {
	dispatch({
		type: RFIDActionTypes.ADD_SCC_TO_404_LIST,
		data,
	});
};

export const addEPCTo404List = (data) => (dispatch) => {
	dispatch({
		type: RFIDActionTypes.ADD_EPC_TO_404_LIST,
		data,
	});
};
