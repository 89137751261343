import React, { memo } from "react";
import cn from "classnames";
import { motion } from "framer-motion";

import { getScaleX, fadeMoveReverse, getFade } from "@/animations";

import propTypes from "./SliderNavigation.propTypes";
import defaultProps from "./SliderNavigation.defaultProps";

// =============================================================================
// Root
// =============================================================================
const SliderNavigation = ({ className = "", slides, step, speed, setCurrentSlide }) => {
	const slideItems = [];

	for (let i = 0; i < slides; i += 1) {
		slideItems.push(
			<SliderNavigationItem
				key={`slide-navigation-${i}`}
				setCurrentSlide={setCurrentSlide}
				i={i}
				step={step}
				speed={speed}
			/>
		);
	}

	return (
		<motion.div
			variants={getFade({ staggerChildren: 0.06 })}
			initial="show"
			animate="show"
			className={cn("c-slider-nav", className)}
		>
			{slideItems}
		</motion.div>
	);
};

SliderNavigation.propTypes = propTypes.SliderNavigation;
SliderNavigation.defaultProps = defaultProps.SliderNavigation;

const SliderNavigationItem = ({ speed, i, step, setCurrentSlide }) => {
	return (
		<motion.div variants={fadeMoveReverse} className="c-slider-nav__item" onClick={() => setCurrentSlide(i)}>
			<div className="c-slider-nav__bg" />

			{step > i && <div className="c-slider-nav__bar" />}
			{step === i && (
				<motion.div
					className="c-slider-nav__bar"
					variants={speed > 0 ? getScaleX({ ease: "linear", duration: speed }) : {}}
					initial="show"
					animate="show"
				/>
			)}
			{step < i && <div className="c-slider-nav__bar is-hidden" />}
		</motion.div>
	);
};

SliderNavigationItem.propTypes = propTypes.SliderNavigationItem;
SliderNavigationItem.defaultProps = defaultProps.SliderNavigationItem;

export default memo(SliderNavigation);
