const Button = {
	type: "button",
	icon: "chevron-right",
	onClick: null,
	url: null,
};

export default {
	Button,
};
