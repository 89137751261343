import React, { useContext } from "react";

import Context from "./Codification.context";
import Word from "./CodificationWord.component";
import defaultProps from "./Codification.defaultProps";
import propTypes from "./Codification.propTypes";

const CodificationLine = ({ words, index }) => {
	const { text, lines } = useContext(Context);
	const isLastLine = lines.length - 1 === index;
	const isOnlyLine = lines.length === 1;
	const startIndex = lines
		.slice(0, index + 1)
		.reduce((total, line, lineIndex) => (lineIndex < index ? total + line.length : total), 0);

	return (
		<>
			{words.map((word, wordIndex) => {
				const totalIndex = startIndex + wordIndex;

				// eslint-disable-next-line react/no-array-index-key
				return (
					<Word
						key={`codification-word-${text}-${totalIndex}`}
						characters={word}
						index={totalIndex}
						isLastWordOnLine={wordIndex === words.length - 1}
					/>
				);
			})}

			{!isLastLine && !isOnlyLine && <br />}
		</>
	);
};

CodificationLine.defaultProps = defaultProps.line;
CodificationLine.propTypes = propTypes.line;

export default CodificationLine;
