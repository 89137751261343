// INFO: Used structure in the filters state:
// technology: { -> key of the filter
// 	activeFilter: "", -> currently active option
// 	label: "", -> Used when no option is selected
// 	options: [], -> An array of strings with all the filter options
// }
const FiltersInitialState = () => ({
	all: {},
	activeFilters: { gender: {}, category: {}, designed_for: {}, surface: {}, activity: {} },
	ids: { gender: {}, kingdom: {}, category: {}, designed_for: {}, surface: {}, activity: {} },
	loading: false,
	error: null,
});

export default FiltersInitialState;
