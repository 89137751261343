import React, { useEffect } from "react";
import { find, pathOr, propEq, replace, isNil } from "ramda";

import { useQuery } from "@/hooks/Router";
import useGrid from "@/hooks/Grid";
import { useLowGPU } from "@/store/ui/ui.hooks";

import technology1Grid from "@/assets/grids/technology_1.json";

import propTypes from "./Technology.propTypes";
import defaultProps from "./Technology.defaultProps";

import {
	ProductGroupingPartialsTitle,
	ProductGroupingPartialsDescription,
	ProductGroupingPartialsBack,
	ProductGroupingPartialsLogo,
	ProductGroupingPartialsImage,
	ProductGroupingPartialsVideo,
} from "../Partials";

const Technology1 = ({ data = {}, lang }) => {
	const query = useQuery();

	const { isGridLoading, start: startGrid, end: endGrid, shouldRerenderGrid } = useGrid();
	const lowGPU = useLowGPU();
	const assets = pathOr(null, ["assets"], data);
	const hasVideo = Boolean(find(propEq("media_type", "video"), assets));
	const qCdnUrl = query.get("cdn-url") || null;

	let videoUrl = "";
	if (hasVideo) {
		videoUrl = find(propEq("media_type", "video"), assets).video_url;
		if (!isNil(qCdnUrl)) videoUrl = replace("https://res.cloudinary.com/", qCdnUrl, videoUrl);
		videoUrl = replace(/\/upload\//, `/upload/c_fill,h_437,w_707,g_auto/`, videoUrl);
	}
	let topLeft = pathOr(null, ["assets", 0, "portrait"], data);
	let topRight = pathOr(null, ["assets", 1, "portrait"], data);
	let bottom = pathOr(null, ["assets", 2, "portrait"], data);
	let logo = null;

	if (
		find((img) => /top_left/.test(img.portrait), assets) &&
		(find((img) => /top_right/.test(img.portrait), assets) || find((img) => /right/.test(img.portrait), assets)) &&
		find((img) => /bottom/.test(img.portrait), assets) &&
		find((img) => /logo/.test(img.portrait), assets)
	) {
		const right =
			find((img) => /top_right/.test(img.portrait), assets) || find((img) => /right/.test(img.portrait), assets);
		topLeft = find((img) => /top_left/.test(img.portrait), assets).portrait;
		topRight = right.portrait;
		bottom = find((img) => /bottom/.test(img.portrait), assets).portrait;
		logo = find((img) => /logo/.test(img.portrait), assets).portrait;
	}

	// #region LIFECYCLE HOOKS
	useEffect(() => {
		endGrid();
		startGrid(technology1Grid);
	}, [isGridLoading]);
	// #endregion LIFECYCLE HOOKS

	// #region RENDER
	return (
		!shouldRerenderGrid &&
		!isGridLoading && (
			<>
				<ProductGroupingPartialsTitle id="TechnologyRightTop" data={data} lang={lang} lowGPU={lowGPU} />

				<ProductGroupingPartialsDescription id="TechnologyRightBottom" data={data} />

				<ProductGroupingPartialsBack id="TechnologyNavA" />

				<ProductGroupingPartialsLogo id="TechnologyCenterMiddle" data={data} lowGPU={lowGPU} src={logo} />

				{hasVideo ? (
					<ProductGroupingPartialsVideo id="TechnologyLeftPrimary" src={videoUrl} shouldShowBottom />
				) : (
					<ProductGroupingPartialsImage id="TechnologyLeftPrimary" src={topLeft} shouldShowBottom />
				)}

				<ProductGroupingPartialsImage id="TechnologyRightCorner" src={topRight} shouldShowBottom delay={0.15} />

				<ProductGroupingPartialsImage id="TechnologyNavB" src={bottom} delay={0.3} />

				<ProductGroupingPartialsBack id="TechnologyNavA" />
			</>
		)
	);
	// #endregion RENDER
};

Technology1.propTypes = propTypes;
Technology1.defaultProps = defaultProps;

export default Technology1;
