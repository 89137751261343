import React, { useRef, useEffect, useState } from "react";
import cn from "classnames";

import setAnalytics from "@/utils/analytics";

import videoAsset from "@/assets/videos/attract-loop.webm";
import videoAssetVertical from "@/assets/videos/attract-loop-vertical.mp4";

import defaultProps from "./AttractLoop.defaultProps";
import propTypes from "./AttractLoop.propTypes";
import { useQuery } from "@/hooks/Router";

// =============================================================================
// Root
// =============================================================================
const AttractLoop = ({ isVisible = false, className = "", isBlurred = false }) => {
	const query = useQuery();

	const video = useRef(null);
	const time = useRef(0);
	const timer = useRef(0);

	const [isFlipped, setIsFlipped] = useState(false);

	const qForm = query.get("form") || "rise";

	const handleVideoEnded = () => {
		if (qForm !== "vertical") setIsFlipped((prevState) => !prevState);
		video.current.play();
	};

	useEffect(() => {
		const $el = video.current;

		if ($el) {
			video.current.addEventListener("ended", handleVideoEnded);
		}

		timer.current = setInterval(() => {
			time.current += 1;
		}, 1000);

		return () => {
			if ($el) {
				$el.removeEventListener("ended", handleVideoEnded);

				clearInterval(timer.current);
				setAnalytics("attract_loop", { dwell_time_seconds: time.current });
			}
		};
	}, []);

	useEffect(() => {
		const $el = video.current;

		if ($el) {
			if (isFlipped) {
				$el.style.transform = "rotate(180deg)";
			}

			if (!isFlipped) {
				$el.style.transform = "rotate(0deg)";
			}
		}
	}, [isFlipped]);

	return (
		<div
			className={cn(
				"u-fill-space a-attract-loop",
				className,
				isVisible && "a-attract-loop--visible",
				isBlurred && "blurred"
			)}
		>
			<video
				ref={video}
				className="u-fill-space"
				autoPlay
				muted
				playsInline
				src={qForm === "vertical" ? videoAssetVertical : videoAsset}
			/>
		</div>
	);
};

AttractLoop.propTypes = propTypes.AttractLoop;
AttractLoop.defaultProps = defaultProps.AttractLoop;

export default AttractLoop;
